import { HOME } from '../actions/home';

export default (state = {}, action) => {
  switch (action.type) {
    case HOME.PAGE_LOAD:
      return { ...state, type: action.type };
    case HOME.REGISTER_FREE_PLAN:
      return {
        ...state,
        inProgressRegisterFreePlan: false,
        success: !action.error && action.payload && action.payload.message,
        type: action.type
      };
    case HOME.JOIN_MAILLING:
      return {
        ...state,
        inProgressJoin: false,
        success: !action.error && action.payload && action.payload.message,
        type: action.type
      };
    case HOME.SUBMIT_QUESTION:
      return {
        ...state,
        inProgressSubmitQuestion: false,
        success: !action.error && action.payload && action.payload.message,
        type: action.type
      };
    case HOME.ASYNC_START:
      switch (action.subtype) {
        case HOME.REGISTER_FREE_PLAN:
          return { ...state, inProgressRegisterFreePlan: true, type: action.type + '_' + action.subtype };
        case HOME.JOIN_MAILLING:
          return { ...state, inProgressJoin: true, type: action.type + '_' + action.subtype };
        case HOME.SUBMIT_QUESTION:
          return { ...state, inProgressSubmitQuestion: true, type: action.type + '_' + action.subtype };
      }
      break;
    case HOME.PAGE_UNLOAD:
      return {};
    default:
      return state;
  }
  return state;
};
