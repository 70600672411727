import React, { useEffect, Fragment, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import ReactGA from 'react-ga';
import _ from 'lodash';
import { Menu, Popover, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  UserCircleIcon,
  ArrowLeftOnRectangleIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
  Cog6ToothIcon,
  DevicePhoneMobileIcon,
  DocumentMinusIcon,
} from '@heroicons/react/24/outline';
import { URL_PATH, ENV, LIMIT_NOTIFICATION_TOPBAR } from '../../constants';
import EnvClient from '../../../env.json';
import SubmitQuestionContainer from '../../containers/sites/SubmitQuestionContainer';
import { messageByType, getNotificationIconByType } from '../../helpers';
import Logo from '../../styles/images/logo.png';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function HeaderPage({
  isAuthenticated,
  currentUser,
  location,
  navigation,
  onLogout,
  getNotifications,
  notifications,
  readAllNotifications,
  models
}) {
  const userNavigation = [
    { name: 'Profile', href: '/my-profile', icon: UserCircleIcon },
    { name: 'Contact', href: URL_PATH.CONTACT.PATH, icon: PhoneIcon },
    { name: 'FAQs', href: URL_PATH.FAQ.PATH, icon: QuestionMarkCircleIcon },
    { name: 'Privacy', href: URL_PATH.PRIVACY.PATH, icon: Cog6ToothIcon },
    { name: 'Terms', href: URL_PATH.TERMS.PATH, icon: DocumentMinusIcon },
    {
      name: 'App',
      href: 'https://apps.apple.com/app/keystack/id1441632592',
      icon: DevicePhoneMobileIcon,
    },
    {
      type: 'button',
      name: 'Sign out',
      href: '#',
      onClick: onLogout,
      icon: ArrowLeftOnRectangleIcon,
    },
  ];

  const [openNewQuestion, setOpenNewQuestion] = useState(false);
  const [readAllNotification, setReadAllNotification] = useState(false);
  const [isNewNotification, setIsNewNotification] = useState(false);

  useEffect(() => {
    getNotifications({ page: 1 });
    const envType = process.env.REACT_ENV || ENV.DEVELOPMENT;
    const googleAnalyticsId = EnvClient[envType].GOOGLE_ANALYTICS_ID;
    ReactGA.initialize(googleAnalyticsId);
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (notifications.length) {
      const noReadNotifications = _.filter(notifications, { is_read: false });
      if (noReadNotifications.length) {
        setIsNewNotification(true);
      }
    }
  }, [notifications]);

  useEffect(() => {
    if (readAllNotification) {
      readAllNotifications();
      setIsNewNotification(false);
    }
  }, [readAllNotification]);

  const onReadAllNotification = () => {
    if (notifications.length) {
      const noReadNotifications = _.filter(notifications, { is_read: false });
      if (noReadNotifications.length) {
        setReadAllNotification(true);
      }
    }
  };
   const getRedirectLink = (link)=>{
    switch (link) {
      case URL_PATH?.MODEL_DETAIL.PATH:
        return `${URL_PATH?.MODEL_DETAIL.PATH}/${models[0]?.slug}`
      case URL_PATH.MY_COLLECTION.PATH:
      case URL_PATH.ASK_AI.PATH:
      return  !isAuthenticated ?  `${URL_PATH.JOIN_WITH_US.PATH}?${link.replace(/\//g, '')}`: link
      default:
        return link
    }
  }


  return (
    <>
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? 'fixed inset-0 z-40 overflow-y-auto no-scrollbar' : '',
            'bg-keystack-bg shadow-sm lg:static lg:overflow-y-visible border border-y-neutral-200'
          )
        }
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="relative flex justify-between lg:gap-8 h-16">
                <div className="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2">
                  <div className="flex flex-shrink-0 items-center">
                    <Link to={URL_PATH.HOME.PATH}>
                      <img
                        className="block h-5 w-auto"
                        src={Logo}
                        alt="Answer"
                      />
                    </Link>
                  </div>
                </div>
                <div className="flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden">
                  {/* Mobile menu button */}
                  {isAuthenticated && (
                    <div className="flex-shrink-0 mr-4">
                        <img
                          className="h-10 w-10 rounded-full object-cover"
                          src={currentUser?.profile_image}
                          alt=""
                        />
                      </div>
                    )}
                  <Popover.Button className="-mx-2 inline-flex items-center justify-center rounded-xl p-2 text-neutral-500 hover:bg-neutral-100 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-neutral-400">
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Popover.Button>
                </div>
                {isAuthenticated ? (
                  <div className="hidden lg:flex lg:items-center lg:justify-end">
                    <Link
                      reloadDocument
                      className="px-4 py-2 text-base font-medium text-neutral-900 hover:text-neutral-600 hover:no-underline"
                      to={URL_PATH.ABOUT.PATH}
                    >
                      {URL_PATH.ABOUT.TITLE}
                    </Link>
                    <Link
                    reloadDocument
                      className="px-4 py-2 text-base font-medium text-neutral-900 hover:text-neutral-600 hover:no-underline"
                      to={URL_PATH.COMING_SOON.PATH}
                    >
                      {URL_PATH.COMING_SOON.TITLE}
                    </Link>
                    <Link
                      reloadDocument
                      className="px-4 py-2 text-base font-medium text-neutral-900 hover:text-neutral-600 hover:no-underline"
                      to={URL_PATH.PRICING.PATH}
                    >
                      {URL_PATH.PRICING.TITLE}
                    </Link>
                    <Menu
                      as="notification"
                      className="relative ml-3 flex-shrink-0"
                    >
                      {({ open: isOpen }) => {
                        if (isOpen) {
                          onReadAllNotification();
                        }

                        return (
                          <>
                            <div>
                              <Menu.Button className="relative flex rounded-full bg-white text-neutral-500 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2">
                                <BellIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                                {isNewNotification && (
                                  <span className="absolute right-1 relative flex h-2 w-2">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75" />
                                    <span className="relative inline-flex rounded-full h-2 w-2 bg-sky-500" />
                                  </span>
                                )}
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-96 origin-top-right rounded-xl bg-white py-1 shadow-lg ring-1 ring-neutral-400 ring-opacity-5 focus:outline-none">
                                {notifications?.length === 0 && (
                                  <p className="py-3 text-sm font-light text-center text-neutral-500">
                                    No items to display
                                  </p>
                                )}
                                {notifications.map((item, idx) => {
                                  if (idx >= LIMIT_NOTIFICATION_TOPBAR) {
                                    return null;
                                  }
                                  const {
                                    id,
                                    type,
                                    object: { title, slug },
                                    actor: {
                                      first_name: firstName,
                                      last_name: lastName,
                                    },
                                    created_at: createdAt,
                                  } = item;
                                  const userName = `${firstName} ${lastName}`;
                                  return (
                                    <Menu.Item key={id}>
                                      {({ active }) => (
                                        <Link
                                          to={`/answers/${slug}`}
                                          className={classNames(
                                            active ? 'bg-neutral-100' : '',
                                            'block w-full p-2 text-sm font-light text-left text-neutral-900 hover:no-underline hover:bg-neutral-100 flex justify-center'
                                          )}
                                        >
                                          {getNotificationIconByType(type)}
                                          <p>
                                            {messageByType(
                                              type,
                                              title,
                                              true,
                                              userName
                                            )}{' '}
                                            <br />
                                            <span className="text-xs text-neutral-500">
                                              {moment(createdAt).fromNow()}
                                            </span>
                                          </p>
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  );
                                })}
                                {notifications?.length >=
                                  LIMIT_NOTIFICATION_TOPBAR && (
                                  <Menu.Item>
                                    <div className="py-1 text-center">
                                      <Link
                                        to={URL_PATH.NOTIFICATIONS.PATH}
                                        className="text-xs font-light text-center text-neutral-900 hover:underline"
                                      >
                                        See more
                                      </Link>
                                    </div>
                                  </Menu.Item>
                                )}
                              </Menu.Items>
                            </Transition>
                          </>
                        );
                      }}
                    </Menu>

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-6 flex-shrink-0">
                      <div>
                        <Menu.Button className="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={currentUser?.profile_image}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-xl bg-white pt-1 shadow-lg ring-1 ring-neutral-400 ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <>
                                  {item.type ? (
                                    <button
                                      type="button"
                                      className={classNames(
                                        active ? 'bg-neutral-100' : '',
                                        'flex items-center w-full py-2 px-4 text-sm text-left text-neutral-700 hover:no-underline hover:bg-neutral-100'
                                      )}
                                      onClick={item.onClick}
                                    >
                                      <item.icon
                                        className="text-neutral-900 opacity-60 group-hover:text-accent-hover col-span-1 flex-shrink-0 -ml-1 mr-2 h-8 w-8 p-1 bg-accent-iconbg rounded-full"
                                        aria-hidden="true"
                                      />
                                      <span className="truncate">
                                        {item.name}
                                      </span>
                                    </button>
                                  ) : (
                                    <Link
                                      to={item.href}
                                      className={classNames(
                                        active ? 'bg-neutral-100' : '',
                                        'flex items-center w-full py-2 px-4 text-sm text-left text-neutral-700 hover:no-underline hover:bg-neutral-100'
                                      )}
                                      onClick={item.onClick}
                                    >
                                      <item.icon
                                        className="text-neutral-900 opacity-60 group-hover:text-accent-hover col-span-1 flex-shrink-0 -ml-1 mr-2 h-8 w-8 p-1 bg-accent-iconbg rounded-full"
                                        aria-hidden="true"
                                      />
                                      <span className="truncate">
                                        {item.name}
                                      </span>
                                    </Link>
                                  )}
                                </>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <button
                      type="button"
                      className="ml-6 inline-flex items-center rounded-full bg-accent-default px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-accent-hover focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2 hover:no-underline"
                      onClick={() => setOpenNewQuestion(true)}
                    >
                      New Question
                    </button>
                  </div>
                ) : (
                  <div className="hidden lg:flex lg:items-center lg:justify-end">
                    <Link
                      className="px-4 py-2 text-base font-medium text-neutral-900 hover:text-neutral-600 hover:no-underline"
                      to={URL_PATH.ABOUT.PATH}
                    >
                      {URL_PATH.ABOUT.TITLE}
                    </Link>
                    <Link
                      className="px-4 py-2 text-base font-medium text-neutral-900 hover:text-neutral-600 hover:no-underline"
                      to={URL_PATH.COMING_SOON.PATH}
                    >
                      {URL_PATH.COMING_SOON.TITLE}
                    </Link>
                    <Link
                      className="px-4 py-2 text-base font-medium text-neutral-900 hover:text-neutral-600 hover:no-underline"
                      to={URL_PATH.PRICING.PATH}
                    >
                      {URL_PATH.PRICING.TITLE}
                    </Link>
                    <Link
                      to={URL_PATH.LOGIN.PATH}
                      className="ml-6 inline-flex items-center rounded-full bg-accent-default px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-accent-hover focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2 hover:no-underline"
                    >
                      Login
                    </Link>
                  </div>
                )}
              </div>
            </div>

            {/* Profile responsive */}
            <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="mx-auto max-w-3xl space-y-1 px-2 pt-2 pb-3 sm:px-4">
                {navigation.map((item) => 
                   (
                    <Link
                      reloadDocument
                      key={item.name}
                      to={item.href === URL_PATH?.MODEL_DETAIL.PATH ? `${URL_PATH?.MODEL_DETAIL.PATH}/${models[0]?.slug}` : getRedirectLink(item.href)}
                      aria-current={item.current ? 'page' : undefined}
                      className={classNames(
                        item.current
                          ? 'bg-neutral-100 text-neutral-900'
                          : 'hover:bg-neutral-50',
                        'block rounded-xl py-2 px-3 text-base font-medium'
                      )}
                    >
                      {item.name}
                    </Link>
                  )
                )}
                <Link
                  reloadDocument
                  className="block rounded-xl py-2 px-3 text-base font-medium hover:bg-neutral-50"
                  to={URL_PATH.ABOUT.PATH}
                >
                  {URL_PATH.ABOUT.TITLE}
                </Link>
                <Link
                  reloadDocument
                  className="block rounded-xl py-2 px-3 text-base font-medium hover:bg-neutral-50"
                  to={URL_PATH.COMING_SOON.PATH}
                >
                  {URL_PATH.COMING_SOON.TITLE}
                </Link>
                <Link
                  reloadDocument
                  className="block rounded-xl py-2 px-3 text-base font-medium hover:bg-neutral-50"
                  to={URL_PATH.PRICING.PATH}
                >
                  {URL_PATH.PRICING.TITLE}
                </Link>
              </div>
              {isAuthenticated ? (
                <>
                  <div className="border-t border-neutral-200 pt-4">
                    <div className="mx-auto flex max-w-3xl items-center px-4 sm:px-6">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full object-cover"
                          src={currentUser?.profile_image}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-neutral-800">{`${currentUser?.first_name} ${currentUser?.last_name}`}</div>
                        <div className="text-sm font-medium text-neutral-500">
                          {currentUser?.email}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-neutral-500 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2"
                      >
                        <span className="sr-only">View notifications</span>
                        <Link to={URL_PATH.NOTIFICATIONS.PATH}>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </Link>
                      </button>
                    </div>
                    <div className="mt-3 px-4 sm:px-6">
                      <div className="space-y-1">
                        {userNavigation.map((item) => (
                          <>
                            {item.type ? (
                              <button
                                type="button"
                                key={item.name}
                                onClick={item.onClick}
                                className="block w-full rounded-xl py-2 px-3 text-base font-medium text-left text-neutral-500 hover:bg-neutral-50 hover:text-neutral-900"
                              >
                                {item.name}
                              </button>
                            ) : (
                              <Link
                                reloadDocument
                                key={item.name}
                                to={item.href}
                                className="block rounded-xl py-2 px-3 text-base font-medium text-neutral-500 hover:bg-neutral-50 hover:text-neutral-900"
                              >
                                {item.name}
                              </Link>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="mx-auto mt-6 max-w-3xl px-4 sm:px-6">
                    <button
                      type="button"
                      className="flex w-full items-center justify-center rounded-full bg-accent-default px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-accent-hover hover:no-underline"
                      onClick={() => setOpenNewQuestion(true)}
                    >
                      New Question
                    </button>
                  </div>
                </>
              ) : (
                <div className="mx-auto mt-2 max-w-3xl px-4 pt-4 sm:px-6 border-t border-neutral-200 flex flex-col">
                  <Link
                    className="flex w-full items-center justify-center rounded-full bg-accent-default px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-accent-hover hover:no-underline"
                    to={URL_PATH.LOGIN.PATH}
                  >
                    Login
                  </Link>
                </div>
              )}

              <div className="flex flex-wrap justify-center px-4 py-2 md:p-2 text-sm font-light text-neutral-600">
                <Link reloadDocument to={URL_PATH.CONTACT.PATH} className="hover:underline">
                  Contact
                </Link>
                <span className="mx-3">·</span>
                <Link reloadDocument to={URL_PATH.FAQ.PATH} className="hover:underline">
                  FAQs
                </Link>
                <span className="mx-3">·</span>
                <Link reloadDocument to={URL_PATH.PRIVACY.PATH} className="hover:underline">
                  Privacy
                </Link>
                <span className="mx-3">·</span>
                <Link reloadDocument to={URL_PATH.TERMS.PATH} className="hover:underline">
                  Terms
                </Link>
                <span className="mx-3">·</span>
                <Link
                  reloadDocument
                  to="https://apps.apple.com/app/keystack/id1441632592"
                  target="_blank"
                  className="hover:underline"
                >
                  App
                </Link>
              </div>
              <div className="mt-6 flex justify-center" />
            </Popover.Panel>
          </>
        )}
      </Popover>
      {openNewQuestion && (
        <SubmitQuestionContainer onClose={() => setOpenNewQuestion(false)} />
      )}
    </>
  );
}

export default withRouter(HeaderPage);
