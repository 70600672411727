import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Menu, Transition } from '@headlessui/react'
import _ from 'lodash';
import { COMMON } from '../../actions';
import agent from '../../agent';
import ModelButton from '../model/ModelButton'

function RealtimeAnswerCard({  item,
  question,
  toggleModelDialog,
  currentUser,
  toggleEditDialog,
  onDelete}) {
  return (
     <li key={item.id} className="bg-white px-4 py-6 sm:rounded-xl sm:p-6 shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] m-2">
      <article aria-labelledby={`question-title-${item.id}`}>
        <div>
         
            <p id={`question-title-${item.id}`} className="text-base font-medium text-neutral-900">
              <p className="mt-3">{question}</p>
            </p>
          
        </div>
        <div className="mt-1 flex flex-wrap items-center">
          {item.models.models && !_.isEmpty(item.models.models) &&item.models.models.map((model) => (
            <ModelButton key={model.id} model={model} toggleModelDialog={() => toggleModelDialog(model.id)} />
          ))}
        </div>
        <div
          className="mt-1 space-y-4 text-sm text-neutral-700 font-light"
        >
          <p className="whitespace-pre-line">{item.answer}</p></div>
        <div className="mt-2 flex justify-between space-x-8">
          {currentUser && currentUser.id === item.user && (
            <div className="flex flex-shrink-0 self-center">
              <div className="relative inline-block text-left">
                <Menu as="div" className="relative ml-6 flex-shrink-0">
                  <div>
                    <Menu.Button className="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2">
                      <span className="sr-only">Open options</span>
                      <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                      </svg>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-5 bottom-0 z-10 mt-2 w-20 origin-top-right rounded-xl bg-white py-1 shadow-lg ring-1 ring-neutral-400 ring-opacity-5 focus:outline-none">
                      <Menu.Item as="div">
                        <button
                          type="button"
                          className="block w-full py-2 px-4 text-sm text-left text-neutral-700 hover:no-underline hover:bg-neutral-100"
                          onClick={() => toggleEditDialog(item)}
                        >Edit</button>
                        <button
                          type="button"
                          className="block w-full py-2 px-4 text-sm text-left text-red-700 hover:no-underline hover:bg-neutral-100"
                          onClick={() => onDelete(item.id)}
                        >Delete</button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          )}
        </div>
      </article>
    </li>
  )
}

const mapDispatchToProps = (dispatch, props) => {
  const { actionPrefix } = props
  return {
    respectKeystack: (data, callback) => dispatch({
      type: actionPrefix.RESPECT,
      payload: agent.KeyStack.respectKeyStack(data.id),
      callback,
    }),
    onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
  };
};

function mapStateToProps(state) {
  return {
    currentUser: state.common && state.common.currentUser,
    isAuthenticated: state.common?.isAuthenticated,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RealtimeAnswerCard);