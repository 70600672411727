import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

function SlickSlide(props) {
  const { list, keyItem, className = '', onClick, selected, settings } = props;
  return (
    <Slider className={`row m-0 ${className}`} {...settings}>
      {Array.isArray(list) &&
        list.map((item, index) => {
          return (
            <div
              className={`col text-center slide-item ${index === selected &&
                'selected'}`}
              key={index}
              onClick={() => onClick(item, index)}
            >
              <p>{item[keyItem]}</p>
            </div>
          );
        })}
    </Slider>
  );
}

SlickSlide.propTypes = {
  list: PropTypes.array,
  onClick: PropTypes.func,
  className: PropTypes.string,
  selected: PropTypes.any,
  settings: PropTypes.object.isRequired,
  keyItem: PropTypes.string
};

SlickSlide.defaultProps = {
  list: [],
  onClick: () => null,
  className: '',
  selected: null,
  keyItem: 'name'
};

export default SlickSlide;
