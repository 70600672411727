/* eslint-disable import/prefer-default-export */
export const KEYSTACKAI = {
  PAGE_LOAD: 'PAGE_LOAD_KEYSTACK',
  PAGE_UNLOAD: 'PAGE_UNLOAD_KEYSTACK',
  ASYNC_START: 'ASYNC_START',
  ASYNC_END: 'ASYNC_END',
  GET_QUESTION_GROUP: 'GET_QUESTION_GROUP',
  GET_QUESTION_TRENDING: 'GET_QUESTION_TRENDING',
  GET_KEYSTACK_BY_ID: 'GET_KEYSTACK_BY_ID',
  GET_AI_KEYSTACK: 'GET_AI_KEYSTACK_KEYSTACK',
  GET_MORE_AI_KEYSTACK: 'GET_MORE_AI_KEYSTACK_KEYSTACK',
  SUBMIT_QUESTION: 'SUBMIT_QUESTION',
  GET_MODEL_BY_ANSWER: 'GET_MODEL_BY_ANSWER',
  ANNOUNCED: 'ANNOUNCED',
};
