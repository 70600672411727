const styles = theme => ({
  dialogPaper: {
    overflowY: 'visible',
    margin: 20,
    width: '100%',
    marginTop: 50,
  },
  dialogPaperAnnounce: {
    overflowY: 'visible',
    maxWidth: 710,
    margin: 20,
    width: '100%',
    'border-radius': 6,
    marginTop: 50,
  },
});

export default styles;