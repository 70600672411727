import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { RelatedUpgradeView } from '../components';
import { CloseDialogButton } from '../../components';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    overflowY: 'visible',
    width: 774,
    margin: 20,
    width: '100%',
    marginTop: 50,
  }
}));

function RelatedUpgradeDialog({
  open,
  onClose,
  fullScreen,
  className,
  ...props
}) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth="md"
      scroll="paper"
      fullScreen={fullScreen}
      className={cx('upgrade-dialog', className)}
    >
      {fullScreen || <CloseDialogButton onClick={onClose} />}
      <DialogContent className="body-dialog">
        <RelatedUpgradeView {...props} />
      </DialogContent>
    </Dialog>
  );
}

export default RelatedUpgradeDialog;
