import React from 'react';
import { Route } from 'react-router-dom';
import { isClientSide } from '../../helpers';

const NormalRouteWithoutLayout = ({ component, ...rest }) => {
  if (isClientSide) {
    document.title = `${rest.name  } - Answer`;
  }
  return (
      <Route
          {...rest}
          render={routeProps => {
              const finalProps = {
                  routeProps,
                  ...rest
              };
              return React.createElement(component, finalProps);
          }}
      />
  );
}

export default NormalRouteWithoutLayout;
