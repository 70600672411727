import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import agent from '../../agent';
import { ANSWER } from '../../actions';
import { DivLoadingComponent, StackSubject, CloseDialogButton, KeystackCard } from '../../components';

const styles = theme => ({
  avatar: {
    width: 45,
    height: 45,
    objectFit: 'cover',
    borderRadius: '50%',
    marginRight: 8
  },
  name: {
    color: '#513A36',
    fontSize: 14,
    lineHeight: '21px',
    marginBottom: 0,
    marginTop: 7,
    'margin-right': 8,
    'font-family': "'Nunito', sans-serif",
    'font-weight': 800,
  },
  company: {
    color: '#513A36',
    fontSize: 12,
    lineHeight: '18px',
    marginBottom: 0
  },
  dialogPaper: {
    overflowY: 'visible',
    margin: 20,
    marginTop: 50,
    width: '100%',
    '& .body-dialog': {
      padding: 0
    }
  }
});

function DetailKeystackDialog({
  inProgressStackByUser,
  getKeyStackByUserQuestion,
  respectKeyStack,
  inProgressRespect,
  question = {},
  inProgressById,
  inProgressKeyStackById,
  classes,
  stack,
  onClose,
  open,
  match,
  onOpenDetail,
}) {

  useEffect(() => {
    if (!open) {
      return;
    }
    const { name, category, author } = match.params;
    if (!name || !category || !author) {
      return;
    }
    getKeyStackByUserQuestion({ id: name, user: author });
  }, [open]);

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        classes={{ paper: classes.dialogPaper }}
        maxWidth="md"
        scroll="paper"
        className="keystack-detail-dialog"
      >
        <DialogContent className="body-dialog">
          <CloseDialogButton onClick={onClose} />
          {inProgressStackByUser || !stack || !question ? (
            <div>
              <DivLoadingComponent />
            </div>
          ) : question && (
            <>
              <StackSubject
                group=""
                title={question.question}
                description={question.description}
                logo
              />
              <div className="p-content">
                <div className="content keystack-answer-content">
                  <KeystackCard
                    item={stack}
                    question={question}
                    isAnswer
                    toggleModelDialog={onOpenDetail}
                    actionPrefix={ANSWER}
                  />
                </div>
              </div>

            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

const mapDispatchToProps = dispatch => ({
    getKeyStackByUserQuestion: data => dispatch({
        type: ANSWER.GET_KEY_STACK_BY_USER_QUESTION,
        payload: agent.KeyStack.getKeyStackByUserQuestion(data)
      }),
  });

function mapStateToProps(state) {
  return {
    ...state.answer,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(DetailKeystackDialog)));
