export const COMMON = {
  APP_LOAD: 'APP_LOAD',
  HOME_PAGE_LOAD: 'HOME_PAGE_LOAD',
  REDIRECT: 'REDIRECT',
  REDIRECT_TO: 'REDIRECT_TO',
  CHANGE_URL_AFTER_LOGIN: 'CHANGE_URL_AFTER_LOGIN',
  ERROR: 'ERROR',
  MESSAGE: 'MESSAGE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  ASYNC_START: 'ASYNC_START',
  ASYNC_END: 'ASYNC_END',
  REGISTER_FREE_PLAN: 'REGISTER_FREE_PLAN',
  GET_PHOTOS: 'GET_PHOTOS_COMMON',
  ANNOUNCE: 'ANNOUNCE',
  RESET_ANNOUNCE: 'RESET_ANNOUNCE',
};