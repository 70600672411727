import React, { useState, useRef, useEffect } from 'react';
import autosize from 'autosize';
import classNames from 'classnames';
import { PaperAirplaneIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Spin from '../../components/tailwind/Spin';

const ReplyForm = ({
  id,
  label,
  onClose,
  onSubmit,
  initialValue = '',
  user,
  isReply,
  isEdit
}) => {
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [placeholder, setPlaceholder] = useState('Write a comment...');
  const textareaRef = useRef();

  const handleChangeValue = e => {
    setValue(e.target.value);
  };

  const handleResize = () => {
    const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    setPlaceholder(w < 640 ? 'Comment...' : 'Write a comment...');
  }
  useEffect(() => {
    textareaRef.current.focus();
    autosize(textareaRef.current);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    onSubmit(
      {
        id,
        data: { content: value }
      },
      () => {
        setLoading(false);
        setValue('');
        onClose();
      });
  };

  return (
    <div className={`flex ${!isEdit ? "pt-3" : "pt-1"} items-center ${isReply && "ml-[3.1rem] sm:ml-14"}`}>
      <div className={`flex-shrink-0 ${isEdit && "hidden"}`}>
        <img
          className="inline-block h-10 w-10 rounded-full object-cover"
          src={user?.profile_image}
          alt=""
        />
      </div>
      <div className={classNames(!isEdit && 'ml-2', "min-w-0 flex-1")}>
        <textarea
          type="text"
          className="block w-full rounded-3xl border-neutral-300 focus:border-accent-default focus:ring-accent-default text-sm font-light resize-none px-3"
          value={value}
          onChange={handleChangeValue}
          placeholder={placeholder}
          rows={1}
          ref={textareaRef}
        />
      </div>
      <div className="ml-2 flex flex-col sm:flex-row flex-shrink-0 self-center items-end gap-1">
        <button
          type="button"
          onClick={handleSubmit}
          className="hidden sm:inline-flex items-center px-3.5 py-2 text-xs leading-4 font-light rounded-full text-white bg-accent-default hover:bg-accent-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400"
        >
          {loading ? (
            <>
              <Spin className="inline mr-3 w-4 h-4 text-white animate-spin" />
              Waiting...
            </>
          ) : label}
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          className="inline-block sm:hidden items-center p-2 text-xs rounded-full text-accent-default bg-transparent hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400"
        >
          {loading ? (
            <Spin className="inline w-6 h-6 animate-spin" />
          ) : <PaperAirplaneIcon className="w-6 h-6" />}
        </button>
        {isEdit && (
          <>
            <button
              type="button"
              onClick={onClose}
              className="hidden sm:inline-flex items-center px-3.5 py-2 text-xs leading-4 font-light rounded-full text-white bg-accent-default hover:bg-accent-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={onClose}
              className="inline-block sm:hidden items-center p-2 text-xs rounded-full text-accent-default bg-transparent hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default ReplyForm;
