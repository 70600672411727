import { QUESTION } from '../actions';
import { SWITCH_QUESTION } from '../constants';
import { sortObj } from '../helpers';

const initState = {
  questions: [],
  categories: [],
  more: false
};
export default (state = initState, action) => {
  switch (action.type) {
    case QUESTION.GET_QUESTION_GROUP:
      return {
        ...state,
        inProgressGetCategory: false,
        categories: Array.isArray(action.payload) ? action.payload : [],
        type: action.type
      };
    case QUESTION.GET_QUESTION_IN_CATEGORY: {
      const payload = action.payload;
      return {
        ...state,
        inProgressInCategory: false,
        questions: payload && payload.data ? payload.data : [],
        more: payload && !!payload.next,
        type: action.type
      };
    }
    case QUESTION.GET_MORE_QUESTION_IN_CATEGORY: {
      let questions = state.questions;
      const payload = action.payload;
      if (payload && payload.data) {
        questions = questions.concat(payload.data);
      }
      return {
        ...state,
        inProgressInCategory: false,
        questions,
        more: payload && !!payload.next,
        type: action.type
      };
    }
    case QUESTION.PAGE_UNLOAD:
      return initState;
    case QUESTION.ASYNC_START:
      switch (action.subtype) {
        case QUESTION.GET_QUESTION_GROUP:
          return { ...state, inProgressGetCategory: true, type: action.type + '_' + action.subtype };
        case QUESTION.GET_QUESTION_IN_CATEGORY:
        case QUESTION.GET_MORE_QUESTION_IN_CATEGORY:
          return { ...state, inProgressInCategory: true, type: action.type + '_' + action.subtype };
        default:
          return state;
      }
    default:
      return state;
  }
};
