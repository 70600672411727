import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { DivLoadingComponent } from '../../components';
import { NotificationItem } from '../../components/notification';

import { URL_PATH } from '../../constants';

function NotificationPage({
  more,
  inProgress,
  notifications,
  isAuthenticated,
  getNotifications,
  getMoreNotifications,
  readAllNotifications,
  deleteNotificationById,
  onRedirectTo,
}) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!isAuthenticated) {
      onRedirectTo(URL_PATH.LOGIN.PATH);
    } else {
      getNotifications({ page });
      readAllNotifications();
    }
  }, [])

  const onClickMore = () => {
    const nextPage = page + 1;

    getMoreNotifications({
      page: nextPage,
    });

    setPage(nextPage)
  };

  const handleNotificationDelete = (id) => () => {
    deleteNotificationById(id)
  }

  const hasNotifications = Array.isArray(notifications) && notifications.length > 0

  return (
    <main className="lg:col-span-9 xl:col-span-10 bg-white px-4 py-6 sm:rounded-xl sm:p-6 shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
      <h1 className="text-xl pb-2">All Notifications</h1>
      <InfiniteScroll
        dataLength={notifications.length}
        next={onClickMore}
        hasMore={more}
        loader={page === 1 ? null : <DivLoadingComponent />}
      >
        <ul>
          {
            hasNotifications &&
            notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onDelete={handleNotificationDelete(notification.id)}

              />
            ))
          }
        </ul>
      </InfiniteScroll>
      {page === 1 && inProgress && <DivLoadingComponent />}
      {!notifications.length && !inProgress && (
        <p className="mt-2 text-center font-light text-sm text-neutral-500">No items to display</p>
      )}
    </main>
  )
}

export default NotificationPage;
