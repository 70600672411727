import { COMMON, AUTH, NOTIFICATION } from './actions';
import {
  setAuthenticated,
  removeAuthenticated,
  updateUser
} from './helpers/LocalStorage';
import { subscribe, unsubscribe, postMessage } from "./helpers/sw"

const promiseMiddleware = store => next => action => {
  const promise = action.payload;
  if (isPromise(promise)) {
    store.dispatch({ type: COMMON.ASYNC_START, subtype: action.type });

    const stateCommon = store.getState().common;
    const currentView = stateCommon.viewChangeCounter;
    const skipTracking = action.skipTracking;

    promise.then(
      res => {
        const currentStateCommon = store.getState().common;
        if (
          !skipTracking &&
          currentStateCommon.viewChangeCounter !== currentView
        ) {
          return;
        }
        action.payload = res.status === 204 ? res.body ?? true : res.body;
        store.dispatch({ type: COMMON.ASYNC_END, promise: action.payload });

        if (action.payload && action.payload.errors) {
          action.error = true;
        } else if (!res.body && res.status !== 204) {
          action.error = true;
        }
        store.dispatch(action);
      },
      error => {
        const currentStateCommon = store.getState().common;
        action.error = true;
        action.payload = error.response ? error.response.body : error.response;
        if (error.status === 401) {
          removeAuthenticated();
        }
        if (
          !skipTracking &&
          currentStateCommon.viewChangeCounter !== currentView
        ) {
          return;
        }

        if (!action.skipTracking) {
          store.dispatch({ type: COMMON.ASYNC_END, promise: action.payload });
        }
        store.dispatch(action);
      }
    ).finally(() => {
      if (action.callback) {
        // if dispatched with with hasMore flag
        // provide if pagination has more pages
        if (action.withPagination) {
          action.callback({
            more: action.payload && !!action.payload.next
          })
        } else {
          action.callback();
        }
      }
    });

    return;
  }

  next(action);
};

const serviceWorkerMiddleware = store => next => action => {
  if (!action.error) {
    switch (action.type) {
      case COMMON.APP_LOAD:
        if (action.payload && action.payload.id) {
          subscribe()
        }
        break;
      case AUTH.LOGIN:
        if (action.payload && action.payload.user) {
          subscribe()
        }
        break;
      case AUTH.SIGN_UP:
        if (action.payload && action.payload.user) {
          subscribe()
        }
        break;
      case AUTH.READ_MESSAGES_BY_IDS:
        postMessage({ type: AUTH.SYNC_READ_MESSAGES_BY_IDS, payload: action.payload })
        break;
      case NOTIFICATION.READ_ALL_NOTIFICATIONS:
        postMessage({ type: NOTIFICATION.SYNC_READ_ALL_NOTIFICATIONS })
        break;
      case AUTH.DELETE_MESSAGE_BY_ID:
      case NOTIFICATION.DELETE_NOTIFICATION_BY_ID:
        if (!action.error) {
          const id = action.param.id
          const { items } = store.getState().common.messenger
          const deletedItem = items.find(item => item.id === id)
          if (deletedItem) {
            postMessage({ type: `SYNC_${action.type}`, param: { id } })
          }
        }

    }
  }
  if (action.type === AUTH.ASYNC_START && action.subtype === AUTH.LOGOUT) {
    unsubscribe();
  }
  next(action);
};

const localStorageMiddleware = store => next => action => {
  if (!action.error) {
    switch (action.type) {
      case AUTH.LOGIN:
        if (action.payload && action.payload.user) {
          setAuthenticated(action.payload);
        }
        break;
      case AUTH.SIGN_UP:
        if (action.payload && action.payload.user) {
          setAuthenticated(action.payload);
        }
        break;
      case AUTH.GET_CURRENT:
        if (action.payload && action.payload.id) {
          updateUser(action.payload);
        }
        break;
    }
  }
  if (action.type === AUTH.LOGOUT) {
    removeAuthenticated();
  }
  next(action);
};

function isPromise(v) {
  return v && typeof v.then === 'function';
}

export { promiseMiddleware, serviceWorkerMiddleware, localStorageMiddleware };
