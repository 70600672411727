import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { CheckCircleIcon, PlusIcon } from '@heroicons/react/20/solid';
import { AUTH, COMMON, PRICING } from '../../../actions';
import PlanPricingList from '../../components/PlanPricingList';
import { LoadingComponent } from '../../../components';
import AddCard from './AddCard';
import Card from './Card';

import agent from '../../../agent';

const PREMIUM_TYPE = {
  APP: {
    value: 'IN-APP',
    name: 'Mentalmodelhub IOS App',
  },
  WEB: {
    value: 'IN-WEB',
    name: 'Mentalmodelhub website',
  },
  SYSTEM: {
    value: 'IN-SYSTEM',
    name: 'SYSTEM',
  },
};

const PaymentPage = ({
  currentUser,
  renewalSubcription,
  onAnnounce,
  onOpen,
  resetPricing,
  type,
  onGetCurrentUser,
  getCards,
  cards,
  inProgressGetCards,
  addCard,
  inProgressAddCard,
  stripeCustomer,
  deleteCard,
  inProgressDeleteCard,
  setDefaultCard,
  inProgressSetDefaultCard,
  getInvoices,
  inProgressGetInvoices,
  invoices,
}) => {
  const { premium, product } = currentUser;
  const [openAddCard, setOpenAddCard] = useState(false);

  const handleOffRenewal = () => {
    onAnnounce({
      message: (
        <p className="name">
          Wait! Are you sure you want to switch off Mentalmodelhub Premium
          Auto-renewal?
        </p>
      ),
      label: `Proceed`,
      onOk: () => renewalSubcription(false),
      onCancel: onOpen,
      className: 'cancel-subscription-dialog',
    });
  };

  const onDeleteCard = (id) => {
    onAnnounce({
      message: <p>Wait! Are you sure you want to delete this card?</p>,
      label: `Oke`,
      onOk: () => deleteCard(id),
    });
  };

  const onSetDefaultCard = (id) => {
    onAnnounce({
      message: <p>Wait! Are you sure you want to set default this card?</p>,
      label: `Oke`,
      onOk: () => setDefaultCard(id),
    });
  };

  const handleAutoRenewal = (e) => {
    const value = e.target.checked;
    if (value) {
      renewalSubcription(value);
      return;
    }
    handleOffRenewal();
  };

  useEffect(() => {
    onGetCurrentUser();
    if (premium) {
      getCards();
      getInvoices();
    }
  }, []);

  useEffect(() => {
    if (type === PRICING.ADD_CARD && !inProgressAddCard) {
      setOpenAddCard(false);
      getCards();
    }
    if (
      (type === PRICING.DELETE_CARD && !inProgressAddCard) ||
      (type === PRICING.SET_DEFAULT_CARD && !inProgressSetDefaultCard)
    ) {
      getCards();
    }
  }, [type, inProgressAddCard, inProgressDeleteCard, inProgressSetDefaultCard]);

  useEffect(() => {
    if (type === PRICING.RENEWAL_SUBCRIPTION) {
      if (!currentUser?.premium?.cancel_at) {
        onAnnounce({
          message: (
            <p className="name">
              Done. You can always switch Auto-renewal back on in your settings
              at any time.
            </p>
          ),
          onClose: resetPricing,
          className: 'cancelled-subscription-dialog',
        });
      }
      onGetCurrentUser();
    }
  }, [type]);

  const checked = !premium?.cancel_at;
  const expiresDate = premium?.expires_date
    ? dayjs(premium.expires_date).format('DD MMMM YYYY')
    : '';
  const isApp = premium?.type === PREMIUM_TYPE.APP.value;

  return (
    <main className="lg:col-span-9 xl:col-span-10">
      <div className="rounded-xl bg-white shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
        <div className="p-6 py-3 border-b border-neutral-100">
          {premium ? (
            <p className="mt-3 text-lg text-center">
              You are on the{' '}
              <span className="font-bold text-green-700">Premium</span> plan!
            </p>
          ) : (
            <p className="mt-3 text-lg text-center">
              You are on the{' '}
              <span className="font-bold text-red-700">Free</span> plan!
            </p>
          )}
        </div>
        {premium ? (
          <div className="mx-auto overflow-hidden grid grid-cols-12 text-sm">
            <div className="col-span-12 lg:col-span-6 p-6 pb-2 lg:pb-6 text-neutral-700">
              <p className="mb-1">
                Price:{' '}
                <span className="font-bold text-lg">{product.price}</span>
              </p>
              <p>
                {checked ? 'Auto-renewal date' : 'Expiration date'}:{' '}
                <span className="text-accent-default">{expiresDate}</span>
              </p>
            </div>
            <div className="col-span-12 lg:col-span-6 p-6 pt-3 lg:pt-6">
              <label className="inline-flex relative items-center mr-5 cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                  checked={checked}
                  onChange={handleAutoRenewal}
                  disabled={isApp}
                />
                <div className="w-11 h-6 bg-neutral-200 rounded-full peer dark:bg-neutral-700 peer-focus:ring-4 peer-focus:ring-accent-300 dark:peer-focus:ring-accent-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-neutral-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-neutral-600 peer-checked:bg-accent-default" />
                <span className="ml-3 font-medium text-neutral-700 dark:text-neutral-300">{`Auto-renewal is ${
                  checked ? 'on' : 'off'
                }`}</span>
              </label>
              <p className="font-light text-xs text-neutral-500">
                Mentalmodelhub Premium will automatically renew on the date
                specified above.
              </p>
            </div>
          </div>
        ) : (
          <div className="mx-auto overflow-hidden grid grid-cols-12">
            <div className="col-span-12 lg:col-span-8 bg-white px-6 py-8 lg:p-12">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                Premium Membership
              </h3>
              <p className="mt-6 text-sm text-neutral-500">
                Upgrade your account to Premium to enjoy all the benefits
                MentalModelHub has to offer and supercharge your decision-making
                skills.
              </p>
              {/* <p className="mt-2 text-sm text-neutral-500 italic">
                For a limited time only our Premium Membership is available at a SPECIAL INTRODUCTORY PRICE. You may cancel your subscription at any time within 2 weeks for a full refund.
              </p> */}
              <div className="mt-8">
                <div className="mt-10 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-accent-default">
                    What’s included
                  </h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                  <li className="flex gap-x-3">
                    <svg
                      className="h-6 w-5 flex-none text-accent-default"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Access to 300+ mental model snapshots
                  </li>
                  <li className="flex gap-x-3">
                    <svg
                      className="h-6 w-5 flex-none text-accent-default"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Use our "Ask AI" feature freely
                  </li>
                  <li className="flex gap-x-3">
                    <svg
                      className="h-6 w-5 flex-none text-accent-default"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Save to favourites an unlimited number of mental models
                  </li>

                  <li className="flex gap-x-3">
                    <svg
                      className="h-6 w-5 flex-none text-accent-default"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Access to all future Premium releases
                  </li>

                  <li className="flex gap-x-3">
                    <svg
                      className="h-6 w-5 flex-none text-accent-default"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Cancel your subscription at anytime
                  </li>
                </ul>
              </div>
            </div>
            <PlanPricingList />
          </div>
        )}
      </div>
      {premium && (
        <>
          <div className="mt-4 p-6 rounded-xl bg-white shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
            <h2 className="text-xl pb-2">Cards</h2>
            {inProgressGetCards && <LoadingComponent />}
            {!inProgressGetCards && cards && cards.length && (
              <div className="grid grid-cols-12 gap-4">
                {cards?.map((card) => (
                  <Card
                    key={card.id}
                    card={card}
                    isDefault={stripeCustomer?.default_source === card.id}
                    onDeleteCard={onDeleteCard}
                    onSetDefaultCard={onSetDefaultCard}
                  />
                ))}
                <div className="col-span-12 sm:col-span-6 xl:col-span-4 m-auto rounded-xl border-2 border-accent-default border-dashed relative">
                  <img
                    className="relative object-cover w-full h-full rounded-xl"
                    src="assets/img/premium/bg-white.png"
                    alt=""
                  />
                  <div className="absolute w-full h-full top-0">
                    <button
                      type="button"
                      onClick={() => setOpenAddCard(true)}
                      className="w-full h-full flex justify-center items-center"
                    >
                      <PlusIcon className="h-10 w-10 text-accent-default" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 p-6 rounded-xl bg-white shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
            <h2 className="text-xl pb-2">Invoices</h2>
            {inProgressGetInvoices && <LoadingComponent />}
            {!inProgressGetInvoices && invoices && invoices.length && (
              <>
                <div className="sm:hidden">
                  <ul className="divide-y divide-neutral-200 overflow-hidden sm:hidden">
                    {invoices.map((invoice) => (
                      <li className="mt-1">
                        <a
                          href={invoice.hosted_invoice_url}
                          target="_blank"
                          className="border border-neutral-200 rounded-xl block bg-white px-4 py-4 hover:bg-neutral-50"
                        >
                          <span className="flex items-center space-x-4">
                            <span className="flex flex-1 space-x-2 truncate">
                              <svg
                                className="h-5 w-5 flex-shrink-0 text-neutral-500"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1 4a1 1 0 011-1h16a1 1 0 011 1v8a1 1 0 01-1 1H2a1 1 0 01-1-1V4zm12 4a3 3 0 11-6 0 3 3 0 016 0zM4 9a1 1 0 100-2 1 1 0 000 2zm13-1a1 1 0 11-2 0 1 1 0 012 0zM1.75 14.5a.75.75 0 000 1.5c4.417 0 8.693.603 12.749 1.73 1.111.309 2.251-.512 2.251-1.696v-.784a.75.75 0 00-1.5 0v.784a.272.272 0 01-.35.25A49.043 49.043 0 001.75 14.5z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="flex flex-col truncate text-sm text-neutral-500">
                                <span className="truncate">
                                  {invoice.number}
                                </span>
                                <span>
                                  <span className="font-medium text-neutral-800">
                                    ${(invoice.amount_paid / 100).toFixed(2)}
                                  </span>{' '}
                                  USD
                                </span>
                                <time>
                                  {dayjs(invoice.created * 1000).format(
                                    'MMM D, YYYY h:mm A'
                                  )}
                                </time>
                              </span>
                            </span>
                            <div>
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 capitalize">
                                {invoice.status}
                              </span>
                            </div>
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="hidden sm:block">
                  <div className="w-full">
                    <div className="mt-2 flex flex-col">
                      <div className="min-w-full overflow-hidden overflow-x-auto align-middle sm:rounded-xl">
                        <table className="min-w-full divide-y divide-neutral-200">
                          <thead>
                            <tr>
                              <th
                                className="bg-orange-200 px-6 py-3 text-left text-sm font-semibold text-neutral-900"
                                scope="col"
                              >
                                Invoice Number
                              </th>
                              <th
                                className="bg-orange-200 px-6 py-3 text-right text-sm font-semibold text-neutral-900"
                                scope="col"
                              >
                                Amount
                              </th>
                              <th
                                className="hidden bg-orange-200 px-6 py-3 text-left text-sm font-semibold text-neutral-900 md:block"
                                scope="col"
                              >
                                Status
                              </th>
                              <th
                                className="bg-orange-200 px-6 py-3 text-right text-sm font-semibold text-neutral-900"
                                scope="col"
                              >
                                Created
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-neutral-200 bg-white">
                            {invoices.map((invoice) => (
                              <tr className="bg-white">
                                <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-neutral-900">
                                  <div className="flex">
                                    <a
                                      href={invoice.hosted_invoice_url}
                                      target="_blank"
                                      className="group inline-flex space-x-2 truncate text-sm"
                                    >
                                      <svg
                                        className="h-5 w-5 flex-shrink-0 text-neutral-500 group-hover:text-neutral-700"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M1 4a1 1 0 011-1h16a1 1 0 011 1v8a1 1 0 01-1 1H2a1 1 0 01-1-1V4zm12 4a3 3 0 11-6 0 3 3 0 016 0zM4 9a1 1 0 100-2 1 1 0 000 2zm13-1a1 1 0 11-2 0 1 1 0 012 0zM1.75 14.5a.75.75 0 000 1.5c4.417 0 8.693.603 12.749 1.73 1.111.309 2.251-.512 2.251-1.696v-.784a.75.75 0 00-1.5 0v.784a.272.272 0 01-.35.25A49.043 49.043 0 001.75 14.5z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                      <p className="truncate text-neutral-600 group-hover:text-neutral-900">
                                        {invoice.number}
                                      </p>
                                    </a>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-neutral-600">
                                  <span className="font-medium text-neutral-900">
                                    ${(invoice.amount_paid / 100).toFixed(2)}
                                  </span>
                                  USD
                                </td>
                                <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-neutral-600 md:block">
                                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 capitalize">
                                    {invoice.status}
                                  </span>
                                </td>
                                <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-neutral-600">
                                  <time>
                                    {dayjs(invoice.created * 1000).format(
                                      'MMM D, YYYY h:mm A'
                                    )}
                                  </time>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <AddCard
        open={openAddCard}
        addCard={addCard}
        inProgressAddCard={inProgressAddCard}
        onClose={() => setOpenAddCard(false)}
      />
    </main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onAnnounce: (data) => dispatch({ type: COMMON.ANNOUNCE, data }),
  resetPricing: () => dispatch({ type: PRICING.RESET }),
  renewalSubcription: () =>
    dispatch({
      type: PRICING.RENEWAL_SUBCRIPTION,
      payload: agent.Pricing.renewalSubcription(),
    }),
  onGetCurrentUser: () =>
    dispatch({
      type: AUTH.GET_CURRENT,
      payload: agent.Auth.getCurrentUser(),
    }),
  getCards: () =>
    dispatch({
      type: PRICING.GET_CARDS,
      payload: agent.Pricing.getCards(),
    }),
  addCard: (data) =>
    dispatch({
      type: PRICING.ADD_CARD,
      payload: agent.Pricing.addCard(data),
    }),
  deleteCard: (id) =>
    dispatch({
      type: PRICING.DELETE_CARD,
      payload: agent.Pricing.deleteCard(id),
    }),
  setDefaultCard: (id) =>
    dispatch({
      type: PRICING.SET_DEFAULT_CARD,
      payload: agent.Pricing.setDefaultCard(id),
    }),
  getInvoices: () =>
    dispatch({
      type: PRICING.GET_INVOICES,
      payload: agent.Pricing.getInvoices(),
    }),
});

function mapStateToProps(state) {
  return {
    ...state.auth,
    ...state.pricing,
    currentUser: state.common?.currentUser,
    type: state.pricing?.type,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
