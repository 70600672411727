import React, { useState, useEffect } from 'react';
import ReactSVG from 'react-svg';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';

import { DivLoadingComponent, StackModel } from '../../../components';
import { URL_PATH } from '../../../constants';
import { RelatedUpgradeView } from '../../components';
import DetailDialog from './DetailDialog';
import { ModelDetailDialog } from '../../dialog';
import { useDialog } from '../../../hook';
import PodcastUpgrade from '../podcast/PodcastUpgrade';

const detailImg = '/assets/img/podcast/go.svg';

const IconBox = styled(ReactSVG)`
  g {
    rect[stroke-linejoin~=round] {
      stroke: ${props => props.text_color};
    }
    circle {
      stroke: ${props => props.text_color};
      fill: ${props => props.text_color};
    }
    path {
      stroke: ${props => props.text_color};
    }
  }
`;

const getPodcastDetailLink = (item) => {
  const payload = {
    title: item.slug,
    episode_no: item.episode_no,
    guest: item.guest
  };
  return URL_PATH.PODCAST_DETAIL.pathParam(payload);
}

const useStyles = makeStyles((theme) => ({
  note: {
    fontFamily: "Inter, sans-serif",
    fontSize: 16,
    color: "#362826",
    padding: 12,
    width: 220
  },
}))

function QuoteTimeNote({ quote, timecode, id, note }) {
  const classes = useStyles();
 
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idPopover = open ? `popover-quote-${id}` : undefined;

  return (
    <>
      <div className="col px-0 podcast-quote-container">
        <p className="description">“{quote}”</p>
        <div className="podcast-page-container row">
          <div className="podcast-page col-auto" />
          <div className="podcast-time col row justify-content-end align-items-center mr-0">
            {note && (
              <IconButton size="small" aria-describedby={idPopover} onClick={handleClickMore}>
                <MoreHorizIcon />
              </IconButton>
            )}
            <span className="pl-1">{timecode}</span>
          </div>
        </div>
      </div>
      {note && (
        <Popover
          id={idPopover}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography className={classes.note}>{note}</Typography>
        </Popover>
      )}
    </>
  )
}

function PodcastDetailPage({
  getPodcastById,
  match,
  isPremium,
  podcast = {},
  inProgressById,
  isAuthenticated,
  onRedirectTo
}) {
  const [modelDetail, onCloseModelDetail, onOpenModelDetail] = useDialog();
  const [openViewDetail, setOpenViewDetail] = useState(false);

  useEffect(() => {
    if (podcast && podcast.title === match.params.title) {
      return;
    }
    if (isPremium) {
      getPodcastById(match.params.title, true);
    }
  }, []);

  const onClickViewDetail = (e) => {
    e.preventDefault();
    setOpenViewDetail(true);
  };

  return (
    <div className="podcast-detail-page">
      {isAuthenticated && isPremium ? (
        <div className="podcast-detail-container">
          {inProgressById ? (
            <div>
              <DivLoadingComponent />
            </div>
          ) : podcast && (
            <div className="row mx-0 stack-subject">
              <div
                className="stack-subject-container col-12 px-0"
                style={{
                  color: podcast.text_color,
                  backgroundColor: podcast.background_color
                }}
              >
                <div className="stack-subject-content">
                  <p className="stack-subject-title">{podcast.title}</p>
                  <p className="stack-subject-title">{podcast.episode_no}</p>
                  <p className="stack-subject-description">
                    {podcast.guest}
                  </p>
                  <a
                    href="#"
                    className="detail-btn"
                    onClick={onClickViewDetail}
                    style={{ bottom: !podcast.guest && '-12px' }}
                  >
                    <IconBox
                      src={detailImg}
                      text_color={podcast.text_color}
                    />
                  </a>
                </div>
              </div>
            </div>
          )}

          <div className="p-content">
            <div className="content">
              {!inProgressById && podcast && Array.isArray(podcast.podcast_analyses) &&
              podcast.podcast_analyses.length > 0 && podcast.podcast_analyses.map((item, index) => (
                  <div className="row podcast-item mx-0" key={item.id}>
                    {item.model && (
                      <StackModel
                        title={item.model.name}
                        style={{
                          color: item.model.category?.text_color,
                          backgroundColor:
                            item.model.category?.background_color
                        }}
                        srcImg={item.model.category?.icon}
                        onClick={() => onOpenModelDetail(item.model.id)}
                        className="col-12 col-md-auto stack-model-container px-0"
                      />
                    )}
                    <QuoteTimeNote
                      quote={item.quote}
                      timecode={item.timecode}
                      note={item.note}
                      id={item.id}
                    />
                  </div>
                ))}
              {!inProgressById && (
                <div className="navigate-model-detail mx-auto">
                  {podcast.prev_podcast && (
                    <Link
                      to={getPodcastDetailLink(podcast.prev_podcast)}
                      className="previous-series"
                    >
                      PREVIOUS IN SERIES
                    </Link>
                  )}
                  {podcast.next_podcast && (
                    <Link
                      to={getPodcastDetailLink(podcast.next_podcast)}
                      className="next-series"
                    >
                      NEXT IN SERIES
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
          <DetailDialog
            open={openViewDetail}
            onClose={() => setOpenViewDetail(false)}
            item={podcast}
          />
          <ModelDetailDialog
            open={!!modelDetail}
            onClose={onCloseModelDetail}
            idModel={modelDetail}
          />
        </div>
      ) : (
        <div className="podcast-page">
        <PodcastUpgrade />
        </div>
        )}
    </div>
  );
}

export default withRouter(PodcastDetailPage);
