import React, { Fragment } from 'react'
import HeaderContainer from '../../containers/layouts/HeaderContainer';
import FooterContainer from '../../containers/layouts/FooterContainer';
import Sidebar from './ProfileSidebar';
import navigation from './navigation'

const ProfileLayout = ({ path, children }) => (
    <>
      <div className="min-h-full">
        <HeaderContainer navigation={navigation} />
        <div className="pt-10 bg-keystack-bg min-h-screen">
          <div className="mx-auto max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-12 lg:gap-8 lg:px-8">
            <Sidebar path={path} />
            {children}
          </div>
        </div>
        <FooterContainer />
      </div>
    </>
  )

export default ProfileLayout;
