export const AUTH = {
  PAGE_LOAD: 'PAGE_LOAD_AUTH',
  PAGE_UNLOAD: 'PAGE_UNLOAD_AUTH',
  ASYNC_START: 'ASYNC_START',
  ASYNC_END: 'ASYNC_END',
  LOGIN: 'LOGIN_AUTH',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD_AUTH',
  UPDATE_PROFILE: 'UPDATE_PROFILE_AUTH',
  UPGRADE: 'UPGRADE_AUTH',
  RESET_PASSWORD: 'RESET_PASSWORD_AUTH',
  SIGN_UP: 'SIGN_UP_AUTH',
  LOGOUT: 'LOGOUT_AUTH',
  GET_CURRENT: 'GET_CURRENT_AUTH',
  VERIFY_ACCOUNT: 'VERIFY_ACCOUNT_AUTH',
  ACTIVE_EMAIL: 'ACTIVE_EMAIL_AUTH',
  CHANGE_PARAMS: 'CHANGE_PARAMS_AUTH',
  GET_NOTIFICATION_SETTINGS: 'GET_NOTIFICATION_SETTINGS_AUTH',
  UPDATE_NOTIFICATION_SETTINGS: 'UPDATE_NOTIFICATION_SETTINGS_AUTH',

  GET_MESSAGES: 'GET_MESSAGES_AUTH',

  DELETE_MESSAGE_BY_ID: 'DELETE_MESSAGE_BY_ID',
  SYNC_DELETE_MESSAGE_BY_ID: 'SYNC_DELETE_MESSAGE_BY_ID',

  READ_MESSAGES_BY_IDS: 'READ_MESSAGES_BY_IDS_AUTH',
  SYNC_READ_MESSAGES_BY_IDS: 'SYNC_READ_MESSAGES_BY_IDS_AUTH',

  PUSH_MESSAGE: 'PUSH_MESSAGE_AUTH',

  GET_UNREAD_MESSAGES_COUNT: "GET_UNREAD_MESSAGES_COUNT_AUTH",

  SET_TYPE: 'SET_TYPE'
};
