import React from "react";
import { Link } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/outline'
import { messageByType } from '../../helpers'





const NotificationItem = ({
  notification,
  onDelete,
  full = true,
  onClick = () => { }
}) => {
  const { id, type, object: { title, slug }, actor: { first_name: firstName, last_name: lastName } } = notification
  const userName = `${firstName} ${lastName}`

  return (
    <li
      key={id}
      className="flex flex-row justify-between p-2 hover:bg-neutral-50"
      >
      <div>
        <Link
          to={`/answers/${slug}`}
          onClick={onClick}
          className="font-medium text-sm text-neutral-700"
        >{messageByType(type, title, !full, userName)}</Link>
        {full && (<p className="font-light text-xs text-neutral-500">{`From: ${userName}`}</p>)}
      </div>
      <div className="flex items-start">
        <TrashIcon onClick={onDelete} className="mt-1 h-5 w-5 text-neutral-500 cursor-pointer hover:text-red-700" aria-hidden="true" />
      </div>
    </li>
  )
}

export default NotificationItem
