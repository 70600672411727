import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { COMMON } from './actions/common';
import agent from './agent';
import { AuthenticatedRoute, NormalRoute, SnackBarCenter, PublicRoute, ProfileRoute } from './components';
import { HOME_BOTTOM, URL_PATH } from './constants';
import AnswerContainer from './containers/sites/AnswerContainer';
import FAQContainer from './containers/sites/FAQContainer';
import HomeContainer from './containers/sites/HomeContainer';
import MentalModelsContainer from './containers/sites/MentalModelsContainer';
import AskAIContainer from './containers/sites/AskAIContainer';
import ModelDetailContainer from './containers/sites/ModelDetailContainer';
import MyProfileContainer from './containers/sites/MyProfileContainer';
import PodCastContainer from './containers/sites/PodCastContainer';
import PodcastDetailContainer from './containers/sites/PodcastDetailContainer';
import PremiumMembershipContainer from './containers/sites/PremiumMembershipContainer';
import PricingPage from './pages/sites/PricingPage';
import LoginContainer from './containers/sites/LoginContainer';
import SignUpContainer from './containers/sites/SignUpContainer';
import { checkAuthenticated } from './helpers/LocalStorage';
import { AboutPage, BookPage, ContactPage, NotFoundPage, PrivacyPage, TermPage, WorkshopsPage, ComingSoonPage } from './pages';
import VerifyContainer from './containers/sites/VerifyContainer';
import NotificationContainer from './containers/sites/NotificationContainer';
import ChangePassword from './pages/sites/profile/ChangePassword';
import Payment from './pages/sites/profile/Payment';
import NormalRouteWithoutLayout from "./components/route/NormalRouteWithoutLayout";
import PricingContainer from './containers/sites/PricingContainer';
import JoinWithUsContainer from './containers/sites/JoinWithUsContainer';


const mapStateToProps = state => ({
    appLoaded: state.common && state.common.appLoaded,
    redirectTo: state.common && state.common.redirectTo,
    error: state.common && state.common.error,
    message: state.common && state.common.message
  });

const mapDispatchToProps = dispatch => ({
  onLoad: (oauthToken, isAuthenticated) => {
    const payload = oauthToken && agent.Auth.getCurrentUser();
    return dispatch({
      type: COMMON.APP_LOAD,
      oauthToken,
      payload,
      isAuthenticated,
      skipTracking: true
    });
  },
  onRedirect: () => dispatch({ type: COMMON.REDIRECT }),
  onCloseError: () => dispatch({ type: COMMON.ERROR }),
  onClearMessage: () => dispatch({ type: COMMON.CLEAR_MESSAGE }),
  getPhotos: () => dispatch({
      type: COMMON.GET_PHOTOS,
      payload: agent.Auth.getPhotos()
    })
});

class App extends Component {
  constructor(props) {
    super(props);
    const auth = checkAuthenticated();
    props.onLoad(auth.oauthToken, auth.isAuthenticated);
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.scrollToTop();
    });
    window.addEventListener('storage', this.updateLocalStorage);
    const { getPhotos } = this.props;
    getPhotos();
  }

  componentDidUpdate(prevProps, prevState) {
    const { redirectTo } = this.props;
    if (redirectTo && prevProps.redirectTo !== redirectTo) {
      const { history, onRedirect } = this.props;

      const goToAccessForm = redirectTo === HOME_BOTTOM;
      const location = goToAccessForm
        ? {
          pathname: URL_PATH.HOME.PATH,
          state: { goToAccessForm: true }
        }
        : redirectTo;

      history.push(location);
      onRedirect();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  onRequestCloseSnackBar = () => {
    const { onClearMessage } = this.props;
    onClearMessage();
  };

  scrollToTop() {
    const { state } = this.props.history.location;
    if (!state || !state.goToAccessForm) {
      animateScroll.scrollToTop({
        duration: 0
      });
    }
  }

  render() {
    const { message, location } = this.props;
    const pathname = location.pathname.split('/')[1] || null;
    const propsCommon = {
      exact: true,
      key: location.pathname,
      pathname,
      location
    };
    return (
      <div id="body-content">
        <Switch>
          <NormalRoute
            {...propsCommon}
            name={URL_PATH.HOME.TITLE}
            path={URL_PATH.HOME.PATH}
            component={HomeContainer}
          />
          <NormalRouteWithoutLayout
            {...propsCommon}
            name={URL_PATH.ABOUT.TITLE}
            path={URL_PATH.ABOUT.PATH}
            component={AboutPage}
          />
          <NormalRouteWithoutLayout
            {...propsCommon}
            name={URL_PATH.COMING_SOON.TITLE}
            path={URL_PATH.COMING_SOON.PATH}
            component={ComingSoonPage}
          />
          <NormalRoute
            {...propsCommon}
            name={URL_PATH.CONTACT.TITLE}
            path={URL_PATH.CONTACT.PATH}
            component={ContactPage}
          />
          <NormalRoute
            {...propsCommon}
            name={URL_PATH.FAQ.TITLE}
            path={URL_PATH.FAQ.PATH}
            component={FAQContainer}
          />
          <NormalRoute
            {...propsCommon}
            name={URL_PATH.TERMS.TITLE}
            path={URL_PATH.TERMS.PATH}
            component={TermPage}
          />
          <NormalRoute
            {...propsCommon}
            name={URL_PATH.PRIVACY.TITLE}
            path={URL_PATH.PRIVACY.PATH}
            component={PrivacyPage}
          />
           <NormalRoute
            {...propsCommon}
            name={URL_PATH.JOIN_WITH_US.TITLE}
            path={URL_PATH.JOIN_WITH_US.PATH}
            component={JoinWithUsContainer}
          />
          <NormalRouteWithoutLayout
            {...propsCommon}
            name={URL_PATH.PRICING.TITLE}
            path={URL_PATH.PRICING.PATH}
            component={PricingContainer}
          />
          <NormalRoute
            exact
            name={URL_PATH.KEYSTACK_DETAIL.TITLE}
            path={URL_PATH.KEYSTACK_DETAIL.PATH}
            component={AnswerContainer}
          />
          <NormalRoute
            {...propsCommon}
            name={URL_PATH.ANSWER.TITLE}
            path={URL_PATH.ANSWER.PATH}
            component={AnswerContainer}
          />
          <NormalRoute
            {...propsCommon}
            name={URL_PATH.PREMIUM_MEMBERSHIP.TITLE}
            path={URL_PATH.PREMIUM_MEMBERSHIP.PATH}
            component={PremiumMembershipContainer}
          />
          <NormalRoute
            {...propsCommon}
            name={URL_PATH.MODEL_DETAIL.TITLE}
            path={URL_PATH.MODEL_DETAIL.PATH}
            component={ModelDetailContainer}
          />
          <NormalRoute
            exact
            name={URL_PATH.MENTAL_MODEL.TITLE}
            path={URL_PATH.MENTAL_MODEL.PATH}
            component={MentalModelsContainer}
          />
          <NormalRoute
            {...propsCommon}
            name={URL_PATH.THOUGHT_LEADERS.TITLE}
            path={URL_PATH.THOUGHT_LEADERS.PATH}
            component={PodCastContainer}
          />
          <PublicRoute
            {...propsCommon}
            name={URL_PATH.LOGIN.TITLE}
            path={URL_PATH.LOGIN.PATH}
            component={LoginContainer}
          />
          <PublicRoute
            {...propsCommon}
            name={URL_PATH.SIGN_UP.TITLE}
            path={URL_PATH.SIGN_UP.PATH}
            component={SignUpContainer}
          />
          <AuthenticatedRoute
            {...propsCommon}
            name={URL_PATH.BOOK.TITLE}
            path={URL_PATH.BOOK.PATH}
            component={BookPage}
          />
          <AuthenticatedRoute
            {...propsCommon}
            name={URL_PATH.WORKSHOPS.TITLE}
            path={URL_PATH.WORKSHOPS.PATH}
            component={WorkshopsPage}
          />
          <AuthenticatedRoute
            {...propsCommon}
            name={URL_PATH.MY_COLLECTION.TITLE}
            path={URL_PATH.MY_COLLECTION.PATH}
            component={MentalModelsContainer}
          />
          <AuthenticatedRoute
            {...propsCommon}
            name={URL_PATH.ASK_AI.TITLE}
            path={URL_PATH.ASK_AI.PATH}
            component={AskAIContainer}
          />
          <ProfileRoute
            {...propsCommon}
            name={URL_PATH.MY_PROFILE.TITLE}
            path={URL_PATH.MY_PROFILE.PATH}
            component={MyProfileContainer}
          />
          <ProfileRoute
            {...propsCommon}
            name={URL_PATH.CHANGE_PASSWORD.TITLE}
            path={URL_PATH.CHANGE_PASSWORD.PATH}
            component={ChangePassword}
          />
          <ProfileRoute
            {...propsCommon}
            name={URL_PATH.PAYMENT.TITLE}
            path={URL_PATH.PAYMENT.PATH}
            component={Payment}
          />
          <AuthenticatedRoute
            {...propsCommon}
            name={URL_PATH.PODCAST_DETAIL.TITLE}
            path={URL_PATH.PODCAST_DETAIL.PATH}
            component={PodcastDetailContainer}
          />
          <ProfileRoute
            {...propsCommon}
            name={URL_PATH.NOTIFICATIONS.TITLE}
            path={URL_PATH.NOTIFICATIONS.PATH}
            component={NotificationContainer}
          />
          <PublicRoute
            {...propsCommon}
            name={URL_PATH.VERIFY.TITLE}
            path={URL_PATH.VERIFY.PATH}
            component={VerifyContainer}
          />
          <NormalRoute
            exact
            name={URL_PATH.NOT_FOUND.TITLE}
            path={URL_PATH.NOT_FOUND.PATH}
            component={NotFoundPage}
          />
          <NormalRoute
            name={URL_PATH.NOT_FOUND.TITLE}
            component={NotFoundPage}
          />
        </Switch>
        <SnackBarCenter
          message={message}
          onClose={this.onRequestCloseSnackBar}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
