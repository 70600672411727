import { objectToFormData } from 'object-to-formdata';
import React, { useState, useEffect, useRef } from 'react';
import { Form, Field } from 'react-final-form'
import { PencilSquareIcon } from '@heroicons/react/20/solid'
import { AUTH } from '../../../actions';
import { TextField, FileField } from '../../../components';
import { validate } from './helper';

const MyProfilePage = ({
  onGetCurrentUser,
  onUpdateProfile,
  type,
  initialValues,
}) => {
  const avatarInputFile = useRef(null);
  const [avatar, setAvatar] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);

  useEffect(() => {
    onGetCurrentUser();
  }, []);

  useEffect(() => {
    setAvatar(initialValues?.profile_image);
  }, [initialValues]);

  const onSubmitMyProfile = values => {
    const data = {
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      email: values.email.trim(),
      biography: values.biography ? values.biography.trim() : values.biography,
      avatar: avatarFile || values.profile_image
    };

    onUpdateProfile(objectToFormData(data));
  };

  const onChangeAvatar = () => {
    avatarInputFile.current.click();
  };

  const onChangeInputFileAvatar = (files) => {
    setAvatarFile(files[0]);
    setAvatar(URL.createObjectURL(files[0]));
  }

  const inProgress = type === (`${AUTH.ASYNC_START}_${AUTH.UPDATE_PROFILE}`);

  return (
    <main className="lg:col-span-9 xl:col-span-10 bg-white px-4 py-6 sm:rounded-xl sm:p-6 shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
      <div className="relative h-28 w-28">
        <img
          className="inline-block w-full rounded-full"
          src={avatar}
          alt=""
        />
        <button type="button" onClick={onChangeAvatar} className="absolute p-1 bg-neutral-100 rounded-full bottom-1 right-1">
          <PencilSquareIcon className="h-5 w-5 text-neutral-600 hover:text-neutral-700" aria-hidden="true" />
        </button>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-5">
        <div className="col-span-1">
          <Form
            validate={validate}
            onSubmit={onSubmitMyProfile}
            initialValues={initialValues}
            enableReinitialize
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="space-y-6">
                <FileField
                  name="profile_image"
                  hidden
                  eleRef={avatarInputFile}
                  onChange={onChangeInputFileAvatar}
                />
                <Field
                  component={TextField}
                  label="First Name"
                  name="first_name"
                  required
                />
                <Field
                  component={TextField}
                  label="Last Name"
                  name="last_name"
                  required
                />
                <Field
                  type="email"
                  component={TextField}
                  label="Email address"
                  name="email"
                  required
                />
    <Field
                          component={TextField}
                          type="textarea"
                          label="About yourself"
                            placeholder="About yourself (e.g., 'Software Engineer at Google specializing in AI. Passionate about tech and education.') Max. 120 characters."
                          name="biography"
                          required
                          rows={3}
                        />

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="flex justify-center rounded-full bg-accent-default py-2 px-6 mb-4 lg:mb-0 text-sm font-medium text-white shadow-sm hover:bg-accent-hover focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2 inline-flex items-center"
                    disabled={inProgress}
                  >
                    {inProgress ? (
                      <>
                        <svg role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                        </svg>
                        Waiting...
                      </>
                    ) : (
                      "Save"
                    )}

                  </button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </div>
    </main>
  );
}

export default MyProfilePage;
