export const KEYSTACK = {
  PAGE_LOAD: 'PAGE_LOAD_KEYSTACK',
  PAGE_UNLOAD: 'PAGE_UNLOAD_KEYSTACK',
  ASYNC_START: 'ASYNC_START',
  ASYNC_END: 'ASYNC_END',
  GET_QUESTION_GROUP: 'GET_QUESTION_GROUP',
  GET_QUESTION_TRENDING: 'GET_QUESTION_TRENDING',
  GET_KEYSTACK_BY_ID: 'GET_KEYSTACK_BY_ID',
  GET_KEYSTACK_IN_CATEGORY: 'GET_KEYSTACK_IN_CATEGORY_KEYSTACK',
  GET_MORE_KEYSTACK_IN_CATEGORY: 'GET_MORE_KEYSTACK_IN_CATEGORY_KEYSTACK',
  SWITCH_LIST: 'SWITCH_KEYSTACK',
  RESPECT: 'RESPECT_KEYSTACK',

  GET_DISCUSSION: 'GET_DISCUSSION_KEYSTACK',
  CREATE_DISCUSSION: 'CREATE_DISCUSSION_KEYSTACK',
  UPDATE_DISCUSSION: 'UPDATE_DISCUSSION_KEYSTACK',
  DELETE_DISCUSSION: 'DELETE_DISCUSSION_KEYSTACK',
  RESPECT_DISCUSSION: 'RESPECT_DISCUSSION_KEYSTACK',

  // GET_COMMENT: 'GET_COMMENT_KEYSTACK',
  CREATE_COMMENT: 'CREATE_COMMENT_KEYSTACK',
  UPDATE_COMMENT: 'UPDATE_COMMENT_KEYSTACK',
  DELETE_COMMENT: 'DELETE_COMMENT_KEYSTACK',
  RESPECT_COMMENT: 'RESPECT_COMMENT_KEYSTACK',
};
