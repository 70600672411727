export const PRICING = {
  GET_PRODUCTS: 'GET_PRODUCTS_PRICING',
  PURCHASE: 'PURCHASE_PRICING',
  RENEWAL_SUBCRIPTION: 'RENEWAL_SUBCRIPTION',
  RESET: 'RESET_PRICING',
  PAGE_UNLOAD: 'PAGE_UNLOAD_PRICING',
  ASYNC_START: 'ASYNC_START',
  CHANGE_PARAMS: 'CHANGE_PARAMS_PRICING',
  GET_CARDS: 'GET_CARDS',
  ADD_CARD: 'ADD_CARD',
  DELETE_CARD: 'DELETE_CARD',
  SET_DEFAULT_CARD: 'SET_DEFAULT_CARD',
  GET_INVOICES: 'GET_INVOICES',
};
