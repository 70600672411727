import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import React, { PureComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { length, required, confirmation } from 'redux-form-validators';
import { AUTH } from '../../actions';
import agent from '../../agent';
import { ProgressContainedButton, ReduxTextField, CloseDialogButton } from '../../components';
import { VALIDATE } from '../../constants';
import { validateRedux } from '../../helpers';

const styles = theme => ({
  dialogPaper: {
    overflowY: 'visible',
    margin: 20,
    width: '100%',
    marginTop: 50,
  }
});

let validations = {
  password: [
    required({ msg: VALIDATE.REQUIRED }),
    length({
      msg: {
        tooLong: VALIDATE.TOO_LONG,
        tooShort: VALIDATE.TOO_SHORT
      },
      max: 255,
      min: 8
    })
  ],
  confirm_password: [
    required({ msg: VALIDATE.REQUIRED }),
    confirmation({
      field: 'password',
      msg: "'Confirm password' and 'password' do not match"
    })
  ]
};

let validate = values => validateRedux(values, validations);

const ChangePasswordDialog = ({ open, type, success, onClose, classes, fullScreen, handleSubmit, onChangePassword }) => {
  useEffect(() => {
    if (type === AUTH.CHANGE_PASSWORD && open) {
      onClose();
    }
  }, [type]);

  const onSubmitChangePassword = values => {
    onChangePassword({ password: values.password, confirm_password: values.confirm_password });
  };

  const inProgress = type === (AUTH.ASYNC_START + '_' + AUTH.CHANGE_PASSWORD);
  return (
    <Dialog
      onClose={onClose}
      open={open}
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      fullScreen={fullScreen}
      className="change-password-dialog"
    >
      {fullScreen || <CloseDialogButton onClick={onClose} />}
      <DialogContent className="body-dialog">
        <h2>CHANGE PASSWORD</h2>
        <form onSubmit={handleSubmit(onSubmitChangePassword)} className="content">
          <Field
            component={ReduxTextField}
            placeholder="New password (8 + characters)"
            className="login-field"
            type="password"
            name="password"
            disabled={inProgress}
          />
          <Field
            component={ReduxTextField}
            placeholder="Confirm password"
            className="login-field"
            type="password"
            name="confirm_password"
            disabled={inProgress}
          />
          <div className="row justify-content-center">
            <ProgressContainedButton
              label="UPDATE MY ACCOUNT"
              className="submit-btn"
              type="submit"
              disabled={inProgress}
              progress
            />
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onChangePassword: values => {
      return dispatch({
        type: AUTH.CHANGE_PASSWORD,
        payload: agent.Auth.changePassword(values)
      });
    }
  };
};

function mapStateToProps(state, ownProps) {
  return {
    ...state.auth
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'ChangePasswordDialog',
    validate
  })(withStyles(styles)(ChangePasswordDialog))
);
