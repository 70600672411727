import URL_PATH from './PageUrlPath';
import {
  STYLE,
} from './Style';

const ENV = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production'
};

const LIMIT_PAGE = 100;
const LIMIT_QUOTE_IN_CATEGORY = 5;
const LIMIT_NOTIFICATION_TOPBAR = 5;
const PER_PAGE = 20;

const SLASH_DATE_FORMAT = 'DD/MM/YYYY';
const MINUS_DATE_FORMAT = 'YYYY-MM-DD';
const SPACE_DATE_FORMAT = 'DD MMM YYYY';
const SPACE_DATE_TIME_FORMAT = 'DD MM YYYY HH:mm:ss';
const SPACE_DATE_FULL_MONTH_FORMAT = 'DD MMMM YYYY';
const HOME_BOTTOM = 'HOME_BOTTOM';

const VALIDATE = {
  EMAIL: 'This field has an invalid email format',
  REQUIRED: 'This field is required',
  POSITIVE_NUMBER: 'This field must be greater than or equal to 0',
  GREATER: 'This number must be greater than {number}',
  NUMBER: 'This field must be a number',
  MAX_LENGTH: 'This field must be a number',
  TOO_LONG: 'Too long, maximum is {count, number} characters',
  TOO_SHORT: 'Too short, minimum is {count, number} characters',
};

const SWITCH_KEY_STACK = {
  top: 'TOP ANSWER',
  latest: 'LATEST ANSWER'
};

const SWITCH_MODEL = {
  categories: 'Categories',
  az: 'A - Z',
  themes: 'Themes',
};

const SWITCH_MODELS = [SWITCH_MODEL.themes, SWITCH_MODEL.categories, SWITCH_MODEL.az];

const ALL_OPTION = {
  id: '',
  name: 'All'
};

const SWITCH_QUESTION = {
  latest: 'LATEST',
  popular: 'POPULAR',
  az: 'A - Z',
};

const SWITCH_QUESTIONS = [SWITCH_QUESTION.latest, SWITCH_QUESTION.popular, SWITCH_QUESTION.az];

const SWITCH_PODCASTS = [SWITCH_QUESTION.latest, SWITCH_QUESTION.az];

const SWITCH_MENTAL_MODEL = {
  categories: 'CATEGORIES',
  themes: 'Themes',
};

const MODEL_CATEGORIES = {
  trending: {
    label: 'TRENDING',
    value: 'trending'
  },
  favorite: {
    label: 'MY FAVORITES',
    value: 'my-favorites'
  },
  az: {
    label: 'A - Z',
    value: ''
  },
};

const SWITCH_MENTAL_MODELS = [SWITCH_MENTAL_MODEL.themes, SWITCH_MENTAL_MODEL.categories];

const ROLE = {
  TRIAL_PREMIUM: {
    value: 'ROLE_IS_TRIAL_PREMIUM_USER',
    name: 'Trial Premium'
  },
  PREMIUM: {
    value: 'ROLE_IS_PREMIUM_USER',
    name: 'Trial Premium'
  },
  FREE: {
    value: 'ROLE_IS_FREE_USER',
    name: 'Free'
  },
}

const SWITCH_KEYSTACK = {
  popular: 'POPULAR',
  latest: 'LATEST',
  // az: 'A - Z',
};

const SWITCH_KEYSTACKS = [
  SWITCH_KEYSTACK.popular,
  SWITCH_KEYSTACK.latest,
  // SWITCH_KEYSTACK.az,
];

const NOTIFICATOR_MESSAGES_NUMBER = 4;

const ASK_AI_LIMIT_MESSAGE = {
  PREMIUM_LIMIT: 'PREMIUM_LIMIT',
  FREE_LIMIT: 'FREE_LIMIT'
}

export {
  SPACE_DATE_FULL_MONTH_FORMAT,
  VALIDATE,
  URL_PATH,
  STYLE,
  ENV,
  SLASH_DATE_FORMAT,
  SPACE_DATE_FORMAT,
  MINUS_DATE_FORMAT,
  SPACE_DATE_TIME_FORMAT,
  HOME_BOTTOM,
  LIMIT_PAGE,
  LIMIT_QUOTE_IN_CATEGORY,
  SWITCH_KEY_STACK,
  SWITCH_MODEL,
  ALL_OPTION,
  SWITCH_QUESTION,
  SWITCH_QUESTIONS,
  SWITCH_MODELS,
  SWITCH_MENTAL_MODEL,
  SWITCH_MENTAL_MODELS,
  SWITCH_PODCASTS,
  MODEL_CATEGORIES,
  ROLE,
  SWITCH_KEYSTACK,
  SWITCH_KEYSTACKS,
  NOTIFICATOR_MESSAGES_NUMBER,
  LIMIT_NOTIFICATION_TOPBAR,
  PER_PAGE,
  ASK_AI_LIMIT_MESSAGE
};
