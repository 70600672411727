import React from 'react';

const features = [
  {
    name: 'Mentorship Program',
    description:
      'Connect with experts and thought leaders in diverse fields. Learn firsthand from professionals who have harnessed the power of mental models in their careers and lives.',
  },
  {
    name: 'Interactive Workshops',
    description:
      'Dive into immersive learning sessions where concepts are transformed into tangible skills. These live sessions, hosted by industry experts, will offer hands-on experiences and active participation.',
  },
  {
    name: 'Personalized Learning Paths',
    description:
      'Your journey is unique. We\'re introducing AI-driven paths that adapt to your interests and learning pace, offering a more tailored and efficient experience.',
  },
  {
    name: 'Community Forums',
    description:
      'A space for open discussions, debates, and knowledge exchange on everything related to mental models, personal growth, and more.',
  },
  {
    name: 'Collaborative Projects',
    description:
      'Learn by doing. Engage in projects that allow you to apply mental models in real-world scenarios, collaborating with fellow members and receiving feedback from mentors.',
  },
  {
    name: 'Leader Highlights',
    description:
      'Explore interviews (in podcast and print) with leaders and influencers, discovering the mental models they use to navigate challenges and achieve success.',
  },
];

export default function StayOnTopSession() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-black sm:text-[40px] leading-none">
          Expanding Your Cognitive Horizons
          </h2>
          <p className="mt-6 text-lg leading-8 text-black opacity-60">
          At MentalModelHub, we are in relentless pursuit of growth—not just for ourselves but for our valued community of thinkers, learners, and innovators. Here's a sneak peek of what's on our horizon to further elevate your e-learning experience.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt className="font-semibold text-black text-lg">
                {feature.name}
              </dt>
              <dd className="mt-1 text-neutral-700 font-normal">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
