import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  bootstrapRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: `1px solid #B7B7B7`,
    fontSize: 16,
    lineHeight: '24px',
    padding: '6px 16px',
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 800,
  }
}));

const ReduxModelTextField = ({ input, meta, inputClasses = null, ...rest }) => {
  const classes = useStyles();

  return (
    <TextField
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.bootstrapRoot,
          input: cx(classes.bootstrapInput, inputClasses)

        }
      }}
      error={meta && meta.touched && meta.invalid}
      helperText={meta && meta.touched && meta.error}
      {...input}
      {...rest}
    />
  );
};

export default ReduxModelTextField;
