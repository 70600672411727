import React from 'react'
import {  Link } from "react-router-dom"
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid';
import renderHTML from 'react-render-html';
import { URL_PATH } from '../../constants';

const pageDetail = [
    {
    path:URL_PATH.MY_COLLECTION.PATH,
    item:[
            {
            title:'<span>Welcome to "My Collection"</span>',
          
            },
            {
            title:`<a href=${URL_PATH.SIGN_UP.PATH} className="border-b border-slate-500"><b>Sign Up Now & Embark on Your Tailored Learning Journey</b></a>`,

            },
            {
            title:'<span>Sign up now to personalize your experience. Save and revisit your favorite models, building a unique cognitive path that grows with you.<span>',
          
            },
            {
            title:`<span><b>Curate Your Learning:</b>"My Collection" is your space to personalize your learning journey. Sign up now to start saving and revisiting your favorite models. Create a tailored path of cognitive exploration that's uniquely yours.<span>`,
          
            },
            {
            title:`<span><b>Begin Building Your Collection:</b> As a registered member, you'll have the exclusive privilege to save your discoveries and insights. Revisit them anytime to deepen your learning and reflection.</span>`,
          
            },
        ]
    }, 
    {
    path:URL_PATH.ASK_AI.PATH,
    item:[
            {
            title:'<span>Discover the Power of AI-Driven Insights!<span>',
            },
            {
            title:`<a href=${URL_PATH.SIGN_UP.PATH} className="border-b border-slate-500"><b>Sign Up Now & Explore with Ask AI!</b></a>`,
           
            },
            {
            title:`<span><b>Dive into a World of Knowledge: </b> Ask questions and get answers through the lens of diverse mental models. Let our AI guide you to new perspectives and deeper understanding.</span>`,
           
            },
            {
            title:`<span><b>Exclusive Access Awaits: </b>Join us today and unlock the full potential of Ask AI. Our AI is ready to suggest the most applicable models for your queries.</span>`,
           
            },
            {
            title:`<span><b>Limited Offer for New Members: </b> Sign up now and enjoy the privilege of asking up to 5 insightful questions per day on our complimentary plan.</span>`,
           
            },
            {
            title:`<span><b>Don't Miss Out: </b>This feature is exclusively available to our registered members. Create your account now and start your journey of exploration and learning with Ask AI.</span> `,
           
            },
        ]
    }

]

const JointWithUsPage = ({  
    questionTrendings,
    getQuestionTrendings,
    location
    }) => {

        const [description, setDescription] = React.useState(pageDetail.filter(item=> item.path===URL_PATH.ASK_AI.PATH));
        const fromPage = location?.search.replace(/\?/g, '');
  
        function joinDetail(searchkey) {
                switch (searchkey) {
                    case URL_PATH.MY_COLLECTION.PATH.replace(/\//g, ''):
                    return setDescription(pageDetail.filter(item=> item.path===URL_PATH.MY_COLLECTION.PATH))
                    case URL_PATH.ASK_AI.PATH.replace(/\//g, ''):
                    return setDescription(pageDetail.filter(item=> item.path===URL_PATH.ASK_AI.PATH));
                    default:
                        return setDescription(pageDetail.filter(item=> item.path===URL_PATH.ASK_AI.PATH))
                    
                }
        }
        React.useEffect(() => {
            getQuestionTrendings();
            
        }, []);
        React.useEffect(() => {
            joinDetail(fromPage)
        }, [location]);

        return (
            <div className="flex flex-col lg:col-span-9 xl:col-span-10 xl:grid xl:grid-cols-12">
            <main className="order-last xl:order-none xl:col-span-8 min-w-0 flex-1 p-4 xl:block rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] bg-white">
            <div className='w-full p-8 rounded-2xl  shadow-[#0000001A_0px_4px_20px_0px] bg-accent-blurred mb-4 ring-1 ring-inset ring-gray-900/5 leading-5'>
            {description[0].item.map((detail,index)=>(
                <div key={detail.title} className={`${index===0 ? 'text-xl font-bold flex justify-center mb-8' : 'text-sm line mb-4'}`}>
                {renderHTML(detail.title)}
                </div>
            ))}
            </div>
            <button type='button' className='flex bg-[#52B3D0] py-3 px-4 rounded-[36px] text-sm ml-auto text-white'><a  href={URL_PATH.SIGN_UP.PATH}>Sign Up</a></button>
            </main>
            <aside className="order-first mb-4 xl:order-none xl:col-span-4">
                <div className="sticky top-4 space-y-4">
                {questionTrendings.length ? (
                    <section className="hidden xl:block rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] m-4 mt-0" aria-labelledby="trending-heading">
                    <div className="rounded-xl bg-white">
                        <div className="p-6">
                        <h2 id="trending-heading" className="text-base font-medium text-neutral-900">
                            Trending
                        </h2>
                        <div className="mt-6 flow-root">
                            <ul className="-my-4 divide-y divide-neutral-200">
                            {questionTrendings.map((question) => (
                                <li key={question._id._id} className="space-x-3 py-4">
                                <div className="min-w-0">
                                    <Link to={`/answers/${question._id[0].slug}`} className="text-sm text-neutral-800 hover:no-underline hover:text-accent-hover">{question._id[0].question}</Link>
                                    <div className="mt-2 flex">
                                    <span className="inline-flex items-center text-sm">
                                        <button
                                        type="button"
                                        className="inline-flex space-x-2 text-neutral-500 hover:text-neutral-500"
                                        >
                                        <ChatBubbleLeftEllipsisIcon className="h-5 w-5" aria-hidden="true" />
                                        <span className="font-medium text-neutral-900">{question.count}</span>
                                        </button>
                                    </span>
                                    </div>
                                </div>
                                </li>
                            ))}
                            </ul>
                        </div>
                        </div>
                    </div>
                    </section>
                ) : null}
                </div>
            </aside>
        
            </div>
        )
}


export default JointWithUsPage


