import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import agent from '../../agent';
import { AUTH } from '../../actions';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';

import { CloseDialogButton, Switch, SnackBarCenter } from '../../components';
import { DivLoadingComponent } from "../../components/loading"

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    overflowY: 'visible',
    maxWidth: 566,
    margin: 20,
    width: '100%',
    marginTop: 50,
  },
  close: {
    boxShadow: "none",
  },
  label: {
    margin: "0 0 0 10px",
    fontFamily: "'Nunito', sans-serif",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 800,
    color: "#513a36",
  },
  text: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: 13,
    lineHeight: "21px",
    fontWeight: 800,
    color: "rgba(81, 58, 54, 0.5)",
  }
}));

const INITIAL_FIELDS = {
  comment: false,
  // mailing_list: false,
  // premium: false,
}
const NOTIFICATION_FIELD_TO_KEY = {
  "comment": "NOTIFY-COMMENT",
  // "mailing_list": "NOTIFY-MAILING-LIST",
  // "premium": "NOTIFY-PREMIUM",
}

const NotificationDialog = ({
  notifications,
  open,
  onClose,
  fullScreen,
  message,
  getNotificationSettings,
  updateNotificationSettings,
}) => {
  const classes = useStyles();
  const [fields, setFields] = useState({ ...INITIAL_FIELDS })
  const [inProgress, setInProgress] = useState(true)

  // request notification settings every time dialog is open
  // so the most recent settings is used
  useEffect(() => {
    if (open) {
      setInProgress(true)
      getNotificationSettings()
    }
  }, [open])

  // populate fields when notification settings renewed
  useEffect(() => {
    if (notifications && inProgress) {

      // for each tracked field get its boolean value
      const populatedFields = Object.entries(NOTIFICATION_FIELD_TO_KEY)
        .reduce((acc, [fieldName, fieldKey]) => {
          acc[fieldName] = !!notifications[fieldKey]
          return acc
        }, {})

      setFields(prev => ({ ...prev, ...populatedFields }))
      setInProgress(false)
    }
  }, [notifications])

  const handleClose = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onClose();
  };

  const handleChange = (event) => {
    const { name, checked } = event.target

    setFields(prev => ({ ...prev, [name]: checked }))

    updateNotificationSettings([{ key: NOTIFICATION_FIELD_TO_KEY[name], value: checked }])
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth="md"
      scroll="paper"
      fullScreen={fullScreen}
      className="notification-dialog"
    >
      {fullScreen || <CloseDialogButton onClick={handleClose} className={classes.close} />}
      <DialogContent className="body-dialog">
        <h2>NOTIFICATIONS</h2>
        {inProgress && !notifications ? <DivLoadingComponent /> :
          <>
            <Box mb={3}>
              <FormControlLabel
                classes={{
                  label: classes.label,
                }}
                control={
                  <Switch
                    checked={fields.comment}
                    onChange={handleChange}
                    name="comment"
                  />
                }
                label="Answer Comments"
              />
              <p className={classes.text}>
                You will receive email notifications each time someone comments on your answer.
              </p>
            </Box>
            {/* <Box mb={3}>
              <FormControlLabel
                classes={{
                  label: classes.label,
                }}
                control={
                  <Switch
                    checked={fields.mailing_list}
                    onChange={handleChange}
                    name="mailing_list"
                  />
                }
                label="Mailing List"
              />
            </Box>
            <Box mb={3}>
              <FormControlLabel
                classes={{
                  label: classes.label,
                }}
                control={
                  <Switch
                    checked={fields.premium}
                    onChange={handleChange}
                    name="premium"
                  />
                }
                label="Premium Mailing List (Premium Subscribers only)"
              />
            </Box> */}
          </>
        }
        <SnackBarCenter message={message} />
      </DialogContent>
    </Dialog>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getNotificationSettings: () => {
      return dispatch({
        type: AUTH.GET_NOTIFICATION_SETTINGS,
        payload: agent.Auth.getNotificationSettings()
      });
    },
    updateNotificationSettings: (data) => {
      return dispatch({
        type: AUTH.UPDATE_NOTIFICATION_SETTINGS,
        payload: agent.Auth.updateNotificationSettings(data),
      });
    },
  };
};

function mapStateToProps(state, ownProps) {
  return {
    notifications: state.common?.currentUser?.notifications,
    message: state.common && state.common.message,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDialog);
