import { SAMPLE } from '../actions/sample';

export default (state = {}, action) => {
  switch (action.type) {
    case SAMPLE.PAGE_LOAD:
      return {
        ...state
      };
    case SAMPLE.GET_MODELS:
      return {
        ...state,
        inProgressGetModels: false,
        models: action.payload && action.payload.data
      };
    case SAMPLE.GET_MODEL_BY_ID: {
      const data = action.payload;
      if (data && data.id) {
        if (data.next) {
          data.nextId = data.next.split('/').pop();
        }
        if (data.prev) {
          data.prevId = data.prev.split('/').pop();
        }
      }
      return {
        ...state,
        inProgressGetModelById: false,
        modelById: data || null
      };
    }
    case SAMPLE.FAVORITE_MODEL: {
      const { payload } = action;
      if (payload && payload.message) {
        if (payload.message === "FAVORITE_LIMITED") {
          return { ...state, favoriteLimited: true, inProgressFavorite: false };
        }
        const modelById = { ...state.modelById };
        modelById.is_favorited = !modelById.is_favorited;
        return { ...state, modelById, favoriteLimited: false, inProgressFavorite: false };
      }
      return { ...state, inProgressFavorite: false };
    }
    case SAMPLE.GET_SIMILAR_MODEL:
      return {
        ...state,
        relatedModels: Array.isArray(action.payload) ? action.payload : []
      };
    case SAMPLE.ASYNC_START:
      switch (action.subtype) {
        case SAMPLE.FAVORITE_MODEL:
          return {
            ...state,
            inProgressFavorite: true
          };
        case SAMPLE.GET_MODELS:
          return {
            ...state,
            inProgressGetModels: true
          };
        case SAMPLE.GET_MODEL_BY_ID:
          return {
            ...state,
            inProgressGetModelById: true
          };
      }
      break;
    default:
      return state;
  }
  return state;
};
