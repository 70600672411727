import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { CloseDialogButton, ProgressContainedButton } from '../../components';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    overflowY: 'visible',
    maxWidth: 760,
    margin: 20,
    width: '100%',
    marginTop: 50,
  }
}));

const HowItWorkKeystackDialog = ({ open, onClose, fullScreen, onOk, isPremium }) => {
  const classes = useStyles();
  const handleClose = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth="md"
      scroll="paper"
      fullScreen={fullScreen}
      className="how-it-works-keystack-dialog about-dialog"
    >
      {fullScreen || <CloseDialogButton onClick={handleClose} />}
      <DialogContent className="body-dialog">
        <h2>How it works</h2>
        {isPremium ? (
          <div className="content">
            <div className="note">
              <p>There are 2 ways to use answers: </p>
              <b>
                1.	Learn from answers others have built
                  </b>
              <br /> <br />
              <p>When you see a question that relates to you, click on it to see other people’s answers. You can read why members have included particular models and how they address the question posed. </p>
              <p>If you are logged in you can also save your favorite mental models to My Favorites or pay respect to your favorite answers by clicking on “Respect”.</p>
              <br />
              <b>
                2.	Build your own answers
                  </b>
              <br /> <br />
              <p>You can also build your own.</p>
              <p>1. Click ‘Add Answer’ to start your own.</p>

              <p>All the mental models are listed for you to select from. Hovering over one will give you a short summary, and clicking on it will give you the snapshot in a pop up window. Close the window by clicking on the ‘x’ in the top right of the window, or simply clicking anywhere outside the window.</p>

              <p>2. Click on all the models you want to add to your answer (if you want to remove one after selecting it, just click a second time).</p>

              <p>3. Click ‘Next’ at the top right of the models. Now you have the chance to add your own thoughts and insights to each of the models in your answer.</p>

              <p>4. Finally you can ‘Preview’ your content and then ‘Add Answer’.</p>

              <p>Congratulations! You’ve created useful information for your own reference and to benefit the entire community!</p>
            </div>
          </div>
        ) : (
            <div className="content">
              <div className="note">
                <p>There are 2 ways to use answers: </p>
                <b>
                  1.	Learn from answers others have built
                  </b>
                <br /> <br />
                <p>Read why members have included particular models and how they address the question posed. </p>
                <p>If you are logged in you can also save your favorite mental models to My Favorites or pay respect to your favorite answers by clicking on “Respect”.</p>
                <br />
                <b>
                  2.	Build your own answers
                  </b>
                <br /> <br />
                <p>You can also build your own answer. Try it out, you can always edit your answer at any time.</p>
              </div>
              <div className="action-btn">
                <ProgressContainedButton
                  label="UPGRADE"
                  onClick={() => onOk()}
                />
              </div>
            </div>
          )}
      </DialogContent>
    </Dialog>
  );
}

export default HowItWorkKeystackDialog;
