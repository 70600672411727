import React from 'react';
import renderHTML from 'react-render-html';

const imgOval = '/assets/img/contact/oval23.png';
const imgMain = '/assets/img/book/book.png';
const imgBackground = '/assets/img/login/img_phone@2x.png';
const imgMainMobile = '/assets/img/book/mobile.png';

const data = {
  title: `Books`,
  detail: [
    `We’re producing a Mentalmodelhub book titled “How Entrepreneurs Think”. The book will be available in both a physical and electronic version.`,
    `“How Entreprenurs Think” will feature excerpts from some of the most influential books on entrepreneurship ever written.`,
    `The title will be featured in our distinct Mentalmodelhub Book Format, a unique layout design that allows readers to quickly and effectively view the mental models, strategies and frameworks used by leaders and entrepreneurs. `,
    `“How Entrepreneurs Think” will be available first to those who subscribe to our mailing list.`
  ],
  email: `hello@mentalmodelhub.com`,
  textEmail: `Join our mailing list today.`,
  imgBackground,
  imgMain,
  imgOval,
  imgMainMobile,
  quote:
    'In my “grit lexicon,” therefore, purpose means “the intention to contribute to the well-being of others.”',
  author: '- Angela Duckworth',
  job: 'Grit'
};

function BookPage() {
  return (
    <div className="content-center page-content book-page">
      <img src={data.imgOval} alt="" className="oval" />
      <div className="row p-content mx-0 book-container">
        <div className="content col-sm-12 col-md px-0">
          <h1>{data.title}</h1>
          <div className="detail">
            {Array.isArray(data.detail) &&
              data.detail.map((detail, index) => <p key={index}>{renderHTML(detail)}</p>)}
            {/* <a className="email" href={`mailto:${data.email}`}>{data.email}</a> */}
          </div>
        </div>
        <div className="col-sm-12 col-md px-0">
          <img src={data.imgBackground} alt="Book background" className="bg" />
          <img src={data.imgMainMobile} alt="Book" className="main" />
          <div className="background-more">
            <p className="quote">{data.quote}</p>
            <p className="author">{data.author}</p>
            <p className="job">{data.job}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookPage;
