import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';


import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import agent from '../../../agent';

import { ReplyForm, Discussion } from '../../components';
import { DivLoadingComponent, UserCard, ProgressAutoButton } from '../../../components';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';

function Discussions({
  open,
  keystack,
  inViewCreateDiscussion,
  hideCreateDiscussion,
  currentUser,
  getDiscussions,
  createDiscussion,
  actionPrefix,
}) {
  // only fetch discussion once on first show
  const [isReady, setIsReady] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false)
  useEffect(() => {
    // only continue if parent open state is true
    if (!open) {
      return;
    }
    // if data is already fetched, skip the rest
    if (isReady) {
      return;
    }

    // if keystack has no discussion, skip the rest
    if (!keystack.number_discussion) {
      setIsReady(true)
      return;
    }

    // fetch discussion by keykeystack
    getDiscussions({ id: keystack.id }, ({ more }) => {
      setHasMore(more)
      setIsReady(true)
    });
  }, [open])

  const handleLoadingAll = () => {
    setLoadingAll(true);
    getDiscussions({
      id: keystack.id,
      all: true,
      param: { reset: true }
    }, () => {
      setHasMore(false)
      setLoadingAll(false);
    });
  }

  return (
    // <>
    //   <Grid container>
    //     {inViewCreateDiscussion && (<>
    //       <Grid item xs={12}>
    //         <Box mb={1.2}>
    //           <UserCard user={currentUser} />
    //         </Box>
    //         <ReplyForm id={keystack.id} onClose={hideCreateDiscussion} onSubmit={createDiscussion} />
    //       </Grid>
    //     </>)}
    //     {keystack.discussion?.data?.map(discussion => (
    //       <Grid item key={discussion.id} xs={12}>
    //         <Discussion item={discussion} actionPrefix={actionPrefix} />
    //       </Grid>
    //     ))}
    //     {hasMore && (
    //       <Grid item xs={12} style={{ textAlign: "center" }}>
    //         <ProgressAutoButton
    //           label="Show All"
    //           disabled={loadingAll}
    //           progress
    //           onClick={handleLoadingAll}
    //         />
    //       </Grid>)}
    //     {!isReady && <Grid item xs={12}><DivLoadingComponent /></Grid>}
    //   </Grid>
    // </>
    <div className={(keystack.discussion?.data?.length || currentUser) && "mt-3 border-t border-grey-300"}>
      {currentUser && (
        <ReplyForm
          user={currentUser}
          label="Add Comment"
          id={keystack.id}
          onClose={hideCreateDiscussion}
          onSubmit={createDiscussion}
        />
      )}
      {keystack.discussion?.data?.map(discussion => (
        <Discussion key={discussion.id} item={discussion} actionPrefix={actionPrefix} />
      ))}
    </div>
  );
}

const mapDispatchToProps = (dispatch, props) => {
  const {actionPrefix} = props
  return {
    getDiscussions: (data, callback) => dispatch({
        type: actionPrefix.GET_DISCUSSION,
        payload: agent.KeyStack.getDiscussion(data),
        callback,
        param: data.param,
        withPagination: true
      }),
    createDiscussion: (data, callback) => dispatch({
        type: actionPrefix.CREATE_DISCUSSION,
        payload: agent.KeyStack.createDiscussion(data),
        callback
      }),
  };
};

function mapStateToProps(state) {
  return {
    currentUser: state.common?.currentUser,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Discussions);
