/* Comment can represent discussion or discussion comment as logic is almost the same */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { ReplyForm, Respect } from ".";


const useStyles = makeStyles((theme) => ({
  createComment: {
    padding: "0 6px",
    border: 0,
    margin: "0 14px",
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    color: "#513a36",
    textDecoration: "underline",
    backgroundColor: "transparent",
    appearance: "none",
    cursor: "pointer",
    outline: "none",
  },
  toolbar: {
    "& button": {
      margin: "0 8px",
    },
    "& button:last-child": {
      margin: 0,
    }
  },
  text: {
    fontFamily: "Inter, sans-serif",
    fontSize: "15px",
    lineHeight: "23px",
    color: "#362826",
    margin: "0 0 10px 0",
    whiteSpace: 'pre-wrap',
  }
}))

function Comment({
  item,
  isOwner = false,
  onCreate = null,
  onUpdate,
  onRespect,
  onDelete
}) {
  const classes = useStyles()

  const { id, content, user, created_at } = item

  const [inViewUpdateComment, setInViewUpdateComment] = useState(false)

  const showUpdateComment = () => {
    setInViewUpdateComment(true)
  }
  const hideUpdateComment = () => {
    setInViewUpdateComment(false)
  }

  const handleDelete = () => {
    onDelete({ id })
  }

  return (
    // <Grid item container>
    //   <Grid item container xs={12} component={Box} mb={1.2} alignItems="center">
    //     <Grid item style={{ flex: 1 }}>
    //       <UserCard user={user} />
    //     </Grid>
    //     <Grid item>
    //       <Grid container alignItems="center">
    //         {isOwner && (
    //           <Box className={classes.toolbar}>
    //             <IconButton onClick={showUpdateComment} className={classes.action} aria-label="edit comment" size="small">
    //               <EditIcon /></IconButton>
    //             <IconButton onClick={handleDelete} className={classes.action} aria-label="delete comment" size="small">
    //               <DeleteIcon /></IconButton>
    //           </Box>
    //         )}
    //       </Grid>
    //     </Grid>
    //   </Grid>
    //   <Grid item xs={12}>
    //     {inViewUpdateComment ? (
    //       <ReplyForm id={item.id} label="SAVE" onClose={hideUpdateComment} onSubmit={onUpdate} initialValue={content} />
    //     ) : (
    //         <p className={classes.text}>{content}</p>
    //       )
    //     }
    //   </Grid>
    //   {!inViewUpdateComment && (
    //     <Grid item container direction="row" xs={12} alignItems="center" wrap="nowrap">
    //       <Grid item style={{ flex: 1 }} />
    //       <Grid item>{/*  component={Box} pr={6} > */}
    //         <Grid container>
    //           {onCreate && (
    //             <button type="button" onClick={onCreate} className={classes.createComment}>Reply</button>
    //           )}
    //           <Respect id={id} totalRespects={item.total_respects} onRespect={onRespect} />
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   )}
    // </Grid>
    <div className={`flex pt-3 ${!onCreate && "ml-[3.1rem] sm:ml-14"}`}>
      <img
        className="inline-block h-10 w-10 rounded-full object-cover"
        src={user.profile_image}
        alt=""
      />
      <div className="pl-2 w-full">
        <p className="text-sm font-medium text-neutral-900">{`${user.first_name} ${user.last_name}`} <span className="mt-1 mr-2 text-xs text-neutral-500 font-light">· {moment(created_at).fromNow()}</span> </p>
        {inViewUpdateComment ? (
          <ReplyForm id={item.id} label="Save" user={user} isEdit={isOwner} onClose={hideUpdateComment} onSubmit={onUpdate} initialValue={content} />
        ) : (
          <>
            <p className="mt-1 space-y-4 text-sm text-neutral-700 font-light">{content}</p>
            <div className="mt-1 flex space-x-5">
              <span className="inline-flex items-center text-sm">
                <Respect id={id} totalRespects={item.total_respects} onRespect={onRespect} />
              </span>
              {onCreate && (
                <span className="inline-flex items-center text-sm">
                  <button type="button" onClick={onCreate} className="text-xs text-neutral-500">Reply</button>
                </span>
              )}
              {isOwner && (
                <>
                  <span className="inline-flex items-center text-sm">
                    <button type="button" onClick={showUpdateComment} className="text-xs text-neutral-500">Edit</button>
                  </span>
                  <span className="inline-flex items-center text-sm">
                    <button type="button" onClick={handleDelete} className="text-xs text-neutral-500">Delete</button>
                  </span>
                </>
              )}
            </div>
          </>
        )}
        
      </div>
    </div>
  )
}

export default Comment;
