import {
  FireIcon,
  HomeIcon,
  // UserGroupIcon,
  StarIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';

export default [
  { name: 'Home', href: '/', icon: HomeIcon },
  { name: 'Mental Models', href: `/mental-models`, icon: FireIcon },
  // { name: 'Thought Leaders', href: '/thought-leaders', icon: UserGroupIcon },
  { name: 'My Collection', href: '/my-collection', auth: true, icon: StarIcon },
  { name: 'Ask AI', href: '/ask-ai', auth: true, icon: SparklesIcon },
];
