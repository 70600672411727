import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles/index';

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
        marginRight: 0,
    },
	fullWidth: {
        width: '100%'
    }
});

function SelectForm(props) {
    const {classes, fullWidth = true, items, valueKey, textKey, onChange, value, label, name, readOnly = false, uppercase, ...rest} = props;

    return (
        <FormControl className={`${classes.formControl} ${fullWidth ? classes.fullWidth : ''}`}>
            <InputLabel>{label}</InputLabel>
            <Select
                onChange={onChange}
                value={value}
                inputProps={{
	                name: name,
	                readOnly: readOnly,
                }}
                {...rest}
            >
                {
                    items && items.length !== 0 && items.map((item, index) => {
                        const id = item[valueKey || 'id'];
                        const text = item[textKey || 'name'];
                        return (
                            <MenuItem key={id} value={id}>{uppercase ? text.toUpperCase() : text}</MenuItem>
                        );
                    })
                }
            </Select>
        </FormControl>
    );
}

export default withStyles(styles)(SelectForm);