const styles = theme => ({
  dialogPaper: {
    overflowY: 'visible',
    margin: 20,
    width: '100%'
  },
  dialogPaperAnnounce: {
    overflowY: 'visible',
    maxWidth: 710,
    margin: 20,
    width: '100%',
    // 'border-radius': 8
  },
});

export default styles;