import { PODCAST } from '../actions';
import { findIndexById } from '../helpers';

const initState = {
  podcasts: [],
  more: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case PODCAST.GET_BY_ID: {
      let podcast = {};
      if (action.payload) {
        podcast = action.payload;
        if (action.payload.next) {
          action.payload.nextId = action.payload.next.split('/').pop();
        }
        if (action.payload.prev) {
          action.payload.prevId = action.payload.prev.split('/').pop();
        }
      }
      return { ...state, inProgressById: false, podcast };
    }
    case PODCAST.GET_MORE_PODCASTS: {
      let podcasts = state.podcasts;
      const payload = action.payload;
      if (payload && payload.data) {
        podcasts = podcasts.concat(payload.data);
      }
      return {
        ...state,
        inProgressPodcasts: false,
        podcasts,
        more: payload && !!payload.next
      };
    }
    case PODCAST.GET_PODCASTS: {
      const payload = action.payload;
      return {
        ...state,
        inProgressPodcasts: false,
        podcasts: Array.isArray(payload.data) ? payload.data : [],
        more: payload && !!payload.next
      };
    }
    case PODCAST.RESET:
      return {
        ...state,
        success: null
      };
    case PODCAST.PAGE_UNLOAD:
      return initState;
    case PODCAST.ASYNC_START:
      switch (action.subtype) {
        case PODCAST.GET_BY_ID:
          return { ...state, inProgressById: true };
        case PODCAST.GET_MORE_PODCASTS:
        case PODCAST.GET_PODCASTS:
          return { ...state, inProgressPodcasts: true };
        default:
          return state;
      }
    default:
      return state;
  }
};
