import queryString from 'query-string';
import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { email, required } from 'redux-form-validators';
import { VALIDATE } from '../../constants';
import { LoadingComponent } from '../../components';
import { validateRedux } from '../../helpers';

let validations = {
  email: [required({ msg: VALIDATE.REQUIRED }), email({ msg: VALIDATE.EMAIL })],
  password: [required({ msg: VALIDATE.REQUIRED })]
};

let validate = values => validateRedux(values, validations);

const VerifyPage = ({ onVerify, inProgress, location }) => {
  useEffect(() => {
    const parsed = queryString.parse(location && location.search);
    if(!parsed || !parsed.userId || !parsed.code) {
      return;
    }
    onVerify({ userId: parsed.userId, code: parsed.code})
  }, []);

  return (
    <div className="content-center page-content verify-page">
      <div className="row p-content mx-0 verify-container">
        <div className="content col-sm-12 col-md px-0 text-center">
          <h1>Verify Account</h1>
          <LoadingComponent />
        </div>
      </div>
    </div>
  );
}

export default reduxForm({
  form: 'VerifyPage',
  validate
})(VerifyPage);
