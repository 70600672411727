import { PremiumMembershipPage } from '../../pages/index';
import { connect } from 'react-redux';
import { COMMON } from '../../actions/common';

const mapDispatchToProps = dispatch => {
  return {
    onRedirectTo: to => {
      return dispatch({ type: COMMON.REDIRECT_TO, to: to });
    }
  };
};

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PremiumMembershipPage);
