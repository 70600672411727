import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    margin: theme.spacing(1),
    color: '#FFFFFF!important',
    backgroundColor: 'var(--bg-green)',
    outline: 'none',
    border: 'none',
    '&:hover': {
      backgroundColor: 'var(--bg-green)'
    },
    '& span': {
      fontFamily: "'Nunito', sans-serif",
      fontWeight: 800,
    }
  },
  disabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  }
});

function ProgressIconButton(props) {
  const {
    classes,
    progress = true,
    label,
    progressColor = '#FFFFFF',
    ...rest
  } = props;
  let progressIcon = '';
  let labelButton = label;
  const styleProgress = {
    color: progressColor
  };
  if (progress && rest.disabled) {
    progressIcon = (
      <CircularProgress size={20} thickness={5} style={styleProgress} />
    );
    labelButton = '';
  }

  return (
    <Button
      classes={{
        root: classes.root,
        disabled: classes.disabled
      }}
      variant="raised"
      color="primary"
      {...rest}
    >
      {labelButton}
      {progressIcon}
    </Button>
  );
}

export default withStyles(styles)(ProgressIconButton);
