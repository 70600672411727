import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PlusIcon } from '@heroicons/react/20/solid'
import { StarIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'flowbite-react';
import { SAMPLE } from '../../actions';
import agent from '../../agent';

function ModelButton({ model, onFavoriteModel, toggleModelDialog }) {
  const [isFavorited, setIsFavorited] = useState(model.is_favorited);

  const onFavorite = () => {
    onFavoriteModel(model.id);
    setIsFavorited(true);
  }
  return (
    <div
      className="inline-flex items-center rounded-full px-3 py-1 mr-2 my-1 text-xs font-normal cursor-default bg-neutral-900 text-neutral-200"
    >
      {!isFavorited ? (
        <Tooltip content="Add to Collection">
          <PlusIcon onClick={onFavorite} className="-ml-1 mr-0.5 h-4 w-4 text-neutral-200 cursor-pointer" aria-hidden="true" />
        </Tooltip>
      ) : (
        <StarIcon className="-ml-1 mr-0.5 h-5 w-5 text-neutral-900" aria-hidden="true" />
      )}
      <button type="button" onClick={toggleModelDialog}>{model.name}</button>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
    onFavoriteModel: id => dispatch({
        type: SAMPLE.FAVORITE_MODEL,
        payload: agent.Model.favouriteModel(id)
      })
  });

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelButton);
