import { connect } from 'react-redux';
import AskAIPageDetail from '../../pages/sites/AskAIPageDetail';
import agent from '../../agent';
import { KEYSTACKAI, COMMON } from '../../actions';

const mapDispatchToProps = (dispatch) => ({
    getQuestionTrendings: () => dispatch({
        type: KEYSTACKAI.GET_QUESTION_TRENDING,
        payload: agent.KeyStack.getQuestionTrendings(),
      }),
    getLeadingKeyStackByGroup: (data) => dispatch({
        type: KEYSTACKAI.GET_AI_KEYSTACK,
        payload: agent.AskAI.getAIKeystack(data),
      }),
    getMoreLeadingKeyStackByGroup: (data) => dispatch({
        type: KEYSTACKAI.GET_MORE_AI_KEYSTACK,
        payload: agent.AskAI.getAIKeystack(data),
      }),
    submitQuestion: values => dispatch({
      type: KEYSTACKAI.SUBMIT_QUESTION,
      payload: agent.AskAI.submitQuestion(values)
    }),
    getModelByAnswer:values=>dispatch({
      type:KEYSTACKAI.GET_MODEL_BY_ANSWER,
      payload:agent.AskAI.getModelByAnswer(values)
    }),
    onRedirectTo: (to) => dispatch({ type: COMMON.REDIRECT_TO, to }),
    onUnload: () => dispatch({ type: KEYSTACKAI.PAGE_UNLOAD }),
    onAnnounce: (data) => dispatch({ type: COMMON.ANNOUNCE, data }),
    onAnnounced: () => dispatch({ type: KEYSTACKAI.ANNOUNCED }),
  });

function mapStateToProps(state) {
  return {
    ...state.keystackAI,
    currentUser: state.common && state.common.currentUser,
    isPremium: state.common && state.common.isPremium,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AskAIPageDetail);
