'use strict';

import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 60,
    height: 26,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: 'white',
    '&$checked': {
      transform: 'translateX(34px)',
      color: 'white',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#513A36',
        borderColor: '#513A36',
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: 'none',
    color: 'white',
  },
  track: {
    borderRadius: 26/2,
    opacity: 1,
    backgroundColor: '#A89D9B',
  },
  checked: {},
}))(Switch);

function StyledSwitch(props) {
  return (
    <AntSwitch checked={props.value || props.checked} {...props} />
  );
}

StyledSwitch.propTypes = {
};

StyledSwitch.defaultProps = {
};

export default StyledSwitch;
