import React from 'react';
import truncateString from "lodash.truncate";
import {
  ChatBubbleBottomCenterIcon,
  ChatBubbleLeftRightIcon,
  HandThumbUpIcon
} from '@heroicons/react/20/solid'
import validateRedux from './ValidateRedux';
import { SWITCH_KEYSTACK } from '../constants';


function toTitleCase(str) {
  if (str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }
  return '';
}

function isNumeric(n) {
  return isFinite(n) && parseFloat(n) == n;
}

function findIndexById(array, id) {
  let index = -1;
  if (Array.isArray(array) && array.length > 0) {
    array.some((item, i) => {
      if (item.id === id) {
        index = i;
        return true;
      }
      return false;
    });
  }
  return index;
}

const isClientSide = typeof window !== 'undefined' && window;

const sortObj = (object, attribute, asc = true) => {
  object.sort((a, b) => {
    if (a[attribute] > b[attribute]) return asc ? 1 : -1;
    if (a[attribute] < b[attribute]) return asc ? -1 : 1;
    return 0;
  });
};

const slugify = text =>
  text
    .toString()
    .toLowerCase()
    .trim()
    // Replace spaces with -
    .replace(/\s+/g, '-')
    .replace(/'+/g, '')
    // Replace & with 'and'
    .replace(/&/g, '-and-')
    // Remove all non-word chars
    .replace(/(?!\w)[\x00-\xC0]/g, '-') // eslint-disable-line
    // Replace multiple - with single -
    .trim('-')
    .replace(/\-\-+/g, '-') // eslint-disable-line
    // Remove - from start & end
    .replace(/-$/, '')
    .replace(/^-/, '');

const currencyFormatter = (currency = 'USD') => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency,
});

const readAsDataURL = (file, callback) => {
  const reader = new FileReader()
  reader.onload = (e) => {
    callback(e.target.result)
  }
  reader.readAsDataURL(file)
}

const getBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(new File([file], 'ooo', { type: 'text/csv' }))
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

const imgProfile = '/assets/img/profile/avatar-small.png';

const getSort = (switchActive) => {
  switch (switchActive) {
    case SWITCH_KEYSTACK.latest:
      return { sort: 'created_at', asc: false };
    case SWITCH_KEYSTACK.popular:
      return { sort: 'total_respects', asc: false };
    default:
      return { sort: 'created_at', asc: false };
  }
};

const messageByType = (type, content = "", shouldTruncate, userName = "") => {
  const truncatedContent = shouldTruncate ? truncateString(content, { length: 40 }) : content

  switch (type) {
    case "COMMENT":
      return `Your answer for “${truncatedContent}” has received a comment`
    case "LIKE":
      return `Your answer “${truncatedContent}” has just gained some respect`
    case "REPLY-COMMENT":
      return `${userName} has replied to your comment “${truncatedContent}”`
    default:
      return truncatedContent
  }
}

const getNotificationIconByType = (type) => {
  switch (type) {
    case "COMMENT":
      return <ChatBubbleBottomCenterIcon className="flex-none mt-3 mr-3 h-5 w-5 text-neutral-500" aria-hidden="true" />
    case "LIKE":
      return <HandThumbUpIcon className="flex-none mt-3 mr-3 h-5 w-5 text-neutral-500" aria-hidden="true" />
    default:
      return <ChatBubbleLeftRightIcon className="flex-none mt-3 mr-3 h-5 w-5 text-neutral-500" aria-hidden="true" />
  }
}

export {
  toTitleCase,
  isNumeric,
  validateRedux,
  findIndexById,
  isClientSide,
  sortObj,
  currencyFormatter,
  slugify,
  readAsDataURL,
  getBase64,
  imgProfile,
  getSort,
  messageByType,
  getNotificationIconByType
};
