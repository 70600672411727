import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import PauseIcon from '@material-ui/icons/PauseCircleOutline';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import './styles.css';

const back15Icon = '/assets/img/model/skip-15.png';

const useStyles = makeStyles(theme => ({
  playIcon: {
    fontSize: 60
  },
  playerBtn: {
    padding: 6
  },
  backTime: {
    padding: 6,
    marginRight: 14,
    marginLeft: 14
  }
}));

let audio = {};
export default function AudioPlayer({ src }) {
  const [playing, setPlaying] = useState(false);
  const [progress, setProgressValue] = useState(false);
  const classes = useStyles();

  const updateProgress = () => {
    const { duration, currentTime } = audio;
    const progress = (currentTime * 100) / duration;
    setProgressValue(progress);
  };

  useEffect(() => {
    audio = document.createElement('audio');
    audio.src = src;

    audio.addEventListener('timeupdate', updateProgress);
    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
      audio.pause();
      audio.remove();
    };
  }, []);

  const pause = () => {
    setPlaying(false);
    audio.pause();
  };
  const play = () => {
    setPlaying(true);
    audio.play();
  };

  const setProgress = e => {
    const target =
      e.target.nodeName === 'SPAN' ? e.target.parentNode : e.target;
    const width = target.clientWidth;

    const rect = target.getBoundingClientRect();

    const offsetX = e.clientX - rect.left;
    const duration = audio.duration;
    const currentTime = (duration * offsetX) / width;
    const progress = (currentTime * 100) / duration;

    audio.currentTime = currentTime;

    setProgressValue(progress);

    play();
  };

  const toggle = () => (playing ? pause() : play());

  const backTime = () => {
    const duration = audio.duration;
    const currentTime = audio.currentTime - 10;
    const progress = (currentTime * 100) / duration;

    audio.currentTime = currentTime;
    setProgressValue(progress);
  };

  return (
    <div className="audio-player">
      <div className="player-progress-container" onClick={e => setProgress(e)}>
        <span
          className="player-progress-value"
          style={{ width: progress + '%' }}
        />
      </div>
      <div className="player-options">
        <IconButton className={cx('invisible', classes.backTime)}>
          <img src={back15Icon} alt="Back" width="40px" height="40px" />
        </IconButton>
        <IconButton onClick={toggle} className={classes.playerBtn}>
          {playing ? (
            <PauseIcon htmlColor="white" className={classes.playIcon} />
          ) : (
            <PlayIcon htmlColor="white" className={classes.playIcon} />
          )}
        </IconButton>
        <IconButton onClick={backTime} className={classes.backTime}>
          <img src={back15Icon} alt="Back" width="40px" height="40px" />
        </IconButton>
      </div>
    </div>
  );
}
