import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';

const useStyles = makeStyles(theme => ({
  cssLabel: {},
  cssFocused: {},
  cssInput: {},
  cssUnderline: {
    '&:before': {
      borderBottomColor: '#F6F5F5!important'
    },
    '&:after': {
      borderBottomColor: '#A89C9A'
    }
  },
  input: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 800,
  }
}));

const ReduxTextField = ({ input, meta, ...rest }) => {
  const classes = useStyles();

  return (
    <TextField
      InputProps={{
        classes: {
          underline: classes.cssUnderline,
          input: classes.input
        },
        className: 'input-field'
      }}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel
        },
        className: 'input-label-field'
      }}
      fullWidth
      error={meta && meta.touched && meta.invalid}
      helperText={meta && meta.touched && meta.error}
      {...input}
      {...rest}
    />
  );
};

export default ReduxTextField;
