import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from './dialogStyle';

const AnnounceDialog = ({ open, onClose, onOk, message, labelBtn }) => (
  <>
    {open && (
      <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto no-scrollbar fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative p-4 w-full max-w-xl h-auto">
            <div className="relative bg-white rounded-xl shadow dark:bg-neutral-700">
              <button onClick={onClose} type="button" className="absolute top-3 right-2.5 text-neutral-500 bg-transparent hover:bg-neutral-200 hover:text-neutral-900 rounded-xl text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-neutral-800 dark:hover:text-white" data-modal-toggle="authentication-modal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
              </button>
              <div className="py-6 px-6 lg:px-8 flex flex-col	items-center">
                <h3 className="my-4 text-lg text-center font-medium text-neutral-900 dark:text-white">{message}</h3>
                {labelBtn && (
                  <button
                    type="button"
                    className="inline-flex items-center px-6 py-2.5 text-sm leading-4 font-light rounded-full text-white bg-accent-default hover:bg-accent-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400"
                    onClick={() => {
                      onClose();
                      if (onOk) {
                        onOk();
                      }
                    }}
                  >{labelBtn}</button>
                )}
              </div>
            </div>

          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </>
    )}
  </>
)

export default withStyles(styles)(AnnounceDialog);
