import { connect } from 'react-redux';
import FooterPage from '../../pages/layouts/FooterPage';
import { COMMON, HOME, AUTH } from '../../actions';
import agent from '../../agent';

const mapDispatchToProps = dispatch => ({
    onRegisterFreePlan: values => {
      const data = {
        email: values.email.trim()
      };
      return dispatch({
        type: HOME.REGISTER_FREE_PLAN,
        payload: agent.KeyStack.registerFreePlan(data)
      });
    },
    onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
    onResetAnnounce: () => {
      dispatch({ type: COMMON.RESET_ANNOUNCE });
      dispatch({ type: AUTH.SET_TYPE, value: null });
    },
    onAnnounce: (data) => dispatch({ type: COMMON.ANNOUNCE, data }),
  });

function mapStateToProps(state) {
  return {
    ...state.home,
    typeAuth: state.auth && state.auth.type,
    isAuthenticated: state.common && state.common.isAuthenticated,
    isPremium: state.common && state.common.isPremium,
    announce: state.common && state.common.announce,
    openUpgradeDialog: state.pricing && state.pricing.openUpgradeDialog,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterPage);
