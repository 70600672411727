'use strict';

import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Field } from 'redux-form';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const activeIcon = '/assets/img/answer/active.png';
const unactiveIcon = '/assets/img/answer/unactive.png';

const styles = () => ({
  root: {
    color: '#EF642A',
    padding: 0,
    '&$checked': {
      color: '#EF642A'
    }
  },
  checked: {}
});

function ReduxCheckbox(props) {
  const { classes, ...rest } = props;

  return (
    <Field
      name="agreeToTerms"
      component={Checkbox}
      icon={<img alt="" width={22} src={unactiveIcon} />}
      checkedIcon={<img alt="" width={22} src={activeIcon} />}
      classes={{
        root: classes.root,
        checked: classes.checked
      }}
      {...rest}
    />
  );
}

ReduxCheckbox.propTypes = {
  classes: PropTypes.object.isRequired
};

ReduxCheckbox.defaultProps = {};

export default withStyles(styles)(ReduxCheckbox);
