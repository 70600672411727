import {
  routerReducer
} from 'react-router-redux';
import {
  reducer as formReducer
} from 'redux-form';
import common from './common';
import home from './home';
import sample from './sample';
import auth from './auth';
import question from './question';
import answer from './answer';
import mentalModel from './mentalModel';
import podcast from './podcast';
import pricing from './pricing';
import keystack from './keystack';
import notification from './notification';
import keystackAI from './keystackAI';

const rootReducer = {
  router: routerReducer,
  common,
  form: formReducer,
  home,
  sample,
  auth,
  question,
  answer,
  mentalModel,
  podcast,
  pricing,
  keystack,
  notification,
  keystackAI
};

export default rootReducer;
