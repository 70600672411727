import { KEYSTACKAI } from '../actions';

const initState = {
  keystacks: [],
  categories: [],
  more: false,
  questionTrendings: [],
  limitMsg: '',
};

export default (state = initState, action) => {
  switch (action.type) {
    case KEYSTACKAI.GET_QUESTION_TRENDING:
      return {
        ...state,
        questionTrendings: Array.isArray(action.payload) ? action.payload : [],
        type: action.type,
      };
    case KEYSTACKAI.GET_AI_KEYSTACK: {
      const { payload } = action;

      return {
        ...state,
        inProgressInCategory: false,
        keystacks: payload && payload.data ? payload.data : [],
        more: payload && !!payload.next,
        type: action.type,
      };
    }
    case KEYSTACKAI.GET_MORE_AI_KEYSTACK: {
      let { keystacks } = state;
      const { payload } = action;
      if (payload && payload.data) {
        keystacks = keystacks.concat(payload.data);
      }
      return {
        ...state,
        inProgressInCategory: false,
        keystacks,
        more: payload && !!payload.next,
        type: action.type,
      };
    }
    case KEYSTACKAI.SUBMIT_QUESTION: {
      const { payload } = action;
      const { keystacks } = state;
      let limitMsg = ''
      if (payload.msg) {
        limitMsg = payload.msg;
      } else {
        keystacks.unshift(payload);
      }

      return {
        ...state,
        inProgressSubmitQuestion: false,
        keystacks,
        type: action.type,
        limitMsg
      };
    }
    case KEYSTACKAI.GET_MODEL_BY_ANSWER:{
       const { payload } = action;
      return {
        ...state,
        inProgressSubmitQuestion: false,
        answer:payload && payload,
        type: action.type,
      };
    }
    case KEYSTACKAI.PAGE_UNLOAD:
      return {};
    case KEYSTACKAI.ANNOUNCED:
      return {
        ...state,
        limitMsg: ''
      }
    case KEYSTACKAI.ASYNC_START:
      switch (action.subtype) {
        case KEYSTACKAI.GET_QUESTION_GROUP:
          return {
            ...state,
            inProgressGetCategory: true,
            type: `${action.type  }_${  action.subtype}`,
          };
        case KEYSTACKAI.SUBMIT_QUESTION:
          return {
            ...state,
            inProgressSubmitQuestion: true,
            type: `${action.type  }_${  action.subtype}`,
          };
        case KEYSTACKAI.GET_AI_KEYSTACK:
        case KEYSTACKAI.GET_MORE_AI_KEYSTACK:
          return {
            ...state,
            inProgressInCategory: true,
            type: `${action.type  }_${  action.subtype}`,
          };
        case KEYSTACKAI.GET_MODEL_BY_ANSWER:
          return {
            ...state,
            inProgressSubmitQuestion: true,
            type: `${action.type  }_${  action.subtype}`,
          };
        default:
          return state;
      }

    default:
      return state;
  }
};
