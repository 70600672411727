import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  button: {
    color: '#FFFFFF!important',
    border: 'none',
    borderRadius: 25,
    height: 50,
    fontSize: 16,
    width: 213,
    fontWeight: 'bold',
    backgroundColor: 'var(--primary)',
    outline: 'none !important',
    '&:hover': {
      backgroundColor: 'var(--primary)'
    },
    paddingTop: 0,
    paddingBottom: 0,
    '& span': {
      fontFamily: "'Nunito', sans-serif",
      fontWeight: 800,
    }
  }
});

function ContainedButton(props) {
  const { classes, label, className = '', ...rest } = props;

  return (
    <Button
      variant="outlined"
      className={`${classes.button} ${className}`}
      {...rest}
    >
      {label}
    </Button>
  );
}

export default withStyles(styles)(ContainedButton);
