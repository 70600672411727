import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';

import { CloseDialogButton } from '../../components';
import { COMMON } from '../../actions';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    overflowY: 'visible',
    maxWidth: 760,
    margin: 20,
    width: '100%',
    marginTop: 50,
  }
}));

const AboutModelDialog = ({ open, onClose, fullScreen, onRedirectTo, onOk }) => {
  const classes = useStyles();
  const handleClose = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth="md"
      scroll="paper"
      fullScreen={fullScreen}
      className="about-model-dialog about-dialog"
    >
      {fullScreen || <CloseDialogButton onClick={handleClose} />}
      <DialogContent className="body-dialog">
        <h2>New to mental models?</h2>
        <div className="content">
          <div className="note">
            <p>No problem.</p>

            <p>Mental models are essentially proven strategies or frameworks for planning and decision-making developed from principles found in mathematics, business, science, and the arts.</p>

            <p>When we see that a principle can be applied in a variety of ways that contribute to personal and professional success, we want to share them with as many people as possible — that’s why we do what we do!</p>

            <p>And mental models are one place where more is better, so we’ve developed Answer to make the right models work for you. </p>

            <p>No doubt there will be some you’ll find more helpful in others. So, familiarize yourself with as many as possible and then master the ones that work best for you. As Charlie Munger once said, “You’ve got to hang experience on a latticework of models in your head.”</p>

            <p>Here we provide snapshots to over 100+ mental models.</p>

            <p>If you want to learn more about mental models there are some exceptional websites out there. Here’s a few that we’re big fans of: </p>

            <p>* https://fs.blog<br />* https://jamesclear.com</p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onRedirectTo: (to) => {
      return dispatch({ type: COMMON.REDIRECT_TO, to });
    },
  };
};

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutModelDialog);
