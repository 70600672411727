import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Field, reduxForm } from 'redux-form';
import { email, length, required } from 'redux-form-validators';
import { AUTH } from '../../actions';
import agent from '../../agent';
import { CloseDialogButton, LoadingComponent, ProgressContainedButton, ReduxTextField, SlideNext, SlidePrev } from '../../components';
import { URL_PATH, VALIDATE } from '../../constants';
import { validateRedux } from '../../helpers';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    overflowY: 'visible',
    margin: '16px 20px 4px 20px',
    'max-height': 'calc(100% - 26px)',
    width: '100%',
    minWidth: 280
  }
}));

const validations = {
  first_name: [
    required({ msg: VALIDATE.REQUIRED }),
    length({
      msg: { tooLong: VALIDATE.TOO_LONG },
      max: 255
    })
  ],
  last_name: [
    required({ msg: VALIDATE.REQUIRED }),
    length({
      msg: { tooLong: VALIDATE.TOO_LONG },
      max: 255
    })
  ],
  email: [
    required({ msg: VALIDATE.REQUIRED }),
    email({ msg: VALIDATE.EMAIL }),
    length({
      msg: { tooLong: VALIDATE.TOO_LONG },
      max: 255
    })
  ],
  password: [
    required({ msg: VALIDATE.REQUIRED }),
    length({
      msg: {
        tooLong: VALIDATE.TOO_LONG,
        tooShort: VALIDATE.TOO_SHORT
      },
      max: 255,
      min: 8
    })
  ],
  biography: [
    length({
      msg: { tooLong: VALIDATE.TOO_LONG },
      max: 120
    })
  ]
};

const validate = values => validateRedux(values, validations);

const SignUpDialog = ({ open, type, success, onClose, onSignUp, fullScreen, handleSubmit, inProgress, photos, inProgressPhotos }) => {
  const classes = useStyles();
  const [profileSelected, setProfileSelected] = useState(null);

  useEffect(() => {
    if (open && !profileSelected) {
      setProfileSelected(null);
    }
    if (type === AUTH.SIGN_UP && open && success) {
      onClose();
    }
  }, [type, open]);

  const onSubmitSignUp = values => {
    const data = { ...values };
    data.icon = profileSelected;
    data.email = values.email.trim();
    onSignUp(data);
  };

  const onSelectAvatar = id => {
    setProfileSelected(id);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    nextArrow: <SlideNext />,
    prevArrow: <SlidePrev />,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        }
      },
      {
        breakpoint: 405,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      }
    ]
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      fullScreen={fullScreen}
      className="sign-up-dialog"
    >
      {fullScreen || <CloseDialogButton onClick={onClose} />}
      <DialogContent className="body-dialog my-profile-container">
        <h2>SIGNUP</h2>
        <div className="content">
          <form onSubmit={handleSubmit(onSubmitSignUp)}>
            <Field
              component={ReduxTextField}
              placeholder="First Name"
              className="profile-field"
              name="first_name"
              disabled={inProgress}
            />
            <Field
              component={ReduxTextField}
              placeholder="Last Name"
              className="profile-field"
              name="last_name"
              disabled={inProgress}
            />
            <Field
              component={ReduxTextField}
              placeholder="Email address"
              className="profile-field"
              name="email"
              disabled={inProgress}
            />
            <Field
              component={ReduxTextField}
              placeholder="Password (8+ characters)"
              className="profile-field"
              name="password"
              disabled={inProgress}
              type="password"
            />
    <Field
                          component={TextField}
                          type="textarea"
                          label="About yourself"
                            placeholder="About yourself (e.g., 'Software Engineer at Google specializing in AI. Passionate about tech and education.') Max. 120 characters."
                          name="biography"
                          required
                          rows={3}
                        />
            <p className="select-your">Select your profile pic</p>
            <ul className="profile-pics">
              {inProgressPhotos ? (
                <LoadingComponent />
              ) : (
                  <Slider {...settings}>
                    {Array.isArray(photos) &&
                      photos.map((item, index) => (
                          <div className="pic-item" key={item.id}>
                            <IconButton
                              aria-label="Avatar"
                              className={classNames(
                                'pic-avatar-btn',
                                profileSelected === item.id && 'active'
                              )}
                              onClick={() => onSelectAvatar(item.id)}
                            >
                              <img
                                src={item.url}
                                alt="Avatar"
                                className="pic-avatar"
                              />
                            </IconButton>
                          </div>
                        ))}
                  </Slider>
                )}
            </ul>
            <p className="term">
              By continuing you agree to Mentalmodelhub’s
                <Link to={URL_PATH.TERMS.PATH} target="_blank">
                {' Terms of Service'}
              </Link>
              {' and '}
              <Link to={URL_PATH.PRIVACY.PATH} target="_blank">
                Privacy Policy
                </Link>
            </p>
            <div className="row justify-content-center">
              <ProgressContainedButton
                label="GET STARTED"
                className="submit-btn"
                type="submit"
                disabled={inProgress}
                progress
              />
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapDispatchToProps = dispatch => ({
    onSignUp: values => dispatch({
        type: AUTH.SIGN_UP,
        payload: agent.Auth.signUp(values)
      })
  });

function mapStateToProps(state, ownProps) {
  return {
    ...state.auth,
    photos: state.common && state.common.photos,
    inProgressPhotos: state.common && state.common.inProgressPhotos
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'SignUpDialog',
    validate
  })(SignUpDialog)
);
