import React from 'react';
import { Route } from 'react-router-dom';
import { isClientSide } from '../../helpers';
import MainLayout from '../../pages/layouts/MainLayout'

const NormalRoute = ({ component, ...rest }) => {
  if (isClientSide) {
    document.title = `${rest.name  } - Mentalmodelhub`;
  }
  const { path } = rest;
  return (
    <MainLayout path={path}>
    <Route
      {...rest}
      render={routeProps => {
        const finalProps = {
          routeProps,
          ...rest
        };
        return React.createElement(component, finalProps);
      }}
    />
    </MainLayout>
  );
}

export default NormalRoute;
