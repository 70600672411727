import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { COMMON } from '../../actions';
import agent from '../../agent';
import { URL_PATH } from '../../constants';


import { ReplyForm, Comment } from ".";
import { UserCard } from '../../components';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';

const useStyles = makeStyles((theme) => ({
  nestedBox: {
    borderLeft: "3px solid rgba(81,58,54,.1)",
    padding: "0 0 0 10px",
    "& .MuiGrid-item:last-child": {
      margin: 0,
    }
  }
}))

function Discussion({
  item,
  actionPrefix,
  currentUser,
  updateDiscussion,
  respectDiscussion,
  deleteDiscussion,
  createComment,
  updateComment,
  respectComment,
  deleteComment,
  onAnnounce,
  isAuthenticated,
  onRedirectTo
}) {
  const classes = useStyles()

  const { id, user } = item

  const [inViewCreateComment, setInViewCreateComment] = useState(false)
  const hideCreateComment = () => {
    setInViewCreateComment(false)
  }
  const toggleCreateComment = () => {
    if (!isAuthenticated) {
      onRedirectTo(URL_PATH.LOGIN.PATH);
      return;
    }

    setInViewCreateComment(prev => !prev)
  }

  const confirmDelete = (content, submit) => {
    onAnnounce({
      message: (<p className="name">{content}</p>),
      label: `Delete`,
      onOk: submit,
    });
  }

  const withKeystackId = (method) => (data = {}, callback) => method({
        ...data,
        param: {
          keystackId: item.keystack
        }
      }, callback)

  const onDiscussionUpdate = updateDiscussion
  const onDiscussionRespect = respectDiscussion
  const onDiscussionDelete = (payload) => {
    confirmDelete(
      `Are you sure you want to delete this Comment${item.number_comment > 0 ? ` with all replies` : ``}?`,
      () => {
        deleteDiscussion({
          ...payload,
          param: {
            id,
            keystackId: item.keystack,
          }
        })
      },
    )
  }

  const onCommentCreate = withKeystackId(createComment)
  const onCommentUpdate = withKeystackId(updateComment)
  const onCommentRespect = withKeystackId(respectComment)
  const onCommentDelete = (payload) => {
    confirmDelete(
      "Are you sure you want to delete this Comment?",
      () => {
        deleteComment({
          ...payload,
          param: {
            id: payload.id,
            discussionId: id,
            keystackId: item.keystack,
          }
        })
      },
    )
  }

  return (
    // <Grid container>
    //   <Grid item xs={12} component={Box} mb={2.5}>
    //     <Comment item={item} isOwner={currentUser?.id === user.id} onCreate={toggleCreateComment} onUpdate={onDiscussionUpdate} onDelete={onDiscussionDelete} onRespect={onDiscussionRespect} />
    //   </Grid>
    //   {(item.comments?.length > 0 || inViewCreateComment) && (
    //     <Grid item container component={Box} className={classes.nestedBox} mb={2.5}>
    //       {inViewCreateComment && (<>
    //         <Grid item xs={12}>
    //           <Box mb={1.2}>
    //             <UserCard user={currentUser} />
    //           </Box>
    //           <ReplyForm id={id} onClose={hideCreateComment} onSubmit={onCommentCreate} />
    //         </Grid>
    //       </>)}
    //     </Grid>
    //   )}
    // </Grid>
    <>
      <Comment item={item} isOwner={currentUser?.id === user.id} onCreate={toggleCreateComment} onUpdate={onDiscussionUpdate} onDelete={onDiscussionDelete} onRespect={onDiscussionRespect} />
      {inViewCreateComment && (
        <ReplyForm
          id={id}
          user={currentUser}
          label="Add Comment"
          isReply
          onClose={hideCreateComment}
          onSubmit={onCommentCreate}
        />
      )}
      {item.comments?.length > 0 &&
        item.comments?.map(comment => (
          <Comment key={comment.id} item={comment} isOwner={currentUser?.id === comment.user.id} onUpdate={onCommentUpdate} onDelete={onCommentDelete} onRespect={onCommentRespect} />
        ))
      }
    </>
    
  )
}



const mapDispatchToProps = (dispatch, props) => {
  const {actionPrefix} = props

  return {
    deleteDiscussion: (data) => dispatch({
        type: actionPrefix.DELETE_DISCUSSION,
        payload: agent.KeyStack.deleteDiscussion(data.id),
        param: data.param
      }),
    updateDiscussion: (data, callback) => dispatch({
        type: actionPrefix.UPDATE_DISCUSSION,
        payload: agent.KeyStack.updateDiscussion(data),
        callback
      }),
    respectDiscussion: (data, callback) => dispatch({
        type: actionPrefix.RESPECT_DISCUSSION,
        payload: agent.KeyStack.respectDiscussion(data.id),
        callback,
        param: data.param,
      }),
    createComment: (data, callback) => dispatch({
        type: actionPrefix.CREATE_COMMENT,
        payload: agent.Comment.createComment(data),
        callback,
        param: data.param,
      }),
    updateComment: (data, callback) => dispatch({
        type: actionPrefix.UPDATE_COMMENT,
        payload: agent.Comment.updateComment(data),
        callback,
        param: data.param,
      }),
    deleteComment: (data) => dispatch({
        type: actionPrefix.DELETE_COMMENT,
        payload: agent.Comment.deleteComment(data.id),
        param: data.param
      }),
    respectComment: (data, callback) => dispatch({
        type: actionPrefix.RESPECT_COMMENT,
        payload: agent.Comment.respectComment(data.id),
        param: data.param,
        callback
      }),
    onAnnounce: (data) => dispatch({ type: COMMON.ANNOUNCE, data }),
    onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
  };
};

function mapStateToProps(state, props) {
  return {
    currentUser: state.common?.currentUser,
    isAuthenticated: state.common?.isAuthenticated,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Discussion);
