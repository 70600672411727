export const NOTIFICATION = {
  PAGE_LOAD: 'PAGE_LOAD_NOTIFICATION',
  PAGE_UNLOAD: 'PAGE_UNLOAD_NOTIFICATION',
  ASYNC_START: 'ASYNC_START',
  ASYNC_END: 'ASYNC_END',

  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS_NOTIFICATION',
  GET_MORE_NOTIFICATIONS: 'GET_MORE_NOTIFICATIONS_NOTIFICATION',

  DELETE_NOTIFICATION_BY_ID: 'DELETE_NOTIFICATION_BY_ID_NOTIFICATION',
  SYNC_DELETE_NOTIFICATION_BY_ID: 'SYNC_DELETE_NOTIFICATION_BY_ID_NOTIFICATION',

  READ_ALL_NOTIFICATIONS: 'READ_ALL_NOTIFICATIONS_NOTIFICATION',
  SYNC_READ_ALL_NOTIFICATIONS: 'SYNC_READ_ALL_NOTIFICATIONS_NOTIFICATION',
};
