import React from 'react';
import { TrashIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'flowbite-react';

const Card = ({
  card,
  isDefault,
  onDeleteCard,
  onSetDefaultCard
}) => (
    <div className="col-span-12 sm:col-span-6 xl:col-span-4 m-auto bg-red-100 rounded-xl relative text-white">
                  <img className="relative object-cover w-full h-full rounded-xl" src="assets/img/premium/bg-credit.png" alt="" />
                  <div className="w-full px-8 absolute top-4 text-xs">
                      <div className="flex justify-between">
                          <div>
                              <h1 className="font-light">Name</h1>
                              <p className="font-medium text-sm">{card.billing_details.name || "No name provided"}</p>
                          </div>
                      </div>
                      <div className="pt-2">
                          <h1 className="font-light">Card Number</h1>
                          <p className="font-medium tracking-wider text-sm">•••• •••• •••• {card.card.last4}</p>
                      </div>
                      <div className="pt-4 pr-6">
                          <div className="flex justify-between">
                              <div>
                                  <h1 className="font-light text-xs text-xs">Expiry</h1>
                                  <p className="font-medium tracking-wider text-sm">{card.card.exp_month}/{card.card.exp_year}</p>
                              </div>
                              <div>
                                  <h1 className="font-light text-xs">CVC</h1>
                                  <p className="font-bold tracking-wider text-sm">•••</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="absolute top-4 right-2 flex justify-end">
                    {isDefault ? (
                      <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-700">Default</span>
                    ) : (
                      <>
                        <Tooltip className="w-28 text-center" content="Set default">
                          <button type="button" onClick={() => onSetDefaultCard(card.id)} className="p-1 ml-1 rounded-full bg-white"><CheckIcon className="w-4 h-4 text-green-700" /></button>
                        </Tooltip>
                        <Tooltip className="w-28 text-center" content="Delete card">
                          <button type="button" onClick={() => onDeleteCard(card.id)} className="p-1 ml-1 rounded-full bg-white"><TrashIcon className="w-4 h-4 text-red-700" /></button>
                        </Tooltip>
                      </>
                    )}
                  </div>
              </div>
  )

export default Card;
