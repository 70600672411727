import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import AppRoute from './App';
import { store } from './store';
import { theme } from './constants/Theme';
import registerServiceWorker from "./helpers/sw";
import EnvClient from '../env.json';
import { ENV } from './constants';
import { isClientSide } from './helpers/index';
import "./styles/style.css"

function Main() {
    const [stripe, setStripe] = useState(null);

    useEffect(() => {
        registerServiceWorker(store)
    }, [registerServiceWorker]);

    useEffect(() => {
        const envType = process.env.REACT_ENV || ENV.DEVELOPMENT;
        const stripePk = EnvClient[envType].STRIPE_PK;

        if (isClientSide && window.Stripe) {
            setStripe(window.Stripe(stripePk));
        } else {
        document.querySelector('#stripe-js').addEventListener('load', () => {
            setStripe(window.Stripe(stripePk));
        });
        }
    }, []);

    return (
        <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <StripeProvider stripe={stripe}>
                    <Elements
                        fonts={[
                            {
                            cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap',
                            }
                        ]}
                    >
                        <BrowserRouter>
                            <AppRoute />
                        </BrowserRouter>
                    </Elements>
                </StripeProvider>
            </MuiThemeProvider>
        </Provider>
    )
}
ReactDOM.render(
    <Main />,
    document.getElementById('root')
);
