import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { URL_PATH } from '../../constants';
import { isClientSide } from '../../helpers';
import { COMMON } from '../../actions/common';
import LoadingPage from '../../pages/sites/LoadingPage';
import MainLayout from '../../pages/layouts/MainLayout'

const mapDispatchToProps = dispatch => ({
    onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
    onChangeUrlAfterLogin: to => dispatch({ type: COMMON.CHANGE_URL_AFTER_LOGIN, to }),
  });

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.common && state.common.currentUser,
    appLoaded: state.common && state.common.appLoaded,
    logout: state.common && state.common.logout
  };
}

const propTypes = {
  path: PropTypes.string.isRequired,
  // component: PropTypes.object.isRequired
};

const defaultProps = {
  path: '',
  // component: function () {
  //   return 'Not found';
  // }
};

class AuthenticatedRoute extends Component {
  state = {
    logout: true
  };

  static getDerivedStateFromProps(props, state) {
    const { currentUser, appLoaded, logout, redirect } = props;
    if (!currentUser && (appLoaded || logout) && state.logout) {
      const toUrl = redirect || URL_PATH.HOME.PATH
      props.onRedirectTo(toUrl);
      if (redirect) {
        props.onChangeUrlAfterLogin(props.location.pathname);
      }
      return {
        logout: false
      };
    }
    return null;
  }

  render() {
    const { component, currentUser, ...rest } = this.props;
    if (currentUser) {
      if (isClientSide) {
        document.title = `${rest.name  } - Mentalmodelhub`;
      }
      const { path } = rest;

      return (
        <MainLayout path={path}>
          <Route
            {...rest}
            render={routeProps => {
              const finalProps = {
                routeProps,
                ...rest
              };
              return React.createElement(component, finalProps);
            }}
          />
          </MainLayout>
      );
    }
    return <LoadingPage />;
  }
}

AuthenticatedRoute.propTypes = propTypes;
AuthenticatedRoute.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatedRoute);
