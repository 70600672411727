import { ANSWER } from '../actions';
import {
  processGetDiscussion,
  processCreateDiscussion,
  processUpdateDiscussion,
  processDeleteDiscussion,
  processRespectDiscussion,
} from './helpers/discussion';
import {
  processCreateComment,
  processUpdateComment,
  processDeleteComment,
  processRespectComment,
} from './helpers/comment';

const initState = {
  categories: [],
  keystacks: [],
  more: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case ANSWER.GET_QUESTION_BY_ID:
      return {
        ...state,
        inProgressById: false,
        question: action.payload ? action.payload : {},
        type: action.type
      };
    case ANSWER.GET_KEY_STACK_QUESTION_BY_ID:
      const {payload} = action;
      return {
        ...state,
        inProgressKeyStackById: false,
        keystacks: payload && payload.data ? payload.data : [],
        more: payload && !!payload.next,
        type: action.type
      };
    case ANSWER.GET_MORE_KEY_STACK_QUESTION_BY_ID: {
      let {keystacks} = state;
      const {payload} = action;
      if (payload && payload.data) {
        keystacks = keystacks.concat(action.payload.data);
      }
      return {
        ...state,
        inProgressKeyStackById: false,
        keystacks,
        more: payload && !!payload.next,
        type: action.type
      };
    }
    case ANSWER.GET_RELATED_QUESTION:
      return {
        ...state,
        inProgressRelatedQuestions: false,
        relatedQuestions: action.payload,
        type: action.type
      };
    case ANSWER.GET_KEY_STACK_BY_USER_QUESTION: {
      let stack = null;
      if (action.payload && action.payload.id) {
        stack = action.payload;
      }
      return {
        ...state,
        inProgressStackByUser: false,
        stack,
        type: action.type
      };
    }
    case ANSWER.GET_MODEL_CATEGORIES:
      return {
        ...state,
        inProgressGetModel: false,
        categories: action.payload ? action.payload : {},
        type: action.type
      };
    case ANSWER.GET_MODEL_THEMES:
      return {
        ...state,
        inProgressGetModel: false,
        themes: action.payload ? action.payload : {},
        type: action.type
      };

    case ANSWER.DELETE_KEY_STACK_BY_ID: {
      if (!action.error) {
        const newKeyStacks = state.keystacks.filter(
          item => item.id !== action.id
        );
        const question = {
          ...state.question,
          is_comment: false,
          total_comments: state.question.total_comments - 1
        };
        return {
          ...state,
          inProgressDelete: false,
          success: true,
          keystacks: newKeyStacks,
          question,
          type: action.type
        };
      }
      return {
        ...state,
        inProgressDelete: false,
        success: false,
        type: action.type
      };
    }
    case ANSWER.RESPECT:
      if (!action.error && action.payload && action.payload.id) {
        return {
          ...state,
          inProgressRespect: false,
          keystacks: state.keystacks.map(item => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                total_respects: action.payload.total_respects,
                is_respect: action.payload.is_respect,
              };
            }
            return item;
          }),
          type: action.type
        };
      }
      return {
        ...state,
        inProgressRespect: false,
        type: action.type
      };


    case ANSWER.ADD_KEY_STACK: {
      if (!action.error) {
        const question = {
          ...state.question,
          is_comment: true,
          total_comments: state.question.total_comments + 1
        };
        return {
          ...state,
          inProgressAdd: false,
          success: true,
          question,
          type: action.type
        };
      }
      return {
        ...state,
        inProgressAdd: false,
        success: false,
        type: action.type
      };
    }
    case ANSWER.UPDATE_KEY_STACK:
      if (!action.error) {
        const keystacks = state.keystacks.map(item => {
          if (item.id !== action.payload.id) {
            return item;
          }
          return action.payload;
        })
        return { ...state, keystacks, type: action.type };
      }
      return { ...state, success: false, type: action.type };
    case ANSWER.GET_DISCUSSION: {
      return processGetDiscussion(state, action);
    }
    case ANSWER.CREATE_DISCUSSION: {
      return processCreateDiscussion(state, action)
    }
    case ANSWER.UPDATE_DISCUSSION: {
      return processUpdateDiscussion(state, action)
    }
    case ANSWER.DELETE_DISCUSSION: {
      return processDeleteDiscussion(state, action)
    }
    case ANSWER.RESPECT_DISCUSSION: {
      return processRespectDiscussion(state, action)
    }
    case ANSWER.CREATE_COMMENT: {
      return processCreateComment(state, action)
    }
    case ANSWER.UPDATE_COMMENT: {
      return processUpdateComment(state, action)
    }
    case ANSWER.DELETE_COMMENT: {
      return processDeleteComment(state, action)
    }
    case ANSWER.RESPECT_COMMENT: {
      return processRespectComment(state, action)
    }

    // case ANSWER.GET_COMMENT: {
    //   if (action.error || !action.payload?.data?.length) {
    //     return { ...state, success: false, type: action.type };
    //   }
    //   const stackId = action.param.keystack
    //   const discussionId = action.param.id;
    //   const keystacks = state.keystacks.map(item => {
    //     if (item.id !== stackId) {
    //       return item;
    //     }
    //     const discussions = item.discussion?.data.map(discussion => {
    //       if (discussion.id !== discussionId) {
    //         return discussion;
    //       }
    //       let oldComments = discussion.comment?.data || [];
    //       discussion.comment = {
    //         ...action.payload,
    //         data: [...oldComments, ...action.payload.data]
    //       };
    //       return { ...discussion };
    //     });
    //     item.discussion = {
    //       ...item.discussion,
    //       data: discussions
    //     };
    //     return { ...item };
    //   });
    //   return { ...state, keystacks, type: action.type };
    // }
    // case ANSWER.RESET_DISCUSSION: {
    //   const stackId = action.param.id;
    //   const keystacks = state.keystacks.map(item => {
    //     if (item.id !== stackId) {
    //       return item;
    //     }
    //     item.discussion = {};
    //     return { ...item };
    //   });
    //   return { ...state, keystacks, type: action.type };
    // }
    // case ANSWER.RESET_COMMENT: {
    //   const stackId = action.param.keystack
    //   const discussionId = action.param.id;
    //   const keystacks = state.keystacks.map(item => {
    //     if (item.id !== stackId) {
    //       return item;
    //     }
    //     const discussions = item.discussion.data?.map(discussion => {
    //       if (discussion.id !== discussionId) {
    //         return discussion;
    //       }
    //       discussion.comment = {};
    //       return { ...discussion };
    //     })
    //     return {
    //       ...item,
    //       discussion: {
    //         ...item.discussion,
    //         data: discussions
    //       }
    //     };
    //   });
    //   return { ...state, keystacks, type: action.type };
    // }

    case ANSWER.RESET:
      return {
        ...state,
        success: null,
        type: null
      };
    case ANSWER.PAGE_UNLOAD:
      return initState;
    case ANSWER.ASYNC_START:
      switch (action.subtype) {
        case ANSWER.GET_QUESTION_BY_ID:
          return {
            ...state,
            inProgressById: true,
            type: `${action.type  }_${  action.subtype}`
          };
        case ANSWER.DELETE_KEY_STACK_BY_ID:
          return {
            ...state,
            inProgressDelete: true,
            type: `${action.type  }_${  action.subtype}`
          };
        case ANSWER.RESPECT:
          return {
            ...state,
            inProgressRespect: true,
            type: `${action.type  }_${  action.subtype}`
          };
        case ANSWER.ADD_KEY_STACK:
          return {
            ...state,
            inProgressAdd: true,
            type: `${action.type  }_${  action.subtype}`
          };
        case ANSWER.GET_KEY_STACK_QUESTION_BY_ID:
          return {
            ...state,
            inProgressKeyStackById: true,
            type: `${action.type  }_${  action.subtype}`
          };
        case ANSWER.GET_RELATED_QUESTION:
          return {
            ...state,
            inProgressRelatedQuestions: true,
            type: `${action.type  }_${  action.subtype}`
          };
        case ANSWER.GET_KEY_STACK_BY_USER_QUESTION:
          return {
            ...state,
            inProgressStackByUser: true,
            type: `${action.type  }_${  action.subtype}`
          };
        case ANSWER.GET_MODEL_CATEGORIES:
        case ANSWER.GET_MODEL_THEMES:
          return {
            ...state,
            inProgressGetModel: true,
            type: `${action.type  }_${  action.subtype}`
          };

        case ANSWER.UPDATE_KEY_STACK:
        case ANSWER.GET_DISCUSSION:
        case ANSWER.CREATE_DISCUSSION:
        case ANSWER.UPDATE_DISCUSSION:
        case ANSWER.DELETE_DISCUSSION:
        case ANSWER.RESPECT_DISCUSSION:
        case ANSWER.CREATE_COMMENT:
        case ANSWER.UPDATE_COMMENT:
        case ANSWER.DELETE_COMMENT:
        case ANSWER.RESPECT_COMMENT:
          return {
            ...state,
            type: `${action.type  }_${  action.subtype}`
          };

        default:
          return state;
      }
    default:
      return state;
  }
};
