import { connect } from 'react-redux';
import { HomePage } from '../../pages/index';
import agent from '../../agent';
import { HOME, COMMON, KEYSTACK } from '../../actions';

const mapDispatchToProps = dispatch => ({
    onJoinMailing: values => {
      const data = {
        email: values.email.trim()
      };
      return dispatch({
        type: HOME.JOIN_MAILLING,
        payload: agent.KeyStack.registerFreePlan(data)
      });
    },
    onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
    getKeyStackById: data => dispatch({
        type: KEYSTACK.GET_KEYSTACK_BY_ID,
        payload: agent.KeyStack.getKeyStackById(data)
      }),
    onLoad: () => dispatch({ type: COMMON.HOME_PAGE_LOAD }),
    onUnload: () => dispatch({ type: COMMON.PAGE_UNLOAD })
  });

function mapStateToProps(state) {
  return {
    ...state.home
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
