import React from 'react';
import renderHTML from 'react-render-html';

const data = {
  title: `Contact Us`,
  detail: [
    `Got an <b>enquiry</b>? Feel free to reach out.`,
    `Or if you have a question you’d like our community to consider, email us your question and provide as much detail as you can including your name, location and why this question is important to you.`,
    `We’d be happy to help.`
  ],
  email: `hello@mentalmodelhub.com`,
  address:
    `<br />
    Level 2, 89 Pirie St.
    <br />
    Adelaide, SA 5000, Australia`,
};

function ContactPage() {
  return (
    <main className="lg:col-span-9 xl:col-span-10 bg-white px-4 py-6 sm:rounded-xl sm:p-6 shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] text-sm leading-relaxed">
      <h1 className="text-2xl text-neutral-900 text-center mb-4">{data.title}</h1>
        {Array.isArray(data.detail) &&
          data.detail.map((detail) => <p className="mt-2 font-light text-neutral-700" key={Math.random()}>{renderHTML(detail)}</p>)}
        <a className="text-accent-default" href={`mailto:${data.email}`}>{data.email}</a>
        <p className="pt-2 text-neutral-700">{renderHTML(data.address)}</p>
    </main>
  );
}

export default ContactPage;
