import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { checkAuthenticated } from '../../helpers/LocalStorage';
import { COMMON, AUTH } from '../../actions';
import { URL_PATH } from '../../constants';
import { isClientSide } from '../../helpers';
import LoadingPage from '../../pages/sites/LoadingPage';

const mapDispatchToProps = dispatch => ({
    onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
    onChangeParamsAuth: payload => dispatch({ type: AUTH.CHANGE_PARAMS, payload }),
    onChangeUrlAfterLogin: to => dispatch({ type: COMMON.CHANGE_URL_AFTER_LOGIN, to }),
  });

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.common?.currentUser,
    isAuthenticated: state.common?.isAuthenticated,
    menusUser: state.common?.menusUser,
    urlAfterLogin: state.common?.urlAfterLogin,
  };
}

const propTypes = {
  path: PropTypes.string.isRequired
};

const defaultProps = {
  path: ''
};

class PublicRoute extends Component {
  constructor(props) {
    super(props);

    const auth = checkAuthenticated();
    this.state = {
      isAuthenticated: !!auth.oauthToken,
      init: true
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.currentUser || !state.init) {
      return null;
    }

    let url = URL_PATH.HOME.PATH;
    if (props.location.pathname === URL_PATH.LOGIN.PATH) {
      if (props.urlAfterLogin !== url) {
        url = props.urlAfterLogin;
        props.onChangeUrlAfterLogin(URL_PATH.HOME.PATH);
      } else if (props.currentUser.change_password_reminder) {
        url = URL_PATH.MY_PROFILE.PATH;
        props.onChangeParamsAuth({ openChangePasswordDialog: true });
      }
    }
    props.onRedirectTo(url);
    return {
      init: false
    };
  }

  render() {
    const { component, ...rest } = this.props;
    const { isAuthenticated } = this.state;
    if (isClientSide) {
      document.title = `${rest.name  } - Mentalmodelhub`;
    }
    return isAuthenticated ? (
      <LoadingPage />
    ) : (
        <Route
          {...rest}
          render={routeProps => {
            const finalProps = {
              routeProps,
              ...rest
            };
            return React.createElement(component, finalProps);
          }}
        />
      );
  }
}

PublicRoute.propTypes = propTypes;
PublicRoute.defaultProps = defaultProps;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PublicRoute)
);
