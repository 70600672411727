import { connect } from 'react-redux';
import agent from '../../agent';
import { COMMON, NOTIFICATION } from '../../actions';
import NotificationPage from '../../pages/sites/NotificationPage';

const mapDispatchToProps = (dispatch) => ({
    getNotifications: data => dispatch({
        type: NOTIFICATION.GET_NOTIFICATIONS,
        payload: agent.Auth.getNotifications(data),
      }),
    getMoreNotifications: data => dispatch({
        type: NOTIFICATION.GET_MORE_NOTIFICATIONS,
        payload: agent.Auth.getNotifications(data),
      }),
    readAllNotifications: () => dispatch({
        type: NOTIFICATION.READ_ALL_NOTIFICATIONS,
        payload: agent.Auth.readAllNotifications(),
      }),
    deleteNotificationById: id => dispatch({
        type: NOTIFICATION.DELETE_NOTIFICATION_BY_ID,
        payload: agent.Auth.deleteNotificationById(id),
        param: { id },
      }),
    onRedirectTo: (to) => dispatch({ type: COMMON.REDIRECT_TO, to }),

  });

function mapStateToProps(state) {
  return {
    ...state.notification,
    isAuthenticated: state.common && state.common.isAuthenticated,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
