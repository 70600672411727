import React, { Component } from 'react';
import { scroller } from 'react-scroll';
import queryString from 'querystring';
import agent from '../../agent';
import { URL_PATH } from '../../constants';
import KeyStackContainer from '../../containers/sites/KeyStackContainer';

class HomePage extends Component {
  state = {
    submit: false,
    displayVideoHeader: false,
    openCongratulationDialog: false
  };

  componentDidMount() {
    const { state } = this.props.location;
    if (state && state.goToAccessForm) {
      scroller.scrollTo('sign-up-form', {
        duration: 0,
        delay: 0
      });
    }
    const search = this.props.location?.search?.replace('?', '');
    const parsed = search && queryString.parse(search);
    if (!parsed) {
      return;
    }
    let redirectTo = '';
    if (parsed.models) { // ok
      redirectTo = URL_PATH.MODEL_DETAIL.pathParam(parsed.models);
    } else if (parsed.questions) {// ok
      redirectTo = URL_PATH.ANSWER.pathParam(parsed.questions);
    } else if (parsed.podcasts || parsed.highlights) {
      redirectTo = URL_PATH.PODCAST_DETAIL.pathParam({ title: parsed.podcasts || parsed.highlights });
    } else if (parsed.keystacks) {
      agent.KeyStack.getKeyStackById(parsed.keystacks).then((result) => {
        if (result && result.body && result.body.id) {
          const keystack = result.body
          const category = keystack.question.category || {}
          const user = keystack.user || {}
          redirectTo = URL_PATH.KEYSTACK_DETAIL.pathParam({
            category: category.slug || 'category',
            name: keystack.question.slug ? keystack.question.slug : keystack.question,
            author: user.slug
          })
          this.props.onRedirectTo(redirectTo);
        }
      })
      return
    }
    if (redirectTo) {
      this.props.onRedirectTo(redirectTo);
    }
  }

  componentDidUpdate(nextProps, prevState) {
    if (nextProps.success && prevState.submit) {
      nextProps.reset();
      this.setState({
        submit: false,
        openCongratulationDialog: true
      })
    }
  }

  componentWillUnMount() {
    const { onUnload } = this.props;
    onUnload();
  }

  render() {
    return (
      <KeyStackContainer
          showSearchBar
        />
    );
  }
}

export default HomePage;
