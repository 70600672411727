import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PRICING, COMMON } from '../../actions';
import URL_PATH from '../../constants/PageUrlPath';
import agent from '../../agent';
import { LoadingComponent } from '../../components';
import { currencyFormatter } from '../../helpers/index';
import CardForm from './Stripe';


function PlanPricingList({
  getProducts,
  product,
  purchase,
  inProgress,
  success,
  resetPricing,
  type,
  isAuthenticated,
  onRedirectTo,
  onAnnounce,
  onChangeparamsPricing,
  handleClose
}) {
  const [planId, setPlanId] = useState(null);
  const [showPurchase, setShowPurchase] = useState(false);

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (type === PRICING.PURCHASE && success) {
      console.log('vao day');
      setShowPurchase(false);
      if (handleClose) {
        handleClose();
      }
      onChangeparamsPricing({ openUpgradeDialog: false });
      resetPricing();
      onRedirectTo(URL_PATH.HOME.PATH);
      onAnnounce({
        message: (
          <p className="name">
            Thank you!
            <br />
            Your Payment has been successful
          </p>
        ),
      });
    }
  }, [type]);

  useEffect(() => {
    if (product && Array.isArray(product.plans) && product.plans.length) {
      let planActive = null
      product.plans.some(item => {
        if (item.active) {
          planActive = item.id;
          return true;
        }
        return false;
      })
      setPlanId(planActive);
    }
  }, [product]);

  const onClickPurchase = () => {
    if (!isAuthenticated) {
      onChangeparamsPricing({ openUpgradeDialog: true });
      onRedirectTo(URL_PATH.LOGIN.PATH);
      return;
    }
    if (planId) {
      setShowPurchase(true);
    }
  };

  const hasPlan = product && Array.isArray(product.plans) && product.plans.length;

  if (!hasPlan) {
    return (
      <div className="col-span-12 lg:col-span-4 bg-accent-blurred py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12">
        <LoadingComponent />
      </div>
    );
  }

  const plantActivate = product?.plans?.find((plan) => !!plan.active)

  return (
    <div className="col-span-12 lg:col-span-4 bg-accent-blurred py-8 px-6 lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12">
      {showPurchase ? (
        <div className="animate-in fade-in">
          <CardForm
            planId={planId}
            purchase={purchase}
            inProgress={inProgress}
          />
        </div>
      ) : (
        <div className="text-center">
          <p className="text-lg font-medium leading-6 text-neutral-900">Renewed Annually</p>
          <div className="mt-4 flex items-center justify-center text-5xl font-bold tracking-tight text-neutral-900">
            <span>{currencyFormatter(plantActivate.currency).format(plantActivate.amount / 100)}</span>
            <span className="ml-3 text-xl font-medium tracking-normal text-neutral-500">USD</span>
          </div>
          <p className="mt-4 text-sm">
            <a href="/privacy" target="_blank" className="font-medium text-neutral-500 underline">
              Learn about our policy
            </a>
          </p>
          <div className="mt-6">
            <button
              type="button"
              className="flex items-center w-full justify-center rounded-full bg-accent-default px-5 py-3 text-base font-medium text-white hover:bg-accent-hover"
              onClick={onClickPurchase}
            >
              Upgrade Now
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  getProducts: values => dispatch({
    type: PRICING.GET_PRODUCTS,
    payload: agent.Pricing.getProducts(values)
  }),
  purchase: values => dispatch({
    type: PRICING.PURCHASE,
    payload: agent.Pricing.purchase(values)
  }),
  resetPricing: () => dispatch({ type: PRICING.RESET }),
  onRedirectTo: to => dispatch({
    type: COMMON.REDIRECT_TO,
    to
  }),
  onAnnounce: (data) => dispatch({ type: COMMON.ANNOUNCE, data }),
  onChangeparamsPricing: (data) => dispatch({ type: PRICING.CHANGE_PARAMS, data }),
});

function mapStateToProps(state) {
  return {
    ...state.pricing,
    isAuthenticated: state.common && state.common.isAuthenticated,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanPricingList);
