import { MENTAL_MODEL } from '../actions';

const initState = {
  models: [],
  categories: [],
  more: false,
  openIFrame:false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case MENTAL_MODEL.GET_CATEGORIES:
      return {
        ...state,
        inProgressGetCategories: false,
        categories: Array.isArray(action.payload) ? action.payload : [],
        type: action.type,
      };
    case MENTAL_MODEL.GET_SUBJECT_BY_CATEGORY_ID:
    case MENTAL_MODEL.GET_SUBJECT_TRENDING:
    case MENTAL_MODEL.GET_SUBJECT_FAVORITE:
    case MENTAL_MODEL.GET_SUBJECT_AZ: {
      const payload = action.payload;
      return {
        ...state,
        inProgressGetModels: false,
        models: payload && payload.data ? payload.data : [],
        more: payload && !!payload.next,
        totals:payload && payload.total,
        lastpage: payload && payload.last_page,
        type: action.type,
      };
    }
    case MENTAL_MODEL.GET_MORE_SUBJECT_BY_CATEGORY_ID:
    case MENTAL_MODEL.GET_MORE_SUBJECT_TRENDING:
    case MENTAL_MODEL.GET_MORE_SUBJECT_FAVORITE:
    case MENTAL_MODEL.GET_MORE_SUBJECT_AZ: {
      let models = state.models;
      const payload = action.payload;
      if (payload && payload.data) {
        models = models.concat(action.payload.data);
      }
      return {
        ...state,
        inProgressGetModels: false,
        models,
        more: payload && !!payload.next,
        type: action.type,
      };
    }
     case MENTAL_MODEL.WATCH_VIDEOS_BY_ID:
      return {...state,openIFrame:action.data && action.data, type: action.type, }
    case MENTAL_MODEL.PAGE_UNLOAD:
      return initState;
    case MENTAL_MODEL.ASYNC_START:
      switch (action.subtype) {
        case MENTAL_MODEL.GET_SUBJECT_BY_CATEGORY_ID:
        case MENTAL_MODEL.GET_SUBJECT_AZ:
        case MENTAL_MODEL.GET_SUBJECT_TRENDING:
        case MENTAL_MODEL.GET_SUBJECT_FAVORITE:
        case MENTAL_MODEL.GET_MORE_SUBJECT_BY_CATEGORY_ID:
        case MENTAL_MODEL.GET_MORE_SUBJECT_TRENDING:
        case MENTAL_MODEL.GET_MORE_SUBJECT_FAVORITE:
        case MENTAL_MODEL.GET_MORE_SUBJECT_AZ:
          return { ...state, inProgressGetModels: true, type: action.type + '_' + action.subtype };
        default:
          return state;
      }
    default:
      return state;
  }
};
