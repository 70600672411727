import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { PlusIcon } from '@heroicons/react/20/solid'
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline'
import agent from '../../../agent';
import { ANSWER, MENTAL_MODEL } from '../../../actions';
import {  LoadingComponent } from '../../../components';
import { findIndexById } from '../../../helpers';
import { MODEL_CATEGORIES } from '../../../constants';
// eslint-disable-next-line import/order, import/imports-first
import InfiniteScroll from 'react-infinite-scroll-component';

const STEPS = [
  { title: 'SELECT MODELS' },
  { title: 'ADD COMMENTARY' },
  { title: 'PREVIEW' }
];
const MAX_MODEL_SELECTED = 10;

class AddAnswer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      groupActive: _.isNull(props?.currentUser?.premium) ? MODEL_CATEGORIES.trending.value  : MODEL_CATEGORIES.az.value ,
      page: 1,
      stepAdd: 0,
      subjectsSelected: [],
      // eslint-disable-next-line react/no-unused-state
      searchModels: [],
      searchKeyword: null,
      comment: null
    };
  }

  componentDidMount() {
    const { getModelCategories, item } = this.props;
    getModelCategories();
    this.handleGetSubject();
    if (!(item && Array.isArray(item.models) && item.models?.length)) {
      return;
    }
   
    const subjectsSelected = item.models.map(itemModel => ({
      id: itemModel.id,
      name: itemModel.name,
      summary: itemModel.summary,
      category: itemModel.category
    }));
    this.setState({
      stepAdd: 0,
      subjectsSelected,
      comment: item.comment
    });
  }

  componentDidUpdate() {
    const { success, type, question, getKeyStackQuestionById, resetPage, onCloseSuccess } = this.props;
    const { stepAdd } = this.state;
    const notSuccess = type !== ANSWER.UPDATE_KEY_STACK && type !== ANSWER.ADD_KEY_STACK && !success;
    if (stepAdd + 1 !== STEPS.length || notSuccess) {
      return;
    }
    onCloseSuccess(type === ANSWER.UPDATE_KEY_STACK);
    resetPage();
    this.setState({ stepAdd: 0 });
    if (type === ANSWER.ADD_KEY_STACK) {
      getKeyStackQuestionById({
        id: question.id,
        page: 1,
        sort: 'created_at'
      });
    }
  }

  handleGetSubject = () => {
    const {
      getSubjectByCategoryId,
      getSubjectTrending,
      getSubjectFavorite,
      getSubjectAZ,
      
    } = this.props;
    const { groupActive, page, keyWord } = this.state;
    switch (groupActive) {
      case MODEL_CATEGORIES.trending.value:
        getSubjectTrending({ page });
        break;
      case MODEL_CATEGORIES.favorite.value:
        getSubjectFavorite({ page });
        break;
      case MODEL_CATEGORIES.az.value:
        getSubjectAZ({ page, keyWord });
        break;
      default:
        getSubjectByCategoryId({ id: groupActive, page });
    }
  };

  onClickGetModelInCategory = id => {
    const { groupActive } = this.state;
    if (id === groupActive) {
      return;
    }
    this.setState({
      groupActive: id,
      page: 1,
    }, this.handleGetSubject);
  };

  onClickMore = () => {
    const { page } = this.state;
    const {totals} = this.props
    const nextPage = page + 1;
    // eslint-disable-next-line no-unused-expressions
    page < Math.ceil(totals/20) && this.setState({
      page: nextPage
    }, this.handleGetSubject);
  };

  backStepAdd = () => {
    const { stepAdd } = this.state;
    const { onClose } = this.props;
    const shouldChangeStep = stepAdd - 1 > -1;
    if (shouldChangeStep) {
      this.setState({
        stepAdd: stepAdd - 1
      });
    } else {
      onClose();
    }
  };

  nextStepAdd = () => {
    const { stepAdd, subjectsSelected } = this.state;
    if (!Array.isArray(subjectsSelected) || subjectsSelected.length === 0) {
      return false;
    }
    const shouldChangeStep = stepAdd + 1 <= STEPS.length;
    if (!shouldChangeStep) {
      return false;
    }

    this.setState({
      stepAdd: stepAdd + 1
    });
    return true;
  };

  submitAddKeyStack = () => {
    const { subjectsSelected, comment } = this.state;
    const { addKeyStack, question, item, updateKeyStack } = this.props;

    const commentValue = comment && comment.trim();
    if (!commentValue) {
      this.setState({ comment: '' });
      return;
    }
    const models = subjectsSelected.map((sub) => sub.id);
    if (!item?.id) {
      const requestData = {
        question_id: question.id,
        comment,
        model_ids: models
      };
      addKeyStack(requestData);
      this.setState({ stepAdd: 2 });
      return;
    }
    const requestData = {
      id: item.id,
      data: { comment, model_ids: models }
    };
    updateKeyStack(requestData);
    this.setState({ stepAdd: 2 });
  };

  handleChangeCheckbox = (e, subject) => {
    const { subjectsSelected } = this.state;
    const newSubjectSelected = [...subjectsSelected];
    if (e.target.checked) {
      if (newSubjectSelected.length === MAX_MODEL_SELECTED) {
        return;
      }
      newSubjectSelected.push({
        id: subject.id,
        name: subject.name,
        summary: subject.summary,
        category: subject.category
      });
    } else {
      const indexRemove = findIndexById(newSubjectSelected, subject.id);
      if (indexRemove === -1) {
        return;
      }
      newSubjectSelected.splice(indexRemove, 1);
    }
    this.setState({
      subjectsSelected: newSubjectSelected
    });
  };

  groupModels = (models = []) => {
    const data = {};
    for (let i = 0; i < models.length; i += 1) {
      const model = models[i];
      const firstChar = model.name[0].toUpperCase();
      const innerArr = [];
      if (!data[firstChar]) {
        innerArr.push(model);
        data[firstChar] = innerArr;
      } else {
        data[firstChar].push(model);
      }
    }

    return data;
  }

  onSearchModels = (keyword) => {
    const { models,totals } = this.props;
     const { page } = this.state;
    const nextPage = page + 1;
   
    const result = _.filter(models, model => model.name.search(new RegExp(keyword, 'i')) >= 0)
    // eslint-disable-next-line no-unused-expressions, react/no-unused-state
    _.isEmpty(result) && page < Math.ceil(totals/20)  ?  this.setState({page: nextPage,searchKeyword:keyword}, this.handleGetSubject) : this.setState({ searchModels: result, searchKeyword: keyword })
  }

  handleChangeComment = e => {
    this.setState({ comment: e.target.value });
  };

  backStep = () => {
    this.setState({ stepAdd: 0 })
  }

  renderModels = () => {
    const { question, inProgressGetModels, models,item } = this.props;
    const { subjectsSelected, searchKeyword,page } = this.state;
    const modelDatas = searchKeyword ? this.groupModels(_.filter(models, model => model.name.search(new RegExp(searchKeyword, 'i')) >= 0)) : this.groupModels(models);
    return (
      <>
        <div>
          <div className="px-3 pt-3 pb-2 lg:px-8">
            <h3 className="mb-3 text-lg font-medium text-center text-neutral-900 dark:text-white">Add Models</h3>
            <div>
              <p className="mb-3">{question.question}</p>
              <input
                type="text"
                className="block w-full rounded-3xl border-neutral-300 focus:border-accent-default focus:ring-neutral-400 text-sm font-light resize-none px-3"
                onChange={(e) => this.onSearchModels(e.target.value)}
                placeholder="Search..."
              />
              <div className="mt-2">
                {subjectsSelected.map(model => (
                  <button
                    key={model.id}
                    type="button"
                    className="inline-flex items-center rounded-full px-3 py-1 mr-2 my-1 text-xs font-normal cursor-default bg-neutral-900 text-neutral-200"
                  >
                    <PlusIcon className="-ml-1 mr-0.5 h-5 w-5 text-neutral-200" aria-hidden="true" />
                    <button type="button">{model.name}</button>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="h-[356px] border-t border-grey-300">
          <div className="flex h-full">
            <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
              <div className="relative z-0 flex flex-1 overflow-hidden">
                <aside className="w-full flex-shrink-0 border-r border-neutral-200 xl:order-first flex flex-col">
                  <nav className="min-h-0 flex-1 overflow-y-auto no-scrollbar" aria-label="Directory">
                    {page===1 && inProgressGetModels && 
                      <LoadingComponent />
                     }
                    <InfiniteScroll
                  dataLength={Object.keys(modelDatas).length}
                  next={this.onClickMore}
                  hasMore={item}
                  loader={
                    page === 1 ? null : (
                      <div />
                        
                    )
                  }
                  height="calc(80vh - 160px)"
                >
                      <>
                        {Object.keys(modelDatas).map((letter) => (
                          <div key={letter} className="relative">
                            <div className="sticky top-0 z-10 bg-white px-3 py-1 text-sm font-medium text-neutral-500">
                              <h3>{letter}</h3>
                            </div>
                            <ul className="relative z-0">
                              {modelDatas[letter].map((model) => {
                                const index = findIndexById(subjectsSelected, model.id);
                                return (
                                  <li key={model.id}>
                                    <div className="relative flex items-center space-x-3 px-3 py-3">
                                      <div className="min-w-0 flex-auto w-95">
                                        <p className="text-sm font-medium text-neutral-900">{model.name}</p>
                                        <p className="truncate text-sm font-light text-neutral-500">{model.summary}</p>
                                      </div>
                                      <div className="min-w-0 flex-auto w-5 flex justify-end">
                                        <input checked={index >= 0} onChange={e => this.handleChangeCheckbox(e, model)} type="checkbox" className="w-4 h-4 text-accent-default rounded border-neutral-300 cursor-pointer" />
                                      </div>
                                    </div>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        ))}
                      </>
                   </InfiniteScroll>
                  </nav>
                </aside>
              </div>
            </div>
          </div>
        </div>
        <div className="py-3 px-3 lg:px-8 flex flex-col	items-end border-t border-grey-300">
          <button
            type="button"
            className="inline-flex items-center px-6 py-2.5 text-sm leading-4 font-light rounded-full text-white bg-accent-default hover:bg-accent-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400"
            onClick={this.nextStepAdd}
          >Next</button>
        </div>
      </>
    );
  };

  renderWriteAnswer(inProgress) {
    const { currentUser, question, item,onAnnounce } = this.props;
    const { subjectsSelected, comment } = this.state;
    const labelBtn = item?.id ? "Update answer" : "Submit answer";
    return (
      <div className="p-5 pt-12">
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <img className="h-10 w-10 rounded-full object-cover" src={currentUser?.profile_image} alt="" />
          </div>
          <div className="min-w-0 flex-1">
            <p className="text-sm font-medium text-neutral-900">{`${currentUser?.first_name} ${currentUser?.last_name}`}</p>
            <p className="text-sm text-neutral-500 font-light">{moment().format('MMMM DD [at] h:mm a')}</p>
          </div>
        </div>
        <div className="pt-4">
          <p>{question.question}</p>
          <div className="my-3">
            {subjectsSelected.map(model => (
              <button
                key={model.id}
                type="button"
                className="inline-flex items-center rounded-full px-3 py-1 mr-2 my-1 text-xs font-normal cursor-default bg-neutral-900 text-neutral-200"
              >
                <PlusIcon className="-ml-1 mr-0.5 h-5 w-5 text-neutral-200" aria-hidden="true" />
                <button type="button">{model.name}</button>
              </button>
            ))}
          </div>
          <textarea
            type="text"
            className="block w-full rounded-3xl border-neutral-300 focus:border-accent-default focus:ring-neutral-400 text-sm font-light resize-none px-3 min-h-100"
            value={comment}
            onChange={this.handleChangeComment}
            placeholder="Add comment here..."
            rows={4}
          />
          {!comment && !_.isNull(comment) && <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">This field is required</span>}
          <div className="py-3 flex flex-col	items-end">
            <button
              type="button"
              className="inline-flex items-center px-6 py-2.5 text-sm leading-4 font-light rounded-full text-white bg-accent-default hover:bg-accent-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400"
              onClick={()=>{
                if(comment && comment.length > 5000){
                    onAnnounce({
                      message: (
                        <p className="name existing">
                         Limit user answer number of characters is 5000 chars 
                        </p>
                      ),
                      label: 'Got it'
                    });
                }else{
                   this.submitAddKeyStack()
                }
               }}
            >{inProgress ? (
              <>
                <svg role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                </svg>
                Submitting...
              </>
            ) : labelBtn}</button>
          </div>
        </div>
      </div>
    )
  }

  renderSuccess = () => {
    const { type } = this.props;
    if (type === ANSWER.UPDATE_KEY_STACK) {
      return (
        <div className="py-6 px-6 lg:px-8 flex flex-col	items-center">
          <h3 className="my-4 text-lg text-center font-medium text-neutral-900 dark:text-white">Congratulations.<br />Your Answer has been submitted.</h3>
          <button
            type="button"
            className="inline-flex items-center px-6 py-2.5 text-sm leading-4 font-light rounded-full text-white bg-accent-default hover:bg-accent-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400"
          >OK</button>
        </div>
      )
    }

    return null;
  }

  render() {
    const { inProgressAdd, type, onClose } = this.props;

    const { stepAdd } = this.state;
    const inProgress = inProgressAdd || type === (`${ANSWER.ASYNC_START}_${ANSWER.UPDATE_KEY_STACK}`);

    return (
      <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto no-scrollbar fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative p-4 w-full max-w-xl h-auto">
            <div className="relative bg-white rounded-xl shadow dark:bg-neutral-700">
              <button onClick={onClose} type="button" className="absolute top-3 right-2.5 text-neutral-500 bg-transparent hover:bg-neutral-200 hover:text-neutral-900 rounded-xl text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-neutral-800 dark:hover:text-white" data-modal-toggle="authentication-modal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
              </button>
              {stepAdd === 0 && (
                this.renderModels()
              )}
              {stepAdd === 1 && (
                <>
                  <button onClick={this.backStep} type="button" className="absolute top-3 left-2.5 text-neutral-500 bg-transparent hover:bg-neutral-200 hover:text-neutral-900 rounded-xl text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-neutral-800 dark:hover:text-white" data-modal-toggle="authentication-modal">
                    <ArrowSmallLeftIcon className="w-5 h-5" />
                  </button>
                  {this.renderWriteAnswer(inProgress)}
                </>
              )}
              {stepAdd === 2 && (
                this.renderSuccess()
              )}
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getKeyStackQuestionById: data => dispatch({
    type: ANSWER.GET_KEY_STACK_QUESTION_BY_ID,
    payload: agent.KeyStack.getKeyStackQuestionById(data)
  }),
  getModelCategories: () => dispatch({
    type: MENTAL_MODEL.GET_CATEGORIES,
    payload: agent.KeyStack.getModelCategories()
  }),
  getSubjectByCategoryId: (data) => dispatch({
    type: data && data.page > 1 ? MENTAL_MODEL.GET_MORE_SUBJECT_BY_CATEGORY_ID : MENTAL_MODEL.GET_SUBJECT_BY_CATEGORY_ID,
    payload: agent.Model.getSubjectByCategoryId(data),
  }),
  getSubjectTrending: (data) => dispatch({
    type: data && data.page > 1 ? MENTAL_MODEL.GET_MORE_SUBJECT_TRENDING : MENTAL_MODEL.GET_SUBJECT_TRENDING,
    payload: agent.Model.getSubjectTrending(data),
  }),
  getSubjectFavorite: (data) => dispatch({
    type: data && data.page > 1 ? MENTAL_MODEL.GET_MORE_SUBJECT_FAVORITE : MENTAL_MODEL.GET_SUBJECT_FAVORITE,
    payload: agent.Model.getSubjectFavorite(data),
  }),
  getSubjectAZ: ({ page, keyWord }) => dispatch({
    type: page > 1 ? MENTAL_MODEL.GET_MORE_SUBJECT_AZ : MENTAL_MODEL.GET_SUBJECT_AZ,
    payload: agent.Model.getSubjectAZ({ page, keyWord })
  }),
  addKeyStack: data => dispatch({
    type: ANSWER.ADD_KEY_STACK,
    payload: agent.KeyStack.addKeyStack(data)
  }),
  resetPage: () =>
    dispatch({
      type: ANSWER.RESET
    }),
  updateKeyStack: data => dispatch({
    type: ANSWER.UPDATE_KEY_STACK,
    payload: agent.KeyStack.updateKeyStack(data)
  }),
});

function mapStateToProps(state, props) {
  const { item } = props;
  let initialValues = {};
  if (item) {
    initialValues = { comment: item.comment };
  }
  return {
    ...state.answer,
    currentUser: state.common && state.common.currentUser,
    categories: state.mentalModel && state.mentalModel.categories,
    inProgressGetModels:
    state.mentalModel && state.mentalModel.inProgressGetModels,
    models: state.mentalModel && state.mentalModel.models,
    totals: state.mentalModel && state.mentalModel.totals,
    more: state.mentalModel && state.mentalModel.more,
    initialValues
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAnswer);
