import { applyMiddleware, combineReducers, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { promiseMiddleware, serviceWorkerMiddleware, localStorageMiddleware } from './middleware';
import reducer from './reducers';

export const history = createBrowserHistory();

const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
    return applyMiddleware(
        myRouterMiddleware,
        promiseMiddleware,
        serviceWorkerMiddleware,
        localStorageMiddleware
    );
};

export const store = createStore(combineReducers(reducer), getMiddleware());
