import React from 'react';
import renderHTML from 'react-render-html';
import { URL_PATH } from '../../constants';


const data = {
  title: `FAQs`,
  detail: [
    `Can’t find your question or want to make a suggestion?<br />Reach out and drop us a line.`
  ],
  button: `CONTACT US`,
  prefixQuestion: `Q: `,
  content: [
    {
      question: `Is it free to sign up and try Mentalmodelhub?`,
      answer: `Absolutely. We have a Free Plan and Premium Membership plan. You can remain on the Free Plan for as long as you like, though we recommend joining the Premium Plan to make the most of all Answer has to offer and boost your decision-making skills. Try it out, if you change your mind let us know within 14 days and we'll happily offer you a full refund.`
    },
    {
      question: `Can I add my own Answers?`,
      answer: `Yes, you may add answers. Simply sign up, select your question and follow the prompts from the "Add Answer" button located on your favourite Answer page.`
    },
    {
      question: `I'd like to share my favorite Answers with others. Is that possible?`,
      answer: `Of course! (Great thinking!) You can find a share button on the bottom of each Answer. Click on the link and copy or share it to your socials.`
    },
    {
      question: `You have outstanding content on your app. Who devises it?`,
      answer: `Excellent question. We research and write all our mental models, strategies, and frameworks in-house. Our team ensures that all the content we provide is up-to-date, engaging, and as accurate as possible. We are highly invested in every aspect of Answer.`
    },
    {
      question: `How often do you add new mental models, strategies and frameworks to your app?`,
      answer: `Very often. We bring you fresh, new content all the time so make sure you sign up to our platform and follow us on socials.`
    },
    {
      question: `How often do you post a new question for the community?`,
      answer: `All the time. We try and post new questions on a daily basis to keep our community mentally engaged and active.`
    },
    {
      question: `I have a problem, dilemma or issue I’d like the community’s help with. Can I send through my question?`,
      answer: `Absolutely. Send us your question in-app or via our Submit a Question page and we’d love to ask our community to help out.`
    },
    {
      question: `Do you have an Android app available?`,
      answer: `Not yet. But stay tuned, we’re working on it. If you’d like us to fast-track an Android app, drop us a line with the words “Android please!” in the subject line.`
    },
    {
      question: `Can I offer suggestions on how to improve your app and request features I’d love to see?`,
      answer: `Absolutely! Email us your ideas and suggestions. We love feedback.`
    },
    {
      question: `I'd like to sign up to the Premium Plan but if it's not for me, can I get a refund?`,
      answer: `As we offer a 14 day Free Premium Trial on signups, we currently do not offer refunds on our Premium subscription.`
    },
    {
      question: `Can I edit a Answer that I've already submitted?`,
      answer: `Yes. You may edit a Answer or simply delete it altogether. Simply locate your answer and follow the prompts to either edit or delete your answer.`
    },
  ],
};

function FAQPage({ onRedirectTo }) {
  const onClickContactUsBtn = () => {
    onRedirectTo(URL_PATH.CONTACT.PATH);
  };

  return (
    <main className="lg:col-span-9 xl:col-span-10 bg-white px-4 py-6 sm:rounded-xl sm:p-6 shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] text-sm leading-relaxed">
      <div className="text-center">
        <h1 className="text-2xl text-neutral-900 text-center mb-4">{data.title}</h1>
        {Array.isArray(data.detail) &&
          data.detail.map((detail) => <p className="mt-2 font-light text-base text-neutral-700" key={Math.random()}>{renderHTML(detail)}</p>)}
        <button type="button" onClick={onClickContactUsBtn} className="flex justify-center rounded-full bg-accent-default py-2 px-6 my-4 text-sm font-medium text-white shadow-sm hover:bg-accent-hover focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2 inline-flex items-center">
          {data.button}
        </button>
      </div>
      <ul>
        {Array.isArray(data.content) &&
          data.content.map((item) => (
            <li key={Math.random()}>
              <p className="text-base text-neutral-900 mt-5">
                {`${renderHTML(data.prefixQuestion)}${renderHTML(
                  item.question
                )}`}
              </p>
              <p className="py-1 font-light text-neutral-700">{renderHTML(item.answer)}</p>
            </li>
          ))}
      </ul>
    </main>
  );
}

export default FAQPage;
