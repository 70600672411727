import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import uniqBy from 'lodash.uniqby'

import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import DetailIcon from '../icons/DetailIcon'
import PodcastIcon from '../icons/PodcastIcon'

import { ModelButton } from "..";
import { URL_PATH } from '../../constants';

const useStyles = makeStyles((theme) => ({
  modelsBox: {
    border: '3px solid #523A35',
    borderRadius: '6px',
    padding: '8px',
    '& button': {
      marginBottom: '8px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  root: {
    borderRadius: '6px',
    padding: "20px 20px 10px",
    margin: "0 0 20px",
  },
  header: {
    fontFamily: "Nunito, sans-serif",
    fontSize: 12,
    fontWeight: 800,
    color: "#362826",
    textAlign: 'left',
    textTransform: "uppercase",
  },
  guest: {
    fontFamily: "Nunito, sans-serif",
    fontSize: 24,
    fontWeight: 800,
    color: "#362826",
    padding: "0 0 0 4px",
    margin: 0,
    textTransform: "uppercase",
    "&:hover": {
      color: "#ef642a",
    }
  },
  duration: {
    color: "rgba(82, 58, 53, .5)",
    padding: "0 24px 0 0",
    margin: "0 0 10px 0",
    '& p': {
      fontFamily: "Nunito, sans-serif",
      fontSize: 12,
      fontWeight: 800,
      margin: "0 0 0 4px",
    }
  },
  note: {
    fontFamily: "Inter, sans-serif",
    fontSize: 16,
    color: "#362826",
    padding: 12,
    width: 220
  },
  model: {
    margin: "0 0 2px 0",
    fontFamily: "Nunito, sans-serif",
    fontSize: 14,
    fontWeight: 800,
    color: "#ef642a",
    textAlign: 'left',
  },
  quote: {
    fontFamily: "Inter, sans-serif",
    fontSize: 16,
    color: "#362826",
    textAlign: 'left',
    padding: "0 34px 0 0",
    margin: "0 0 2px 0",
    whiteSpace: 'pre-wrap',
  },
  // actions: {
  //   textAlign: "right"
  // },
  action: {
    "&:hover svg": {
      opacity: 1,
    },
    "& svg": {
      opacity: 0.5,
      fill: "#362826",
      width: "24px",
      height: "24px",
    }
  },
  svg: {
    width: "24px",
    height: "24px",
  },
  podcastIcon: {
    margin: "0 6px 0 0",
    "& g rect": {
      fill: props => props.color
    }
  },
  more: {
    fontFamily: "Inter, sans-serif",
    fontSize: 14,
    color: "#362826",
    textAlign: 'left',
    textDecoration: "underline",
    cursor: "pointer",
  },

}));

function QuoteCard({ modelName, quote, timecode, id, note }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idPopover = open ? `popover-quote-${id}` : undefined;

  return (
    <>
      <p className={classes.model}>{modelName}</p>
      <p className={classes.quote}>“{quote}”</p>
      <Box display="flex" alignItems="center" justifyContent="flex-end" className={classes.duration}>
        {note && (
          <IconButton size="small" aria-describedby={idPopover} onClick={handleClickMore}>
            <MoreHorizIcon />
          </IconButton>
        )}
        <p>{timecode}</p>
      </Box>
      {note && (
        <Popover
          id={idPopover}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography className={classes.note}>{note}</Typography>
        </Popover>
      )}
    </>
  )
}

function PodcastCard({ item, toggleModelDialog, toggleDetailDialog }) {

  const [isExpanded, setIsExpanded] = useState(false);

  const classes = useStyles({ color: item.text_color });

  // const hasQuotes = item.podcast_analyses.length >= 1;
  const visibleQuotesAmount = Math.max(1, Math.floor(item.podcast_analyses.length / 2) - 1);
  const hasMore = item.podcast_analyses.length > visibleQuotesAmount;
  const visibleQuotes = item.podcast_analyses.slice(0, visibleQuotesAmount);
  const collapsedQuotes = item.podcast_analyses.slice(visibleQuotesAmount);

  const handleToggleMore = () => {
    setIsExpanded(prev => !prev);
  }

  const toDetail = () => URL_PATH.PODCAST_DETAIL.pathParam({ title: item.slug })

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      component="li"
      className={classes.root}

      style={{
        color: "#362826", // item.text_color,
        backgroundColor: "rgba(238, 219, 193, .1)" // item.background_color,
      }}
    >
      <Grid item container component={Box} mb={2.5}>
        <Grid item>
          <PodcastIcon className={classes.podcastIcon} />
        </Grid>
        <Grid item style={{ flex: 1 }}>

          <Typography variant="h1" className={classes.header}>{item.title}</Typography>
          <Typography variant="h2" className={classes.header}>{item.episode_no}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => toggleDetailDialog(item)} className={classes.action} aria-label="podcast summary" size="small">
            <DetailIcon /></IconButton>
        </Grid>
      </Grid>
      <Grid item component={Box} mb={1.25}>
        <Link to={() => toDetail()} className={classes.guest}>{item.guest}</Link>

      </Grid>

      {Array.isArray(item.podcast_analyses) &&
        item.podcast_analyses.length > 0 && (
          <Grid item container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box className={classes.modelsBox}>
                {uniqBy(item.podcast_analyses, (value) => value.model.id).map((quote, index) => (
                  <Fragment key={quote.id}>
                    {quote.model && (
                      <ModelButton
                        title={quote.model.name}
                        color={quote.model.category?.text_color}
                        bgColor={quote.model.category?.background_color}
                        icon={quote.model.category?.icon}
                        onClick={() => toggleModelDialog(quote.model.id)}
                      />
                    )}
                  </Fragment>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              {
                visibleQuotes.map((quote) => (
                  <QuoteCard key={quote.id} modelName={quote.model.name} quote={quote.quote} timecode={quote.timecode} id={quote.id} note={quote.note} />
                ))
              }
              {
                hasMore && (
                  <>
                    <Collapse in={isExpanded}>
                      {collapsedQuotes.map((quote) => (
                        <QuoteCard key={quote.id} modelName={quote.model.name} quote={quote.quote} timecode={quote.timecode} id={quote.id} note={quote.note} />
                      ))}

                    </Collapse>
                    {<p className={classes.more} onClick={handleToggleMore}>Read {isExpanded ? "less" : "more"}</p>}
                    {/* <Box className={classes.actions}>

                      <IconButton onClick={handleToggleMore} className={classes.action} aria-label="expand or shrink podcast quotes" size="small">
                        {isExpanded ? <ShrinkIcon /> : (
                          <MoreHorizIcon />
                        )}</
                      IconButton>
                    </Box> */}
                  </>
                )
              }
            </Grid>
          </Grid>
        )}
    </Grid>
  );
}

export default PodcastCard;
