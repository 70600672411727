import { connect } from 'react-redux';
import PricingPage from '../../pages/sites/PricingPage';

const mapDispatchToProps = () => ({});

function mapStateToProps(state) {
  return {
    isAuthenticated: state.common && state.common.isAuthenticated,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PricingPage);
