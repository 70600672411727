import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/20/solid'

export default function StartedComingSession() {
  return (
    <div className="bg-white">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-neutral-900 sm:text-[40px] sm:leading-[48px]">
          Got any features you'd love to see or any feedback for us?
            <br />
            {/* Start using our app today. */}
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-neutral-900 opacity-80">
          Please send us an email directly at hello@mentalmodelhub.com
            <br />
            or reach out through our contact page.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/contact"
              className="rounded-xl bg-accent-default px-3.5 py-2.5 text-sm font-normal text-white shadow-sm hover:bg-accent-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
