import { KEYSTACK } from '../actions';
import { SWITCH_KEYSTACK } from '../constants';
import {
  processGetDiscussion,
  processCreateDiscussion,
  processUpdateDiscussion,
  processDeleteDiscussion,
  processRespectDiscussion,
} from './helpers/discussion';
import {
  processCreateComment,
  processUpdateComment,
  processDeleteComment,
  processRespectComment,
} from './helpers/comment';

const initState = {
  keystacks: [],
  categories: [],
  more: false,
  tabActive: SWITCH_KEYSTACK.popular,
  questionTrendings: []
};

export default (state = initState, action) => {
  switch (action.type) {
    case KEYSTACK.GET_QUESTION_GROUP:
      return {
        ...state,
        inProgressGetCategory: false,
        categories: Array.isArray(action.payload) ? action.payload : [],
        type: action.type,
      };
    case KEYSTACK.GET_QUESTION_TRENDING:
      return {
        ...state,
        questionTrendings: Array.isArray(action.payload) ? action.payload : [],
        type: action.type,
      };
    case KEYSTACK.GET_KEYSTACK_IN_CATEGORY: {
      const {payload} = action;

      return {
        ...state,
        inProgressInCategory: false,
        keystacks: payload && payload.data ? payload.data : [],
        more: payload && !!payload.next,
        type: action.type,
      };
    }
    case KEYSTACK.GET_MORE_KEYSTACK_IN_CATEGORY: {
      let {keystacks} = state;
      const {payload} = action;
      if (payload && payload.data) {
        keystacks = keystacks.concat(payload.data);
      }
      return {
        ...state,
        inProgressInCategory: false,
        keystacks,
        more: payload && !!payload.next,
        type: action.type,
      };
    }
    case KEYSTACK.SWITCH_LIST: {
      return {
        ...state,
        tabActive: action.value
      };
    }
    case KEYSTACK.RESPECT: {
      if (!action.error && action.payload && action.payload.id) {
        return {
          ...state,
          inProgressRespect: false,
          keystacks: state.keystacks.map(item => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                total_respects: action.payload.total_respects,
                is_respect: action.payload.is_respect,
              };
            }
            return item;
          }),
          type: action.type
        };
      }
      return {
        ...state,
        inProgressRespect: false,
        type: action.type
      };
    }
    case KEYSTACK.GET_DISCUSSION: {
      return processGetDiscussion(state, action);
    }
    case KEYSTACK.CREATE_DISCUSSION: {
      return processCreateDiscussion(state, action)
    }
    case KEYSTACK.UPDATE_DISCUSSION: {
      return processUpdateDiscussion(state, action)
    }
    case KEYSTACK.DELETE_DISCUSSION: {
      return processDeleteDiscussion(state, action)
    }
    case KEYSTACK.RESPECT_DISCUSSION: {
      return processRespectDiscussion(state, action)
    }
    case KEYSTACK.CREATE_COMMENT: {
      return processCreateComment(state, action)
    }
    case KEYSTACK.UPDATE_COMMENT: {
      return processUpdateComment(state, action)
    }
    case KEYSTACK.DELETE_COMMENT: {
      return processDeleteComment(state, action)
    }
    case KEYSTACK.RESPECT_COMMENT: {
      return processRespectComment(state, action)
    }
    case KEYSTACK.PAGE_UNLOAD:
      return {};
    case KEYSTACK.ASYNC_START:
      switch (action.subtype) {
        case KEYSTACK.GET_QUESTION_GROUP:
          return {
            ...state,
            inProgressGetCategory: true,
            type: `${action.type  }_${  action.subtype}`,
          };
        case KEYSTACK.GET_KEYSTACK_IN_CATEGORY:
        case KEYSTACK.GET_MORE_KEYSTACK_IN_CATEGORY:
          return {
            ...state,
            inProgressInCategory: true,
            type: `${action.type  }_${  action.subtype}`,
          };
        case KEYSTACK.RESPECT:
          return {
            ...state,
            inProgressRespect: true,
            type: `${action.type  }_${  action.subtype}`
          };
        default:
          return state;
      }
    default:
      return state;
  }
};
