import React from 'react';
import { DivLoadingComponent } from '../../components/loading';

function LoadingPage() {
  return (
    <div className="content-center page-content text-center">
      <div className="row justify-content-center align-items-center">
        <div className="col-12">
          <DivLoadingComponent />
        </div>
        <div className="col-12">
          <h4>Please wait a moment</h4>
        </div>
      </div>
    </div>
  );
}

export default LoadingPage;
