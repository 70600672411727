import React, { Component } from 'react';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements';

const createOptions = () => ({
  style: {
    base: {
      fontFamily: "'Inter', sans-serif"
    },
    invalid: {
      color: '#9e2146',
    },
  },
});

class _CardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: ''
    };
  }

  onClickAddCard = async () => {
    const { addCard, stripe } = this.props;
    if (stripe) {
      const { fullname } = this.state;
      const payload = await stripe.createToken({ name: fullname });
      const stripeToken = payload.token.id;
      const data = { stripeToken };
      await addCard(data);
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  onChangeField = (event) => {
    this.setState({ fullname: event.target.value });
  };

  render() {
    const { inProgressAddCard, onClose, open } = this.props;
    const { fullname } = this.state;

    return (
      <>
        {open && (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto no-scrollbar fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative p-4 w-full max-w-lg h-full md:h-auto">
                <div className="relative bg-white rounded-xl shadow dark:bg-neutral-700">
                  <button onClick={onClose} type="button" className="absolute top-3 right-2.5 text-neutral-500 bg-transparent hover:bg-neutral-200 hover:text-neutral-900 rounded-xl text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-neutral-800 dark:hover:text-white" data-modal-toggle="authentication-modal">
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                  </button>
                  <div className="py-6 px-6 lg:px-8">
                    <h3 className="mb-2 text-xl font-medium text-neutral-900 dark:text-white">Add Card</h3>
                    <div className="mt-4">
                      <div>
                        <p className="block text-sm font-medium text-neutral-700">Full Name</p>
                        <input
                          type="text"
                          className="mt-1 block w-full rounded-xl border-neutral-300text-sm focus:outline-0 focus:border-neutral-300 focus:ring-neutral-300 focus:ring-0"
                          name="fullname"
                          placeholder="Enter card holder"
                          value={fullname}
                          onChange={this.onChangeField}
                        />
                      </div>

                      <div className="mt-4">
                        <p className="block text-sm font-medium text-neutral-700">Card Number</p>
                        <div className="mt-1 block p-2 w-full rounded-xl border border-neutral-300text-sm bg-white">
                          <CardNumberElement
                            placeholder="Enter card number"
                            {...createOptions()}
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <p className="block text-sm font-medium text-neutral-700">Expiry Date</p>
                        <div className="mt-1 block p-2 w-full rounded-xl border border-neutral-300text-sm bg-white">
                          <CardExpiryElement
                            placeholder="MM/YY"
                            className="expiry-date"
                            {...createOptions()}
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <p className="block text-sm font-medium text-neutral-700">Security Code</p>
                        <div className="mt-1 block p-2 w-full rounded-xl border border-neutral-300text-sm bg-white">
                          <CardCVCElement
                            placeholder="CVC"
                            className="security-code"
                            {...createOptions()}
                          />
                        </div>
                      </div>

                      <div className="mt-5 flex justify-center">
                        <button
                          type="submit"
                          className="flex justify-center rounded-full border border-transparent bg-accent-default py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-accent-hover focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2 inline-flex items-center"
                          disabled={inProgressAddCard}
                          onClick={this.onClickAddCard}
                        >
                          {inProgressAddCard ? (
                            <>
                              <svg role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                              </svg>
                              Waiting...
                            </>
                          ) : (
                            "Add Card"
                          )}
                        </button>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </>
        )}
      </>
    );
  }
}

const AddCard = injectStripe(_CardForm);

export default AddCard;
