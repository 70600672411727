import { LoginPage } from '../../pages/index';
import agent from '../../agent';
import { connect } from 'react-redux';
import { AUTH, COMMON } from '../../actions';

const mapDispatchToProps = dispatch => {
  return {
    onLogin: values => {
      return dispatch({
        type: AUTH.LOGIN,
        payload: agent.Auth.login(values)
      });
    },
    onRedirectTo: to => {
      return dispatch({ type: COMMON.REDIRECT_TO, to: to });
    },
    verifyAccount: values => {
      return dispatch({
        type: AUTH.VERIFY_ACCOUNT,
        payload: agent.Auth.verifyAccount(values)
      });
    },
    activeEmail: values => {
      return dispatch({
        type: AUTH.ACTIVE_EMAIL,
        payload: agent.Auth.activeEmail(values)
      });
    },
    onUnload: () => {
      return dispatch({ type: AUTH.PAGE_UNLOAD });
    }
  };
};

function mapStateToProps(state, ownProps) {
  return {
    ...state.auth
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
