import React from 'react';
import { email, length, required, confirmation } from 'redux-form-validators';

import { SlideNext, SlidePrev } from '../../../components';
import { VALIDATE } from '../../../constants';
import { validateRedux } from '../../../helpers';

const validations = {
  first_name: [
    required({ msg: VALIDATE.REQUIRED }),
    length({
      msg: { tooLong: 'Too long, maximum is {count, number} characters' },
      max: 255
    })
  ],
  last_name: [
    required({ msg: VALIDATE.REQUIRED }),
    length({
      msg: { tooLong: 'Too long, maximum is {count, number} characters' },
      max: 255
    })
  ],
  email: [
    required({ msg: VALIDATE.REQUIRED }),
    email({ msg: VALIDATE.EMAIL }),
    length({
      msg: { tooLong: 'Too long, maximum is {count, number} characters' },
      max: 255
    })
  ],
  biography: [
    required({ msg: VALIDATE.REQUIRED }),
    length({
      msg: { tooLong: 'Too long, maximum is {count, number} characters' },
      max: 120
    })
  ]
};

const validate = values => validateRedux(values, validations);

const changePasswordValidations = {
  password: [
    required({ msg: VALIDATE.REQUIRED }),
    length({
      msg: {
        tooLong: VALIDATE.TOO_LONG,
        tooShort: VALIDATE.TOO_SHORT
      },
      max: 255,
      min: 8
    })
  ],
  confirm_password: [
    required({ msg: VALIDATE.REQUIRED }),
    confirmation({
      field: 'password',
      msg: "'Confirm password' and 'password' do not match"
    })
  ]
};

const changePasswordValidate = values => validateRedux(values, changePasswordValidations);

const slideSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 7,
  nextArrow: <SlideNext />,
  prevArrow: <SlidePrev />,
  swipeToSlide: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 15,
        slidesToScroll: 15
      }
    },
    {
      breakpoint: 945,
      settings: {
        slidesToShow: 14,
        slidesToScroll: 14
      }
    },
    {
      breakpoint: 880,
      settings: {
        slidesToShow: 13,
        slidesToScroll: 13
      }
    },
    {
      breakpoint: 815,
      settings: {
        slidesToShow: 12,
        slidesToScroll: 12
      }
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 11,
        slidesToScroll: 11
      }
    },
    {
      breakpoint: 685,
      settings: {
        slidesToShow: 10,
        slidesToScroll: 10
      }
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 9
      }
    },
    {
      breakpoint: 555,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8
      }
    },
    {
      breakpoint: 490,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7
      }
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6
      }
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5
      }
    }
  ]
};

const imgMain = '/assets/img/profile/main.png';
const imgBackground = '/assets/img/login/img_phone@2x.png';
const imgOval = '/assets/img/contact/oval23.png';
const imgProfile = '/assets/img/profile/avatar-small.png';

const DATA = {
  title: `MY PROFILE`,
  imgBackground,
  imgMain,
  imgOval
};

export { validate, slideSettings, DATA, imgProfile, changePasswordValidate };
