import { connect } from 'react-redux';
import { AUTH, COMMON } from '../../actions';
import agent from '../../agent';
import { MyProfilePage } from '../../pages/index';

const mapDispatchToProps = dispatch => ({
    onUpdateProfile: values => dispatch({
        type: AUTH.UPDATE_PROFILE,
        payload: agent.Auth.updateProfile(values)
      }),
    onGetCurrentUser: () => dispatch({
        type: AUTH.GET_CURRENT,
        payload: agent.Auth.getCurrentUser()
      }),
    onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
    onChangeParams: payload => dispatch({ type: AUTH.CHANGE_PARAMS, payload }),
    onUnload: () => dispatch({ type: AUTH.PAGE_UNLOAD }),
    onLogout: () => dispatch({
        type: AUTH.LOGOUT,
        payload: agent.Auth.logout()
      })
  });

function mapStateToProps(state, ownProps) {
  return {
    ...state.auth,
    initialValues: state.common?.profileUser,
    photos: state.common?.photos,
    inProgressPhotos: state.common?.inProgressPhotos,
    isPremium: state.common?.isPremium
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProfilePage);
