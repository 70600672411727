import { VerifyPage } from '../../pages/index';
import agent from '../../agent';
import { connect } from 'react-redux';
import { AUTH, COMMON } from '../../actions';

const mapDispatchToProps = dispatch => {
  return {
    onVerify: values => {
      return dispatch({
        type: AUTH.VERIFY,
        payload: agent.Auth.verify(values)
      });
    },
    onRedirectTo: to => {
      return dispatch({ type: COMMON.REDIRECT_TO, to: to });
    },
    onUnload: () => {
      return dispatch({ type: AUTH.PAGE_UNLOAD });
    }
  };
};

function mapStateToProps(state, ownProps) {
  return {
    ...state.auth
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyPage);
