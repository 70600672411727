import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
// import { Form, Field } from 'react-final-form';
import {
  MagnifyingGlassIcon,
  ChevronLeftIcon,
} from '@heroicons/react/20/solid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link,useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  DivLoadingComponent,
  LoadingComponent,
  // TextField,
} from '../../../components';
import { MODEL_CATEGORIES, URL_PATH, ROLE } from '../../../constants';
import ModelDetailContainer from '../../../containers/sites/ModelDetailContainer';
import { useParams } from 'react-router';

const MentalModelsPage = ({
  location: { search },
  models,
  getSubjectByCategoryId,
  getSubjectTrending,
  getSubjectFavorite,
  getSubjectAZ,
  isAuthenticated,
  inProgressGetModels,
  path,
  more,
  currentUser,
  totals,
  openIframe
}) => {
  const titles = ['Mental Models', 'My Collection'];
  const [keyWord, setKeyWord] = useState('');
  const [page, setPage] = useState(1);
  const [selectModel, setSelectModel] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [allmodel,setAllModel] = useState(models)
  const isMyCollectionPage =
    path === URL_PATH.MY_COLLECTION.PATH && isAuthenticated;
  const [notAutoSelectModel, setNotAutoSelectModel] = useState(false);
const {pathname} = useLocation()
const {slug} = useParams()
  const handleGetSubject = (groupActive, newPage) => {
    const params = { page: newPage ?? page };
    switch (groupActive) {
      case MODEL_CATEGORIES.trending.value:
         params.keyWord = keyWord;
        getSubjectTrending(params);
        break;
      case MODEL_CATEGORIES.favorite.value:
        if (isAuthenticated) {
          params.keyWord = keyWord;
          getSubjectFavorite(params);
        }
        break;
      case MODEL_CATEGORIES.az.value:
        params.keyWord = keyWord;
        getSubjectAZ(params);
        break;
      default:
        params.id = groupActive;
        getSubjectByCategoryId(params);
    }
  };

  const refreshCollection = () => {
    if (isMyCollectionPage) {
      setNotAutoSelectModel(true);
      getSubjectFavorite({ page });
    }
  };

  const onSelectModel = (id) => {
    setSelectModel(id);
    const { innerWidth: width } = window;
    if (width < 1280) {
      setShowDetail(true);
    }
  };

  const getModels = (newPage) => {
    const parsed = queryString.parse(search);
    let groupModelActive='';
    if(!_.isUndefined(currentUser)){
      if(currentUser?.premium){
        groupModelActive=MODEL_CATEGORIES.az.value
      }else{
        groupModelActive = MODEL_CATEGORIES.trending.value
      }
    }else{
         groupModelActive = MODEL_CATEGORIES.trending.value 
    }
    if (search && parsed && parsed.category) {
      groupModelActive = parsed.category;
    }

    if (path === URL_PATH.MY_COLLECTION.PATH) {
      groupModelActive = MODEL_CATEGORIES.favorite.value;
    }

    handleGetSubject(groupModelActive, newPage);
  };
  
  const getSlugNotContainQuotes = (Slug) => Slug.replace(/["'’]/g, '');
  


  useEffect(()=>{
   
   if(page >2){
   setAllModel(prev=> [...prev, ...models])
   }else{
     setAllModel(models)
   }
  
  },[models])
 

  useEffect(() => {
    if (!notAutoSelectModel && !_.isUndefined(pathname)) {
     
      if(pathname !==URL_PATH.MODEL_DETAIL.PATH  && slug !=='undefined'){
        
        const findIdbySlug = models?.filter(item=> getSlugNotContainQuotes(item?.slug) === String(slug))[0]?.id;
        if(_.isUndefined(findIdbySlug) && page <=Math.ceil(totals/20)){
          // eslint-disable-next-line no-return-assign, no-param-reassign
          setPage(prev=> prev +=1)
          getModels(page)
        }
       
          // eslint-disable-next-line no-unused-expressions
          _.isEmpty(findIdbySlug) ?  setSelectModel(models[0]?.id) : setSelectModel(findIdbySlug);
          
      }else{
        if(models[0]?.slug)
        window.location.replace(`/mental-models/${models[0]?.slug}`)
         setSelectModel(models[0]?.id);
      }
      const { innerWidth: width } = window;
    if (width < 1280) {
      setShowDetail(true);
    }
     
    }
  }, [models,slug]);

  // useEffect(() => {
  //   if (keyWord) {
  //     getModels(1);
  //   }
  //   getModels();
  // }, [keyWord]);

  useEffect(() => {
    setPage(1);
    const delayDebounceFn = setTimeout(() => {
      getModels(1);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [keyWord]);

  const groupModels = (inputs = []) => {
    const data = {};
    for (let i = 0; i < inputs.length; i += 1) {
      const model = inputs[i];
      const firstChar = model.name[0].toUpperCase();
      const innerArr = [];
      if (!data[firstChar]) {
        innerArr.push(model);
        data[firstChar] = innerArr;
      } else {
        data[firstChar].push(model);
      }
    }

    return data;
  };


  const onSearchModels = (keyword) => {
    setKeyWord(keyword);
  };

  const onClickMore = () => {
    const nextPage = page + 1;

    getModels(nextPage);

    setPage(nextPage);
  };
  const modelDatas = groupModels(models);
  const title = path === URL_PATH.MENTAL_MODEL.PATH ? titles[0] : titles[1];
  const isPremium =
    isAuthenticated && currentUser?.role?.name === ROLE.PREMIUM.value;
  const isUpgrade = !isMyCollectionPage && !more;
  return (
    <main className="xl:h-screen lg:col-span-9 xl:col-span-10 flex min-w-0 flex-1 flex-col overflow-hidden">
      <div className="flex h-full">
        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          <div className="relative z-0 flex flex-1 overflow-hidden">
            <main
              className={`${
                !showDetail && 'hidden'
              } relative xl:h-screen z-0 flex-1 focus:outline-none xl:order-last xl:flex animate-in fade-in`}
            >
              <div className="bg-white xl:w-full overflow-y-auto no-scrollbar m-4 rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
                <nav
                  className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden"
                  aria-label="Breadcrumb"
                >
                  <button
                    type="button"
                    onClick={() => setShowDetail(false)}
                    className="inline-flex items-center space-x-3 text-sm font-medium text-neutral-900"
                  >
                    <ChevronLeftIcon
                      className="-ml-2 h-5 w-5 text-neutral-500"
                      aria-hidden="true"
                    />
                    <span>{title}</span>
                  </button>
                </nav>
                {selectModel && (
                  <ModelDetailContainer
                    idModel={selectModel}
                    refreshCollection={refreshCollection}
                  />
                )}
              </div>
            </main>
            <aside
              className={`${
                showDetail && 'hidden'
              } w-full xl:w-96 flex-shrink-0 xl:order-first flex flex-col ${openIframe ? 'z-0': ''}`}
            >
              <div className="px-4 py-6 bg-white m-4 mb-0 rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
                <h2 className="text-lg font-medium text-neutral-900">
                  {title}
                </h2>
                <div className="mt-3 relative rounded-full">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-neutral-500"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="search"
                    name="search"
                    id="search"
                    className="block w-full rounded-full border-neutral-300 pl-10 focus:border-accent-default focus:ring-accent-default text-sm font-light"
                    placeholder="Enter search term..."
                    onChange={(e) => onSearchModels(e.target.value)}
                  />
                  {/* <Form onSubmit={onSearchModels} enableReinitialize>
                    {({ handleSubmit }) => (
                      <form onSubmit={handleSubmit} className="space-y-6">
                        <Field
                          component={TextField}
                          name="search"
                          className="block w-full rounded-full border-neutral-300 pl-10 focus:border-accent-default focus:ring-accent-default text-sm font-light"
                        />
                      </form>
                    )}
                  </Form> */}
                </div>
              </div>
              {/* Directory list */}
              <nav
                className="min-h-0 flex-1 overflow-y-auto no-scrollbar pb-4 mt-2"
                aria-label="Directory"
              >
                {page === 1 && inProgressGetModels && <LoadingComponent />}
                <InfiniteScroll
                  dataLength={allmodel.length}
                  next={onClickMore}
                  hasMore={more}
                  loader={
                    page === 1 ? null : (
                      <div className="px-6 py-2">
                        <DivLoadingComponent />
                      </div>
                    )
                  }
                  height="calc(100vh - 160px)"
                >
                  <>
                    {Object.keys(Object.groupBy(allmodel,({name})=>name[0].toUpperCase())).map((letter) => (
                      <div key={letter} className="relative">
                        <div className="px-6 py-2 text-sm font-medium text-neutral-500">
                          <h3>{letter}</h3>
                        </div>
                        <ul className="relative z-0 divide-y divide-neutral-200 overflow-hidden bg-white mx-4 rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
                          {Object.groupBy(models,({name}) => name[0].toUpperCase())[letter].map((model,index) => (
                            <li key={model.id}>
                              <button
                                type="button"
                                onClick={() => {onSelectModel(model.id);
                                window.history.replaceState({},``,`${URL_PATH.MODEL_DETAIL.PATH}/${getSlugNotContainQuotes(model.slug)}`)}}
                                className={`relative w-full flex items-center px-6 py-4 text-left ${
                                  selectModel === model.id && 'bg-neutral-50'
                                } hover:bg-neutral-50`}
                              >
                                <div className="min-w-0 flex-1 flex gap-4">
                                  <div className='flex justify-center items-center'>
                                    <div className='bg-slate-300 rounded-full p-2 mb-auto'>
                                      {index%2 !== 0 ? (
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10 9C11.6569 9 13 7.65685 13 6C13 4.34315 11.6569 3 10 3C8.34315 3 7 4.34315 7 6C7 7.65685 8.34315 9 10 9Z" fill="#9CA3AF"/>
                                      <path d="M3 18C3 14.134 6.13401 11 10 11C13.866 11 17 14.134 17 18H3Z" fill="#9CA3AF"/>
                                    </svg>
                                    ):(
                                       <svg width="20" height="20" viewBox="0 0 50 48" fill="#9CA3AF" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M15.9996 0C14.7862 0 13.6923 0.730927 13.2279 1.85195C12.7636 2.97297 13.0202 4.26333 13.8782 5.12132L15.9996 7.24264V18.5147C15.9996 19.3104 15.6835 20.0734 15.1209 20.636L3.12088 32.636C-2.5488 38.3057 1.46669 48 9.48484 48H40.5143C48.5324 48 52.5479 38.3057 46.8782 32.636L34.8782 20.636C34.3156 20.0734 33.9996 19.3104 33.9996 18.5147V7.24264L36.1209 5.12132C36.9789 4.26333 37.2355 2.97297 36.7712 1.85195C36.3069 0.730927 35.2129 0 33.9996 0H15.9996ZM21.9996 18.5147V6H27.9996V18.5147C27.9996 20.9017 28.9478 23.1909 30.6356 24.8787L33.7183 27.9613C31.5677 27.4611 29.313 27.5623 27.2047 28.2651L25.7941 28.7353C23.3309 29.5563 20.6679 29.5563 18.2047 28.7353L16.5173 28.1728C16.4031 28.1347 16.2882 28.1002 16.1729 28.0693L19.3635 24.8787C21.0513 23.1908 21.9996 20.9017 21.9996 18.5147Z" fill="#9CA3AF"/>
</svg>
                                    )}
                                    </div>
                                  </div>
                                  <div>
                                  <p className="text-sm font-medium text-neutral-900">
                                    {model.name}
                                  </p>
                                  <p className="text-sm font-light text-neutral-500">
                                    {model.summary}
                                  </p>
                                  </div>
                                </div>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                    {!models.length && (
                      <p className="mt-3 text-sm font-medium text-center text-neutral-500">
                        No items to display
                      </p>
                    )}
                    {!isPremium && isUpgrade && (
                      <div className="my-6 bg-white mx-4 rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] px-6 py-4">
                        <div className="min-w-0 flex-1">
                          <p className="text-sm font-medium text-neutral-900">
                            You have reached the limit of the Free Plan
                          </p>
                          <p className="text-sm font-light text-neutral-500">
                            To gain unlimited access please upgrade to our
                            Premium Plan.
                          </p>
                        </div>
                        <div className="mt-2 flex justify-end">
                          <Link
                            to={
                              isAuthenticated
                                ? URL_PATH.PAYMENT.PATH
                                : `${URL_PATH.LOGIN.PATH}?next=${URL_PATH.PAYMENT.PATH}`
                            }
                          >
                            <button
                              className="h-9 mt-3 rounded-full bg-accent-default px-6 py-2 text-sm font-medium text-white hover:bg-accent-hover"
                              type="button"
                            >
                              {isAuthenticated ? 'Upgrade' : 'Login'}
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                  </>
                </InfiniteScroll>
              </nav>
            </aside>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MentalModelsPage;
