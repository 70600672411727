import React, { useState } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { withRouter } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const twImg = '/assets/img/answer/tw.png';
const fbImg = '/assets/img/answer/fb.png';
const moreImg = '/assets/img/common/more.svg';

const ShareSocial = ({ onCopyLink = () => null, history, link }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenShare = event => {
    // This prevents ghost click.
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const shareLink = link;

  return (
    <div className="share-icon">
      <IconButton onClick={onOpenShare} size="small">
        <img
          alt="Share"
          src={moreImg}
        />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className="share-icon__popover"
      >
        <MenuItem onClick={() => setAnchorEl(null)}>
          <TwitterShareButton
            className="social-icon social-icon--tw"
            url={shareLink}
          >
            <img src={twImg} alt="Twitter" />
            <span className="name">Twitter</span>
          </TwitterShareButton>
        </MenuItem>
        <MenuItem onClick={() => setAnchorEl(null)}>
          <FacebookShareButton
            className="social-icon social-icon--fb"
            url={shareLink}
            quote={shareLink}
          >
            <img src={fbImg} alt="Facebook" />
            <span className="name">Facebook</span>
          </FacebookShareButton>
        </MenuItem>
        <CopyToClipboard text={shareLink} options={{ format: 'text/plain' }}>
          <MenuItem
            className="social-icon--link"
            onClick={() => {
              setAnchorEl(null);
              onCopyLink();
            }}
          >
            <span className="name">Copy Link</span>
          </MenuItem>
        </CopyToClipboard>
      </Popover>
    </div>
  );
}

export default withRouter(ShareSocial);
