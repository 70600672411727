const LOCAL_STORAGE = 'token';
const MESSAGING_META = 'messaging_meta';

export function checkAuthenticated() {
    let oauthToken = null;
    try {
        oauthToken = JSON.parse(localStorage.getItem(LOCAL_STORAGE));
    } catch (error) { }

    if (oauthToken && oauthToken.user && oauthToken.token) {
        return {
            isAuthenticated: true,
            oauthToken: oauthToken
        };
    }
    return {
        isAuthenticated: false,
        oauthToken: null
    };
}

export function setAuthenticated(oauthToken) {
    localStorage.setItem(LOCAL_STORAGE, JSON.stringify(oauthToken));
}

export function removeAuthenticated() {
    localStorage.removeItem(LOCAL_STORAGE);
}

export function getOauthToken() {
    let oauthToken = null;
    try {
        oauthToken = JSON.parse(localStorage.getItem(LOCAL_STORAGE));
    } catch (error) { }
    return oauthToken;
}

export function updateUser(user) {
    let oauthToken = getOauthToken();
    if (oauthToken && oauthToken.user) {
        oauthToken.user = user;
        localStorage.setItem(LOCAL_STORAGE, JSON.stringify(oauthToken));
    }
}

export function setMessagingMeta(token) {
    localStorage.setItem(MESSAGING_META, JSON.stringify(token));
}

export function getMessagingMeta() {
    const defaultToken = { token: null, isDeviceRegistered: false };
    let token = null
    try {
        token = JSON.parse(localStorage.getItem(MESSAGING_META));
    } catch (error) { }

    return token || defaultToken;
}

export function resetMessagingMeta() {
    localStorage.setItem(MESSAGING_META, JSON.stringify({ token: null, isDeviceRegistered: false }));
}
