import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import queryString from 'query-string';
import { Form, Field } from 'react-final-form'
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid'

import { ModelDetailDialog } from '../dialog';
import { getSort } from '../../helpers'

import {
  DivLoadingComponent,
  KeystackCard,
  TextField
} from '../../components';

import {
  ALL_OPTION,
  SWITCH_KEYSTACK,
} from '../../constants';
import { ANSWER, KEYSTACK } from '../../actions';
import { useDialog } from '../../hook';
import AddAnswer from './answer/AddAnswer';
import Newletter from '../layouts/Newletter';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const tabs = [
  { key: SWITCH_KEYSTACK.popular, name: 'Most Popular' },
  { key: SWITCH_KEYSTACK.latest, name: 'Recent' }
]

function KeyStackPage({
  categories,
  keystacks,
  inProgressInCategory,
  more,
  getLeadingKeyStackByGroup,
  getMoreLeadingKeyStackByGroup,
  onSwitchList,
  tabActive,
  questionTrendings,
  getQuestionTrendings,
  onAnnounce,
  deleteKeyStackById,
  currentUser,
  answerType,
  onResetAnswer,
}) {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [groupActive, setGroupActive] = useState(ALL_OPTION.id);
  const [showModelFor, setShowModelFor] = useState(null);
  const [itemEdit, onCloseEdit, onOpenEdit] = useDialog();
  const searchText = useRef(null);

  useEffect(() => {
    searchText.current = '';
    getQuestionTrendings();
  }, []);

  useEffect(() => {
    const sortQuery = getSort(tabActive);
    const initialQuery = {
      page: 1,
      keyWord: '',
      asc: false,
      ...sortQuery
    }

    // if location has category=slug initiate request by category instead of all
    const parsed = location && location.search && queryString.parse(location.search)

    if (parsed && parsed.category) { // if location has category slug in url
      initialQuery.id = parsed.category
    }

    searchText.current = '';
    getLeadingKeyStackByGroup(initialQuery);
  }, [tabActive])

  useEffect(() => {
    // only when categories loaded
    if (!categories.length) { return; }

    // if location has category=slug or locatin state has category id in filters
    const parsed = location && location.search && queryString.parse(location.search)
    if (parsed && parsed.category) {
      // find category index by slug
      const activeCategoryIndex = categories.findIndex(cat => cat.slug === parsed.category)

      // if provided category is ligitimate
      if (activeCategoryIndex !== -1) {
        const activeCategory = categories[activeCategoryIndex]

        // set state with provided category id
        setGroupActive(activeCategory.id)

      }
    }
  }, [categories])

  const onSubmitSearch = (values) => {
    setPage(1)
    const sort = getSort(tabActive);
    const { keyWord } = values;
    searchText.current = keyWord;
    const parsed = location && location.search && queryString.parse(location.search);
    if (parsed && parsed.category) {
      const category = _.find(categories, { slug: parsed.category });
      getLeadingKeyStackByGroup({ id: category.id, page: 1, keyWord, ...sort });
    }
    getLeadingKeyStackByGroup({ page: 1, keyWord, ...sort });
  };

  const onClickMore = () => {
    const nextPage = page + 1;
    const sort = getSort(tabActive);

    getMoreLeadingKeyStackByGroup({
      id: groupActive,
      page: nextPage,
      ...sort,
    });

    setPage(nextPage)
  };

  const handleModelDialog = (modelId) => {
    setShowModelFor(modelId)
  }

  const onDeleteKeystack = (id) => {
    onAnnounce({
      message: (
        <p>Are you sure you want to delete this Answer?</p>
      ),
      label: `Delete`,
      onOk: () => deleteKeyStackById(id)
    });
  }

  const isBlocked = () => {
    if (currentUser && currentUser.blocked) {
      onAnnounce({
        message: <p className="name">Your account has been blocked.<br />Contact administrator for details.</p>,
        label: 'OK'
      });
      return true;
    }
    return false;
  }

  const onOpenEditDialog = (updateStack) => {
    if (isBlocked()) {
      return;
    }
    onOpenEdit(updateStack);
  };

  const onCloseSuccessEditDialog = () => {
    onCloseEdit();
    const message = 'Your Answer has been edited.';
    onAnnounce({
      message: <p className="name">Congratulations.<br />{message}</p>,
      label: 'OK',
    });
    onSubmitSearch({ keyWord: searchText.current || '' });
  };

  useEffect(() => {
    if (answerType === ANSWER.DELETE_KEY_STACK_BY_ID) {
      onSubmitSearch({ keyWord: searchText.current || '' });
      onResetAnswer();
    }
  }, [answerType]);

  const inProgress = inProgressInCategory;
  const hasKeystacks = Array.isArray(keystacks) && keystacks.length > 0;

  return (
    <div className="flex flex-col lg:col-span-9 xl:col-span-10 xl:grid xl:grid-cols-12">
      <main className="order-last xl:order-none xl:col-span-8">
        <div className="px-4">
          <div className="sm:hidden">
            <select
              id="question-tabs"
              className="block w-full rounded-xl border-neutral-300 text-base font-medium text-neutral-900 focus:border-accent-default focus:ring-neutral-400"
              defaultValue={tabActive}
              onChange={e => onSwitchList(e.target.value)}
            >
              {tabs.map((tab) => (
                <option key={tab.key} value={tab.key}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="isolate flex rounded-xl" aria-label="Tabs">
              {tabs.map((tab, tabIdx) => (
                <button
                  key={tab.key}
                  type="button"
                  onClick={() => onSwitchList(tab.key)}
                  aria-current={tab.key === tabActive ? 'page' : undefined}
                  className={classNames(
                    tab.key === tabActive ? 'text-white bg-accent-default' : 'text-neutral-500 hover:text-white border border-neutral-200',
                    tabIdx === 0 ? 'rounded-l-xl' : '',
                    tabIdx === tabs.length - 1 ? 'rounded-r-xl' : '',
                    'group relative min-w-0 flex-1 overflow-hidden py-4 px-6 text-sm font-normal text-center hover:bg-accent-default focus:bg-accent-default'
                  )}
                >
                  <span>{tab.name}</span>
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className="mt-4">
          {page === 1 && inProgress && <DivLoadingComponent />}
          <InfiniteScroll
            dataLength={keystacks.length}
            next={onClickMore}
            hasMore={more}
            loader={page === 1 ? null : <DivLoadingComponent />}
          >
            <ul className="space-y-4 pb-4">
              {

                hasKeystacks &&
                keystacks.filter(item => item && item.question).map((item) => (
                  <KeystackCard
                    component="li"
                    key={item.id}
                    item={item}
                    question={item.question}
                    toggleModelDialog={handleModelDialog}
                    filters={{ category: categories.find(f => f.id === groupActive)?.slug }}
                    actionPrefix={KEYSTACK}
                    toggleEditDialog={onOpenEditDialog}
                    onDelete={onDeleteKeystack}
                  />
                ))
              }
            </ul>
          </InfiniteScroll>
          {!hasKeystacks && !inProgress && (
            <p className="text-center">No items to display</p>
          )}
        </div>
      </main>
      <aside className="order-first mb-4 xl:order-none xl:col-span-4">
        <div className="sticky top-4 space-y-4 xl:overflow-y-auto xl:h-[calc(100vh-32px)]">
          <section aria-labelledby="who-to-follow-heading" className="rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] m-4 mt-0">
            <div className="rounded-none sm:rounded-xl bg-white">
              <div className="px-4 py-6 sm:p-6">
                <h2 id="who-to-follow-heading" className="text-base font-medium text-neutral-900">
                  Search
                </h2>
                <div className="mt-2">
                  <Form
                    onSubmit={onSubmitSearch}
                    enableReinitialize
                  >
                    {({ handleSubmit }) => (
                      <form onSubmit={handleSubmit} className="space-y-6">
                        <Field
                          component={TextField}
                          placeholder="Enter search term..."
                          name="keyWord"
                          isRoundedFull
                        />
                      </form>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </section>
          {questionTrendings.length ? (
            <section className="hidden xl:block rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] m-4 mt-0" aria-labelledby="trending-heading">
              <div className="rounded-xl bg-white">
                <div className="p-6">
                  <h2 id="trending-heading" className="text-base font-medium text-neutral-900">
                    Trending
                  </h2>
                  <div className="mt-6 flow-root">
                    <ul className="-my-4 divide-y divide-neutral-200">
                      {questionTrendings.map((question) => (
                        <li key={question._id._id} className="space-x-3 py-4">
                          <div className="min-w-0">
                            <Link to={`/answers/${question._id[0].slug}`} className="text-sm text-neutral-800 hover:no-underline hover:text-accent-hover">{question._id[0].question}</Link>
                            <div className="mt-2 flex">
                              <span className="inline-flex items-center text-sm">
                                <button
                                  type="button"
                                  className="inline-flex space-x-2 text-neutral-500 hover:text-neutral-500"
                                >
                                  <ChatBubbleLeftEllipsisIcon className="h-5 w-5" aria-hidden="true" />
                                  <span className="font-medium text-neutral-900">{question.count}</span>
                                </button>
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
          <Newletter />
        </div>
      </aside>
      {itemEdit && (
        <AddAnswer
          item={itemEdit}
          question={itemEdit.question}
          onClose={onCloseEdit}
          onCloseSuccess={onCloseSuccessEditDialog}
        />
      )}
      <ModelDetailDialog
        open={!!showModelFor}
        onClose={() => handleModelDialog(null)}
        idModel={showModelFor}
      />
    </div>
  )
}

export default KeyStackPage;
