
export const processGetDiscussion = (state, action) => {
  if (action.error || !action.payload?.data?.length) {
    return { ...state, type: action.type };
  }

  const isReset = !!action.param?.reset
  const stackId = action.payload.data[0]?.keystack;

  const keystacks = state.keystacks.map(item => {
    if (item.id !== stackId) {
      return item;
    }

    if (!isReset) {
      let oldDiscussion = item.discussion?.data || [];

      item.discussion = {
        ...action.payload,
        data: [...oldDiscussion, ...action.payload.data]
      };
    } else {
      item.discussion = action.payload;
    }
    return { ...item };
  });

  return { ...state, keystacks, type: action.type };
}

export const processCreateDiscussion = (state, action) => {
  if (action.error || !action.payload.id) {
    return { ...state, success: false, type: action.type };
  }
  const stackId = action.payload.keystack
  const keystacks = state.keystacks.map(item => {
    if (item.id !== stackId) {
      return item;
    }
    let oldDiscussions = item.discussion?.data || [];

    action.payload.comments = []

    item.discussion = {
      ...item.discussion,
      data: [action.payload, ...oldDiscussions]
    };
    const number_discussion = (item.number_discussion || 0) + 1;
    return { ...item, number_discussion };
  });
  return { ...state, keystacks, type: action.type };
}

export const processUpdateDiscussion = (state, action) => {
  if (action.error || !action.payload.id) {
    return { ...state, success: false, type: action.type };
  }
  const { id: discussionId, keystack: stackId } = action.payload

  const keystacks = state.keystacks.map(item => {
    if (item.id !== stackId) {
      return item;
    }


    const discussions = item.discussion?.data.map(discussion => {
      if (discussion.id !== discussionId) {
        return discussion;
      }

      return {
        ...discussion,
        ...action.payload,
      };
    });

    item.discussion = {
      ...item.discussion,
      data: discussions
    };

    return item;
  });
  return { ...state, keystacks, type: action.type };
}

export const processDeleteDiscussion = (state, action) => {
  if (action.error) {
    return { ...state, success: false, type: action.type };
  }
  const { keystackId, id: discussionId } = action.param;

  const keystacks = state.keystacks.map(keystack => {
    if (keystack.id !== keystackId) {
      return keystack;
    }

    let discussions = keystack.discussion?.data.filter(discussion => discussion.id !== discussionId) || [];
    keystack.discussion = {
      ...keystack.discussion,
      data: discussions,
    };
    const number_discussion = Math.max((keystack.number_discussion || 0) - 1, 0);
    return { ...keystack, number_discussion };
  });

  return { ...state, keystacks, type: action.type };

}
export const processRespectDiscussion = (state, action) => {
  if (action.error || !action.payload?.id) {
    return { ...state, type: action.type };
  }
  return {
    ...state,
    keystacks: state.keystacks.map(item => {
      if (item.id !== action.payload.keystack) {
        return item;
      }
      const discussions = item.discussion?.data.map(discussion => {
        if (discussion.id !== action.payload.id) {
          return discussion;
        }
        return {
          ...discussion,
          total_respects: action.payload.total_respects,
          is_respect: action.payload.is_respect,
        }
      });
      item.discussion = {
        ...item.discussion,
        data: discussions
      };
      return { ...item };
    }),
    type: action.type
  }
}
