import { PRICING } from '../actions';

const defaultState = {
  openUpgradeDialog: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PRICING.GET_PRODUCTS: {
      let product = null;
      if (!Array.isArray(action.payload) || !action.payload.length) {
        return;
      }
      product = action.payload[0];
      if (!product || !product.active) {
        return;
      }
      return { ...state, inProgressGetProduct: false, product, type: action.type };
    }
    case PRICING.GET_CARDS: {
      const { cards, customer } = action.payload;
      return { ...state, inProgressGetCards: false, cards, stripeCustomer: customer, type: action.type };
    }
    case PRICING.ADD_CARD: {
      return { ...state, inProgressAddCard: false, type: action.type };
    }
    case PRICING.DELETE_CARD: {
      return { ...state, inProgressDeleteCard: false, type: action.type };
    }
    case PRICING.SET_DEFAULT_CARD: {
      return { ...state, inProgressSetDefaultCard: false, type: action.type };
    }
    case PRICING.GET_INVOICES: {
      return { ...state, inProgressGetInvoices: false, invoices: action.payload, type: action.type };
    }
    case PRICING.PURCHASE: {
      let success = false;
      if (action.payload) {
        success = true;
      }
      return { ...state, inProgress: false, success, type: action.type };
    }
    case PRICING.RESET:
      return {
        ...state,
        success: null,
        type: action.type
      };
    case PRICING.CHANGE_PARAMS:
      return {
        ...state,
        ...action.data,
        type: action.type
      };
    case PRICING.RENEWAL_SUBCRIPTION:
      return {
        ...state,
        type: action.type
      };
    case PRICING.PAGE_UNLOAD:
      return defaultState;
    case PRICING.ASYNC_START:
      switch (action.subtype) {
        case PRICING.GET_PRODUCTS:
          return { ...state, inProgressGetProduct: true, type: `${action.type  }_${  action.subtype}` };
        case PRICING.GET_CARDS:
          return { ...state, inProgressGetCards: true, type: `${action.type  }_${  action.subtype}` };
          case PRICING.GET_INVOICES:
          return { ...state, inProgressGetInvoices: true, type: `${action.type  }_${  action.subtype}` };
        case PRICING.PURCHASE:
          return { ...state, inProgress: true, type: `${action.type  }_${  action.subtype}` };
        case PRICING.ADD_CARD:
          return { ...state, inProgressAddCard: true, type: `${action.type  }_${  action.subtype}` };
        case PRICING.DELETE_CARD:
          return { ...state, inProgressDeleteCard: true, type: `${action.type  }_${  action.subtype}` };
        case PRICING.SET_DEFAULT_CARD:
          return { ...state, inProgressSetDefaultCard: true, type: `${action.type  }_${  action.subtype}` };
        case PRICING.RENEWAL_SUBCRIPTION:
          return { ...state, type: `${action.type  }_${  action.subtype}` };
        default:
          return state;
      }
    default:
      return state;
  }
};
