import Dialog from '@material-ui/core/Dialog';
import dayjs from 'dayjs';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import styles from './dialogStyle';
import { CloseDialogButton } from '../../../components';

class DetailDialog extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { open } = this.props;
    const { open: openNext } = nextProps;
    if (open !== openNext) {
      return true;
    }
    return false;
  }

  render() {
    const {
      classes,
      open,
      onClose,
      fullScreen,
      message,
      labelBtn,
      item,
      ...rest
    } = this.props;

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="podcast-detail-dialog"
        className="podcast-detail-dialog"
        open={open}
        classes={{
          paper: fullScreen ? classes.dialogPaper : classes.dialogPaperAnnounce
        }}
        fullWidth
        maxWidth="md"
        scroll="paper"
        fullScreen={fullScreen}
        
      >
        {fullScreen || <CloseDialogButton onClick={onClose} />}
        <DialogContent
          className="body-dialog"
          style={{
            color: item.text_color,
            backgroundColor: item.background_color
          }}
        >
          {item && (
            <div className="podcast-detail-dialog-container">
              <div className="text-center">
                <p className="title">{item.title}</p>
                <p className="title">{item.episode_no}</p>
                <h2 className="name">{item.guest}</h2>
              </div>
              <p className="released">{`Released: ${dayjs(item.published_date).format(
                'DD MMMM YYYY'
              )}`}</p>
              <p className="episode">Episode Summary</p>
              <p className="summary">{item.episode_details}</p>
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DetailDialog);
