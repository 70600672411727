'use strict';

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = {
  dialog: {
    padding: '24px 8px 0 24px'
  },
  dialogAction: {
    display: 'inline-block',
    paddingLeft: 20,
    marginLeft: 'auto'
  },
  title: {
    fontSize: '22px !important',
    paddingBottom: 20
  }
};

function CloseDialog(props) {
  const {
    classes,
    title,
    open,
    onClose,
    children,
    labelCancel = 'CLOSE',
    ...rest
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.dialog
      }}
      maxWidth="md"
      fullWidth={true}
      {...rest}
    >
      <Typography className={classes.title} variant="headline">
        {title}
      </Typography>
      {children}
      <DialogActions className={classes.dialogAction}>
        <Button onClick={onClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(CloseDialog);
