import React from 'react';
import HeaderContainer from '../../containers/layouts/HeaderContainer';
import FooterContainer from '../../containers/layouts/FooterContainer';

function PricingPage({ isAuthenticated }) {
  const btnPath = isAuthenticated ? "/payment" : "/sign-up";
  const btnLabel = isAuthenticated ? "Upgrade" : "Sign Up";
  return (
    <>
<HeaderContainer navigation={[]} />
<div className="bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl sm:text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Simple no-tricks pricing</h2>
      <p className="mt-6 text-lg leading-8 text-gray-600">Empower your decision-making skills by unlocking the full potential of MentalModelHub. Access an extensive library of mental models and premium features designed to facilitate your learning and application of mental models.</p>
    </div>
    
    {/* Unified Comparison and Premium Membership Section */}
    <div className="mx-auto mt-16 max-w-4xl rounded-xl bg-gray-50 p-8 shadow sm:mt-20">
      <h3 className="text-xl font-bold tracking-tight text-gray-900 text-center">Choose Your Plan</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        
        {/* Free Plan */}
        <div className="border border-gray-300 rounded-lg p-6">
          <h4 className="text-lg font-semibold text-gray-900">Free Membership</h4>
          <ul className="mt-4 space-y-3">
            <li className="flex items-center text-sm text-gray-600">
              Access to 100 mental model snapshots
            </li>
            <li className="flex items-center text-sm text-gray-600">
              Save 5 mental models to favourites
            </li>
            <li className="flex items-center text-sm text-gray-600">
              Use the "Ask AI" feature to ask up to 5 questions per day
            </li>
            <li className="flex items-center text-sm text-gray-600">
              No access to Premium feature releases
            </li>
          </ul>
        </div>
        
        {/* Premium Plan */}
        <div className="border border-gray-300 rounded-lg p-6 bg-white shadow-md">
          <h4 className="text-lg font-semibold text-gray-900">Premium Membership</h4>
          <ul className="mt-4 space-y-3">
            <li className="flex items-center text-sm text-gray-600">
              Access to 300+ mental model snapshots
            </li>
            <li className="flex items-center text-sm text-gray-600">
              Save to favourites an unlimited number of mental models
            </li>
            <li className="flex items-center text-sm text-gray-600">
              Use our "Ask AI" feature freely
            </li>
            <li className="flex items-center text-sm text-gray-600">
              Access to all future Premium releases
            </li>
            <li className="flex items-center text-sm text-gray-600">
              Cancel your subscription at anytime
            </li>
          </ul>
          {/* Price Box */}
          <div className="mt-8 p-4 bg-accent-blurred rounded-xl text-center shadow-md">
            <p className="text-base font-semibold text-gray-600">Renewed Annually</p>
            <p className="mt-4 flex items-baseline justify-center gap-x-2">
              <span className="text-5xl font-bold tracking-tight text-gray-900">$79.00</span>
              <span className="text-sm font-semibold tracking-wide text-gray-600">USD</span>
            </p>
             {/* Upgrade/Sign Up Button */}
             <a href={btnPath} className="mt-6 block w-full rounded-md bg-accent-default px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-hover">{btnLabel}</a>
            <a href="/privacy" target="_blank" className="mt-4 block text-xs leading-5 text-gray-600">Learn about our policy</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<FooterContainer />


    </>
  );
}

export default PricingPage;
