import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { MagnifyingGlassIcon, ChevronLeftIcon, FolderIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';
import { DocumentTextIcon } from '@heroicons/react/24/outline'
import { URL_PATH } from '../../../constants';
import { LoadingComponent } from '../../../components';
import { ModelDetailDialog } from '../../dialog';
import ModelButton from '../../../components/model/ModelButton'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const PodcastPage = ({
  podcasts,
  inProgressPodcasts,
  getPodcasts,
  isPremium
}) => {
  const tabs = ['Highlights', 'Collection'];

  const [selectPodcast, setSelectPodcast] = useState([]);
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const [showModelFor, setShowModelFor] = useState(null);
  const [showDetail, setShowDetail] = useState(false);

  const onSelectPodcast = (podcast) => {
    setSelectPodcast(podcast);
    const { innerWidth: width } = window;
    if (width < 1280) {
      setShowDetail(true);
    }
  }

  useEffect(() => {
    const limitParam = { limit: 5 };
    if (isPremium) {
      limitParam.limit = 200;
    }
    getPodcasts({ sort: 'created_at', asc: false, keyWord: '', page: 1, ...limitParam });
  }, []);

  useEffect(() => {
    setCurrentTab(tabs[0]);
  }, [selectPodcast]);

  const handleModelDialog = (modelId) => {
    setShowModelFor(modelId)
  }

  const groupPodcasts = (inputs = []) => {
    const data = {};
    for (let i = 0; i < inputs.length; i += 1) {
      const podcast = inputs[i];
      const guestWords = podcast.guest?.name.split(' ').reverse();
      if (!guestWords) {
        continue;
      }
      const firstChar = guestWords[0][0].toUpperCase();
      const innerArr = [];
      if (!data[firstChar]) {
        innerArr.push(podcast);
        data[firstChar] = innerArr;
      } else {
        data[firstChar].push(podcast);
      }
    }

    const dataOrdered = Object.keys(data).sort().reduce(
      (obj, key) => {
        const item = obj
        const resultItem = {};
        const dataItem = data[key];
        for (let i = 0; i < dataItem.length; i += 1) {
          const podcastItem = dataItem[i];
          const guestId = podcastItem.guest?.id;
          const guestItemArr = [];
          if (!resultItem[guestId]) {
            guestItemArr.push(podcastItem);
            resultItem[guestId] = guestItemArr;
          } else {
            resultItem[guestId].push(podcastItem);
          }
        }
        item[key] = resultItem;
        return item;
      },
      {}
    );
    return dataOrdered;
  }

  useEffect(() => {
    if (podcasts.length) {
      const podcastDatas = groupPodcasts(podcasts);
      const firstGuest = podcastDatas[Object.keys(podcastDatas)[0]];
      setSelectPodcast(firstGuest[Object.keys(firstGuest)[0]]);
    }
  }, [podcasts]);

  const renderDetail = () => {
    const firstPodcast = selectPodcast[0];
    if (Object.keys(firstPodcast || {}).length) {
      return (
        <article className="min-w-full">
          <div>
            <div>
              <img className="h-32 w-full object-cover lg:h-48" src={firstPodcast.guest?.image} alt="" />
            </div>
            <div className="mt-3 mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <div className="min-w-0 flex-1">
                <p className="mb-1 font-light text-accent-default">{firstPodcast.guest?.bio}</p>
                <h1 className="text-3xl font-bold text-neutral-900">{firstPodcast.guest?.name}</h1>
              </div>
            </div>
          </div>


          <div className="mt-6 sm:mt-2 2xl:mt-5">
            <div className="border-b border-neutral-200">
              <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <button
                      key={tab}
                      type="button"
                      className={classNames(
                        currentTab === tab
                          ? 'border-accent-default text-neutral-900'
                          : 'border-transparent text-neutral-500 hover:text-neutral-700 hover:border-neutral-300',
                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                      onClick={() => setCurrentTab(tab)}
                    >
                      {tab}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          {currentTab === tabs[0] ? (
            <>
              {selectPodcast.map(podcast => (
                <div className="mx-auto my-6 max-w-5xl px-4 sm:px-6 lg:px-8">
                  <div className="relative space-x-3 rounded-xl border border-neutral-300 bg-white p-3">
                    <div className="flex space-x-3">
                      <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full object-cover" src={podcast.logo} alt="" />
                      </div>
                      <div className="min-w-0 flex-1">
                        <p className="text-sm font-medium text-neutral-900">{podcast.title}</p>
                        <p className="text-sm text-neutral-500 font-light">{podcast.episode_no}</p>
                      </div>
                      <div className="flex flex-shrink-0 self-center items-center">
                        {podcast.podcast_interview && <p className="inline-flex items-center border-accent-default border rounded-full px-3 py-0.5 mr-2 my-1 text-xs font-medium text-neutral-900 cursor-default">{podcast.podcast_interview}</p>}
                        <DocumentTextIcon onClick={() => setOpen(true)} className="ml-3 h-5 cursor-pointer" aria-hidden="true" />
                      </div>
                    </div>
                    <p className="mt-4">Highlights</p>
                    {podcast.podcast_analyses.map((podcastAnalyse, idx) => (
                      <div className={`mt-4 ${(idx >= 5 && !expand) ? "hidden" : "animate-in fade-in"}`}>
                        <ModelButton key={podcastAnalyse.model.id} model={podcastAnalyse.model} toggleModelDialog={() => handleModelDialog(podcastAnalyse.model.id)} />
                        <p className="mt-1 font-light whitespace-pre-line text-sm">{`"${podcastAnalyse.quote}"`}</p>
                      </div>
                    ))}
                    {!expand && podcast.podcast_analyses.length > 5 && (
                      <div className="mt-6 flex justify-end space-x-8">
                        <div className="flex space-x-6">
                          <span className="inline-flex items-center text-sm">
                            <button type="button" onClick={() => setExpand(true)} className="inline-flex space-x-2 text-neutral-500 hover:text-neutral-500">
                              <FolderIcon className="h-5 w-5" aria-hidden="true" />
                              <span className="font-medium text-neutral-900">Expand</span>
                            </button>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="mx-auto my-6 max-w-5xl px-4 sm:px-6 lg:px-8">
              <p className="my-4 text-sm text-neutral-700 font-medium">{`Popular mental models engaged by ${firstPodcast.guest?.name}`}</p>
              <div className="grid grid-cols-12 gap-3">
                {_.uniqBy(_.flatMap([...selectPodcast.map((podcast) => podcast.podcast_analyses)]), (value) => value?.model?.id).map((podcastAnalyse) => (
                  <div
                    key={podcastAnalyse.id}
                    className="relative col-span-6 space-x-3 rounded-xl border border-neutral-300 bg-white px-6 py-4 focus-within:ring-2 focus-within:ring-neutral-400 focus-within:ring-offset-2 hover:border-neutral-400"
                  >
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-medium text-neutral-900">{podcastAnalyse.model.name}</p>
                      <p className="mt-2 text-sm text-neutral-500 font-light">{podcastAnalyse.model.summary}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

        </article>
      )
    }
    return null;
  }

  const podcastDatas = groupPodcasts(podcasts);

  return (
    <main className="lg:col-span-9 xl:col-span-10 flex min-w-0 flex-1 flex-col overflow-hidden">
      {!isPremium && (
        <div className="py-5 md:px-20 bg-white text-center m-4 rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
          <h2 className="text-2xl text-neutral-900">Premium Plan Available</h2>
          <p className="mt-1 font-light text-neutral-700">With Premium Membership you will be able to turbocharge your learning by exploring the mental models applied by well-known thought leaders as well as receiving exclusive Premium content.</p>
          <Link to={URL_PATH.PAYMENT.PATH}>
            <button className="h-9 mt-3 rounded-full bg-accent-default px-6 py-2 text-sm font-medium text-white hover:bg-accent-hover" type="button">Upgrade</button>
          </Link>
        </div>
      )}
      <div className="flex xl:h-screen">
        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          <div className="relative z-0 flex flex-1 overflow-hidden">
          <main className={`${!showDetail && "hidden"} relative xl:h-screen z-0 flex-1 focus:outline-none xl:order-last xl:flex animate-in fade-in`}>
              <div className="bg-white xl:w-full overflow-y-auto no-scrollbar m-4 rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
                <nav className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden" aria-label="Breadcrumb">
                  <button type="button" onClick={() => setShowDetail(false)} className="inline-flex items-center space-x-3 text-sm font-medium text-neutral-900">
                    <ChevronLeftIcon className="-ml-2 h-5 w-5 text-neutral-500" aria-hidden="true" />
                    <span>Thought Leaders</span>
                  </button>
                </nav>
                {renderDetail()}
              </div>
            </main>
            <aside className={`${showDetail && "hidden"} w-full xl:w-96 flex-shrink-0 xl:order-first flex flex-col`}>
              <div className="px-4 py-6 bg-white m-4 mb-0 rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
                <h2 className="text-lg font-medium text-neutral-900">Thought Leaders</h2>
                <div className="mt-3 relative rounded-full">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                  </div>
                  <input
                    type="search"
                    name="search"
                    id="search"
                    className="block w-full rounded-full border-neutral-300 pl-10 focus:border-accent-default focus:ring-accent-default text-sm font-light"
                    placeholder="Enter search term..."
                  />
                </div>
              </div>
              {/* Directory list */}
              <nav className="min-h-0 flex-1 overflow-y-auto no-scrollbar pb-4" aria-label="Directory">
                {inProgressPodcasts ? (
                  <LoadingComponent />
                ) : (
                  <>
                    {Object.keys(podcastDatas).map((letter) => (
                      <div key={letter} className="relative">
                        <div className="px-6 py-2 text-sm font-medium text-neutral-500">
                          <h3>{letter}</h3>
                        </div>
                        <ul className="relative z-0 divide-y divide-neutral-200 overflow-hidden bg-white mx-4 rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
                          {Object.keys(podcastDatas[letter]).map((guest) => {
                            const podCastArr = podcastDatas[letter][guest];
                            return (
                            <li key={podCastArr[0]?.guest.id}>
                              <button type="button" onClick={() => { onSelectPodcast(podCastArr); setExpand(false); }} className={`relative w-full flex items-center px-6 py-4 text-left ${selectPodcast[0]?.guest.id === podCastArr[0]?.guest.id && 'bg-neutral-50'} hover:bg-neutral-50`}>
                                <div className="min-w-0 flex-1">
                                  <p className="text-sm font-medium text-neutral-900">{podCastArr[0].guest?.name}</p>
                                  <p className="text-sm font-light text-neutral-500">{podCastArr[0].guest?.bio}</p>
                                </div>
                              </button>
                            </li>
                          )})}
                        </ul>
                      </div>
                    ))}
                    {!Object.keys(podcastDatas).length && (
                      <p className="mt-3 text-sm font-medium text-center text-neutral-500">No items to display</p>
                    )}
                  </>
                )}
              </nav>
              {!isPremium && (
                <div className="px-6 py-6 bg-white text-center m-4 rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)]">
                  <p className="font-light text-neutral-700">Upgrade to premium and get more thought leaders</p>
                  <Link to={URL_PATH.PAYMENT.PATH}>
                    <button className="h-9 mt-3 rounded-full bg-accent-default px-6 py-2 text-sm font-medium text-white hover:bg-accent-hover" type="button">Upgrade</button>
                  </Link>
                </div>
              )}
            </aside>
          </div>
        </div>
      </div>
      {open && (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto no-scrollbar fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative p-4 w-full max-w-xl h-auto">
              <div className="relative bg-white rounded-xl shadow dark:bg-neutral-700">
                <button onClick={() => setOpen(false)} type="button" className="absolute top-3 right-2.5 text-neutral-500 bg-transparent hover:bg-neutral-200 hover:text-neutral-900 rounded-xl text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-neutral-800 dark:hover:text-white" data-modal-toggle="authentication-modal">
                  <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                </button>
                <div className="py-6 px-6 lg:px-8">
                  <div className="flex flex-col	items-center">
                    <p className="text-sm font-medium text-neutral-900">{selectPodcast.title}</p>
                    <p className="text-sm text-neutral-500 font-light">{selectPodcast.episode_no}</p>
                    <h3 className="my-4 text-2xl text-center font-medium text-neutral-900 dark:text-white">{selectPodcast.guest?.name}</h3>
                  </div>
                  <p className="text-lg font-medium text-neutral-900">Episode Summary</p>
                  <p className="mt-1 text-sm font-light text-neutral-700">{selectPodcast.episode_details}</p>
                </div>
              </div>

            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      )}
      <ModelDetailDialog
        open={!!showModelFor}
        onClose={() => handleModelDialog(null)}
        idModel={showModelFor}
      />
    </main>
  );
}

export default PodcastPage;
