import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function PodcastIcon(props) {
  return (
    <SvgIcon {...props}>
      <g
        transform="matrix(0.00370214,0,0,-0.00370214,0.9306014,23.026754)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M 2805,5674 C 2321,5623 1934,5472 1555,5187 1452,5109 1221,4878 1143,4775 888,4437 737,4081 673,3670 c -24,-160 -24,-501 0,-660 93,-593 392,-1121 841,-1482 103,-83 234,-174 240,-167 2,2 0,36 -5,74 -4,39 -11,139 -15,224 l -7,154 -95,91 c -326,311 -521,679 -599,1131 -24,138 -23,470 1,610 71,415 242,761 521,1054 309,324 680,520 1130,598 139,23 471,23 610,0 449,-77 822,-275 1130,-598 277,-291 451,-643 522,-1054 23,-140 24,-472 0,-610 -78,-448 -270,-814 -589,-1121 l -105,-101 -7,-154 c -4,-85 -11,-185 -15,-224 -5,-38 -7,-72 -4,-74 6,-8 166,105 264,187 439,367 726,881 816,1462 24,160 24,500 0,660 -160,1035 -949,1827 -1977,1985 -101,15 -441,28 -525,19 z" />
        <path
          d="m 2805,4769 c -237,-33 -481,-131 -671,-272 -89,-65 -236,-212 -301,-301 -361,-489 -376,-1155 -38,-1655 36,-53 78,-111 94,-130 l 30,-34 77,72 c 42,39 105,90 139,113 l 63,41 -66,86 c -80,105 -154,256 -189,388 -23,87 -26,117 -26,263 0,147 3,176 26,265 32,119 116,294 186,386 62,81 169,184 252,239 90,62 235,127 346,157 87,23 117,26 263,26 146,0 176,-3 263,-26 111,-30 256,-95 346,-157 83,-55 190,-158 252,-239 70,-92 154,-267 186,-386 23,-89 26,-118 26,-265 0,-147 -3,-176 -26,-265 -35,-128 -110,-283 -191,-388 l -64,-84 63,-41 c 34,-23 97,-74 140,-114 l 77,-72 26,34 c 116,145 174,242 233,384 158,384 141,823 -47,1193 -199,393 -560,669 -997,764 -108,23 -364,33 -472,18 z" />
        <path
          d="m 2885,3871 c -155,-34 -289,-131 -364,-265 -51,-90 -66,-151 -66,-266 0,-76 5,-120 19,-160 56,-166 172,-287 336,-349 55,-21 82,-25 180,-25 98,0 125,4 180,25 164,62 280,183 336,349 14,40 19,84 19,160 0,76 -5,120 -19,160 -75,224 -261,366 -491,375 -49,2 -108,0 -130,-4 z" />
        <path
          d="m 2850,2434 c -14,-2 -59,-9 -100,-15 -228,-33 -458,-155 -564,-299 -91,-124 -109,-235 -87,-545 33,-460 127,-837 289,-1165 33,-67 42,-77 91,-102 l 53,-28 h 457 457 l 52,26 c 62,31 72,47 151,232 98,229 160,457 200,737 37,260 52,571 30,664 -52,229 -286,409 -619,476 -73,15 -359,28 -410,19 z" />
      </g>
    </SvgIcon>
  );
}
export default PodcastIcon;
