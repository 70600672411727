import { NOTIFICATION, AUTH } from '../actions';

const initState = {
  notifications: [],
  more: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case NOTIFICATION.GET_NOTIFICATIONS: {
      const payload = action.payload;
      return {
        ...state,
        inProgress: false,
        notifications: Array.isArray(payload.data) ? payload.data : [],
        more: payload && !!payload.next
      };
    }
    case NOTIFICATION.GET_MORE_NOTIFICATIONS: {
      let items = state.notifications;
      const payload = action.payload;

      if (payload && payload.data) {
        items = items.concat(payload.data);
      }

      return {
        ...state,
        inProgress: false,
        notifications: items,
        more: payload && !!payload.next
      };
    }
    case AUTH.PUSH_MESSAGE: {
      let items = state.notifications;
      const payload = action.payload;

      if (payload) {
        items = [payload].concat(items);
      }

      return {
        ...state,
        notifications: items,
      };
    }
    case AUTH.DELETE_MESSAGE_BY_ID:
    case AUTH.SYNC_DELETE_MESSAGE_BY_ID:
    case NOTIFICATION.DELETE_NOTIFICATION_BY_ID:
    case NOTIFICATION.SYNC_DELETE_NOTIFICATION_BY_ID: {
      if (!action.error) {
        const id = action.param.id
        const newItems = state.notifications.filter(item => item.id !== id)
        return {
          ...state,
          notifications: newItems
        };
      }
    }

    case NOTIFICATION.PAGE_UNLOAD:
      return initState;
    case NOTIFICATION.ASYNC_START:
      switch (action.subtype) {
        case NOTIFICATION.GET_NOTIFICATIONS:
        case NOTIFICATION.GET_MORE_NOTIFICATIONS:
          return { ...state, inProgress: true };
        default:
          return state;
      }
    default:
      return state;
  }
};
