import { connect } from 'react-redux';
import Sidebar from '../../pages/layouts/Sidebar';
import { KEYSTACK, COMMON, MENTAL_MODEL } from '../../actions';
import agent from '../../agent';

const mapDispatchToProps = dispatch => ({
   getSubjectAZ: ({ page, keyWord }) => dispatch({
        type: page > 1 ? MENTAL_MODEL.GET_MORE_SUBJECT_AZ : MENTAL_MODEL.GET_SUBJECT_AZ,
        payload: agent.Model.getSubjectAZ({ page, keyWord })
      }),
  getQuestionGroups: () => dispatch({
      type: KEYSTACK.GET_QUESTION_GROUP,
      payload: agent.KeyStack.getQuestionGroups(),
    }),
  getLeadingKeyStackByGroup: (data) => dispatch({
    type: KEYSTACK.GET_KEYSTACK_IN_CATEGORY,
    payload: agent.KeyStack.getLeadingKeyStackByGroup(data),
  }),
  onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
  });
  

function mapStateToProps(state) {
  return {
    ...state.keystack,
    ...state.mentalModel,
    ...state.common,
    isAuthenticated: state.common && state.common.isAuthenticated,
    initCategory:state.keystack.categories,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
