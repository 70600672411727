import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 45,
    height: 45,
    objectFit: 'cover',
    borderRadius: '50%',
    marginRight: 8
  },
  name: {
    color: '#513A36',
    fontSize: 14,
    lineHeight: '21px',
    marginBottom: 0,
    marginTop: 3,
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 800,
  },
  company: {
    color: '#513A36',
    fontSize: 12,
    lineHeight: '18px',
    marginBottom: 0
  }
}));

function Avatar(props) {
  const { avatar, name, company, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      className={`row mx-0 align-self-center justify-content-start avatar-container ${className}`}
      {...rest}
    >
      <div className="col-auto px-0">
        <img alt="" src={avatar} className={classes.avatar} />
      </div>
      <div className="col px-0">
        <p className={classNames(classes.name, 'avatar-name')}>{name}</p>
        <p className={classNames(classes.company, 'avatar-company')}>
          {company}
        </p>
      </div>
    </div>
  );
}

Avatar.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  company: PropTypes.string
};

Avatar.defaultProps = {
  className: '',
  avatar: '',
  name: '',
  company: ''
};

export default Avatar;
