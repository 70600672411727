import * as firebase from "firebase/app"
import "firebase/messaging"

import { ENV } from '../constants'
import EnvClient from '../../env.json'

import agent from "../agent"
import { isClientSide } from "./index"
import { getMessagingMeta, setMessagingMeta, resetMessagingMeta } from "./LocalStorage"
import { AUTH } from "../actions"


let envType = process.env.REACT_ENV || ENV.DEVELOPMENT
const {
  FIREBASE_API_KEY,
  FIREBASE_PROJECT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
} = EnvClient[envType]

// Firebase configuration
var firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  projectId: FIREBASE_PROJECT_ID,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const isSWSupported = isClientSide && 'serviceWorker' in navigator
const isFirebaseSupported = isClientSide && firebase.messaging.isSupported()
let isSWRegistered = false



let messaging = null
// Retrieve Firebase Messaging object.
if (isFirebaseSupported) {
  messaging = firebase.messaging()
}





const subscribeOnServer = (token) => {
  setMessagingMeta({ token, isDeviceRegistered: false })
  agent.Auth.registerDeviceForMessaging(token).then((data) => {
    setMessagingMeta({ token, isDeviceRegistered: true })
  })
}

const registerDevice = (token) => {
  // record state of messaging token
  let messagingMeta = getMessagingMeta()

  if (token === messagingMeta.token) { // if same token as before
    if (messagingMeta.isDeviceRegistered) { // and registered do nothing
      // all done
    } else { // and not registered register
      subscribeOnServer(token)
    }
  } else { // if new token is different
    if (messagingMeta.token) { // and old token exist unregister old token
      unsubscribe()
    }
    // register new token
    subscribeOnServer(token)
  }
}

export const unsubscribe = () => {
  if (!isFirebaseSupported) {
    return;
  }
  let token = getMessagingMeta().token
  if (token === null) {
    return;
  }

  agent.Auth.unregisterDeviceFromMessaging(token).then((data) => {
    messaging.deleteToken(token).then(() => {
      resetMessagingMeta({ token: null, isDeviceRegistered: false })
    })
  })
}

export const subscribe = () => {
  if (!isFirebaseSupported) {
    return;
  }
  if (!isSWRegistered) {
    setTimeout(() => { subscribe() }, 100)
    return;
  }

  messaging.requestPermission()
    .then(() => {
      messaging.getToken().then(token => {
        registerDevice(token)
      })
        .catch(err => {
          console.log("Unable to retrieve token ", err)
        })
    })
    .catch(err => {
      console.log("Unable to get permission to notify.", err)
    })

  // // Callback fired if Instance ID token is updated.
  // messaging.onTokenRefresh(() => {
  //   messaging.getToken().then((refreshedToken) => {
  //     console.log('Messaging refreshed token: ', token)
  //     debugger

  //     registerDevice(refreshedToken)
  //   }).catch((err) => {
  //     console.log('Unable to retrieve refreshed token ', err)
  //   })
  // })
}

export const postMessage = (action) => {
  if (!isFirebaseSupported) {
    return;
  }
  if (isSWSupported && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage(action)
  }
}


const registerServiceWorker = (store) => {
  // REGISTER SW
  if (isSWSupported && isFirebaseSupported) {

    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/sw.js')
        .then(registration => {

          messaging.useServiceWorker(registration)
          isSWRegistered = true
        })
        .catch(err => console.log('Service Worker Error', err))
    })

    // messaging.onMessage(payload => {})

    // listen for messages from firebase and from other pages
    navigator.serviceWorker.addEventListener('message', event => {
      if (event?.data?.direct) {
        const { type, dispatch, payload, param } = event.data

        if (type === "PUSH_MESSAGE") {
          store.dispatch({
            type: AUTH.PUSH_MESSAGE,
            payload
          })
        } else {
          if (dispatch) {
            store.dispatch({
              type,
              payload,
              param,
            })
          }
        }
      }
    })
  }
  else {
    console.log('ServiceWorker is not supported.')
  }
}

export default registerServiceWorker;
