const STYLE = {
  WIDTH_NO_SCROLL: {
    width: 'calc(100% - 17px)'
  },
  WIDTH_MENU_BAR: 220,
  LOGO_HOME_HEIGHT: 80,
  ICON_SIZE: 24,
  SCROLL_TOP_Y: 50,
  SCROLL_TOP_TIME: 16.6,
  PADDING_SIZE: {
    TINY: 15,
    EX_SMALL: 30,
    SMALL: 70,
    MEDIUM: 90,
    LARGE: 120,
    EX_LARGE: 160
  },
  PADDING_POSITION: {
    BOTH: 'both',
    TOP: 'top',
    BOTTOM: 'bottom'
  }
};

export {
  STYLE
};
