import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { PODCAST } from '../../../actions';
import agent from '../../../agent';

import { ModelDetailDialog } from '../../dialog';
import DetailDialog from '../podcast-detail/DetailDialog';
import { DivLoadingComponent, PodcastCard } from '../../../components';
import { OutLineButton } from '../../../components';
import { UpgradeDialog } from '../../dialog';

// const mainImg = '/assets/img/podcast/macbook-3x.png';

function PodcastUpgrade({ /* buttonBottom = false, */ inProgressPodcasts, getPodcasts, podcasts, onUnload }) {

  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [showModelFor, setShowModelFor] = useState(null);
  const [showDetailFor, setShowDetailFor] = useState(null);

  useEffect(() => {
    getPodcasts({ sort: 'created_at', asc: false, keyWord: '', page: 1 });
    return () => {
      onUnload()
    }
  }, [])

  const onToggleUpgradeDialog = () => {
    setOpenUpgradeDialog(!openUpgradeDialog);
  };

  const handleModelDialog = (modelId) => {
    setShowModelFor(modelId)
  }
  const handleDetailDialog = (podcastId) => {
    setShowDetailFor(podcastId)
  }

  return (
    <div className="row podcast-upgrade podcast-container">
      <div className="content col-12 px-0 align-self-center">
        <h2 className="header">Premium Plan Available </h2>
        <p className="paragraph">
          With Premium Membership, you will have full, exclusive access to explore the most popular models in podcasts, access the audio versions of all our mental models and receive exclusive Premium content.
         {/*  <br />   <br />
          It’s pretty cool stuff we reckon, so check it out! */}
        </p>
        {/* {buttonBottom || ( */}
        <OutLineButton label="UPGRADE" className="action-button" onClick={onToggleUpgradeDialog} />
        {/* )} */}
        <p className="sample-paragraph">A SAMPLE OF OUR PODCAST HIGHLIGHTS</p>
        <ul className="podcasts">
          {inProgressPodcasts ? (
              <DivLoadingComponent />
            ) : Array.isArray(podcasts) && podcasts.length > 0 ? (
              podcasts.map((item, index) => {
                return (
                  <PodcastCard
                    key={item.id}
                    item={item}
                    toggleModelDialog={handleModelDialog}
                    toggleDetailDialog={handleDetailDialog}
                  />
                );
              })
            ) : (
              !inProgressPodcasts && (
                <li>
                  <p className="no-item">No items to display</p>
                </li>
              )
          )}
        </ul>
        {/* <img src={mainImg} className="poster" alt="Upgrade to Premium" /> */}
        {/* {buttonBottom && ( */}
        <OutLineButton
          className="button-bottom action-button"
          label="UPGRADE"
          onClick={onToggleUpgradeDialog}
        />
        {/* )} */}
      </div>
      <UpgradeDialog onClose={onToggleUpgradeDialog} open={openUpgradeDialog} />

      <ModelDetailDialog
        open={!!showModelFor}
        onClose={() => setShowModelFor(null)}
        idModel={showModelFor}
      />
      {showDetailFor &&
        <DetailDialog
          open={!!showDetailFor}
          onClose={() => setShowDetailFor(null)}
          item={showDetailFor}
        />}
    </div>
  );
}

PodcastUpgrade.propTypes = {};

PodcastUpgrade.defaultProps = {};


const mapDispatchToProps = (dispatch, props) => {
  return {
    getPodcasts: data => {
      return dispatch({
        type: PODCAST.GET_PODCASTS,
        payload: agent.KeyStack.getPodcasts(data)
      });
    },
    onUnload: () => {
      return dispatch({ type: PODCAST.PAGE_UNLOAD });
    }
  };
};

function mapStateToProps(state, props) {
  return {
   ...state.podcast,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PodcastUpgrade);
