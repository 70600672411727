import { connect } from 'react-redux';
import agent from '../../agent';
import JointWithUsPage from '../../pages/sites/JointWithUsPage';
import { KEYSTACKAI } from '../../actions';

const mapDispatchToProps = dispatch => ({
   getQuestionTrendings: () => dispatch({
        type: KEYSTACKAI.GET_QUESTION_TRENDING,
        payload: agent.KeyStack.getQuestionTrendings(),
      }),
  });

function mapStateToProps(state) {
  return {
 ...state.keystackAI,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JointWithUsPage);
