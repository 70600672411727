import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link, useHistory } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'query-string';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid'
import _, { isUndefined } from 'lodash';
import { ModelDetailDialog } from '../dialog';
import { getSort } from '../../helpers'
import agent from '../../agent';

import {
  DivLoadingComponent,
  AIKeystackCard,
} from '../../components';

import { KEYSTACK } from '../../actions';
import { ASK_AI_LIMIT_MESSAGE, ENV, URL_PATH } from '../../constants'
import EnvClient from '../../../env.json';
import { getOauthToken } from '../../helpers/LocalStorage';
import RealtimeAnswerCard from '../../components/card/RealtimeAnswerCard';


function KeyStackPage({
  keystacks,
  inProgressInCategory,
  inProgressSubmitQuestion,
  more,
  getLeadingKeyStackByGroup,
  getMoreLeadingKeyStackByGroup,
  tabActive,
  questionTrendings,
  getQuestionTrendings,
  currentUser,
  submitQuestion,
  limitMsg,
  onAnnounce,
  onAnnounced,
  onRedirectTo,
  getModelByAnswer, 
  answer
}) {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [questionValue, setQuestionValue] = useState('');
  const [showModelFor, setShowModelFor] = useState(null);
  const [realtimeAnswer,setRealtimeAnswer] = useState([]);
  const [models, setModels] = useState([]);
  const [msg,setMsg] = useState('')
  const [nextQuestion,setNextQuestion] = useState(false)
  const [count,setCount] = useState(0)
  const abortController = new AbortController();


const history = useHistory();
const unblock = useRef(true);




  
  useEffect(() => {
    getQuestionTrendings();
  }, []);
  useEffect(() => {
    let counter = count;
    const interval = setInterval(() => {
      if (counter > realtimeAnswer.length) {
        clearInterval(interval);
      } else {
        setCount(prev => prev + 1);
        counter +=1; // local variable that this closure will see
      }
    }, 50);
    return () => clearInterval(interval); 
  }, [realtimeAnswer]);

  useEffect(()=>{
    if(!_.isUndefined(answer) ){
      setModels(prev=>[{id:answer.id,question:answer.question,answer:realtimeAnswer, models:answer},...prev])
    }
  },[answer])
  useEffect(() => {
    if (msg !=='') {
      if (msg === ASK_AI_LIMIT_MESSAGE.FREE_LIMIT) {
        onAnnounce({
          message: "Free users are only allowed to ask a maximum of 5 questions per day",
          label: "Upgrade",
          onOk: () => { onAnnounced(); onRedirectTo(URL_PATH.PAYMENT.PATH) },
        })
      }
      if (msg === ASK_AI_LIMIT_MESSAGE.PREMIUM_LIMIT) {
        onAnnounce({
          message: "Premium users are only allowed to ask a maximum of 30 questions per day",
          label: "Oke",
          onOk: () => { onAnnounced() },
        })
      }
    }
  }, [msg]);



  useEffect(() => {
    if (!inProgressSubmitQuestion) {
      setQuestionValue('');
    }
  }, [inProgressSubmitQuestion]);

  useEffect(() => {
    const sortQuery = getSort(tabActive);
    const initialQuery = {
      page: 1,
      keyWord: '',
      asc: false,
      ...sortQuery
    }

    // if location has category=slug initiate request by category instead of all
    const parsed = location && location.search && queryString.parse(location.search)

    if (parsed && parsed.category) { // if location has category slug in url
      initialQuery.id = parsed.category
    }

    getLeadingKeyStackByGroup(initialQuery);
  }, [tabActive])

  const onClickMore = () => {
    const nextPage = page + 1;
    const sort = getSort(tabActive);

    getMoreLeadingKeyStackByGroup({
      page: nextPage,
      ...sort,
    });

    setPage(nextPage)
  };

  const handleModelDialog = (modelId) => {
    setShowModelFor(modelId)
  }
  const handleSubmit = async () => {
     unblock.current = history.block(() => {
       onAnnounce({
          message: "Hang Tight! Your AI answer is still unfolding.",
          label: "OK",
          onOk: () => { onAnnounced()},
        })
        return false
     })
    if(questionValue.length > 1000){
        onAnnounce({
          message: "Sorry, Limit Ask AI number of characters is 1000",
          label: "OK",
          onOk: () => { onAnnounced()},
        })
    }else{
    setRealtimeAnswer([]);
    setNextQuestion(true)
         try{
    const envType = process.env.REACT_ENV || ENV.DEVELOPMENT;
     const oauthToken = getOauthToken();
      const test = await fetch(`${EnvClient[envType].SERVICE_URL}/api/v1/ai-keystacks/question`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept":"*/*",
                "Accept-Encoding":"gzip, deflate, br",
                "Accept-Language":"en-US,en;q=0.9",
                "Authorization":`Bearer ${oauthToken.token}`,
                
            },
            body: JSON.stringify({
                question: questionValue,
            }),
        },{ signal: abortController.signal });
           // eslint-disable-next-line no-inner-declarations, no-restricted-syntax
         
       // eslint-disable-next-line no-inner-declarations
       async function streamToString(body) {
           const decode = new TextDecoderStream();
            const reader = body?.pipeThrough(decode).getReader();
            let text='';
           
            while (reader) {
              const stream = await reader.read();
              
                
                if (stream.done){
                  getModelByAnswer({question:questionValue,answer: text.split("+")})
                   setTimeout(()=>{
                    setNextQuestion(false);
                    setCount(0);
                    unblock.current = history.block(() => true)
                  },4000)
                  break;
                } else{
                   
                  if(stream.value.indexOf(`"status":false`) ===1){
                        setMsg(JSON.parse(stream.value).msg);
                        setNextQuestion(false);
                        break;
                }
                    const chunks = stream.value
                    .replaceAll(/^data: /gm, "")
                    .split("\n")
                    .filter((c) => Boolean(c.length) && c !== "[DONE]")
                    // .map((c) => JSON.parse(c));
                   
                if (chunks) {
               
                    // eslint-disable-next-line no-restricted-syntax
                    for (const chunk of chunks) {
                    
                      let data = chunk.slice(chunk.indexOf("delta")-1,chunk.indexOf("logprobs")-2).replaceAll(/"delta":/g,"")   
                      if(chunk.indexOf("assistant")<0 && _.isString(chunk) && _.isString(data)){
                       if(data.indexOf("}")===-1 || data.indexOf("}") !== data.length -1){
                        data +="}"
                       }else{
                        const checktype = JSON.parse(chunk.slice(chunk.indexOf("delta")-1,chunk.indexOf("logprobs")-2).replaceAll(/"delta":/g,""))
                       
                         if(!_.isEmpty(checktype)){
                       
                             setRealtimeAnswer((prev)=>[...prev,checktype.content.replaceAll(/[\\#\\*]/g,"")])
                            text+=checktype.content.replaceAll(/[\\#\\*]/g,"")
                         }
                       }
                        
                       
                      }
                    
                    }
                }
                }
               
              
            }
           
        
        }
        streamToString(test.body);
    }catch(Err){
      console.log(Err);
    }
    }
 
   
   

  };


  const handleChangeValue = e => {
    setQuestionValue(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
  };

  const inProgress = inProgressInCategory;
  const hasKeystacks = Array.isArray(keystacks) && keystacks.length > 0;
  const [timeExceeded, setTimeExceeded] = useState(false);

  useEffect(() => {
    let timer;
    if (inProgressSubmitQuestion) {
      timer = setTimeout(() => {
        setTimeExceeded(true);
      }, 60000); // Set timeout for 60 seconds
    } else {
      setTimeExceeded(false);
    }
    return () => clearTimeout(timer);
  }, [inProgressSubmitQuestion]);

  return (
    <div className="flex flex-col lg:col-span-9 xl:col-span-10 xl:grid xl:grid-cols-12">
      <main className="order-last xl:order-none xl:col-span-8">
         {/* <div className="flex-shrink-0 md:visible hidden">
              <img
                className="inline-block h-10 w-10 rounded-full object-cover"
                src={currentUser?.profile_image}
                alt=""
              />
            </div> */}
        <div className="md:px-4 px-0">
          <div className="flex md:space-x-5 items-start">
            <div className="flex-shrink-0 md:visible hidden">
              <img
                className="inline-block h-10 w-10 rounded-full object-cover"
                src={currentUser?.profile_image}
                alt=""
              />
            </div>
            <div className="min-w-0 flex-1 p-4 xl:block rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] bg-white">
              {/* Informative Section */}
            
              <div className="w-full p-4 rounded-2xl shadow-sm bg-accent-blurred mb-4 text-center ring-1 ring-inset ring-gray-900/5">
                <h2 className="text-lg font-semibold text-gray-900">Try Our Ask AI Feature!</h2>
                <p className="text-sm font-medium text-gray-900">
                  Unlock insights by asking questions through the lens of mental models! Our AI will suggest applicable models for you to apply. Note: Limited to 5 questions per day on our free plan.
                </p>
              </div>
              <div>
                <textarea
                  type="text"
                  className="block w-full text-sm font-light border-none focus:border-white focus:ring-white"
                  value={questionValue}
                  onChange={handleChangeValue}
                  placeholder="Ask your question here..."
                  rows={4}
                />
              </div>
              <div className="mt-2 flex justify-end">
  <button
    type="button"
    onClick={()=>{
      if(questionValue===''){
        onAnnounce({
          message: "Sorry, I didn't get that. Please enter your question here.",
          label: "OK",
          onOk: () => { onAnnounced()},
        })
      }else{
        handleSubmit()
      }
      }}
    className="inline-flex items-center rounded-full bg-accent-default px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-accent-hover focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2 hover:no-underline"
    disabled={inProgressSubmitQuestion}
  >
    {inProgressSubmitQuestion || nextQuestion ? (
      <>
        <svg role="status" className="inline mr-2 w-6 h-6 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M50 10c22.091 0 40 17.909 40 40s-17.909 40-40 40S10 72.091 10 50"
            stroke="#fff"
            strokeWidth="8"
            strokeLinecap="round"
          />
        </svg>
        {timeExceeded ? (
          <p className="text-white">
            This is taking longer than expected, please be patient or try again later.
          </p>
        ) : (
          "Generating your answer... This may take up to a minute."
        )}
      </>
    ) : (
      "Submit"
    )}
  </button>
</div>
{inProgressSubmitQuestion && (
  <div className="mt-2 text-sm text-gray-600">
    Answers typically take less than 60 seconds to generate.
  </div>
              )}
            </div>
          </div>
        </div>
 
        <div className="mt-4">
          {page === 1 && inProgress && <DivLoadingComponent />}
          <InfiniteScroll
            dataLength={keystacks.length}
            next={onClickMore}
            hasMore={more}
            loader={page === 1 ? null : <DivLoadingComponent />}
          >
            <ul className="space-y-4 pb-4">
             
                    
               
             {nextQuestion &&  (
               <li  className="bg-white px-4 py-6 sm:rounded-xl sm:p-6 shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] m-2">
                <p  className="text-base font-medium text-neutral-900">
                  <p className="mt-3">{questionValue}</p>
                </p>
                  <div className="h-[22px] bg-neutral-900 rounded-full w-[142px] flex items-center px-6 my-2" >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="-ml-1 mr-0.5 h-4 w-4 text-neutral-200 cursor-pointer"><path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" /></svg>
                        <div className='flex px-2'>
                          <span className="dot-one  bg-gray-200 rounded-full   w-[5px] h-[5px]" /> 
                          <span className="dot-two bg-gray-200 rounded-full mx-2 w-[5px] h-[5px]" /> 
                          <span className="dot-three bg-gray-200 rounded-full  w-[5px] h-[5px]" /> 
                        </div>
                  </div>
                <div className='whitespace-pre-line'>
                
                  {realtimeAnswer.slice(0,count).map(comment=> (<span className="mt-1 space-y-4 text-sm text-neutral-700 font-light" key={comment.id}>{comment}</span>))}
                   <div className='px-2'> 
                    <span className="dot-one inline-flex h-[5px]  w-[5px]  rounded-full bg-slate-800 opacity-75" />
                    <span className="dot-two inline-flex mx-2 h-[5px]  w-[5px]  rounded-full bg-slate-800 opacity-75" />
                    <span className="dot-three inline-flex h-[5px]  w-[5px] rounded-full bg-slate-800 opacity-75" />
                  </div>
                  </div>
                </li>
          )}
             {!_.isEmpty(models) && models.map(item=>(
              <RealtimeAnswerCard component="li"
                    key={item.id}
                    item={item}
                    question={item.question}
                    toggleModelDialog={handleModelDialog}
                    actionPrefix={KEYSTACK}
                    
                    />
             ))}
              {
                hasKeystacks &&
                keystacks.filter(item => item && item.question).map((item) => (
                  <AIKeystackCard
                    component="li"
                    key={item.id}
                    item={item}
                    question={item.question}
                    toggleModelDialog={handleModelDialog}
                    actionPrefix={KEYSTACK}
                  
                  />
                ))
              }
            </ul>
          </InfiniteScroll>
          {!hasKeystacks && !inProgress && (
            <p className="text-center" />
          )}
        </div>
      </main>
      <aside className="order-first mb-4 xl:order-none xl:col-span-4">
        <div className="sticky top-4 space-y-4">
          {questionTrendings.length ? (
            <section className="hidden xl:block rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] m-4 mt-0" aria-labelledby="trending-heading">
              <div className="rounded-xl bg-white">
                <div className="p-6">
                  <h2 id="trending-heading" className="text-base font-medium text-neutral-900">
                    Trending
                  </h2>
                  <div className="mt-6 flow-root">
                    <ul className="-my-4 divide-y divide-neutral-200">
                      {questionTrendings.map((question) => (
                        <li key={question._id._id} className="space-x-3 py-4">
                          <div className="min-w-0">
                            <Link to={`/answers/${question._id[0].slug}`} className="text-sm text-neutral-800 hover:no-underline hover:text-accent-hover">{question._id[0].question}</Link>
                            <div className="mt-2 flex">
                              <span className="inline-flex items-center text-sm">
                                <button
                                  type="button"
                                  className="inline-flex space-x-2 text-neutral-500 hover:text-neutral-500"
                                >
                                  <ChatBubbleLeftEllipsisIcon className="h-5 w-5" aria-hidden="true" />
                                  <span className="font-medium text-neutral-900">{question.count}</span>
                                </button>
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
        </div>
      </aside>
      <ModelDetailDialog
        open={!!showModelFor}
        onClose={() => handleModelDialog(null)}
        idModel={showModelFor}
      />
    </div>
  )
}

export default KeyStackPage;
