import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  bootstrapRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3)
    },
    width: 387,
    boxShadow: 'none'
  },
  bootstrapInput: {
    borderRadius: 20,
    backgroundColor: theme.palette.common.white,
    border: `1px solid #F7B194`,
    fontSize: 14,
    padding: '10px 36px',
    color: 'var(--light)',
    'font-family': "'Nunito', sans-serif",
    'font-weight': 800,
    boxShadow: 'none'
  }
}));

const ReduxOutLineTextField = ({ input, meta, ...rest }) => {
  const classes = useStyles();

  return (
    <TextField
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.bootstrapRoot,
          input: classes.bootstrapInput
        }
      }}
      error={meta && meta.touched && meta.invalid}
      helperText={meta && meta.touched && meta.error}
      {...input}
      {...rest}
    />
  );
};

export default ReduxOutLineTextField;
