import React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Cognitive Clarity',
    description: 'Mental models streamline complex thoughts, ensuring lucidity and effective decision-making.',
  },
  {
    name: 'Problem Solving',
    description: 'They equip thinkers with structured approaches, turning challenges into manageable tasks.',
  },
  {
    name: 'Enhanced Memory',
    description: 'Familiar frameworks anchor new information, promoting better retention and recall.',
  },
  {
    name: 'Perspective Shift',
    description: 'Mental models encourage multiple viewpoints, enriching understanding and reducing biases.',
  },
  {
    name: 'Efficient Learning',
    description: 'They serve as scaffolding for knowledge, accelerating the acquisition of new concepts.',
  },
  {
    name: 'Informed Decisions',
    description: 'Applying the right model can highlight potential outcomes, leading to better choices.',
  },
  {
    name: 'Continuous Improvement',
    description: 'Mental models foster a growth mindset, sparking perpetual learning and evolution.',
  },
  {
    name: 'Strategic Thinking',
    description: 'They provide a roadmap for navigating complex scenarios, ensuring goals remain within reach.',
  },
];

export default function StayCustomerSupportSession() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-2xl font-semibold text-accent-default">
              Benefits of Mental Models
            </h2>
            <p className="mt-4 text-3xl font-semibold tracking-tight text-black sm:text-[32px]">
            Elevating Your Thinking with Mental Models
            </p>
            <p className="mt-6 text-base leading-7 text-neutral-700 font-normal">
              Discover the advantages of utilizing mental models for clearer thinking and effective decision-making. Also, don't forget to explore our Coming Soon page for a glimpse of what's on the horizon.
            </p>
            <div className="mt-6">
              <a
                href="/comingsoon"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-accent-default hover:bg-accent-hover focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-accent-active"
              >
                Explore Coming Soon
              </a>
            </div>
          </div>
          
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-neutral-700 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="font-semibold text-gray-900">
                  <CheckIcon
                    className="absolute left-0 top-1 h-5 w-5 text-accent-default"
                    aria-hidden="true"
                  />
                  <div className="text-lg text-neutral-900">{feature.name}</div>
                </dt>
                <dd className="mt-2">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
