import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: 14,
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 800
  },
  root: {
    justifyContent: 'flex-start',
    padding: 12,
    borderRadius: 6,
    '& .MuiButton-label': {
      fontWeight: 800,
      textTransform: 'none',
      fontFamily: "'Nunito', sans-serif",
    },
    '& .MuiButton-startIcon': {
      marginLeft: 2,
      marginRight: 12
    }
  }
}));

function StackModelButton(props) {
  const { srcImg, title, summary, style = {}, onClick = () => null, ...rest } = props;
  const classes = useStyles();

  const content = (
    <Button
      className={classes.root}
      style={style}
      onClick={onClick}
      startIcon={srcImg && <img src={srcImg} alt={title} width={20} height={20} />}
      fullWidth
      {...rest}
    >
      {title}
    </Button>
  );
  if (summary) {
    return (
      <Tooltip title={summary} placement="top" arrow classes={{ tooltip: classes.tooltip }}>
        {content}
      </Tooltip>
    );
  }
  return content;
}

export default StackModelButton;
