import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const arrow = '/assets/img/sample/left-arrow.svg';

const styles = () => ({
  root: {
    position: 'absolute',
    left: 2,
    top: 65,
    zIndex: 2,
    width: 40,
    height: 40,
    outline: 'none!important'
  },
  label: {
    marginTop: -12
  }
});

function SlidePrevArrow(props) {
  const { className, style, onClick, classes } = props;
  let display = ' d-block';
  if (className.includes('slick-disabled')) {
    display = ' d-none';
  }
  return (
    <IconButton
      classes={{ root: classes.root, label: classes.label }}
      className={`prev-arrow ${display}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={arrow} alt="" width={40} />
    </IconButton>
  );
}

export default withStyles(styles)(SlidePrevArrow);
