import React from 'react';
import renderHTML from 'react-render-html';

const imgMain = '/assets/img/workshops/workshops.png';
const imgBackground = '/assets/img/login/img_phone@2x.png';
const imgOval = '/assets/img/contact/oval23.png';

const data = {
  title: `Workshops`,
  detail: [
    `Want to learn more?`,
    `We're currently offering customized Mentalmodelhub Workshops (online through Zoom or Google Meet due to Covid-19) for those interested in deep-diving into mental models, strategies and frameworks.`,
    `Enquire today to find out more about pricing and to book a Mentalmodelhub Workshop.`,
    `Make sure you also join our mailing list to find out when we’ll have physical workshops again, as well as find out about cool things like the 
    <a href="/book">book</a>
     we’re producing. `,
    `Contact us here:`,
  ],
  email: `hello@mentalmodelhub.com`,
  imgBackground,
  imgMain,
  imgOval
};

function WorkshopsPage() {
  return (
    <div className="content-center page-content workshops-page">
      <img src={data.imgOval} alt="" className="oval" />
      <div className="row p-content mx-0 workshops-container">
        <div className="content col-sm-12 col-md px-0">
          <h1>{data.title}</h1>
          <div className="detail">
            {Array.isArray(data.detail) &&
              data.detail.map((detail, index) => <p key={index}>{renderHTML(detail)}</p>)}
            <a className="email" href={`mailto:${data.email}`}>{data.email}</a>
          </div>
        </div>
        <div className="col-sm-12 col-md px-0">
          <img src={data.imgBackground} alt="Background" className="bg" />
          <img src={data.imgMain} alt="Background workshop" className="main" />
        </div>
      </div>
    </div>
  );
}

export default WorkshopsPage;
