import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  button: {
    color: 'var(--primary)',
    borderColor: 'rgba(239,100,42,0.5)',
    borderRadius: 20,
    height: 40,
    fontSize: 14,
    width: 152,
    fontWeight: 800,
    backgroundColor: '#FFFFFF',
    outline: 'none !important',
    '&:hover': {
      backgroundColor: '#FFFFFF'
    },
    '& span': {
      fontFamily: "'Nunito', sans-serif",
      fontWeight: 800,
    }
  }
}));

function OutLineButton({ label, className, ...rest }) {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      className={cx(classes.button, className)}
      {...rest}
    >
      {label}
    </Button>
  );
}

export default OutLineButton;
