import React from 'react';
import StayOnTopSession from '../components/StayOnTopSession';
import StartedComingSession from '../components/StartedComingSession';
import HeaderContainer from '../../containers/layouts/HeaderContainer';
import FooterContainer from '../../containers/layouts/FooterContainer';

function ComingSoonPage() {
  return (
    <>
      <HeaderContainer navigation={[]} />
      <StayOnTopSession />
      <StartedComingSession />
      <FooterContainer />
    </>
  );
}

export default ComingSoonPage;