import React, { useState } from 'react';
import { connect } from 'react-redux';
import agent from '../../agent';
import { URL_PATH } from '../../constants';
import Spin from '../../components/tailwind/Spin';
import { COMMON } from '../../actions';

const Newletter = ({ onAnnounce }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email.trim()) {
      return;
    }
    try {
      setLoading(true);
      await agent.KeyStack.registerFreePlan({ email: email.trim() });
      onAnnounce({
        message: (
          <p className="name">
          Thank you for subscribing!
          <br />
          We’re thrilled to have you on board and will be in touch shortly. Stay tuned!
        </p>
        
        ),
        label: 'OK',
      });
      setEmail('');
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="bg-white p-6 rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] m-4">
      <div className="text-base font-bold tracking-tight text-gray-900">
        <h2 className="inline">
          Want product news and updates?
        </h2>{' '}
        <p className="inline">
          Sign up for our newsletter.
        </p>
      </div>
      <form className="pt-4" onSubmit={handleSubmit}>
        <div className="flex gap-x-2">
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-focus sm:text-sm sm:leading-6"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            type="submit"
            className="min-w-[96px] flex justify-center items-center rounded-md bg-accent-default px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-accent-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-default disabled:bg-gray-500 disabled:focus:ring-gray-400 disabled:cursor-default"
            disabled={loading}
          >
            {loading ? <Spin className="animate-spin h-5 w-5 text-white" /> : 'Subscribe'}
          </button>
        </div>
        <p className="mt-4 text-sm leading-6 text-gray-900">
          We care about your data. Read our{' '}
          <a
            href={URL_PATH.PRIVACY.PATH}
            className="font-semibold text-accent-default hover:text-accent-hover"
            target='_blank'
          >
            privacy policy
          </a>
          .
        </p>
      </form>
    </div>
  );
};


const mapDispatchToProps = dispatch => ({
  onAnnounce: (data) => dispatch({ type: COMMON.ANNOUNCE, data }),
});

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Newletter);
