import { connect } from 'react-redux';
import SubmitQuestionPage from '../../pages/sites/SubmitQuestionPage';
import agent from '../../agent';
import { HOME, COMMON } from '../../actions';

const mapDispatchToProps = dispatch => ({
    onSubmitQuestion: values => dispatch({
        type: HOME.SUBMIT_QUESTION,
        payload: agent.KeyStack.submitQuestion(values)
      }),
    onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
    onUnload: () => dispatch({ type: HOME.PAGE_UNLOAD })
  });

function mapStateToProps(state) {
  return {
    ...state.home,
    currentUser: state.common && state.common.currentUser,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitQuestionPage);
