import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline'
import { COMMON } from '../../actions';
import agent from '../../agent';
import { URL_PATH } from '../../constants';
import { Respect } from '../../pages/components';
import ModelButton from '../model/ModelButton'
import Discussions from '../../pages/sites/answer/Discussions';


function KeystackCard({
  item,
  question,
  toggleModelDialog,
  isAnswer = false,
  filters = {},
  respectKeystack,
  actionPrefix,
  currentUser,
  toggleEditDialog,
  onDelete
}) {

  const [inViewComments, setInViewComments] = useState(false);
  const [inViewCreateDiscussion, setInViewCreateDiscussion] = useState(false);


  const toggleComments = () => {
    setInViewComments(prev => !prev)
  }

  const hideCreateDiscussion = () => {
    setInViewCreateDiscussion(false)
  }
  return (
    <li key={item?.id} className="bg-white px-4 py-6 sm:rounded-xl sm:p-6 shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] m-2">
      <article aria-labelledby={`question-title-${item?.id}`}>
        <div>
          <div className="flex justify-between space-x-2">
            <div className="flex space-x-3">
              <div className="flex-shrink-0">
                <img className="h-10 w-10 rounded-full object-cover" src={item?.user?.profile_image} alt="" />
              </div>
              <div className="min-w-0 flex-1">
                <p className="text-sm font-medium text-neutral-900">{`${item?.user?.first_name} ${item?.user?.last_name}`}</p>
                <p className="text-sm text-neutral-500 font-light break-words">{item?.user?.biography}</p>
              </div>
            </div>
            {currentUser && currentUser?.id === item?.user?.id && (
              <div className="flex flex-shrink-0 self-center">
                <div className="relative inline-block text-left">
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2">
                        <span className="sr-only">Open options</span>
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                        </svg>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-xl bg-white py-1 shadow-lg ring-1 ring-neutral-400 ring-opacity-5 focus:outline-none">
                        <Menu.Item as="div">
                          <button
                            type="button"
                            className="block w-full py-2 px-4 text-sm text-left text-neutral-700 hover:no-underline hover:bg-neutral-100"
                            onClick={() => toggleEditDialog(item)}
                          >Edit</button>
                          <button
                            type="button"
                            className="block w-full py-2 px-4 text-sm text-left text-red-700 hover:no-underline hover:bg-neutral-100"
                            onClick={() => onDelete(item?.id)}
                          >Delete</button>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            )}
          </div>
          {!isAnswer && (
            <Link id={`question-title-${item?.id}`} className="text-base font-medium text-neutral-900 hover:no-underline hover:text-accent-hover" to={{ pathname: URL_PATH.ANSWER.pathParam(question.slug), state: { filters } }}>
              <p className="mt-3">{question.question}</p>
            </Link>
          )}
        </div>
        <div className="mt-1 flex flex-wrap items-center">
          {item?.models?.map((model) => (
            <ModelButton key={model?.id} model={model} toggleModelDialog={() => toggleModelDialog(model?.id)} />
          ))}
        </div>
        <div
          className="mt-2 space-y-4 text-base text-neutral-700 font-light"
        >
          <p className="whitespace-pre-line">{item?.comment}</p></div>
        <div className="mt-3 flex justify-between space-x-8 items-center">
          <div className="flex space-x-6">
            <span className="inline-flex items-center text-sm">
              <Respect id={item?.id} totalRespects={item?.total_respects} onRespect={respectKeystack} />
            </span>
            <span className="inline-flex items-center text-sm">
              <button type="button" onClick={toggleComments} className="inline-flex space-x-2 text-neutral-900 hover:bg-neutral-200">
                <ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5" aria-hidden="true" />
                <span className="font-medium text-neutral-900">{item?.number_discussion}</span>
                <span className="sr-only">replies</span>
              </button>
            </span>
          </div>
          {!isAnswer && (
            <Link className="text-neutral-900/60 font-normal text-sm underline" to={{ pathname: URL_PATH.ANSWER.pathParam(question?.slug), state: { filters } }}>
              View more answers
            </Link>
          )}
        </div>
        <div className={!inViewComments ? "hidden" : undefined}>
          <Discussions keystack={item} open={inViewComments} shareLink=""
            inViewCreateDiscussion={inViewCreateDiscussion} hideCreateDiscussion={hideCreateDiscussion}
            actionPrefix={actionPrefix} />
        </div>
      </article>
    </li>
  );
}

const mapDispatchToProps = (dispatch, props) => {
  const { actionPrefix } = props
  return {
    respectKeystack: (data, callback) => dispatch({
      type: actionPrefix.RESPECT,
      payload: agent.KeyStack.respectKeyStack(data.id),
      callback,
    }),
    onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
  };
};

function mapStateToProps(state) {
  return {
    currentUser: state.common && state.common.currentUser,
    isAuthenticated: state.common?.isAuthenticated,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeystackCard);
