import React, { useEffect, useState } from 'react';
import { PlusIcon, StarIcon } from '@heroicons/react/20/solid'
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import { ENV, URL_PATH } from '../../constants'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ModelDetailPage = ({
  onGetModelById,
  getSimilarModel,
  onFavoriteModel,
  onUnfavoriteModel,
  isAuthenticated,
  modelById = {},
  idModel,
  relatedModels,
  refreshCollection,
  inProgressFavorite,
  favoriteLimited,
  onAnnounce,
  onRedirectTo,
  onWatchVideos,
  openIframe
}) => {
  const [tabs, setTabs] = useState(['Snapshot']);
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [isOnFavorite, setIsOnFavorite] = useState(false);
  const [icon,setIcon] = useState(false)

  const getModelById = id => {
    onGetModelById(id, true);
    getSimilarModel(id);
  };

  useEffect(() => {
    getModelById(idModel);
    setCurrentTab(tabs[0]);
  }, [idModel]);

  useEffect(() => {
    if (modelById?.quotes?.length) {
      setTabs(['Snapshot', 'Quotes']);
    } else {
      setTabs(['Snapshot']);
    }
  }, [modelById]);

  useEffect(() => {
    if (favoriteLimited && isOnFavorite) {
      onAnnounce({
        message: "Free users can save up to 5 models.",
        label: "Upgrade",
        onOk: () => { setIsOnFavorite(false); onRedirectTo(URL_PATH.PAYMENT.PATH) },
      })
    }
  }, [favoriteLimited]);

  useEffect(() => {
    if (!inProgressFavorite) {
      refreshCollection()
    }
  }, [inProgressFavorite]);

  const onClickFavorite = () => {
    if (modelById.is_favorited) {
      onUnfavoriteModel(modelById.id);
    } else {
      setIsOnFavorite(true);
      onFavoriteModel(modelById.id);
    }
  };
  return (
    <article className="min-w-full">
      <Helmet>
        <meta property="og:image" content={`${modelById?.cover}`} />
        <meta property="og:image:width" content="1200" />
         <meta property="og:image:height" content="675" />
        <meta property="twitter:image" content={`${modelById?.cover}`} />
        <meta property="twitter:image:width" content="1200" />
         <meta property="twitter:image:height" content="675" />
      </Helmet>
      <div>
        {!openIframe && (
        <div>
          <img className="aspect-[716/409] w-full object-cover" src={modelById.cover} alt="" />
        </div>
        )}
        {openIframe && (
        <div className='fixed top-0 left-0 w-full h-full justify-center items-center flex'>
          <div className='absolute w-full h-full bg-slate-600 opacity-25' onClick={()=> onWatchVideos(false)}/>
          <div className='z-50 bg-black relative sm:w-[calc(70%)] w-[calc(90%)]  sm:h-[calc(80%)] h-[calc(50%)] rounded-3xl'>
          <iframe title='videos' src="https://assets.mentalmodelhub.com/model-books/the-dilema-of-choice/story.html" width="100%" height="100%" className='rounded-3xl' />
          </div>
        </div>
        )}
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-end space-x-5 mb-2">
            <div className="sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-between sm:space-x-6 sm:pb-1">
              <div className='bg-slate-200  rounded-full  mt-[-30px] w-[65px] h-[65px] flex items-center justify-center' onMouseEnter={()=>setIcon(true)} onMouseLeave={()=> setIcon(false)}>
                <button type='button'  onClick={()=> onWatchVideos(true)}>
                  {!icon ? (
                    <svg width="32" height="32" viewBox="0 0 50 48" fill="#9CA3AF" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M15.9996 0C14.7862 0 13.6923 0.730927 13.2279 1.85195C12.7636 2.97297 13.0202 4.26333 13.8782 5.12132L15.9996 7.24264V18.5147C15.9996 19.3104 15.6835 20.0734 15.1209 20.636L3.12088 32.636C-2.5488 38.3057 1.46669 48 9.48484 48H40.5143C48.5324 48 52.5479 38.3057 46.8782 32.636L34.8782 20.636C34.3156 20.0734 33.9996 19.3104 33.9996 18.5147V7.24264L36.1209 5.12132C36.9789 4.26333 37.2355 2.97297 36.7712 1.85195C36.3069 0.730927 35.2129 0 33.9996 0H15.9996ZM21.9996 18.5147V6H27.9996V18.5147C27.9996 20.9017 28.9478 23.1909 30.6356 24.8787L33.7183 27.9613C31.5677 27.4611 29.313 27.5623 27.2047 28.2651L25.7941 28.7353C23.3309 29.5563 20.6679 29.5563 18.2047 28.7353L16.5173 28.1728C16.4031 28.1347 16.2882 28.1002 16.1729 28.0693L19.3635 24.8787C21.0513 23.1908 21.9996 20.9017 21.9996 18.5147Z" fill="#9CA3AF"/>
</svg>

                  )
                :(
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#9CA3AF" d="M9 7v8l7-4zm12-4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 14H3V5h18z"/></svg>
                )}
                  
                </button>
              </div>
              <div className="justify-stretch mt-4 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                 <button
                    type="button"
                    className="inline-flex items-center rounded-full px-3 py-0.5 mr-2 text-xs font-medium cursor-pointer bg-neutral-900 text-neutral-200"
                    onClick={()=> onWatchVideos(true)}
                  >Watch E-Learning Module</button>
                {isAuthenticated && (
                  <button
                    type="button"
                    className="inline-flex items-center rounded-full px-3 py-0.5 mr-2 text-xs font-medium cursor-pointer bg-neutral-900 text-neutral-200"
                    onClick={onClickFavorite}
                  >
                    {modelById.is_favorited ? (
                      <>
                        <StarIcon className="-ml-1 mr-0.5 h-5 w-5 text-neutral-200" aria-hidden="true" />
                        Remove from Collection
                      </>
                    ) : (
                      <>
                        <PlusIcon className="-ml-1 mr-0.5 h-5 w-5 text-neutral-200" aria-hidden="true" />
                        Add to Collection
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="min-w-0 flex-1">
            <p className="mb-1 font-light text-accent-default uppercase">{modelById?.category?.name}</p>
            <h1 className="text-3xl font-bold text-neutral-900">{modelById.name}</h1>
          </div>
        </div>
      </div>


      <div className="mt-6 sm:mt-2 2xl:mt-5">
        <div className="border-b border-neutral-200">
          <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  type="button"
                  className={classNames(
                    currentTab === tab
                      ? 'border-accent-default text-neutral-900'
                      : 'border-transparent text-neutral-500 hover:text-neutral-700 hover:border-neutral-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  onClick={() => setCurrentTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {currentTab === tabs[0] ? (
        <>
          <div className="mx-auto my-6 max-w-5xl px-4 sm:px-6 lg:px-8 prose prose-sm prose-indigo">
            {modelById.content && parse(modelById.content)}
          </div>
          {relatedModels && !!relatedModels.length && (
            <div className="mx-auto max-w-5xl px-4 pb-12 sm:px-6 lg:px-8">
              <h2 className="text-sm font-medium text-neutral-500">Related Models</h2>
              <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                {relatedModels.map((relatedModel) => (
                  <div
                    key={relatedModel.id}
                    className="relative flex items-center space-x-3 rounded-xl border border-neutral-300 bg-white px-6 py-4 focus-within:ring-2 focus-within:ring-neutral-400 focus-within:ring-offset-2 hover:border-neutral-400"
                  >
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-medium text-neutral-900">{relatedModel.name}</p>
                      <p className="text-sm text-neutral-500 font-light">{relatedModel.summary}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="mx-auto my-6 max-w-5xl px-4 sm:px-6 lg:px-8">
          {modelById?.quotes?.map(quote => (
            <div
              key={quote.id}
              className="relative flex items-center space-x-3 rounded-xl border border-neutral-300 bg-white mb-3 px-6 py-4 focus-within:ring-2 focus-within:ring-neutral-400 focus-within:ring-offset-2 hover:border-neutral-400"
            >
              <div className="min-w-0 flex-1">
                <p className="text-sm font-medium text-neutral-900">{quote?.guest?.name || quote.name}</p>
                <p className="mt-2 prose prose-sm prose-indigo max-w-full">
                  {quote.content && parse(quote.content)}
                </p>
              </div>
            </div>
          ))}
          {!modelById?.quotes?.length && (<p className="text-sm font-medium text-center text-neutral-500">No items to display</p>)}
        </div>
      )}

    </article>
  );
}

export default ModelDetailPage;
