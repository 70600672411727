import React from 'react';

const mainImg = '/assets/img/404@4x.png';

function NotFoundPage() {
  return (
    <div className="content-center p-content not-found-page row justify-content-center">
      <div className="col-12 px-0">
        <h1>Oops. Page not found.</h1>
      </div>
      <div className="col-12 px-0 text-center">
        <img src={mainImg} alt="Not found" className="main" />
      </div>
    </div>
  );
}

export default NotFoundPage;
