import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#FFFFFF!important',
    backgroundColor: 'var(--bg-green)',
    outline: 'none',
    '&:hover': {
      backgroundColor: 'var(--bg-green)'
    },
    '& span': {
      fontFamily: "'Nunito', sans-serif",
      fontWeight: 800,
    }
  },
  progress: {
    position: 'absolute',
    right: 4,
    bottom: 4
  }
});

function ProgressButton(props) {
  const {
    classes,
    progress = true,
    label,
    progressColor = '#6D6778',
    brow,
    ...rest
  } = props;
  let progressIcon = '';
  const styleProgress = {
    color: progressColor
  };
  if (progress && rest.disabled) {
    progressIcon = (
      <CircularProgress
        size={28}
        thickness={5}
        style={styleProgress}
        className={classes.progress}
      />
    );
  }

  return (
    <Button
      classes={{
        root: classes.root
      }}
      variant="raised"
      color="primary"
      {...rest}
    >
      {label}
      {progressIcon}
    </Button>
  );
}

export default withStyles(styles)(ProgressButton);
