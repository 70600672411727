import React from 'react';
import renderHTML from 'react-render-html';

const data = {
  title: `Terms & Conditions`,
  detail: [`Last updated: January 1, 2024`],
  content: [
    {
      title: `ACCEPTANCE`,
      detail: [
        `We encourage you to read these Terms of Use (the “Terms”). This is a legally binding contract, even if you are just browsing through <a class='text-accent-default' href='https://mentalmodelhub.com' target='_blank'>mentalmodelhub.com</a> (the “Site”) or Mentalmodelhub mobile application (the “App”) without registering an account or contacting us.The Site and the App are collectively referred to herein as the “Service.” Our Privacy Policy [<a class='text-accent-default' href='https://mentalmodelhub.com/privacy' target='_blank'>https://mentalmodelhub.com/privacy</a>] also governs your use of the Service. If you do not agree to accept these Terms or the Privacy Policy, you must exit the Service. Note that we may revise the aforementioned documents whenever we feel it is appropriate, sometimes without prior notification. If you continue visiting our Service after we have published the revised versions, this constitutes your acceptance of the changes. If you do not agree with any changes, you must exit the Service.`
      ]
    },
    {
      title: `VENUE`,
      content: [
        `Our Serviceallows users to learn about mental models and strategies, and apply them on a regular basis. Our Service is a neutral venue that is not a party to any user interactions and decisions.`,
        `There are two levels of membership:Free and Premium. The Free one has limited functionality, whereas the Premium one gives users access to all functions of the Service.`
      ]
    },
    {
      title: `INTELLECTUAL PROPERTY`,
      content: [
        `<i>Our IP.</i> All Service contents, registered and unregistered trademarks, designs, information and images (the “Intellectual Property”) belong to us and our content suppliers. The Intellectual Property is protected by international trademark, copyright, privacy, and other intellectual property laws.  You obtain no interest in that Intellectual Property. All rights not expressly granted under these Terms are reserved by us.  Unless expressly stated otherwise, you shall not copy, reproduce or replicate any Intellectual Property.`,
        `<i>Copyright Infringement Claims.</i> Unauthorized copying, distribution, modification, public display, or public performance of copyrighted works is an infringement of the copyright holder’s rights. You agree that you will not use our Service to infringe anyone’s intellectual property rights. We will investigate copyright infringement claims if they are reported to us to <a class='text-accent-default' href="mailto:hello@mentalmodelhub.com">hello@mentalmodelhub.com</a>.`
      ]
    },
    {
      title: `YOUR OBLIGATIONS`,
      detail: [`By using this Service, you represent, warrant and agree that:`],
      content: [
        `You will only use the Service for legal purposes and you will remain responsible for complying with all laws and regulations applicable to your use of the Service.`,
        `You will not use our Service to stalk, harass or defame anybody.`,
        `Our Service may contain typographical errors or other inaccuracies.`,
        `You will not submit unsolicited bulk or commercial messages ("spam") to our Service, other users or anyone else. Any unsolicited message must also not direct the recipient to any third party site or other resource.`,
        `We have the right to refuse access, service or disable your account on our Service at any time for any reason or no reason without notice, explanation or liability of any kind.`,
        `You may not use any web spiders, bots, indexers, robots, crawlers, harvesters, or any other automatic process to access, acquire, copy or monitor any portion of the Service or any content, or in any way reproduce or circumvent the navigational structure or presentation of the Service or any content.`,
        `You will not interfere with the proper working of the Service. You will not impersonate any other person or entity, submit any false, defamatory, offensive, harassing material, or any material that violates another party's rights of privacy and publicity.`
      ]
    },
    {
      title: `REMEDIES FOR BREACH OF THESE TERMS`,
      content: [
        `We may take any action that we deem necessary or appropriate if we believe that any user violates the Terms of Use, infringes any intellectual property right, privacy right or confidentiality principles, threatens anybody’s personal safety or uses offensive language. We may: (i) disclose user’s identity to law enforcement agencies, to any third party claiming violation of their intellectual property or privacy right, (ii) block user’s IP address, notify user’s Internet Service Provider, (iii) take any other action provided for in these Terms of Use or available under equity or law.`,
        `Since we cannot ensure that all material submitted to us is accurate and free of third party claims, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party.`
      ]
    },
    {
      title: `DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY`,
      content: [
        `THIS SERVICE AND ALL OF ITS CONTENT ARE PROVIDED "AS IS". YOU MUST NOT RELY ON ANY INFORMATION PUBLISHED ON OR LINKED TO THIS SERVICE WITHOUT FIRST MAKING YOUR OWN ENQUIRIES TO VERIFY IT IS ACCURATE, CURRENT AND COMPLETE. WE DO NOT MAKE, AND EXPRESSLY DISCLAIM, ANY REPRESENTATIONS, WARRANTIES, COVENANTS AND CONDITIONS, EXPRESS OR IMPLIED, ARISING OUT OF OPERATION OF LAW, COURSE OF PERFORMANCE, COURSE OF DEALING, USAGE OF TRADE OR OTHERWISE, INCLUDING ANY EXPRESS OR IMPLIED WARRANTIES AND CONDITIONS OF: MERCHANTABILITY; FITNESS FOR A PARTICULAR PURPOSE; NON-INFRINGEMENT; SAFETY; QUALITY; FREEDOM FROM DEFECTS OR THAT DEFECTS WILL BE CORRECTED; UNINTERRUPTED, VIRUS-FREE OR ERROR-FREE USE OF THIS SERVICE; OR ACCURACY, CURRENCY, RELIABILITY, COMPLETENESS OR APPROPRIATENESS RELATING TO THE CONTENT OF THIS SERVICE.`,
        `WE SHALL NOT BE LIABLE TO YOU FOR INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS AND PROPERTY DAMAGE, EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, NOR SHALL WE BE HELD LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND OUR REASONABLE CONTROL. NO CLAIM, SUIT OR ACTION MAY BE BROUGHT AGAINST US AFTER SIX MONTHS FROM THE UNDERLYING CAUSE OF ACTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION, OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.`
      ]
    },
    {
      title: `INDEMNIFICATION`,
      detail: [
        `You agree to defend, indemnify and hold harmless our company, its officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the Service; (ii) your violation of any provision of these Terms; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that one of your user submissions caused damage to a third party.`
      ]
    },
    {
      title: `GOVERNING LAW & JURISDICTION`,
      detail: [
        `These Terms and any action related thereto will be governed by the laws ofCalifornia. Any controversy or claim arising out of or relating to these Terms, or the breach thereof, shall be settled by courts located inCalifornia.`
      ]
    },
    {
      title: `GENERAL`,
      content: [
        `<i>Electronic Communications.</i> You agree that all notices or other communications regarding your account and/or your use of the Service ("Communications"), may be provided to you electronically and you agree to receive all Communications from us in electronic form. You may print a copy of any Communications and retain it for your records. All electronic Communications will be considered just as legally binding as if they were in paper form. You may revoke your consent to receive Communications electronically, but if you revoke your consent to receive Communications electronically, we reserve the right to terminate your right to use our Service.`,
        `<i>Assignment.</i> We may transfer, assign or subcontract the rights, interests or obligations under these Terms, at our sole discretion, without obtaining your consent.`,
        `<i>Hyperlinks.</i> You may link to our Service, as long as this is done in a fair way that does not negatively affect our reputation or business and does not suggest any form of association where there is none. We reserve the right to request that you withdraw any link and you agree to cooperate with us in causing any unauthorized framing or linking to terminate immediately.`,
        `<i>Severability.</i> Should any part of these Terms of Use be rendered or declared invalid by an appropriate authority, such invalidation of such part or portion of these Terms of Use should not invalidate the remaining portions thereof, and they shall remain in full force and effect.`,
        `<i>No Waiver.</i> Enforcement of these Terms of Use is solely in our discretion, and failure to enforce the Terms of Use in some instances does not constitute a waiver of our right to enforce them in other instances.`,
        `<i>Prevailing Language.</i> In the event of any discrepancy between the English original version of these Terms and any foreign language translation, the English version prevails.`
      ]
    },
    {
      title: `CONTACT US`,
      detail: [
        `Please submit your inquiries to <a class='text-accent-default' href="mailto:hello@mentalmodelhub.com">hello@mentalmodelhub.com</a>. You can also contact us by mail at the following address:`,
        `Mentalmodelhub`,
        `Level 2, 89 Pirie St`,
        `Adelaide SA 5000 Australia`
      ]
    }
  ]
};

function TermPage() {
  return (
    <main className="lg:col-span-9 xl:col-span-10 bg-white px-4 py-6 sm:rounded-xl sm:p-6 shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] text-sm leading-relaxed">
      <div className="text-center">
        <h1 className="text-2xl text-neutral-900 mb-2">{data.title}</h1>
        {Array.isArray(data.detail) &&
          data.detail.map((detail) => (
            <p key={Math.random()} className="font-light text-base text-neutral-700">
              {renderHTML(detail)}
            </p>
          ))
        }
      </div>
      <ol>
        {Array.isArray(data.content) &&
          data.content.map((item, idx) => (
              <li key={Math.random()}>
                <p className="text-base text-neutral-900 mt-5">{`${idx + 1}. ${item.title}`}</p>
                {Array.isArray(item.detail) &&
                  item.detail.map((detail) => (
                      <p key={Math.random()} className="py-2 font-light text-neutral-700">
                        {renderHTML(detail)}
                      </p>
                    ))}
                <ul className="list-disc pl-7">
                  {Array.isArray(item.content) &&
                    item.content.map((content) => (
                        <li key={Math.random()}>
                          <p className="py-1 font-light text-neutral-700">{renderHTML(content)}</p>
                        </li>
                      ))}
                </ul>
              </li>
            ))}
      </ol>
    </main>
  );
}

export default TermPage;
