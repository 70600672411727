import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    typography: {
        useNextVariants: true,
        fontSize: 14
    },
    overrides: {
        MuiLinearProgress: {
            barColorPrimary: {
                backgroundColor: 'var(--bg-green)'
            }
        },
        MuiSnackbarContent: {
            root: {
                justifyContent: 'center'
            }
        },
        MuiTableCell: {
            root: {
                paddingRight: 8,
                paddingLeft: 8,
                maxWidth: 300
            }
        }
    }
});

export { theme };
