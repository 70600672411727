import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: 14,
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 800
  },
}));

function StackModel(props) {
  const { srcImg, title, summary, style = {}, onClick = () => null, ...rest } = props;
  const classes = useStyles();

  const content = (
    <div className="col-12 col-md-6 stack-model-container px-0" {...rest}>
      <div className="row mx-0 stack-model-row" style={style} onClick={onClick}>
        <div className="col-auto px-0 align-self-center">
          <img src={srcImg} alt={title} />
        </div>
        <div className="col px-0 align-self-center stack-model-title-container">
          <p className="stack-model-title">{title}</p>
        </div>
      </div>
    </div>
  );
  if (summary) {
    return (
      <Tooltip title={summary} placement="top" arrow classes={{ tooltip: classes.tooltip }}>
        {content}
      </Tooltip>
    );
  }
  return content;
}

export default StackModel;
