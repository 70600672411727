import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import queryString from 'querystring';
import EnvClient from '../env.json';
import { ENV, NOTIFICATOR_MESSAGES_NUMBER } from './constants';
import { getOauthToken } from './helpers/LocalStorage';

const envType = process.env.REACT_ENV || ENV.DEVELOPMENT;
const superagent = superagentPromise(_superagent, global.Promise);

const SERVICE_URL = `${EnvClient[envType].SERVICE_URL}/api/v1/`;

const tokenPlugin = (req) => {
  const oauthToken = getOauthToken();
  if (oauthToken) {
    req.set('Authorization', `Bearer ${oauthToken.token}`);
  }
};

const secretPlugin = (req) => {
  req.set('Secret', EnvClient[envType].SECRET_HEADER);
};

const requestBase = {
  post: (url, body) =>
    superagent.post(`${SERVICE_URL}${url}`, body).use(secretPlugin),
  put: (url, body) =>
    superagent.put(`${SERVICE_URL}${url}`, body).use(secretPlugin),
  get: (url, params = {}) =>
    superagent.get(`${SERVICE_URL}${url}`).query(params).use(secretPlugin),
  del: (url) => superagent.del(`${SERVICE_URL}${url}`).use(secretPlugin),
};

const serviceRequest = {
  post: (url, body) =>
    requestBase.post(url, body).use(tokenPlugin).use(secretPlugin),
  put: (url, body) =>
    requestBase.put(url, body).use(tokenPlugin).use(secretPlugin),
  get: (url, params) =>
    requestBase.get(url, params).use(tokenPlugin).use(secretPlugin),
  del: (url) => requestBase.del(url).use(tokenPlugin).use(secretPlugin),
};

const Auth = {
  login: (data) => serviceRequest.post(`signin`, data),
  logout: () => serviceRequest.get(`me/logout`),
  signUp: (data) => serviceRequest.post(`signup`, data),
  verifyAccount: (data) => serviceRequest.get(`me/verify`, data),
  activeEmail: (data) => serviceRequest.get(`me/active`, data),
  changePassword: (data) => serviceRequest.put(`me/update-password`, data),
  resetPassword: (data) => serviceRequest.put(`me/forgot-password`, data),
  updateProfile: (data) => serviceRequest.put(`me`, data),
  getCurrentUser: () => serviceRequest.get(`me`),
  getPhotos: () => requestBase.get(`icons`),
  getNotificationSettings: () => serviceRequest.get(`me/settings`),
  updateNotificationSettings: (data) => serviceRequest.put(`me/settings`, data),
  registerDeviceForMessaging: (messagingToken) =>
    serviceRequest.post(`devices`, {
      device_token: messagingToken,
      platform: 'WEB',
    }),
  unregisterDeviceFromMessaging: (messagingToken) =>
    serviceRequest.del(`devices`, {
      device_token: messagingToken,
    }),
  getNotifications: ({ page = 1 }) =>
    serviceRequest.get(`notifications?page=${page}`),
  getRecentNotifications: () =>
    serviceRequest.get(
      `notifications?page=1&limit=${NOTIFICATOR_MESSAGES_NUMBER}`
    ),
  deleteNotificationById: (id) => serviceRequest.del(`notifications/${id}`),
  getUnreadNotificationsCount: () => serviceRequest.get(`notifications/unread`),
  readNotificationsByIds: (ids) =>
    // update notifications selected by ids as read (true) and return total and totalUnread
    serviceRequest.put(`notifications/read-some`, ids),
  readAllNotifications: () =>
    // update all notifications read state to true
    serviceRequest.put(`notifications`),
};

const KeyStack = {
  registerFreePlan: (data = {}) =>
    serviceRequest.post(`joining`, queryString.stringify(data)),
  submitQuestion: (data = {}) =>
    serviceRequest.post(`proposed-questions`, queryString.stringify(data)),
  upgrade: (data) => serviceRequest.post(`login`, data),
  getPodcasts: ({
    sort = 'created_at',
    asc = true,
    keyWord = '',
    page = 1,
    limit,
  }) => {
    const path = `?sort=${sort},${
      asc ? 'ASC' : 'DESC'
    }&q=${keyWord}&page=${page}&limit=${limit}`;
    return serviceRequest.get(`podcasts${path}`);
  },
  getPodcastById: (id, ref = false) =>
    serviceRequest.get(`podcasts/${id}?ref=${ref}`),
  getQuestionGroups: () => serviceRequest.get('questions/categories'),
  getQuestionTrendings: () => serviceRequest.get('questions/trending'),
  getRelatedQuestions: (slug) =>
    serviceRequest.get(`questions/${slug}/related`),
  getQuestionByGroup: ({
    id,
    page = 1,
    keyWord = '',
    sort = 'published_date',
    asc = false,
  }) => {
    if (id) {
      return serviceRequest.get(
        `categories/${id}/questions?page=${page}&q=${keyWord}&sort=${sort},${
          asc ? 'ASC' : 'DESC'
        }`
      );
    }
    return serviceRequest.get(
      `questions?page=${page}&q=${keyWord}&sort=${sort},${asc ? 'ASC' : 'DESC'}`
    );
  },
  getQuestionById: (id) => serviceRequest.get(`questions/${id}`),
  getKeyStackQuestionById: ({
    id,
    sort = 'created_at',
    asc = false,
    page = 1,
  }) =>
    serviceRequest.get(
      `questions/${id}/keystacks?page=${page}&sort=${sort},${
        asc ? 'ASC' : 'DESC'
      }`
    ),
  getKeyStackByUserQuestion: ({ id, user = '' }) =>
    serviceRequest.get(`questions/${id}/keystacks/${user}`),
  getLeadingKeyStackByGroup: ({
    id,
    page = 1,
    keyWord = '',
    sort = 'created_at',
    asc = false,
  }) => {
    if (id) {
      return serviceRequest.get(
        `categories/${id}/leading-keystacks?page=${page}&q=${keyWord}&sort=${sort},${
          asc ? 'ASC' : 'DESC'
        }`
      );
    }
    return serviceRequest.get(
      `keystacks/leading?page=${page}&q=${keyWord}&sort=${sort},${
        asc ? 'ASC' : 'DESC'
      }`
    );
  },

  getModelCategories: () => serviceRequest.get(`models/categories`),
  getKeyStackById: (id) => serviceRequest.get(`keystacks/${id}`),
  deleteKeyStackById: (id) => serviceRequest.del(`keystacks/${id}`),
  respectKeyStack: (id) => serviceRequest.post(`keystacks/${id}/respects`),
  addKeyStack: (data) => serviceRequest.post(`keystacks`, data),
  updateKeyStack: ({ id, data }) => serviceRequest.put(`keystacks/${id}`, data),
  getDiscussion: ({ id, all = false }) =>
    serviceRequest.get(
      `keystacks/${id}/discussion?page=1${all ? `&limit=0` : ``}`
    ),
  createDiscussion: ({ id, data }) =>
    serviceRequest.post(`keystacks/${id}/discussion`, data),
  updateDiscussion: ({ id, data }) =>
    serviceRequest.put(`discussion/${id}`, data),
  deleteDiscussion: (id) => serviceRequest.del(`discussion/${id}`),
  respectDiscussion: (id) => serviceRequest.post(`discussion/${id}/respects`),
};

const Model = {
  getSubjectByCategoryId: ({ id, page = 1 }) =>
    serviceRequest.get(
      `categories/${id}/models?page=${page}&limit=20&sort=name,asc`
    ),
  getSubjectTrending: ({ page = 1, keyWord = '' }) =>
    serviceRequest.get(`models/trendings?page=${page}&q=${keyWord}&limit=20&sort=name,asc`),
  getSubjectFavorite: ({ page = 1, keyWord = '' }) =>
    serviceRequest.get(`me/favorites?page=${page}&q=${keyWord}&limit=20`),
  getSubjectAZ: ({ page = 1, keyWord = '' }) =>
    serviceRequest.get(
      `models?page=${page}&q=${keyWord}&limit=20&sort=name,asc`
    ),
  favouriteModel: (id) => serviceRequest.post(`models/${id}/favorites`),
  unfavoriteModel: (id) => serviceRequest.del(`models/${id}/favorites`),
  getSimilarModel: (id) => serviceRequest.get(`models/${id}/similars`),
  getModelById: (id, ref = false) =>
    serviceRequest.get(`models/${id}?ref=${ref}`),
};

const Pricing = {
  getProducts: () => requestBase.get(`products`),
  purchase: (data) => serviceRequest.post(`purchase`, data),
  renewalSubcription: () => serviceRequest.put(`subscriptions/renewal`),
  getCards: () => serviceRequest.get('payment/cards'),
  addCard: (data) => serviceRequest.post('payment/cards', data),
  deleteCard: (id) => serviceRequest.del(`payment/cards/${id}`),
  setDefaultCard: (id) => serviceRequest.put(`payment/cards/${id}/default`),
  getInvoices: () => serviceRequest.get('payment/invoices'),
};

const Comment = {
  getComment: (id) => serviceRequest.get(`discussion/${id}/comments`),
  createComment: ({ id, data }) =>
    serviceRequest.post(`discussion/${id}/comments`, data),
  updateComment: ({ id, data }) =>
    serviceRequest.put(`discussion-comments/${id}`, data),
  deleteComment: (id) => serviceRequest.del(`discussion-comments/${id}`),
  respectComment: (id) =>
    serviceRequest.post(`discussion-comments/${id}/respects`),
};

const AskAI = {
  getAIKeystack: ({
    page = 1,
    keyWord = '',
    sort = 'created_at',
    asc = false,
  }) =>
    serviceRequest.get(
      `ai-keystacks/leading?page=${page}&q=${keyWord}&sort=${sort},${
        asc ? 'ASC' : 'DESC'
      }`
    ),
  submitQuestion: (data = {}) =>
    serviceRequest.post(`ai-keystacks/question`, queryString.stringify(data)),
  
  getModelByAnswer:(answer={})=>
  serviceRequest.post(`ai-keystacks/detectmodel`,queryString.stringify(answer))
};

export default {
  KeyStack,
  Auth,
  Model,
  Pricing,
  Comment,
  AskAI,
};
