import React, { Fragment } from 'react'
import HeaderContainer from '../../containers/layouts/HeaderContainer';
import FooterContainer from '../../containers/layouts/FooterContainer';
import SidebarContainer from '../../containers/layouts/SidebarContainer';
import navigation from './navigation'

const MainLayout = ({ path, children }) => (
    <>
      <div className="min-h-full">
        <HeaderContainer navigation={navigation} />
        <div className="md:pt-10 pt-6 bg-keystack-bg min-h-screen">
          <div className="mx-auto max-w-3xl px-4 lg:grid lg:max-w-7xl lg:grid-cols-12">
            <SidebarContainer path={path} navigation={navigation} />
            {children}
          </div>
        </div>
        <FooterContainer />
      </div>
    </>
  )

export default MainLayout;
