import React from 'react';
import StayTopSession from '../components/StayTopSession';
import StartedSession from '../components/StartedSession';
import StayCustomerSupportSession from '../components/StayCustomerSupportSession';
import HeaderContainer from '../../containers/layouts/HeaderContainer';
import FooterContainer from '../../containers/layouts/FooterContainer';

function AboutPage() {
  return (
    <>
      <HeaderContainer navigation={[]} />
      <StayTopSession />
      <StartedSession />
      <StayCustomerSupportSession />
      <FooterContainer />
    </>
  );
}

export default AboutPage;