import React from 'react';

const TextField = ({ label, input, meta, isRoundedFull, ...rest }) => {
  const { type = "text", rows } = input
  const isError = meta && meta.touched && meta.invalid

  return (
    <div>
      {label && (
        <label className="block text-sm font-medium text-neutral-700">
          {label}
        </label>
      )}
      <div className="mt-1">
        {type === "textarea" ? (
          <textarea
            rows={rows}
            className={`block w-full border-neutral-300 focus:border-accent-default focus:ring-accent-default text-sm font-light ${isRoundedFull ? 'rounded-full' : 'rounded-xl'}`}
            {...input}
            {...rest}
          />
        ) : (
          <input
            type={type}
            className={`block w-full border-neutral-300 focus:border-accent-default focus:ring-accent-default text-sm font-light ${isRoundedFull ? 'rounded-full' : 'rounded-xl'}`}
            {...input}
            {...rest}
          />
        )}
        {isError && (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {meta.error}
          </span>
        )}

      </div>
    </div>
  )
}

export default TextField;
