import React from 'react';
import { InlineLogo } from '../logo';

function StackSubject({ group, title, description, logo = false }) {
  return (
    <div className="row mx-0 stack-subject">
      {group && (
        <div className="col-12 content stack-subject-group">
          <h2 className="text-center">{group}</h2>
        </div>
      )}
      <div className="col-12 p-content stack-subject-container">
        {logo && (
          <div className="stack-subject-logo">
            <InlineLogo />
          </div>
        )}
        <p className="stack-subject-title">{title}</p>
        <p className="stack-subject-description">{description}</p>
      </div>
    </div>
  );
}

export default StackSubject;
