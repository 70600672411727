import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import {
  ChatBubbleLeftEllipsisIcon,
  CodeBracketIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  FlagIcon,
  HandThumbUpIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  ShareIcon,
  StarIcon,
  PencilSquareIcon
} from '@heroicons/react/20/solid'
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline'

import { URL_PATH } from '../../constants';
import { COMMON } from '../../actions/common';

const respectImg = '/assets/img/answer/respect.png';

const useStyles = makeStyles((theme) => ({

  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

  },
  text: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: "#EF642A",
    textTransform: "none",
  },
  icon: {
    width: "24px",
    height: "18px",
  },
  total: {
    margin: "0 0 0 8px",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: "#513A36",
  }
}))






const Respect = ({
  id,
  totalRespects,
  onRespect,
  isAuthenticated,
  onRedirectTo
}) => {
  const classes = useStyles();

  const [inProgress, setInProgress] = useState(false)
  const handleRespect = stackId => {
    if (!isAuthenticated) {
      onRedirectTo(URL_PATH.LOGIN.PATH);
      return;
    }
    if (!inProgress) {
      setInProgress(true);
      onRespect({ id }, () => {
        setInProgress(false)
      });
    }
  };


  return (
    <button type="button" onClick={handleRespect} className="inline-flex space-x-2 text-neutral-500 hover:text-neutral-500 px-1 sm:px-3 py-1 rounded-full">
      <ArrowUpCircleIcon className="h-5 w-5 text-neutral-900 hover:text-black" aria-hidden="true" />
      <span className="font-medium text-neutral-900">{totalRespects}</span>
      <span className="sr-only">likes</span>
    </button>
  );
}

const mapDispatchToProps = dispatch => ({
    onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
  });

function mapStateToProps(state, props) {
  return {
    isAuthenticated: state.common && state.common.isAuthenticated,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Respect);
