import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function DetailIcon(props) {
  return (
    <SvgIcon {...props}>
      <g
        transform="matrix(0.80753092,0,0,0.80753092,3.1171598,3.1886155)"
        stroke="currentColor"
        fill="none"
      >
        <rect
          height="21.650793"
          width="20.952381"
          y="0.17460318"
          x="0.52380955"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <circle
          r="1.2222222"
          cy="16.761906"
          cx="5.5873017"
        />
        <circle
          r="1.2222222"
          cy="11.174603"
          cx="5.5873017" />
        <circle
          r="1.2222222"
          cy="5.5873017"
          cx="5.5873017" />
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          d="m 10.857391,16.761905 h 5.729911" />
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          d="m 10.857391,11.174603 h 5.729911" />
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          d="m 10.857391,5.5873016 h 5.729911" />
      </g>
    </SvgIcon>
  );
}
export default DetailIcon;
