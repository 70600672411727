export const ANSWER = {
  PAGE_LOAD: 'PAGE_LOAD_ANSWER',
  PAGE_UNLOAD: 'PAGE_UNLOAD_ANSWER',
  ASYNC_START: 'ASYNC_START',
  ASYNC_END: 'ASYNC_END',
  GET_QUESTION_BY_ID: 'GET_QUESTION_BY_ID_ANSWER',
  GET_RELATED_QUESTION: 'GET_RELATED_QUESTION',
  GET_KEY_STACK_QUESTION_BY_ID: 'GET_KEY_STACK_QUESTION_BY_ID_ANSWER',
  GET_MORE_KEY_STACK_QUESTION_BY_ID: 'GET_MORE_KEY_STACK_QUESTION_BY_ID_ANSWER',
  GET_MODEL_CATEGORIES: 'GET_MODEL_CATEGORIES_ANSWER',
  GET_MODEL_THEMES: 'GET_MODEL_THEMES_ANSWER',
  DELETE_KEY_STACK_BY_ID: 'DELETE_KEY_STACK_BY_ID_ANSWER',
  RESET: 'RESET_ANSWER',
  RESPECT: 'RESPECT_ANSWER',
  ADD_KEY_STACK: 'ADD_KEY_STACK_ANSWER',
  GET_KEY_STACK_BY_USER_QUESTION: 'GET_KEY_STACK_BY_USER_QUESTION',
  UPDATE_KEY_STACK: 'UPDATE_KEY_STACK_QUESTION',

  GET_DISCUSSION: 'GET_DISCUSSION_ANSWER',
  CREATE_DISCUSSION: 'CREATE_DISCUSSION_ANSWER',
  UPDATE_DISCUSSION: 'UPDATE_DISCUSSION_ANSWER',
  DELETE_DISCUSSION: 'DELETE_DISCUSSION_ANSWER',
  RESPECT_DISCUSSION: 'RESPECT_DISCUSSION_ANSWER',
  // RESET_DISCUSSION: 'RESET_DISCUSSION_ANSWER',

  // GET_COMMENT: 'GET_COMMENT_ANSWER',
  CREATE_COMMENT: 'CREATE_COMMENT_ANSWER',
  UPDATE_COMMENT: 'UPDATE_COMMENT_ANSWER',
  DELETE_COMMENT: 'DELETE_COMMENT_ANSWER',
  RESPECT_COMMENT: 'RESPECT_COMMENT_ANSWER',
  // RESET_COMMENT: 'RESET_COMMENT_ANSWER',
};
