export const MENTAL_MODEL = {
  PAGE_LOAD: 'PAGE_LOAD_MENTAL_MODEL',
  PAGE_UNLOAD: 'PAGE_UNLOAD_MENTAL_MODEL',
  ASYNC_START: 'ASYNC_START',
  ASYNC_END: 'ASYNC_END',
  GET_CATEGORIES: 'GET_CATEGORIES_MENTAL_MODEL',
  GET_SUBJECT_BY_CATEGORY_ID: 'GET_SUBJECT_BY_CATEGORY_ID_MENTAL_MODEL',
  GET_MORE_SUBJECT_BY_CATEGORY_ID:
    'GET_MORE_SUBJECT_BY_CATEGORY_ID_MENTAL_MODEL',
  GET_SUBJECT_TRENDING: 'GET_SUBJECT_TRENDING_MENTAL_MODEL',
  GET_MORE_SUBJECT_TRENDING: 'GET_MORE_SUBJECT_TRENDING_MENTAL_MODEL',
  GET_SUBJECT_FAVORITE: 'GET_SUBJECT_FAVORITE_MENTAL_MODEL',
  GET_MORE_SUBJECT_FAVORITE: 'GET_MORE_SUBJECT_FAVORITE_MENTAL_MODEL',
  GET_SUBJECT_AZ: 'GET_SUBJECT_AZ_MENTAL_MODEL',
  GET_MORE_SUBJECT_AZ: 'GET_MORE_SUBJECT_AZ_MENTAL_MODEL',
  WATCH_VIDEOS_BY_ID: 'WATCH_VIDEOS_BY_ID',
};
