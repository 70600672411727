import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  UserCircleIcon,
  KeyIcon,
  BellIcon,
  BanknotesIcon,
} from '@heroicons/react/24/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const navigation = [
  { name: 'Profile', href: '/my-profile', icon: UserCircleIcon },
  { name: 'Password', href: '/change-password', icon: KeyIcon },
  { name: 'Notifications', href: '/notifications', icon: BellIcon },
  { name: 'Plan & Billing', href: '/payment', icon: BanknotesIcon },
]

const Sidebar = ({ path, history }) => {
  const handleChangeTab = (e) => {
    history.push(e.target.value);
  };

  return (
    <div className="lg:col-span-3 lg:block xl:col-span-2">
      <nav aria-label="Sidebar" className="sticky top-4 divide-y divide-neutral-300">
        <div className="sm:hidden px-4 pb-4">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-accent-default focus:ring-accent-default bg-white"
            value={path}
            onChange={handleChangeTab}
          >
            {navigation.map((tab) => (
              <option key={tab.href} value={tab.href}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:grid grid-cols-4 lg:grid-cols-1 lg:space-y-1 pb-4">
          {navigation.map((item) => {
            const current = path === item.href;
            return (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  current ? 'bg-neutral-900 text-neutral-100' : 'text-neutral-900 hover:text-neutral-200 hover:bg-neutral-900',
                  'col-span-1 group flex items-center p-3 text-sm font-medium rounded-xl hover:no-underline'
                )}
                aria-current={current ? 'page' : undefined}
              >
                <item.icon
                  className={classNames(
                    current ? 'bg-neutral-900' : 'text-neutral-900 group-hover:text-neutral-200',
                    'col-span-1 flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
              </Link>
            )
          })}
        </div>
      </nav>
    </div>
  );
}

export default withRouter(Sidebar);
