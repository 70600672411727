import { ArrowRightIcon } from '@heroicons/react/20/solid';
import React from 'react';

export default function StartedSession() {
  return (
    <div className="bg-black">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-[40px] sm:leading-[48px]">
          Engage, Learn, and Grow
            <br />
            {/* Start using our app today. */}
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-white opacity-80">
          Our platform stands as a testament to the transformative power of collective wisdom. We're more than just a repository of information; we're a global community of learners, thinkers, and visionaries.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              className="rounded-xl bg-white px-3.5 py-2.5 text-sm font-normal text-neutral-900 shadow-sm hover:bg-slate-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Get started
            </a>
            {/* <a href="#" className="text-sm font-normal leading-6 text-white flex items-center space-x-2">
              <span>Learn more</span>
              <ArrowRightIcon className="w-6 h-6" />
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
}
