import { AUTH } from '../actions';

const initState = {
  openChangePasswordDialog: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case AUTH.LOGIN:
      return {
        ...state,
        inProgress: false,
        success: !action.error && action.payload && action.payload.message,
        type: action.type
      };
    case AUTH.CHANGE_PARAMS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      };
    case AUTH.SET_TYPE:
      return {
        ...state,
        type: action.value
      };
    case AUTH.SIGN_UP:
      return {
        ...state,
        inProgress: false,
        success: !!(!action.error && action.payload && action.payload.token),
        type: action.type
      };
    case AUTH.UPDATE_PROFILE:
      return {
        ...state,
        inProgress: false,
        success: !action.error && action.payload && action.payload.id,
        type: action.type
      };
    case AUTH.CHANGE_PASSWORD:
      return {
        ...state,
        type: action.type,
        inProgress: false,
        success: !!(!action.error && action.payload && action.payload.message),
      };
    case AUTH.VERIFY_ACCOUNT:
      return {
        ...state,
        type: action.type,
        inProgress: false,
        data: action.payload,
      };
    case AUTH.ACTIVE_EMAIL:
      return {
        ...state,
        type: action.type,
        inProgress: false,
        data: action.payload,
      };
    case AUTH.UPDATE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        inProgress: false,
        type: action.type,
      };
    case AUTH.PAGE_UNLOAD:
      return initState;
    case AUTH.ASYNC_START:
      switch (action.subtype) {
        case AUTH.LOGIN:
        case AUTH.SIGN_UP:
        case AUTH.UPDATE_PROFILE:
        case AUTH.CHANGE_PASSWORD:
        case AUTH.VERIFY_ACCOUNT:
        case AUTH.ACTIVE_EMAIL:
        case AUTH.UPDATE_NOTIFICATION_SETTINGS:
          return {
            ...state,
            inProgress: true,
            type: `${action.type  }_${  action.subtype}`
          };
      }
      break;
    default:
      return state;
  }
  return state;
};
