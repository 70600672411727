import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Switch from './Switch';

const styles = () => ({
  root: {
    color: '#EF642A',
    padding: 0,
    '&$checked': {
      color: '#EF642A'
    }
  },
  checked: {}
});

function ReduxSwitch(props) {
  const { classes, ...rest } = props;

  return (
    <Field
      component={Switch}
      {...rest}
    />
  );
}

ReduxSwitch.propTypes = {
  classes: PropTypes.object.isRequired
};

ReduxSwitch.defaultProps = {};

export default withStyles(styles)(ReduxSwitch);
