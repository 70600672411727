import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';

import { CloseDialogButton } from '../../components';
import { PlanPricingList } from '../components';
import { PRICING } from '../../actions';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    overflowY: 'visible',
    maxWidth: 566,
    margin: 20,
    width: '100%',
    marginTop: 50,
  }
}));

const UpgradeDialog = ({ open, onClose, fullScreen, onChangeparamsPricing }) => {
  const classes = useStyles();
  const handleClose = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onClose();
    onChangeparamsPricing({ openUpgradeDialog: false });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth="md"
      scroll="paper"
      fullScreen={fullScreen}
      className="upgrade-dialog"
    >
      {fullScreen || <CloseDialogButton onClick={handleClose} />}
      <DialogContent className="body-dialog">
        <h2>UPGRADE</h2>
        <p className="title">
          Upgrade your account to Premium to enjoy all the benefits Mentalmodelhub has to offer and supercharge your decision-making skills.
          </p>
          <p className="title">
          **For a limited time only our Premium Membership is available at a SPECIAL INTRODUCTORY PRICE. You may cancel your subscription at any time within 2 weeks for a full refund.**
          </p>
        <div className="content">
          <PlanPricingList labelBtn="UPGRADE" handleClose={handleClose} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapDispatchToProps = dispatch => ({
    onChangeparamsPricing: (data) => dispatch({ type: PRICING.CHANGE_PARAMS, data }),
  });

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeDialog);
