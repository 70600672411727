export const processCreateComment = (state, action) => {
  if (action.error || !action.payload.id) {
    return { ...state, success: false, type: action.type };
  }
  const stackId = action.param.keystackId;
  const keystacks = state.keystacks.map(item => {
    if (item.id !== stackId) {
      return item;
    }
    const discussions = item.discussion?.data.map(discussion => {
      if (discussion.id !== action.payload.discussion) {
        return discussion;
      }

      const number_comment = (discussion.number_comment || 0) + 1;

      discussion.comments = [
        action.payload,
        ...discussion.comments
      ]

      return { ...discussion, number_comment };
    });

    item.discussion = {
      ...item.discussion,
      data: discussions
    };
    return { ...item };
  });
  return { ...state, keystacks, type: action.type };
}

export const processUpdateComment = (state, action) => {
  if (action.error || !action.payload.id) {
    return { ...state, success: false, type: action.type };
  }
  const stackId = action.param.keystackId;
  const keystacks = state.keystacks.map(item => {
    if (item.id !== stackId) {
      return item;
    }
    const discussions = item.discussion?.data.map(discussion => {
      if (discussion.id !== action.payload.discussion) {
        return discussion;
      }

      const comments = discussion?.comments.map(comment => {
        if (comment.id !== action.payload.id) {
          return comment
        }

        return action.payload
      })

      discussion.comments = comments

      return { ...discussion };
    });

    item.discussion = {
      ...item.discussion,
      data: discussions
    };
    return { ...item };
  });
  return { ...state, keystacks, type: action.type };
}

export const processDeleteComment = (state, action) => {
  if (action.error) {
    return { ...state, success: false, type: action.type };
  }
  const { keystackId, discussionId, id: commentId } = action.param;

  const keystacks = state.keystacks.map(keystack => {
    if (keystack.id !== keystackId) {
      return keystack;
    }

    const discussions = keystack.discussion?.data.map(discussion => {
      if (discussion.id !== discussionId) {
        return discussion;
      }

      discussion.comments = discussion.comments.filter(
        comment => comment.id !== commentId
      );


      const number_comment = Math.max((discussion.number_comment || 0) - 1, 0);
      return { ...discussion, number_comment };
    });

    keystack.discussion = {
      ...keystack.discussion,
      data: discussions
    };
    return { ...keystack };
  });

  return { ...state, keystacks, success: true, type: action.type };

}

export const processRespectComment = (state, action) => {
  if (action.error || !action.payload?.id) {
    return { ...state, type: action.type };
  }
  const keystackId = action.param.keystackId;
  const keystacks = state.keystacks.map(item => {
    if (item.id !== keystackId) {
      return item;
    }
    const discussions = item.discussion?.data.map(discussion => {
      if (discussion.id !== action.payload.discussion) {
        return discussion;
      }
      discussion.comments = discussion.comments.map(comment => {
        if (comment.id !== action.payload.id) {
          return comment;
        }
        return {
          ...comment,
          total_respects: action.payload.total_respects,
          is_respect: action.payload.is_respect,
        }
      });

      return { ...discussion };
    });
    item.discussion = {
      ...item.discussion,
      data: discussions
    };
    return { ...item };
  });
  return {
    ...state,
    keystacks,
    type: action.type
  };
}
