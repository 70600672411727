import { COMMON, HOME, AUTH, QUESTION, ANSWER, SAMPLE, PRICING, KEYSTACK, NOTIFICATION } from '../actions';
import { URL_PATH, ROLE } from '../constants';

const premiumRoles = [ROLE.PREMIUM.value, ROLE.TRIAL_PREMIUM.value]

const defaultState = {
  viewChangeCounter: 0,
  urlAfterLogin: URL_PATH.HOME.PATH,
  messenger: {
    total: 0,
    totalUnread: 0,
    items: [],
    isLoaded: false,
  }
};

const messageDefault = 'Oops! Something went wrong, please try again.';
const checkError = (action, state) => {
  if (action.error || (action.payload && action.payload.message)) {
    return {
      ...state,
      message: (action.payload && action.payload.message) || messageDefault
    };
  }
  return state;
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case COMMON.APP_LOAD:
      if (action.payload && action.payload.id) {
        const user = action.payload;
        const isPremium = premiumRoles.includes(user?.role?.name);
        return {
          ...state,
          currentUser: user,
          isPremium,
          isAuthenticated: true,
          appLoaded: true,
          logout: false,
          inProgress: false
        };
      }
      return {
        ...state,
        appLoaded: true,
        isAuthenticated: false,
        isPremium: false,
        inProgress: false,
        currentUser: null
      };
    case COMMON.HOME_PAGE_LOAD:
      return { ...state };
    case COMMON.REDIRECT:
      return { ...state, redirectTo: null };
    case COMMON.REDIRECT_TO:
      return { ...state, redirectTo: action.to };
    case COMMON.CHANGE_URL_AFTER_LOGIN:
      return { ...state, urlAfterLogin: action.to };
    case COMMON.ANNOUNCE:
      return { ...state, announce: action.data };
    case COMMON.RESET_ANNOUNCE:
      return { ...state, announce: null };
    case COMMON.CLEAR_MESSAGE:
      return { ...state, message: '' };
    case COMMON.MESSAGE:
      return { ...state, message: action.message };
    case COMMON.GET_PHOTOS:
      if (!action.error && Array.isArray(action.payload)) {
        return {
          ...state,
          inProgressPhotos: false,
          photos: action.payload
        };
      }
      return {
        ...state,
        inProgressPhotos: false
      };
    case HOME.REGISTER_FREE_PLAN:
      if (action.error) {
        return checkError(action, state);
      }
      return state;
    case AUTH.LOGIN:
    case AUTH.SIGN_UP:
      if (action.payload && action.payload.token && action.payload.user) {
        const {user} = action.payload;
        const isPremium = premiumRoles.includes(user?.role?.name);
        return {
          ...state,
          currentUser: action.payload.user,
          isAuthenticated: true,
          logout: false,
          isPremium
        };
      }
      return {
        ...state,
        isAuthenticated: false,
        isPremium: false,
        message: (action.payload && action.payload.message) || messageDefault
      };
    case AUTH.LOGOUT:
      return {
        ...state,
        logout: true,
        isAuthenticated: false,
        isPremium: false,
        currentUser: null,
        messenger: {
          total: 0,
          totalUnread: 0,
          items: [],
          isLoaded: false,
        }
      };
    case COMMON.GET_PHOTOS:
      if (!action.error && action.payload && action.payload.data) {
        return {
          ...state,
          inProgressPhotos: false,
          photos: action.payload.data
        };
      }
      return {
        ...state,
        inProgressPhotos: false
      };
    case AUTH.GET_CURRENT:
      if (!action.error && action.payload && action.payload.id) {
        const currentUser = { ...state.currentUser, ...action.payload }
        return {
          ...state,
          currentUser,
          profileUser: action.payload
        };
      }
      return checkError(action, state);
    case AUTH.UPDATE_PROFILE:
      if (!action.error && action.payload && action.payload.id) {
        return {
          ...state,
          currentUser: { ...state.currentUser, ...action.payload },
          profileUser: { ...state.profileUser, ...action.payload },
          message: 'Profile updated successfully.'
        };
      }
      return checkError(action, state);
    case AUTH.GET_NOTIFICATION_SETTINGS:
      if (!action.error && action.payload && Array.isArray(action.payload)) {

        // convert array of objects [{key, value}] to object of key as property name and value as value
        const ns = action.payload.reduce((acc, n) => {
          acc[n.key] = n.value
          return acc
        }, {})

        const currentUser = { ...state.currentUser, notifications: ns }
        return {
          ...state,
          currentUser,
        };
      }
      return checkError(action, state);
    case AUTH.UPDATE_NOTIFICATION_SETTINGS:
      if (!action.error && action.payload && Array.isArray(action.payload)) {

        // convert array of objects [{key, value}] to object of key as property name and value as value
        const ns = action.payload.reduce((acc, n) => {
          acc[n.key] = n.value
          return acc
        }, {})

        const currentUser = { ...state.currentUser, notifications: ns }
        return {
          ...state,
          currentUser,
          message: 'Notification settings updated successfully.'
        };
      }
      return checkError(action, state);

    case AUTH.GET_MESSAGES:
      if (!action.error && action?.payload?.data && Array.isArray(action.payload.data)) {
        const { data, total } = action.payload
        return {
          ...state,
          messenger: {
            ...state.messenger,
            total,
            items: data,
            isLoaded: true,
          }
        };
      }

      return {
        ...state,
        messenger: {
          ...state.messenger,
          total: 0,
          items: [],
          isLoaded: false
        }
      };

    case AUTH.GET_UNREAD_MESSAGES_COUNT:
      if (!action.error && action?.payload?.total) {
        const { total } = action.payload
        return {
          ...state,
          messenger: {
            ...state.messenger,
            totalUnread: total
          }
        };
      }

      return {
        ...state,
        messenger: {
          ...state.messenger,
          totalUnread: 0
        }
      };
    case AUTH.READ_MESSAGES_BY_IDS:
    case AUTH.SYNC_READ_MESSAGES_BY_IDS:
      if (!action.error && action.payload) {
        const { total, totalUnread } = action.payload

        return {
          ...state,
          messenger: {
            ...state.messenger,
            total,
            totalUnread,
            items: state.messenger.items.map(m => ({ ...m, is_read: true })),
          }
        };
      }
      return checkError(action, state);
    case NOTIFICATION.READ_ALL_NOTIFICATIONS:
    case NOTIFICATION.SYNC_READ_ALL_NOTIFICATIONS:
      return {
        ...state,
        messenger: {
          ...state.messenger,
          totalUnread: 0,
          items: state.messenger.items.map(m => ({ ...m, is_read: true })),
        }
      };
    case AUTH.PUSH_MESSAGE:
      const { total, totalUnread, items } = state.messenger
      return {
        ...state,
        messenger: {
          ...state.messenger,
          total: total + 1,
          totalUnread: totalUnread + 1,
          items: [action.payload, ...items]
        }
      };
    case AUTH.DELETE_MESSAGE_BY_ID:
    case AUTH.SYNC_DELETE_MESSAGE_BY_ID:
    case NOTIFICATION.DELETE_NOTIFICATION_BY_ID:
    case NOTIFICATION.SYNC_DELETE_NOTIFICATION_BY_ID:
      if (!action.error) {
        const {id} = action.param
        const { total, totalUnread, items } = state.messenger
        const deletedItem = items.find(item => item.id === id)
        if (deletedItem) {
          const newItems = items.filter(item => item.id !== id)

          return {
            ...state,
            messenger: {
              ...state.messenger,
              total: total - 1,
              totalUnread: totalUnread - (deletedItem.is_read ? 0 : 1),
              items: newItems,

            }
          };
        }
      } else {
        return checkError(action, state);
      }

    case PRICING.PURCHASE:
      if (!action.error && action.payload && action.payload.id) {
        const user = action.payload;
        const isPremium = premiumRoles.includes(user?.role?.name);
        return {
          ...state,
          currentUser: { ...state.currentUser, ...action.payload },
          profileUser: { ...state.profileUser, ...action.payload },
          isPremium,
        };
      }
      return checkError(action, state);
    case PRICING.RENEWAL_SUBCRIPTION:
      if (!action.error && action.payload && action.payload.id) {
        const user = action.payload;
        const isPremium = premiumRoles.includes(user?.role?.name);
        return {
          ...state,
          currentUser: { ...state.currentUser, ...action.payload },
          profileUser: { ...state.profileUser, ...action.payload },
          isPremium,
        };
      }
      return checkError(action, state);
    case ANSWER.RESPECT:
    case ANSWER.RESPECT_DISCUSSION:
    case ANSWER.RESPECT_COMMENT:
    case KEYSTACK.RESPECT:
    case KEYSTACK.RESPECT_DISCUSSION:
    case KEYSTACK.RESPECT_COMMENT:
      if (!action.error && action.payload && action.payload.id) {
        return { ...state, message: 'Thanks for sharing the love.' };
      }
      return checkError(action, state);
    case ANSWER.DELETE_KEY_STACK_BY_ID:
      if (!action.error) {
        return { ...state, message: 'Answer deleted successfully' };
      }
      return checkError(action, state);
    case QUESTION.GET_QUESTION_GROUP:
    case ANSWER.GET_QUESTION_BY_ID:
    case ANSWER.ADD_KEY_STACK:
    case AUTH.SIGN_UP:
    case AUTH.CHANGE_PASSWORD:
    case SAMPLE.FAVORITE_MODEL:
      if (action.payload.message === 'FAVORITE_LIMITED') {
        return { ...state };
      }
      return checkError(action, state);
    case ANSWER.ASYNC_START:
      switch (action.subtype) {
        case COMMON.GET_PHOTOS:
          return {
            ...state,
            inProgressPhotos: true
          };
        default:
          return state;
      }
    default:
      return state;
  }
};
