import { connect } from 'react-redux';
import { PodcastPage } from '../../pages/index';
import agent from '../../agent';
import { PODCAST, COMMON } from '../../actions';

const mapDispatchToProps = dispatch => ({
    getPodcasts: data => dispatch({
        type: PODCAST.GET_PODCASTS,
        payload: agent.KeyStack.getPodcasts(data)
      }),
    getMorePodcasts: data => dispatch({
        type: PODCAST.GET_MORE_PODCASTS,
        payload: agent.KeyStack.getPodcasts(data)
      }),
    onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
    onSearch: value => dispatch({ type: PODCAST.SEARCH, value }),
    onUnload: () => dispatch({ type: PODCAST.PAGE_UNLOAD })
  });

function mapStateToProps(state) {
  return {
    ...state.podcast,
    isPremium: state.common && state.common.isPremium,
    isAuthenticated: state.common && state.common.isAuthenticated
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PodcastPage);
