import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  cssUnderline: {
    '&:before': {
      borderBottomColor: '#F6F5F5!important'
    },
    '&:after': {
      borderBottomColor: '#F6F5F5'
    }
  },
  root: {
    fontSize: 18,
    'font-family': "'Nunito', sans-serif",
    'font-weight': 800,
  },
  searchButton: {
    width: 24,
    height: 24,
    minHeight: 24,
    backgroundColor: 'white',
    color: '#979797'
  }
}));

const SearchTextField = ({ onClickSearch, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Input
      className={cx('search-text-field', classes.root, className)}
      fullWidth
      autoFocus
      endAdornment={
        <InputAdornment position="end">
          <Fab
            aria-label="Close"
            className={cx(classes.searchButton, 'search-button')}
            onClick={onClickSearch}
          >
            <CloseIcon />
          </Fab>
        </InputAdornment>
      }
      classes={{
        underline: classes.cssUnderline
      }}
      {...rest}
    />
  );
};

export default SearchTextField;
