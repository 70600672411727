import { slugify } from '../helpers';

const URL_PATH = {
  HOME: {
    TITLE: 'Home',
    PATH: '/',
  },
  PREMIUM_MEMBERSHIP: {
    TITLE: 'Premium Membership',
    PATH: '/premium-membership',
  },
  ABOUT: {
    TITLE: 'About',
    PATH: '/about',
  },
  COMING_SOON: {
    TITLE: 'Coming soon',
    PATH: '/comingsoon',
  },
  FAQ: {
    TITLE: 'FAQ',
    PATH: '/faq',
  },
  TERMS: {
    TITLE: 'Terms',
    PATH: '/terms',
  },
  PRICING: {
    TITLE: 'Pricing',
    PATH: '/pricing',
  },
  PRIVACY: {
    TITLE: 'Privacy',
    PATH: '/privacy',
  },
  CONTACT: {
    TITLE: 'Contact',
    PATH: '/contact',
  },
  NOT_FOUND: {
    TITLE: 'Page Not Found',
    PATH: '/404',
  },
  LOGOUT: {
    TITLE: 'Logout',
    PATH: '/logout',
  },
  LOGIN: {
    TITLE: 'Login',
    PATH: '/login',
  },
  SIGN_UP: {
    TITLE: 'Sign Up',
    PATH: '/sign-up',
  },
  VERIFY: {
    TITLE: 'Verify account',
    PATH: '/verify',
  },
  WORKSHOPS: {
    TITLE: 'Workshops',
    PATH: '/workshops',
  },
  BOOK: {
    TITLE: 'Book',
    PATH: '/book',
  },
  // KEYSTACKS: {
  //   TITLE: 'Keystacks',
  //   PATH: '/keystacks'
  // },
  ANSWER: {
    TITLE: 'View Answers',
    PATH: '/answers/:slug',
    pathParam: (slug) => `/answers/${slug}`,
  },
  KEYSTACK_DETAIL: {
    TITLE: 'View Answer',
    PATH: '/answers/:category/:name/:author?',
    pathParam: ({ category = '', name = '', author = '' }) =>
      `/answers/${category}/${name}/${author}`,
  },
  MODEL_DETAIL: {
    TITLE: 'Model Detail',
    PATH: '/mental-models',
    pathParam: (slug) => `/mental-models`,
  },
  MENTAL_MODEL: {
    TITLE: 'Mental models',
    PATH: '/mental-models/:slug',
    pathParam: (slug) => `/mental-models/${slug}`,
  },
  MY_COLLECTION: {
    TITLE: 'My Collection',
    PATH: '/my-collection',
  },
  ASK_AI: {
    TITLE: 'Ask AI',
    PATH: '/ask-ai',
  },
  MY_PROFILE: {
    TITLE: 'My Profile',
    PATH: '/my-profile',
  },
  CHANGE_PASSWORD: {
    TITLE: 'Change Password',
    PATH: '/change-password',
  },
  PAYMENT: {
    TITLE: 'Plan & Billing',
    PATH: '/payment',
  },
  THOUGHT_LEADERS: {
    TITLE: 'Thought Leaders',
    PATH: '/thought-leaders',
  },
  PODCAST_DETAIL: {
    TITLE: 'Podcast Detail',
    PATH: '/podcast-highlights/:title/:episode_no?/:guest?',
    pathParam: ({ title = '', episode_no = '', guest = '' }) => {
      const epi = slugify(episode_no) ? `${slugify(episode_no)}/` : '';
      const gu = slugify(guest) ? `${slugify(guest)}/` : '';
      return `/podcast-highlights/${title}/${epi}${gu}`;
    },
  },
  SUBMIT_QUESTION: {
    TITLE: 'Submit a question',
    PATH: '/submit-a-question',
  },
  NOTIFICATIONS: {
    TITLE: 'All Notifications',
    PATH: '/notifications',
  },
  JOIN_WITH_US: {
    TITLE: 'Join with us',
    PATH: '/join-with-us',
  },
};

export default URL_PATH;
