import { connect } from 'react-redux';
import KeyStackPage from '../../pages/sites/KeyStackPage';
import agent from '../../agent';
import { KEYSTACK, COMMON, ANSWER } from '../../actions';

const mapDispatchToProps = (dispatch) => ({
    getQuestionGroups: () => dispatch({
        type: KEYSTACK.GET_QUESTION_GROUP,
        payload: agent.KeyStack.getQuestionGroups(),
      }),
    getQuestionTrendings: () => dispatch({
        type: KEYSTACK.GET_QUESTION_TRENDING,
        payload: agent.KeyStack.getQuestionTrendings(),
      }),
    getLeadingKeyStackByGroup: (data) => dispatch({
        type: KEYSTACK.GET_KEYSTACK_IN_CATEGORY,
        payload: agent.KeyStack.getLeadingKeyStackByGroup(data),
      }),
    getMoreLeadingKeyStackByGroup: (data) => dispatch({
        type: KEYSTACK.GET_MORE_KEYSTACK_IN_CATEGORY,
        payload: agent.KeyStack.getLeadingKeyStackByGroup(data),
      }),
    onRedirectTo: (to) => dispatch({ type: COMMON.REDIRECT_TO, to }),
    onSwitchList: (value) => dispatch({ type: KEYSTACK.SWITCH_LIST, value }),
    onUnload: () => dispatch({ type: KEYSTACK.PAGE_UNLOAD }),
    onAnnounce: (data) => dispatch({ type: COMMON.ANNOUNCE, data }),
    onResetAnswer: () => dispatch({ type: ANSWER.RESET }),
    deleteKeyStackById: id => dispatch({
        type: ANSWER.DELETE_KEY_STACK_BY_ID,
        payload: agent.KeyStack.deleteKeyStackById(id),
        id
      }),
  });

function mapStateToProps(state) {
  return {
    ...state.keystack,
    answerType: state.answer.type,
    currentUser: state.common && state.common.currentUser,
    isPremium: state.common && state.common.isPremium
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyStackPage);
