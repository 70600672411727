import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

function SnackBarCenter(props) {
  const { message, ...rest } = props;
  return (
    <Snackbar
      open={!!message}
      autoHideDuration={3000}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
      message={
        <span id="message-id" className="text-center">
          {message}
        </span>
      }
      {...rest}
    />
  );
}

export default SnackBarCenter;
