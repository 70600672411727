import React, { useState, useEffect } from 'react';
import { AnnounceDialog } from '../dialog';
import { HOME, AUTH } from '../../actions';

const FooterPage = ({
  reset,
  isAuthenticated,
  type,
  onResetAnnounce,
  onAnnounce,
  announce,
  typeAuth,
  isPremium,
  openUpgradeDialog
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (type === HOME.REGISTER_FREE_PLAN) {
      reset();
      onAnnounce({
        message: (
          <p className="name">
          Thank you for subscribing!
          <br />
          We’re thrilled to have you on board and will be in touch shortly. Stay tuned!
        </p>
        
        ),
        label: 'OK'
      });
    }
  }, [type]);

  useEffect(() => {
    if (typeAuth === AUTH.SIGN_UP && isAuthenticated) {
      if (openUpgradeDialog && !isPremium) {
        return;
      }
      onAnnounce({
        message: (
          <p className="name">Welcome to Mentalmodelhub, where your journey to mastering mental models begins! We are thrilled to have you on board.</p>
        ),
        label: 'Enter'
      });
    }
  }, [typeAuth]);

  useEffect(() => {
    if (announce) {
      setOpenDialog(true);
    }
  }, [announce]);

  return (
    <AnnounceDialog
        open={!!(announce?.message && openDialog)}
        onClose={() => {
          onResetAnnounce();
          setOpenDialog(false);
          if (announce?.onClose) {
            announce?.onClose();
          }
        }}
        onOk={announce?.onOk}
        onCancel={announce?.onCancel}
        message={announce?.message}
        labelBtn={announce?.label}
        className={announce?.className}
      />
  );
}

export default FooterPage;

