import React from 'react';
import { PlanPricingList } from '../components';
import { isClientSide } from '../../helpers';

const imgMain = '/assets/img/premium/Group-4.png';
const imgBackground = '/assets/img/contact/img_phone@2x.png';
const imgOval = '/assets/img/contact/oval23.png';

const data = {
  title: `Premium Membership`,
  detail:
    'Why not take advantage of all the features available on a Premium Plan including:',
  content: [
    {
      title: 'Podcast Highlights.',
      detail:
        'We analyse popular podcasts and explore the mental models used by contemporary leaders and influencers. No time to listen to all the great content out there? No problem! We’ll evaluate them for you and bring the highlights to your inbox.'
    },
    {
      title: 'Premium Updates.',
      detail:
        'We regularly release Premium-only content available to subscribers. This is true levelling-up content that can transform the way you think.'
    },
    {
      title: 'Audio Mental Models.',
      detail:
        'Listen to mental models on-the-go with over 100 models available on audio on our platform.'
    }
  ],
  imgBackground: imgBackground,
  imgMain: imgMain,
  imgOval: imgOval,
  pricing: {
    title: 'Pricing',
    detail: '**For a limited time only our Premium Membership is available at a SPECIAL INTRODUCTORY PRICE. You may cancel your subscription at any time within 2 weeks for a full refund.**'
  }
};

function PremiumMembershipPage() {
  return (
    <div className="content-center page-content premium-membership-page">
      <img src={data.imgOval} alt="" className="oval" />
      <div className="row p-content mx-0 premium-membership-container">
        <div className="content col-sm-12 col-md px-0">
          <h1>{data.title}</h1>
          <ul className="detail">
            <li>
              <p className="feature">{data.detail}</p>
            </li>
            {Array.isArray(data.content) &&
              data.content.map((content, index) => {
                return (
                  <li key={index}>
                    <p>
                      <strong>{content.title}</strong> {content.detail}
                    </p>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="col-sm-12 col-md px-0">
          <img src={data.imgBackground} alt="" className="bg" />
          <img src={data.imgMain} alt="" className="main" />
        </div>
        <div className="col-12 px-0 pricing-upgrade-container">
          <h2>{data.pricing.title}</h2>
          <p className="offer">{data.pricing.detail}</p>
          {isClientSide && <PlanPricingList auto labelBtn="JOIN PREMIUM" />}
        </div>
      </div>
    </div>
  );
}

export default PremiumMembershipPage;
