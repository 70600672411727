import { connect } from 'react-redux';
import { AnswerPage } from '../../pages/index';
import agent from '../../agent';
import { ANSWER, COMMON } from '../../actions';

const mapDispatchToProps = dispatch => ({
    getQuestionById: data => dispatch({
        type: ANSWER.GET_QUESTION_BY_ID,
        payload: agent.KeyStack.getQuestionById(data)
      }),
    getKeyStackQuestionById: data => dispatch({
        type: ANSWER.GET_KEY_STACK_QUESTION_BY_ID,
        payload: agent.KeyStack.getKeyStackQuestionById(data)
      }),
    getMoreKeyStackQuestionById: data => dispatch({
        type: ANSWER.GET_MORE_KEY_STACK_QUESTION_BY_ID,
        payload: agent.KeyStack.getKeyStackQuestionById(data)
      }),
    deleteKeyStackById: id => dispatch({
        type: ANSWER.DELETE_KEY_STACK_BY_ID,
        payload: agent.KeyStack.deleteKeyStackById(id),
        id
      }),
    getRelatedQuestions: slug => dispatch({
        type: ANSWER.GET_RELATED_QUESTION,
        payload: agent.KeyStack.getRelatedQuestions(slug)
      }),
    resetPage: () =>
      dispatch({
        type: ANSWER.RESET
      }),
    onUnload: () =>
      dispatch({
        type: ANSWER.PAGE_UNLOAD
      }),
    onRedirectTo: to => dispatch({
        type: COMMON.REDIRECT_TO,
        to
      }),
    onAnnounce: (data) => dispatch({ type: COMMON.ANNOUNCE, data }),
  });

function mapStateToProps(state) {
  return {
    ...state.answer,
    currentUser: state.common && state.common.currentUser,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnswerPage);
