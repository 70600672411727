import React from 'react';
import { Field } from "react-final-form";

const FileField = ({ name, eleRef, onChange, ...props }) => (
  <Field name={name}>
    {({ input: { value, ...input } }) => (
      <input
        {...input}
        type="file"
        onChange={({ target }) => onChange(target.files)}
        ref={eleRef}
        {...props}
      />
    )}
  </Field>
);

export default FileField;
