import React from 'react';
import { StarIcon, QuestionMarkCircleIcon, BookOpenIcon, SparklesIcon, ArrowTrendingUpIcon, ChatBubbleLeftEllipsisIcon  } from '@heroicons/react/24/outline';

const features = [
  {
    name: 'Q&A Exploration',
    description:
      'Dive deep into a realm where questions meet profound answers. Here, your curiosity is matched with insights rooted in time-tested mental models. Got a question? Our platform or community might just have the perfect answer.',
    href: '/',
    icon:ChatBubbleLeftEllipsisIcon,
  },
  {
    name: 'Mental Model Library',
    description:
      'Navigate through our meticulously curated e-library, where hundreds of mental models await your discovery. These are more than just concepts; they are the building blocks of innovative thinking.',
    href: '/mental-models',
    icon: BookOpenIcon,
  },
  {
    name: 'My Collection',
    description:
      'Personalize your learning journey. Save and revisit your favorite models, creating a tailored path of cognitive exploration unique to you.',
    href: '/login',
    icon: StarIcon,
  },
  {
    name: 'Ask AI Feature',
    description:
      'Leverage the power of artificial intelligence to find answers to your burning questions. Ask AI any question and it will be answered through mental models, an innovative feature designed to enhance your thinking.',
    href: '/login',
    icon: SparklesIcon,
  },
  {
    name: 'Trending Questions',
    description:
      'Stay in the loop with what’s buzzing in the community! View trending questions and explore what’s currently topical, sparking conversations and insights.',
    href: '/',
    icon: ArrowTrendingUpIcon,
  },
  {
    name: 'Submit a Question',
    description:
      'Got a query? Submit your question and engage with the community. It’s a space for knowledge exchange, where your curiosity meets collective wisdom.',
    href: '/',
    icon: QuestionMarkCircleIcon,
  },
];


export default function StayTopSession() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-[40px]">
          MentalModelHub: Your Home for Cognitive Discovery
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          At MentalModelHub, we're not just another website; we're an e-learning revolution. We exist at the intersection of education and insight, fueled by a relentless passion to reshape the way you think, learn, and grow.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:max-w-none">
        <h3 className="text-xl font-semibold text-gray-900 mb-8">
            Experience Our Platform Pillars
          </h3>
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-lg bg-accent-default">
                    <feature.icon
                      className="h-8 w-8 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="text-lg">{feature.name}</div>
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-5">
                    <a
                      href={feature.href}
                      className="text-sm font-normal leading-6 text-accent-default"
                    >
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
