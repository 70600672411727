import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    right: 12,
    top: 11,
    width: 24,
    height: 24,
    minHeight: 24,
    zIndex: 1301,
    backgroundColor: 'white',
    color: '#979797'
  }
}));

function CloseDialogButton({ className, ...rest }) {
  const classes = useStyles();
  return (
    <Fab
      aria-label="Close"
      className={cx(classes.root, 'close-icon', className)}
      {...rest}
    >
      <CloseIcon />
    </Fab>
  );
}

export default CloseDialogButton;
