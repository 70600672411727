import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';

import { CloseDialogButton } from '../../components';
import { COMMON } from '../../actions';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    overflowY: 'visible',
    maxWidth: 760,
    margin: 20,
    width: '100%',
    marginTop: 50,
  }
}));

const AboutPodcastDialog = ({ open, onClose, fullScreen, onRedirectTo, onOk }) => {
  const classes = useStyles();
  const handleClose = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth="md"
      scroll="paper"
      fullScreen={fullScreen}
      className="about-podcast-dialog about-dialog"
    >
      {fullScreen || <CloseDialogButton onClick={handleClose} />}
      <DialogContent className="body-dialog">
        <h2>About Podcast Highlights</h2>
        <div className="content">
          <div className="note">
            <p>Here we analyse popular podcast interviews and explore the mental models discovered within each interview.</p>

            <p>Each podcast is selected based on the interview subject being a leader or influencer of note. Throughout the interview we identify the models being referred to - naturally there’s fascinating and useful crossover with related models.</p>

            <p>If you have any suggestions please feel free to reach out and we’ll happily look at your suggestion! </p>

            <p>Enjoy.</p>

            <p>PS. The timecode corresponds with the end time of each quote rather than the beginning.</p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onRedirectTo: (to) => {
      return dispatch({ type: COMMON.REDIRECT_TO, to });
    },
  };
};

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutPodcastDialog);
