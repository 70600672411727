import { connect } from 'react-redux';
import HeaderPage from '../../pages/layouts/HeaderPage';
import { AUTH, COMMON, MENTAL_MODEL, NOTIFICATION } from '../../actions';
import agent from '../../agent';

const mapDispatchToProps = dispatch => ({
  getSubjectAZ: ({ page, keyWord }) => dispatch({
        type: page > 1 ? MENTAL_MODEL.GET_MORE_SUBJECT_AZ : MENTAL_MODEL.GET_SUBJECT_AZ,
        payload: agent.Model.getSubjectAZ({ page, keyWord })
      }),
    onRedirectTo: to => dispatch({ type: COMMON.REDIRECT_TO, to }),
    onLogout: async () => {
      await dispatch({
        type: AUTH.LOGOUT,
        payload: agent.Auth.logout()
      })
    },
    getNotifications: data => dispatch({
      type: NOTIFICATION.GET_NOTIFICATIONS,
      payload: agent.Auth.getNotifications(data),
    }),
    readAllNotifications: () => dispatch({
      type: NOTIFICATION.READ_ALL_NOTIFICATIONS,
      payload: agent.Auth.readAllNotifications(),
    }),
  });

function mapStateToProps(state) {
  return {
    ...state.notification,
    ...state.mentalModel,
    currentUser: state.common && state.common.currentUser,
    isAuthenticated: state.common && state.common.isAuthenticated,
    isPremium: state.common && state.common.isPremium,
    photos: state.common && state.common.photos
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderPage);
