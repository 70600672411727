import React from 'react';
import renderHTML from 'react-render-html';

const data = {
  title: `Privacy Policy`,
  detail: [`Effective Date: January 1, 2024`],
  content: [
    {
      title: `Acceptance`,
      detail: [
        `We have created this Privacy Policy (the “Policy”) because we value users’ privacy. The Policy describes the types of information we may collect from you, or that you may provide, when you access mentalmodelhub.com (the “Site”) or Mentalmodelhub mobile application (the “App”) without registering an account or contacting us. The Site and the App are collectively referred to herein as the “Service.”  This Policy does not apply to information collected by any third party, including through any external website that may link to or be accessible from the Service. Please check directly with each such third party to avoid unfair surprises and misunderstandings.`,
        `<b>Your use of this Service constitutes your acceptance of this Privacy Policy</b>. If you do not agree with this Privacy Policy, you may not use our Service. This Policy may change from time to time, and the date of the last update is indicated at the top of this page. We will notify you about material changes in the way we treat personally identifiable information by email.  Your continued use of this Service after we revise this Policy is deemed to be acceptance of the revisions, so please check this page from time to time for updates.`,
        `Where we make minor changes to our Privacy Policy, we will update our Privacy Policy with a new effective date stated at the beginning of it. Our processing of your information will be governed by the practices set out in that new version of the Privacy Policy from its effective date onwards. Major changes to our Privacy Policy or the purposes for which we process your information Where we make major changes to our Privacy Policy or intend to use your information for a new purpose or a different purpose than the purposes for which we originally collected it, we will notify you by email (where possible) or by posting a notice on our website. We will provide you with the information about the change in question and the purpose and any other relevant information before we use your information for that new purpose.`
      ]
    },
    {
      title: `What information we collect`,
      detail: [
        `We may collect several types of information from and about users of our Service, including the following:`
      ],
      content: [
        `<i>Personal Info</i>. Name, email, credit card information.`,
        `<i>Analytics</i>. Statistical data that does not identify any individual user.  We use Google Analytics to help us understand how our customers use the Service - you can read more about how Google uses your personal information here: <a class='text-accent-default' href='https://www.google.com/intl/en/policies/privacy/' target='_blank'>https://www.google.com/intl/en/policies/privacy/</a>. You can also opt-out of Google Analytics here: <a class='text-accent-default' href='https://tools.google.com/dlpage/gaoptout' target='_blank'>https://tools.google.com/dlpage/gaoptout</a>.`,
        `<i>Voluntary Data</i>: Information you voluntarily provide when you contact us for any reason.`
      ],
      detailMore: [`We collect this information:`],
      contentMore: [
        `Directly from you when you provide it to us.`,
        `Automatically as you navigate through the Service.`
      ]
    },
    {
      title: `How we use your information`,
      detail: [
        `We use information that we collect about you or that you provide to us, including any personal information:`
      ],
      content: [
        `To provide the services you requested.`,
        `To email you our newsletter but you can unsubscribe.`,
        `To notify you about changes to our Service or any services we offer or provide though it.`,
        `To carry out our obligations and enforce our rights.`,
        `In any other way we may describe when you provide the information.`
      ]
    },
    {
      title: `Disclosure of your information`,
      detail: [
        `We do not sell any of your personal information to 3rd parties. We may disclose personal information that we collect or you provide as described in this Policy:`
      ],
      content: [
        `To fulfill the purpose for which you provide it.`,
        `To contractors, service providers and other third parties we use to support our business.`,
        `To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of the Service's assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information about our Service users is among the assets transferred.`,
        `We may, without restriction, disclose aggregated information about our users and information that does not identify any individual.`,
        `For any other purpose disclosed by us when you provide the information.`
      ],
      detailMore: [`We may also disclose your personal information:`],
      contentMore: [
        `To comply with any court order, law or legal process, including to respond to any government or regulatory request.`,
        `If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Service, our customers or others.`
      ]
    },
    {
      title: `Cookie Policy `,
      detail: [
        `Cookies are small text files which a website may put on your computer or mobile device when you first visit a site or page. The cookie will help our Service, or another website, to recognize your device the next time you visit. For example, cookies can help us to remember your username and preferences, analyze how well our website is performing, or even allow us to recommend content we believe will be most relevant to you.`,
        `We may use cookies for the following reasons and purposes:`
      ],
      content: [
        `<i>To provide the service you have asked for</i>. Some cookies are essential so you can navigate through the website and use its features. Without these cookies, we would not be able to provide the services you’ve requested. For example, some cookies allow us to identify subscribers and ensure they can access the subscription only pages. If a subscriber opts to disable these cookies, the user will not be able to access all of the content that a subscription entitles them to. These cookies don't gather information about you that could be used for marketing or remembering where you've been on the internet. Essential cookies keep you logged in during your visit.`,
        `<i>To improve your browsing experience</i>. These cookies allow the website to remember choices you make, such as your language or region and they provide improved features. These cookies will help remembering your preferences and settings, including marketing preferences, remembering if you've filled in certain forms, so you're not asked to do it again, remembering if you've been to the Service before and restricting the number of times you're shown a particular advertisement.`,
        `<i>Analytics</i>. To improve your experience on our Service, we like to keep track of what pages and links are popular and which ones don't get used so much to help us keep our Service relevant and up to date. It's also very useful to be able to identify trends of how people navigate (find their way through) our Service and if they get error messages from web pages. This group of cookies, often called “analytics cookies” are used to gather this information. These cookies don't collect information that identifies you. The information collected is anonymous and is grouped with the information from everyone else’s cookies. We can then see the overall patterns of usage rather than any one person’s activity.`
      ],
      detailMore: [
        `Most browsers allow you to turn off cookies. To do this, look at the “help” menu on your browser. Switching off cookies may restrict your use of the Service and/or delay or affect the way in which it operates.`
      ]
    },
    {
      title: `Data security`,
      detail: [
        `Personal information you provide to us is stored on a password protected server accessible only by administrator. We use SSL. However, we cannot guarantee the security of your personal information transmitted to our Service because any transmission of information over the Internet has its inherent risks. You are responsible for keeping your login credentials, if any, confidential.`
      ]
    },
    {
      title: `Children’s privacy`,
      detail: [
        `Our Service is not directed to children under the age of 16. We do not knowingly collect any personal information about children under the age of 16. If we obtain actual knowledge that we have collected personal information about a child under the age of 16, that information will be promptly deleted from our database, unless it is necessary to retain it for legal purposes.`,
        `If a parent believes that his or her child has submitted personal information to us, he or she can contact us via e-mail. We will promptly delete the information upon learning that it relates to a child under the age of 16. Please note that it is possible some of this information may remain archived in web logs and back-up archives after we delete the information from our active database.`
      ]
    },
    {
      title: `Your California privacy rights`,
      detail: [
        `We will not share any personal data with third-parties for their direct marketing purposes to the extent prohibited by California law. If our practices change, we will do so in accordance with applicable laws and will notify you in advance. California law requires that operators of online services disclose how they respond to a Do Not Track signal. Some browsers have incorporated “Do Not Track” features. Most of these features, when turned on, send a signal or preference to the online service that a user visits, indicating that the user does not wish to be tracked. We do not currently respond to Do Not Track signal.`
      ]
    },
    {
      title: `International transfer`,
      detail: [
        `Your information, including Personal Information, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.`,
        `If you are located outside the United States and choose to provide information to us, please note that we transfer the information, including personal information, to the United States and process it there, if there is a legal basis for us to do it.`
      ]
    },
    {
      title: `EU users’ rights`,
      detail: [
        `This section of our Privacy Policy applies to the users of our platform in the EU. We would like to inform you about your EU General Data Protection Regulation (GDPR) rights and how we safeguard them.`
      ],
      content: [
        `<i>Your GDPR rights to be informed, to access, rectify, erase or restrict the processing of your personal information.</i> You have the right to receive free information about what personal data we have obtained about you, where it is stored, for how long, for what purposes it is used, to whom it was disclosed. You have the right to require that we, without undue delay, rectify of inaccurate personal data concerning you. That means you can request we change your personal data in our records, or have your incomplete personal data completed. You have the “right to be forgotten,” i.e. to have us delete your personal information, without undue delay, if the data is no longer necessary in relation to the purposes for which it was collected. However, GDPR gives us the right to refuse erasure if we can demonstrate compelling legitimate grounds for keeping your information.`,
        `<i>GDPR gives you the right to restrict processing</i> if any of the following applies:<br/>
                i. If you contest the accuracy of your personal data, we will restrict processing it for a period enabling us to verify its accuracy.<br/>
                ii. The processing is unlawful and you oppose its erasure and request instead the restriction of its use.<br />
                iii. We no longer need your personal data for the purposes of the processing, but you require us to restrict processing for the establishment, exercise or defence of legal claims.<br/>
                iv. You have objected to processing pursuant to Article 21(1) of the GDPR pending the verification whether our legitimate grounds override yours.`,
        `<i>Right to data portability</i>. Upon request, we will provide you your personal data in our possession, in a structured, commonly used and machine-readable format. You have the right to transmit that data to another controller if doing so does not adversely affect the rights and freedoms of others.`,
        `<i>Right to object</i>. You can object, on grounds relating your particular situation, at any time, to processing of your personal information, if based on point (e) or (f) of Article 6(1) of the GDPR.  We will then have to stop processing, unless we can demonstrate compelling legitimate grounds for the processing. If you object to the processing for direct marketing purposes, we will have to stop processing for these purposes.`,
        `<i>Right to withdraw consent</i>. GDPR grants you the right to withdraw your earlier given consent, if any, to processing of your personal data at any time.`,
        `<i>Rights related to automated decision making</i>. As a responsible business, we do not rely on any automated decision making, such as profiling.`
      ]
    },
    {
      title: `Data retention`,
      detail: [
        `We retain your personal data for as long as your account is active or for as long as we need it to fulfill the purposes for which we have initially collected it, unless otherwise permitted or required by law. We will retain and use information as necessary to comply with our legal obligations, resolve disputes, and enforce our rights. We will cease to retain your personal data, or remove the means by which the data can be associated with you, as soon as it is reasonable to assume that such retention no longer serves the purpose for which the personal data was collected, and is no longer necessary for legal or business purposes.`
      ]
    },
    {
      title: `Accessing and correcting your personal information`,
      detail: [
        `Please send us an e-mail to <a class='text-accent-default' href="mailto:hello@mentalmodelhub.com">hello@mentalmodelhub.com</a> to request access to, correct or delete any personal information that you have provided to us or to ask questions about this Privacy Policy. We reserve the right to refuse a request if we believe it would violate any law or cause the information to be incorrect. You can edit or delete your own information in your account settings. You can also contact us by mail at the following address:`,
        `Mentalmodelhub`,
        `Level 2, 89 Pirie St`,
        `Adelaide SA 5000 Australia`
      ]
    }
  ]
};

function PrivacyPage() {
  return (
    <main className="lg:col-span-9 xl:col-span-10 bg-white px-4 py-6 sm:rounded-xl sm:p-6 shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] text-sm leading-relaxed">
      <div className="text-center">
        <h1 className="text-2xl text-neutral-900 mb-2">{data.title}</h1>
        {Array.isArray(data.detail) &&
          data.detail.map((detail) => (
              <p key={Math.random()} className="font-light text-base text-neutral-700">
                {renderHTML(detail)}
              </p>
            ))}
      </div>
      <ol>
        {Array.isArray(data.content) &&
          data.content.map((item, idx) => (
              <li key={Math.random()}>
                <p className="text-base text-neutral-900 mt-5">{`${idx + 1}. ${renderHTML(item.title)}`}</p>
                {Array.isArray(item.detail) &&
                  item.detail.map((detail) => (
                      <p key={Math.random()} className="py-2 font-light text-neutral-700">
                        {renderHTML(detail)}
                      </p>
                    ))}
                <ul className="list-disc pl-7">
                  {Array.isArray(item.content) &&
                    item.content.map((content) => (
                        <li key={Math.random()}>
                          <p className="py-1 font-light text-neutral-700">{renderHTML(content)}</p>
                        </li>
                      ))}
                </ul>
                {Array.isArray(item.detailMore) &&
                  item.detailMore.map((detail) => (
                      <p key={Math.random()} className="py-2 font-light text-neutral-700">
                        {renderHTML(detail)}
                      </p>
                    ))}
                <ul className="list-disc pl-7">
                  {Array.isArray(item.contentMore) &&
                    item.contentMore.map((content) => (
                        <li key={Math.random()}>
                          <p className="py-1 font-light text-neutral-700">
                            {renderHTML(content)}
                          </p>
                        </li>
                      ))}
                </ul>
              </li>
            ))}
      </ol>
    </main>
  );
}

export default PrivacyPage;
