import { connect } from 'react-redux';
import { PodcastDetailPage } from '../../pages/index';
import agent from '../../agent';
import { PODCAST, COMMON } from '../../actions';

const mapDispatchToProps = dispatch => {
  return {
    getPodcastById: (id, ref) => {
      return dispatch({
        type: PODCAST.GET_BY_ID,
        payload: agent.KeyStack.getPodcastById(id, ref)
      });
    },
    resetPage: () =>
      dispatch({
        type: PODCAST.RESET
      }),
    onUnload: () =>
      dispatch({
        type: PODCAST.PAGE_UNLOAD
      }),
    onRedirectTo: to => {
      return dispatch({
        type: COMMON.REDIRECT_TO,
        to
      });
    }
  };
};

function mapStateToProps(state) {
  return {
    ...state.podcast,
    isPremium: state.common && state.common.isPremium,
    isAuthenticated: state.common && state.common.isAuthenticated
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PodcastDetailPage);
