'use strict';

import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const styles = () => ({
  root: {
    color: '#513A36!important',
    padding: 0,
    '&$checked': {
      color: '#513A36'
    }
  },
  checked: {},
  checkIcon: {
    backgroundColor: '#FFFFFF',
    width: 22,
    height: 22,
    borderRadius: 6
  },
  checkNumber: {
    width: 22,
    height: 22,
    backgroundColor: '#000000',
    border: '2px solid #FFFFFE',
    borderRadius: 6,
  },
});

function MaterialCheckbox(props) {
  const { classes, number, ...rest } = props;

  return (
    <Checkbox
      icon={<div className={classes.checkIcon} />}
      checkedIcon={<div className={classes.checkNumber} />}
      classes={{
        root: classes.root,
        checked: classes.checked
      }}
      {...rest}
    />
  );
}

MaterialCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  number: PropTypes.any
};

MaterialCheckbox.defaultProps = {
  number: ''
};

export default withStyles(styles)(MaterialCheckbox);
