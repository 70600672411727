import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { URL_PATH } from '../../constants'
import { getSort } from '../../helpers'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Sidebar = ({
  navigation,
  categories = [],
  getQuestionGroups,
  getLeadingKeyStackByGroup,
  path,
  tabActive,
  onRedirectTo,
  isAuthenticated,
  models,
  initCategory,
}) => {
  const location = useLocation();
  const [categoryActive, setCategoryActive] = useState(null);
  
  useEffect(() => {
    getQuestionGroups();
   
  }, []);
  useEffect(() => {
    const parsed = location && location.search && queryString.parse(location.search);

    if (categories.length && parsed && parsed.category) {
      const activeCategoryIndex = categories.findIndex(cat => cat.slug === parsed.category);

      if (activeCategoryIndex !== -1) {
        const activeCategory = categories[activeCategoryIndex]
        setCategoryActive(activeCategory.id)

      }
    }
  }, [categories]);
  const handleCategoryChange = (id, slug) => {
    if (id !== categoryActive) {
      setCategoryActive(id)

      const sort = getSort(tabActive);
      getLeadingKeyStackByGroup({ id, page: 1, keyWord: '', ...sort });

      let redirectUrl = URL_PATH.HOME.PATH;
      if (slug) {
        redirectUrl += `?category=${slug}`;
      }
      onRedirectTo(redirectUrl);
    } else {
      setCategoryActive(null)
      const sort = getSort(tabActive);
      getLeadingKeyStackByGroup({ page: 1, keyWord: '', ...sort });
      onRedirectTo(URL_PATH.HOME.PATH);
    }
  }; 
  const getRedirectLink = (link)=>{
    switch (link) {
      case URL_PATH?.MODEL_DETAIL.PATH:
        return `${URL_PATH?.MODEL_DETAIL.PATH}/${models[0]?.slug}`
      case URL_PATH.MY_COLLECTION.PATH:
      case URL_PATH.ASK_AI.PATH:
      return  !isAuthenticated ?  `${URL_PATH.JOIN_WITH_US.PATH}?${link.replace(/\//g, '')}`: link
      default:
        return link
    }
  }

  return (
    <div className="hidden lg:col-span-3 lg:block xl:col-span-2 mx-4">
      <nav aria-label="Sidebar" className="sticky top-4 divide-y divide-neutral-300">
        <div className="space-y-1 pb-8">
          {navigation.map((item) => {
           
            const current = path.replace(/\/:slug/g, '') === item.href || location.search.replace(/\?/g, '/') === item.href ;
            return (
              <Link
              reloadDocument
                key={item.name}
                to={getRedirectLink(item.href)}
                className={classNames(
                  current ? 'bg-neutral-900 text-neutral-100' : 'text-neutral-900 hover:bg-neutral-900 hover:text-neutral-100',
                  'group flex items-center p-3 text-sm font-normal rounded-xl hover:no-underline'
                )}
                aria-current={current ? 'page' : undefined}
                
              >
                <item.icon
                  className={classNames(
                    current ? 'bg-neutral-900' : 'text-neutral-900 group-hover:text-neutral-100',
                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
              </Link>
            )
          })}
        </div>
        {path === URL_PATH.HOME.PATH && (
          <div className="pt-10">
            <p className="px-2 text-sm font-medium text-neutral-500" id="communities-headline">
              Categories
            </p>
            <div className="mt-3 space-y-2" aria-labelledby="communities-headline">
              {initCategory && initCategory.map((category) => (
                <button
                  key={category.name}
                  type="button"
                  onClick={() => handleCategoryChange(category.id, category.slug)}
                  className={classNames(
                    categoryActive === category.id ? 'bg-neutral-900 text-neutral-100' : 'text-neutral-900 hover:bg-neutral-900 hover:text-neutral-100',
                    'w-full group flex items-center rounded-xl p-3 text-sm font-normal'
                  )}
                >
                  <span className="truncate">{category.name}</span>
                </button>
              ))}
            </div>
          </div>
        )}
      </nav>
    </div>
  )}

export default Sidebar;
