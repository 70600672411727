import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    color: '#FFFFFF!important',
    border: 'none!important',
    borderRadius: 25,
    height: 50,
    fontSize: 16,
    width: 'auto',
    backgroundColor: 'var(--primary)',
    outline: 'none !important',
    '&:hover': {
      backgroundColor: 'var(--primary)'
    },
    '&:disabled': {
      backgroundColor: `var(--primary)!important`
    },
    '& span': {
      'font-family': "'Nunito', sans-serif",
      'font-weight': 800
    }
  },
  progress: {
    marginLeft: 6
  }
}));

function ProgressContainedButton({
  progress = false,
  label,
  progressColor = '#FFFFFF',
  className = '',
  ...rest
}) {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      className={`${classes.button} ${className}`}
      {...rest}
    >
      {label}
      {progress && rest.disabled && (
        <CircularProgress
          size={28}
          thickness={5}
          style={{
            color: progressColor
          }}
          className={classes.progress}
        />
      )}
    </Button>
  );
}

export default ProgressContainedButton;
