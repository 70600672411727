import { useState } from 'react'

const useDialog = () => {
  const [item, setItem] = useState(null)

  const onClose = () => setItem(null)
  const onOpen = value => setItem(value)

  return [item, onClose, onOpen]
}

export default useDialog
