import { SignUpPage } from '../../pages/index';
import agent from '../../agent';
import { connect } from 'react-redux';
import { AUTH, COMMON } from '../../actions';

const mapDispatchToProps = dispatch => {
  return {
    onSignUp: values => {
      return dispatch({
        type: AUTH.SIGN_UP,
        payload: agent.Auth.signUp(values)
      });
    },
    onRedirectTo: to => {
      return dispatch({ type: COMMON.REDIRECT_TO, to: to });
    },
    onUnload: () => {
      return dispatch({ type: AUTH.PAGE_UNLOAD });
    }
  };
};

function mapStateToProps(state, ownProps) {
  return {
    ...state.auth,
    photos: state.common && state.common.photos,
    inProgressPhotos: state.common && state.common.inProgressPhotos
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpPage);
