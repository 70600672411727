import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  main: {
    position: 'absolute',
    right: -14,
    top: 'calc(50% - 12px)',
    zIndex: 2,
    color: '#CCCCCC',
    outline: 'none',
    padding: 0,
    '&:hover': {
      color: '#FF165E'
    },
    '& img': {
      height: 15
    }
  }
};

function SlideNext(props) {
  const { className, style, onClick, classes } = props;
  let display = ' d-block';
  if (className.includes('slick-disabled')) {
    display = ' d-none';
  }
  return (
    <a
      href="#"
      className={classNames('next-arrow', display, classes.main)}
      style={style}
      onClick={onClick}
    >
      <img src="/assets/img/question/next-icon.png" alt="Next" />
    </a>
  );
}

export default withStyles(styles)(SlideNext);
