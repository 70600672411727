import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { CloseDialogButton, Switch } from '../../components';
import { COMMON, PRICING, AUTH } from '../../actions';
import { ROLE } from '../../constants';
import agent from '../../agent';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    overflowY: 'visible',
    width: 570,
    margin: 20,
    marginTop: 50,
  }
}));

const PREMIUM_TYPE = {
  APP: {
    value: 'IN-APP',
    name: 'Mentalmodelhub IOS App'
  },
  WEB: {
    value: 'IN-WEB',
    name: 'Mentalmodelhub website'
  },
  SYSTEM: {
    value: 'IN-SYSTEM',
    name: 'SYSTEM'
  },
}

const getPurchasedOn = (type) => {
  const plan = Object.values(PREMIUM_TYPE).find(item => item.value === type);
  return plan?.name || PREMIUM_TYPE.WEB.name;
}

const PaymentDetailDialog = ({
  open,
  onClose,
  onOpen,
  fullScreen,
  currentUser,
  onOpenUpgradeDialog,
  onAnnounce,
  renewalSubcription,
  resetPricing,
  type,
  onGetCurrentUser
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (type === PRICING.RENEWAL_SUBCRIPTION) {
      if (!currentUser?.premium?.cancel_at) {
        onClose();
        onAnnounce({
          message: (
            <p className="name">
              Done. You can always switch Auto-renewal back on in your settings at any time.
            </p>
          ),
          onClose: resetPricing,
          className: 'cancelled-subscription-dialog',
        });
      }
      onGetCurrentUser();
    }
  }, [type]);

  const handleOffRenewal = () => {
    onClose();
    onAnnounce({
      message: (
        <p className="name">
          Wait! Are you sure you want to switch off
          Mentalmodelhub Premium Auto-renewal?
        </p>
      ),
      label: `PROCEED`,
      onOk: () => renewalSubcription(false),
      onCancel: onOpen,
      className: 'cancel-subscription-dialog'
    });
  };

  const handleClickUpgrade = event => {
    event.preventDefault();
    if (onOpenUpgradeDialog) {
      onOpenUpgradeDialog();
      onClose();
    }
  };

  const handleAutoRenewal = (e) => {
    const value = e.target.checked
    if (value) {
      renewalSubcription(value);
      return;
    }
    handleOffRenewal();
  };

  const renderContent = () => {
    if (!currentUser) {
      return null;
    }
    const { premium, role } = currentUser
    const expiresDate = premium?.expires_date ? dayjs(premium.expires_date).format('DD MMMM YYYY') : ''

    if (role?.name === ROLE.TRIAL_PREMIUM.value) {
      return (
        <div className="trial-plan">
          <div className="item-sub">
            <p className="title">Subscription Plan:</p>
            <p className="value">14 Day Premium Trial</p>
          </div>
          <div className="item-sub">
            <p className="title">This plan expires on:</p>
            <p className="value">{expiresDate}</p>
          </div>
          <Link href="#" onClick={handleClickUpgrade}>
            Upgrade Subscription
          </Link>
        </div>
      )
    }

    // Premium
    if (role?.name === ROLE.PREMIUM.value) {
      const checked = !premium?.cancel_at;
      const isApp = premium?.type === PREMIUM_TYPE.APP.value
      return (
        <div className="premium-plan">
          <div className="item-sub">
            <p className="title">Subscription Plan:</p>
            <p className="value">Premium</p>
          </div>
          <div className="item-sub">
            <p className="title">Auto-renewal date:</p>
            <p className="value">{expiresDate}</p>
          </div>
          {/* <div className="item-sub">
            <p className="title">Plan purchased on:</p>
            <p className="value">{type}</p>
          </div> */}
          <div className="switch-renewal">
            <Switch checked={checked} onChange={handleAutoRenewal} disabled={isApp} />
            <span className="renew-label">
              {`Auto-renewal is ${checked ? 'on' : 'off'}`}
            </span>
          </div>
          <p className="note">
            {isApp ? 'Your subscription was purchased through the App Store. To manage auto-renewal settings refer to Subscription Terms in your Mentalmodelhub IOS app.' :
              checked ?
                'Mentalmodelhub Premium will automatically renew on the date specified above.' :
                'Switch on Auto-renewal to ensure uninterrupted access to Mentalmodelhub Premium.'}
          </p>
        </div>
      )
    }

    // Free
    if (role?.name === ROLE.FREE.value) {
      return (
        <div className="free-plan">
          <div className="item-sub">
            <p className="title">Subscription Plan</p>
            <p className="value">Free</p>
          </div>
          <Link href="#" onClick={handleClickUpgrade}>
            Upgrade Subscription
          </Link>
        </div>
      )
    }
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth="md"
      scroll="paper"
      fullScreen={fullScreen}
      className="payment-detail-dialog"
    >
      {fullScreen || <CloseDialogButton onClick={onClose} />}
      <DialogContent className="body-dialog">
        <h2>MY SUBSCRIPTION</h2>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.common?.currentUser,
    type: state.pricing?.type,
  };
}

const mapDispatchToProps = dispatch => ({
    onAnnounce: (data) => dispatch({ type: COMMON.ANNOUNCE, data }),
    resetPricing: () => dispatch({ type: PRICING.RESET }),
    renewalSubcription: () => dispatch({
        type: PRICING.RENEWAL_SUBCRIPTION,
        payload: agent.Pricing.renewalSubcription()
      }),
    onGetCurrentUser: () => dispatch({
        type: AUTH.GET_CURRENT,
        payload: agent.Auth.getCurrentUser()
      }),
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDetailDialog);
